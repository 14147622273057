import { endpoints } from "../configs/documentManagementEndPoints";
import Api from "./apiCallsHandler";
import Axios from "axios";
import { ERROR_CODES } from "../configs/constants/errorCode";
import URL from "../configs/connection";
import { createApplicationsHistory } from "./applicationHistoryApi";

const api = Api.getApi();

const productDocumentFileDataUrl = `${URL.REACT_APP_BACKEND_HOST}/document-management/get-application-document-details`;
const inspectionDocumentFileData = `${URL.REACT_APP_BACKEND_HOST}/los/get-inspection-document`;
const productDocumentFileHistoryDataUrl = `${URL.REACT_APP_BACKEND_HOST}/document-management/get-application-document-detail-history`;
const productDocumentStatusUpdateUrl = `${URL.REACT_APP_BACKEND_HOST}/document-management/application-document-status-change`;
const disbursementDocumentFileDataUrl = `${URL.REACT_APP_BACKEND_HOST}/los/get-disbursement-document-file`;

export const createDocumentType = (data) => {
  return api.post(endpoints.documentType, data);
};

export const createProductDocumentMapping = (data) => {
  return api.post(endpoints.productDocumentMapping, data);
};

export const uploadImageDropzone = (data, uniqueId, item) => {
  // console.log("==== data ===", data);
  let formData = new FormData();
  // console.log("===== imageName ====", item?.key?.get());
  formData.append("imageName", item?.key?.get());
  formData.append("uniqueId", uniqueId);
  formData.append("file", data);
  //   console.log("===== formData ====", formData);
  return Axios.post(`${URL.REACT_APP_DOCUMENT_UPLOAD}/document-upload/uploaddropzone`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getDocumentDetails = (folderId) => {
  return Axios.get(`${URL.REACT_APP_BACKEND_HOST}/document-management/documentDetails`, {
    headers: {
      folderid: folderId,
    },
  });
};

export const getDocumentTypes = () => {
  return Axios.get(`${URL.REACT_APP_BACKEND_HOST}/document-management/documentTypes`);
};

export const getDocumentType = (id) => {
  return Axios.get(`${URL.REACT_APP_BACKEND_HOST}/document-management/documentType`, {
    headers: {
      document_id: id,
    },
  });
};

export const updateDocumentType = (data) => {};

export const deleteDocumentType = (data) => {
  return Axios.patch(`${URL.REACT_APP_BACKEND_HOST}/document-management/deleteDocumentType`, data);
};

export const getProductDocumentMapping = (id) => {
  return Axios.get(`${URL.REACT_APP_BACKEND_HOST}/document-management/productDocumentapping`, {
    headers: {
      catalogKey: id,
    },
  });
};

export const uploadProductDocumentFile = async (uploadDataObject, file, setFileUploadProgress) => {
  try {
    let formData = new FormData();
    formData.append("applicationId", uploadDataObject?.applicationId);
    formData.append("applicantType", uploadDataObject?.applicantType);
    formData.append("participantId", uploadDataObject?.participantId);
    formData.append("documentId", uploadDataObject?.documentId);
    formData.append("uploadedBy", uploadDataObject?.uploadedBy);
    formData.append("pageNumber", uploadDataObject?.scheduleId);
    formData.append("isPrimary", uploadDataObject?.isPrimary ? "1" : "0");
    formData.append("individualId", uploadDataObject?.individualId);

    formData.append("file", file);

    const accessToken: any = sessionStorage.getItem("accessToken");

    const result = await Axios.post(
      `${URL.REACT_APP_DOCUMENT_UPLOAD}/document-upload/product-document-upload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `bearer ${accessToken}`,
        },
        onUploadProgress: (data) => {
          setFileUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      }
    );

    if (result?.data) {
      if (result?.data?.status === ERROR_CODES.success) {
        createApplicationsHistory(
          uploadDataObject?.applicationId,
          `Document upload - ${result?.data?.data?.name}`,
          null,
          uploadDataObject,
          "/product-document-upload"
        );

        return true;
      } else {
        //result?.data?.data
        return false;
      }
    }

    return false;
  } catch (error) {
    return false;
  }
};

export const getProductDocumentFileDataByDocumentParticipant = async (uploadDataObject: any, progress) => {
  try {
    // const data = await api.post(`${productDocumentFileDataUrl}`, {
    //   applicationId: uploadDataObject.applicationId,
    //   documentId: uploadDataObject.documentId,
    //   participantId: uploadDataObject.participantId,
    //   variant: uploadDataObject.variant,
    //   cribDocId: uploadDataObject.cribDocId,
    //   fileName: uploadDataObject.fileName
    // });

    let fileDetails = {
      applicationId: uploadDataObject.applicationId,
      documentId: uploadDataObject.documentId,
      participantId: uploadDataObject.participantId,
      variant: uploadDataObject.variant,
      cribDocId: uploadDataObject.cribDocId,
      priorApprovalDocId: uploadDataObject.priorApprovalDocId,
      fileName: uploadDataObject.fileName
    }
        
    const data = await api.get(`${productDocumentFileDataUrl}`, fileDetails, undefined, progress );

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getInspectionDocument = async (uploadDataObject: any, progress) => {
  try {

    let fileDetails = {
      docId: uploadDataObject?.docId
    }
        
    const data = await api.get(`${inspectionDocumentFileData}`, fileDetails, undefined, progress );

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getDisbursementDocumentFileData = async (uploadDataObject: any) => {
  try {    
    const data = await api.get(`${disbursementDocumentFileDataUrl}`, {
      applicationId: uploadDataObject.applicationId,
      productDocId: uploadDataObject.productDocId,
      disbIndex: uploadDataObject?.disbIndex,
      docIndex: uploadDataObject?.docIndex
    });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const uploadDisbursementDocument = async (uploadDataObject, file, setFileUploadProgress) => {

  try {
    let formData = new FormData();
    formData.append("createdBy", uploadDataObject?.createdBy);
    formData.append("applicationId", uploadDataObject?.applicationId);
    formData.append("documentId", uploadDataObject?.documentId);
    formData.append("uploadedBy", uploadDataObject?.uploadedBy);
    formData.append("docIndex", uploadDataObject?.docIndex);
    formData.append("productDocId", uploadDataObject?.productDocId);
    formData.append("pageNumber", uploadDataObject?.docIndex);
    formData.append("disbIndex", uploadDataObject?.disbIndex);

    formData.append("file", file);

    const accessToken: any = sessionStorage.getItem("accessToken");

    const result = await Axios.post(
      `${URL.REACT_APP_DOCUMENT_UPLOAD}/document-upload/disbursement-document-upload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `bearer ${accessToken}`,
        },
        onUploadProgress: (data) => {
          setFileUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      }
    );

    if (result?.data) {
      if (result?.data?.status === ERROR_CODES.success) {
        createApplicationsHistory(
          uploadDataObject?.applicationId,
          `Document upload - ${result?.data?.data?.name}`,
          null,
          uploadDataObject,
          "/disbursement-document-upload"
        );

        return true;
      } else {
        return false;
      }
    }

    return false;
  } catch (error) {
    return false;
  }
};

export const getProductDocumentFileHistoryDataByDocumentParticipant = async (
  uploadDataObject: any
) => {
  try {
    const data = await api.post(`${productDocumentFileHistoryDataUrl}`, {
      applicationId: uploadDataObject.applicationId,
      documentId: uploadDataObject.documentId,
      participantId: uploadDataObject.participantId,
      isPrimary: uploadDataObject?.isPrimary ? "1" : "0",
    });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const updateApplicationDocumentStatus = async (dataObject: any) => {
  try {
    const data = await api.patch(`${productDocumentStatusUpdateUrl}`, {
      applicationId: dataObject.applicationId,
      documentId: dataObject.documentId,
      applicantType: dataObject.applicantType,
      participantId: dataObject.participantId,
      isPrimary: dataObject?.isPrimary ? "1" : "0",
      status: dataObject.status,
      verifiedBy: dataObject.verifiedBy,
      fileName: dataObject.fileName
    });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const createNewDocumentType = async (payload: any) => {
  try {
    const data = await api.post(`${endpoints.addNewDocumentType}`, {
      ...payload,
    });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const updateAddedDocumentType = async (payload: any) => {
  try {
    const data = await api.put(`${endpoints.addNewDocumentType}`, {
      ...payload,
    });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const deleteDisbursementDocument = async (data) => {
  try {
    const res = await api.delete(`${URL.REACT_APP_BACKEND_HOST}/los/delete-disbursement-document`, data);
    return res;
  } catch (error) {}
};

export const getUploadedDisbursementDocuments = async (data) => {
  try {
    const res = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/get-disbursement-document`, data);
    return res;
  } catch (error) {}
};

export const getInspectionDocuments = async (data) => {
  try {
    const res = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/get-inspection-document-list`, data);
    return res?.data;
  } catch (error) {}
};

export const viewDisbursementDocumentHisroy = async (data) => {
  try {
    const res = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/view-disbursement-document-history`, {
      applicationId: data.applicationId,
      documentId: data.documentId,
    });
    return res;
  } catch (error) {}
};

export const updateDisbursementDocumentStatus = async (data) => {
  
  try {
    const res = await api.put(`${URL.REACT_APP_BACKEND_HOST}/los/update-disbursement-document-status`, {
      status: data.status,
      applicationId: data.applicationId,
      approvedBy: data.approvedBy,
      docIndex: data.docIndex,
      disbIndex: data.disbIndex,
      productDocId: data.productDocId
    });
    return res;
  } catch (error) {}
};
