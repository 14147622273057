import { Downgraded } from "@hookstate/core";
import moment from "moment";
import { LengthItemValidate } from "../../../components/OtherComponents/ApplicationForm/Sections/SecurityDetails/SecurityData/Helpers/LengthItemValidatedFn";
import { langDictionary } from "../../../configs/languageDictionaryEn";
import { validateFutureDateWithToday, validatePastDateWithToday } from "../../../configs/validations/validation-rules/validationRules";
import AppConfig from "../../AppConfig";
import { getCopy } from "../../util";
import { messageMappings } from "./messageMapping";
import { validatedFieldKey, validateWithStructure } from "./structureValidation";

interface EmploymentStructure {
  fields: Record<string, any>;
}
interface ErrorResult {
  status: number;
  message: string;
}
interface FieldConfig {
  dependancyDataKey?: string;
  visible?: number;
  mandatory?: number;
  key: string;
}

export const employmentDetails = (
  structure,
  state,
  isGuarantor = false,
  isJointBorrower = false,
  participantState,
  isSecuritySectionNext?,
  formStore?
) => {
  let { currentEmployment = [], previousEmployment = [] } = state.attach(Downgraded).get()
  let { type, sector, scheme } = formStore?.creditData.attach(Downgraded).get();
  const currentProductCatalog = [type, sector, scheme].join('-');
  const { employementDetails: { currentEmployement: currentEmployementConfig = {}, previousEmployement: previousEmployementConfig = {} } } = AppConfig.config[currentProductCatalog];
  let fields: { dateArrays: {}, current: any; previous: any; pastDateArray: string[]; futureDateArray: string[] } = { dateArrays: {}, current: {}, previous: {}, pastDateArray: [], futureDateArray: [] };
  let dependantFields: any = { current: {}, previous: {} };
  let mainMessage = { current: "Current", previous: "Previous" };
  let configMandator = { current: currentEmployementConfig, previous: previousEmployementConfig };
  let otherParticipants = (isGuarantor || isJointBorrower);


  (() => {
    // Extract employment structure from the structure object
    const employmentStructure = getCopy(structure.content[0].fields);

    // Organizes the field configuration based on various conditions
    const organizeFieldConfig = (
      config: { dependancyDataKey: string, mandatory: 1 | 0, visible: 1 | 0, pastDate: boolean, futureDate: boolean },
      currObj: any,
      type: string
    ) => {
      const {
        dependancyDataKey = "",
        mandatory = 0,
        visible = 0,
        pastDate = false,
        futureDate = false
      } = config;

      const dataKey = currObj.key || "";
      const pastDateArray: string[] = [];
      const futureDateArray: string[] = [];

      // Set the mandatory flag if the field is visible
      currObj.mandatory = mandatory;
      currObj.visible = visible;

      // Register the field in dependantFields if it has a dependency key and isn't already registered
      if (dependancyDataKey && !dependantFields[currObj.key]) {
        dependantFields[type] = {
          ...(dependantFields[type] || {}),
          [currObj.key]: { ...currObj, dependancyDataKey }
        };
      }

      // Collect past and future date fields
      if (pastDate) pastDateArray.push(dataKey);

      if (futureDate) futureDateArray.push(dataKey);

      return { data: currObj, pastArray: pastDateArray, futureArray: futureDateArray };
    }

    // Process each field in the employment structure
    fields = Object.entries(employmentStructure).reduce(
      (
        fieldItems: { current: any, previous: any, pastDateArray: string[], futureDateArray: string[], dateArrays: {} },
        [key, currObj]: [string, any]
      ) => {

        // If the field doesn't have configuration, add it to both current and previous
        if (!currObj?.fieldConfig) {
          fieldItems.current[key] = currObj;
          fieldItems.previous[key] = currObj;
          return fieldItems;
        }

        const { current, previous } = currObj.fieldConfig;

        // Organize current and previous configurations
        const { data: currData, pastArray: currPastArray, futureArray: currFutureArray } = getCopy(organizeFieldConfig(current, currObj, "current"));

        fieldItems.current[key] = currData;

        const { data: prevData, pastArray: prevPastArray, futureArray: prevFutureArray } = getCopy(organizeFieldConfig(previous, currObj, "previous"));
        // Update the fieldItems with organized data and date arrays
        fieldItems.previous[key] = prevData;

        fieldItems.dateArrays = {
          current: {
            futureDateArray: [...(fieldItems.dateArrays["current"]?.futureDateArray || []), ...currFutureArray],
            pastDateArray: [...(fieldItems.dateArrays["current"]?.pastDateArray || []), ...currPastArray]
          },
          previous: {
            futureDateArray: [...(fieldItems.dateArrays?.["previous"]?.futureDateArray || []), ...prevFutureArray],
            pastDateArray: [...(fieldItems.dateArrays?.["previous"]?.pastDateArray || []), ...prevPastArray]
          }
        }
        return fieldItems;
      }, { current: {}, previous: {}, pastDateArray: [], futureDateArray: [], dateArrays: {} });
  })();

  const ValidationCollector = (array: any[], typeEmp: string): string[] => {
    return array.reduce((errorArray: string[], emp, index) => {

      if (
        (typeEmp === 'current' && emp.employmentStatus === 2) ||
        (!emp.employmentStatus && (!configMandator?.[typeEmp]?.mandatory || otherParticipants))
      ) {
        return errorArray;
      }

      let preparedErrorMessages: string[] = [];
      const employmentStructure: EmploymentStructure = { ...structure.content[0] };
      employmentStructure.fields = fields?.[typeEmp] || {};

      // Validate with the employment structure
      let validationErrors = validateWithStructure(employmentStructure, emp);
      // Dependency validation management
      if (dependantFields?.[typeEmp]) {
        const dependencyErrors = Object.entries(dependantFields[typeEmp]).reduce((errorArray: string[], [key, config]: any) => {
          const { dependancyDataKey = "", visible = 0, mandatory = 0 } = config;

          if (errorArray.includes(key)) return errorArray;

          if (dependancyDataKey === "permenantEmployment") {
            if (emp[dependancyDataKey] && key === "permanentDate") {
              const result = validatedFieldKey(emp[key], key);
              return result && visible ? [...errorArray, result] : errorArray;
            }

            if (!emp[dependancyDataKey] && key === "natureOfEmployment") {
              const result = validatedFieldKey(emp[key], key);
              return result && visible ? [...errorArray, result] : errorArray;
            }
            return errorArray;
          }

          const result = validatedFieldKey(emp[key], key);
          return result && visible && mandatory ? [...errorArray, result] : errorArray;
        }, []);
        validationErrors = [...validationErrors, ...dependencyErrors];
      }

      // Past date validation
      if ((fields.dateArrays?.[typeEmp]?.pastDateArray || []).length) {
        const pastDateErrors = fields.dateArrays?.[typeEmp]?.pastDateArray.reduce((errorArray: string[], key) => {

          if (!emp?.[key]) return errorArray;

          const date = (key === 'startDate' && typeEmp === 'previous') ? moment(emp?.[key], "MM/DD/YYYY").format("DD/MM/YYYY") : emp?.[key];

          const validationResult: null | ErrorResult = validatePastDateWithToday(langDictionary?.[key], date);
          return validationResult ? [...errorArray, validationResult.message] : errorArray;
        }, []);
        preparedErrorMessages = [...preparedErrorMessages, ...pastDateErrors];
      }

      // Future date validation
      if ((fields.dateArrays?.[typeEmp]?.futureDateArray || []).length) {
        const futureDateErrors = fields.dateArrays?.[typeEmp]?.futureDateArray.reduce((errorArray: string[], key) => {

          if (!emp?.[key]) return errorArray;

          const validationResult: null | ErrorResult = validateFutureDateWithToday(langDictionary?.[key], emp?.[key]);
          return validationResult ? [...errorArray, validationResult.message] : errorArray;
        }, []);
        preparedErrorMessages = [...preparedErrorMessages, ...futureDateErrors];
      }

      //Employement Contact custom Validations
      let empErrorMessges = LengthItemValidate.validatedArray(emp.contactTypes).reduce((errorArray, currContact, index) => {
        if (!currContact.empContactType) return errorArray;

        let array: string[] = [];

        if (!currContact.empContactNumber) array.push("empContactNumber");
        if (!currContact.empContactName) array.push("empContactName");

        if (currContact.empEmailType && !currContact.empEmailAddress) array.push("empEmailAddress");

        errorArray = [...errorArray, ...messageMappings(array)]

        if (currContact.empEmailType && currContact.empEmailAddress && !validateEmail(currContact.empEmailAddress)) {
          errorArray.push('Enter a valid Email Address')
        }
        return errorArray;
      }, []);

      // Combine all error messages
      preparedErrorMessages = removeDuplicates([...messageMappings(validationErrors), ...preparedErrorMessages, ...empErrorMessges]);
      const mainMessages = !errorArray.length ? `${mainMessage?.[typeEmp]} Employment: ${index + 1}` : `\n${mainMessage?.[typeEmp]} Employment: ${index + 1}`;

      return preparedErrorMessages.length ? [...errorArray, mainMessages, ...preparedErrorMessages] : errorArray;
    }, []);
  }

  //CURRENT EMPLOYEMENT VALIDATION COLLECTOR
  let curreEmpErrorArray = ValidationCollector(currentEmployment, 'current');

  //PREVIOUS EMPLOYEMENT VALIDATION COLLECTOR
  let prevEmpErrorArray = ValidationCollector(previousEmployment, 'previous');

  return curreEmpErrorArray.length ? [...curreEmpErrorArray, '\n', ...prevEmpErrorArray] : prevEmpErrorArray;
};

const filterErrorArrayKeysForMicroProducts = (erros) => {
  let filteredErros: any = [];
  const includeDateValidations: any = [
    "futureDate_startDate",
    "futureDate_permanentDate",
    "pastDate_pensionableDate",
    "previous_futureDate_startDate",
    "previous_futureDate_endDate",
    "previous_futureDate_pensionableDate",
  ];
  if (erros.length > 0) {
    filteredErros = erros.filter((errorKey) => {
      return includeDateValidations.includes(errorKey);
    });
  }

  return filteredErros;
};

export const removeDuplicates = (arr) => {
  return arr.filter((item, index) => arr.indexOf(item) === index);
};

const checkProductWiseMandatory = (isMandatory, employmentStatus) => {
  if (isMandatory === 1) {
    if (!employmentStatus) {
      return true;
    } else return false;
  }
  return false;
};

function validateEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}
