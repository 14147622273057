import { Downgraded, useHookstate, useState } from "@hookstate/core";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import { Divider, Grid, IconButton, Paper, Stack, Tooltip, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo } from "react";
import { loanRequirementContributions } from "../../../../../../../../configs/constants/contants";
import { userDetails } from "../../../../../../../../configs/mainStore";
import { deleteLoanRequirementComment, getAnalysisLoanRequirementCommentsData, getAnalysisOfLoanRequirementDetails, saveAppraisalData, saveLoanRequirementCommentData, saveMicroLoanRequirements } from "../../../../../../../../services/creditFileApiCall";
import { select_basic } from "../../../../../../../../styles";
import isProductOf, { ProductType } from "../../../../../../../../utility/AthamaruProductBusinessDetails/AthamaruComponentsValidator";
import { dateFormatWithTimeChange } from "../../../../../../../../utility/fomatters";
import { userName } from "../../../../../../../../utility/helpers/creditFileUtility";
import { responseSuccessCode } from "../../../../../../../../utility/other";
import { getLowerCaseString } from "../../../../../../../../utility/util";
import { ButtonComponent } from "../../../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { SelectComponent } from "../../../../../../../InputComponents/SelectComponent/SelectComponent";
import { closeGlobalModal, openGlobalModal } from "../../../../../../GlobalModal/GlobalModal";
import { addToaster, toast } from "../../../../../../GlobalToast";
import { InputGrid } from "../../../../../../InputGrid/InputGrid";
import { CircularProgressComponent } from "../../../../../../ProgressComponent/ProgressComponent";
import { disableAccess } from "../../../../UserCreditComponents/ApplicationMandatoryValidations/isSecurityDocUploadApprovedPermitted";
import { getInspectionBusinesData } from "../../../Micro/Api/AppraisalApi";
import AnalysisLoanRequirement from "../../../Micro/Components/AnalysisOfLoanRequirement/AnalysisLoanRequirement";
import { RetuenedArray, setStateFromBusinessDataAnalysisOfLoanRequirement } from "../../../Micro/Components/AnalysisOfLoanRequirement/Helpers/SetStateFromBusinessData/SetStateFromBusinessData";
import { AddLoanRequirementForm } from "./AddLoanRequirementForm";

const AnalysisOfLoanRequirement = ({ data, applicationId, isMainContactDetails = true, mainTabData = { isAppraisal: false }, isRawVersion, innerComponentData, tabsToEdit }) => {
  const [loanRequirementList, setLoanRequirementList] = React.useState<any>([]);
  const loading: any = useState({ details: true, saving: false, delete: -1 });
  const { userId }: any = userDetails.attach(Downgraded).get();
  //New Commercial Variables & Functions
  const [filteredComentList, setFilteredComentList]: any[] = React.useState([]);
  const commercial = isProductOf({ types: [ProductType.COMMERCIAL] });

  const compareByCommentId = (a, b) => {
    return b.id - a.id;
  };

  useEffect(() => {
    let isMounted = true;
    (async () => {
      if (isMounted) {
        loading.details.set(true);
      }
      const [result = {}, resultComments = {}] = await Promise.all([getAnalysisOfLoanRequirementDetails({ applicationId, }), getAnalysisLoanRequirementCommentsData({ applicationId })])

      let { data, comments } = result;

      if (result instanceof Array) {
        data = result?.[0];
      }

      if (result.section === "analysis_loan_applicant") {
        if (comments && comments?.[0]?.comment) {
          let commentsArr: any[] = Object.values(comments);
          commentsArr.sort(compareByCommentId);
          if (isMounted) {
            setFilteredComentList(commentsArr);
          }

          if (commercial) {
            if (isMounted) {
              setLoanRequirementList({});
            }
          } else {
            if (isMounted) {
              setLoanRequirementList([]);
            }
          }
        }
        if (isMounted) {
          loading.details.set(false);
        }

        return;
      }

      if (data) {
        if (commercial) {
          if (isMounted) {
            setLoanRequirementList(data ?? {});
          }
        } else {
          if (isMounted) {
            setLoanRequirementList(data);
          }
        }
      }

      if (comments) {
        let commentsArr: any[] = Object.values(comments);
        commentsArr.sort(compareByCommentId);
        if (isMounted) {
          setFilteredComentList(commentsArr);
        }
      }

      if (isMounted) {
        loading.details.set(false);
      }
    })();

    return () => {
      isMounted = false;
    };
  }, []);

  const addNewRequirementHandler = (selectedRequirement, analysisOfLoanRequirementDetails) => {
    if (!selectedRequirement) {
      addToaster({
        status: "error",
        title: "Empty",
        message: "Please select a contribution",
      });
    } else {
      let existingRowItems: any = analysisOfLoanRequirementDetails.attach(Downgraded).get();
      const dataKeys = Object.keys(existingRowItems);
      const selectedContribution: any = loanRequirementContributions.find((obj) => obj.id == selectedRequirement) || {};
      let lastObjectIndex: any = dataKeys?.length > 0 ? dataKeys[dataKeys.length - 1] : 0;

      let defaultItemObj = {
        createdBy: userId,
        data: {
          description: selectedContribution?.name,
          existingEquality: 0,
          existingBank: 0,
          proposedEquality: 0,
          proposedBank: 0,
          totalCost: 0,
          existingEqualityContribution: 0,
          existingBankContribution: 0,
          proposedEqualityContribution: 0,
          proposedBankContribution: 0,
        },
      };

      if (dataKeys?.length < 1) {
        existingRowItems[lastObjectIndex] = defaultItemObj;
      } else {
        existingRowItems[parseInt(lastObjectIndex) + 1] = defaultItemObj;
      }

      analysisOfLoanRequirementDetails.set(existingRowItems);
    }
  };

  const callBackOnAddComment = async (comment) => {
    const obj = {
      applicationId: applicationId,
      comment: comment ? comment : "",
      createdAt: new Date().toISOString(),
    };

    const alert = {
      status: "error",
      message: "Comment cannot be empty",
    };

    try {
      if (!comment) {
        throw new Error("Comment cannot be empty");
      }

      closeGlobalModal();
      setFilteredComentList([{ comment, id: -2, createdBy: userName(userId) }, ...filteredComentList]);
      let {
        status,
        data: { comments },
      } = await saveLoanRequirementCommentData(obj);
      if (status == 1) {
        alert.status = "success";
        alert.message = "Comment saved successfully.";

        setFilteredComentList(Object.values(comments).sort(compareByCommentId));
      }
    } catch (error: any) {
      alert.message = error.message;
      setFilteredComentList(filteredComentList.splice(0, 1));
    } finally {
      toast(alert);
    }
  };

  const deleteCommentDispatcher = (id) => {
    if (id != null) {
      openGlobalModal({
        modalSize: "sm",
        title: "Are you sure ?",
        bodyId: "micro-appraisal-delete-confirm",
        close: false,
        modalParams: {
          textMessage: "Comment will be deleted.",
          callBackOnAction: () => commentConfirmDelete(id),
        },
      });
    } else {
      addToaster({
        status: "error",
        title: "Comment cannot be empty.",
        message: "Comment id is required to proceed the deletion",
      });
    }
  };

  const commentConfirmDelete = async (id) => {
    let obj = {
      id: id,
      applicationId: applicationId,
    };

    const alert = {
      status: "success",
      message: "Comment deleted successfully!",
    };

    try {
      closeGlobalModal();

      loading.delete.set(id);
      let { status } = await deleteLoanRequirementComment(obj);

      if (status === 1) {
        setFilteredComentList(filteredComentList.filter((item) => item.id !== id));
      }
    } catch (error) {
      alert.status = "error";
      alert.message = "Failed to delete comment.";
    } finally {
      toast(alert);
      loading.delete.set(-1);
    }
  };

  const addCommentSection = () => {
    openGlobalModal({
      modalSize: "md",
      title: "",
      bodyId: "requirement-comment",
      close: false,
      modalParams: {
        callBackOnSave: callBackOnAddComment,
      },
    });
  };

  const changeRequirementData = async (items, userId) => {
    let tempObj = { ...items, userId, created: new Date() };
    await onSaveData([tempObj]);
  };

  const onSaveData = async (tempReqList) => {
    const alert = {
      status: "success",
      message: "",
    };

    try {
      loading.saving.set(true);
      const res: any = await saveAppraisalData({
        section: "analysis_loan_requirement",
        applicationId: applicationId,
        data: tempReqList,
      });

      alert.message = res?.status === responseSuccessCode ? res?.data?.msg : "Failed to proceed.";
    } catch (error: any) {
      alert.status = "error";
      alert.message = error?.message ?? "Failed to proceed.";
    } finally {
      toast(alert);
      loading.saving.set(false);
    }
  };

  const checkPrivilegeStatus = disableAccess({
    isTabInEditString: tabsToEdit.includes(innerComponentData?.id),
  });

  const CommercialContent = ({ loanRequirement, disableStatus }) => {
    const [selectedRequirement, setSelectedRequirement] = React.useState(1);
    const analysisOfLoanRequirementDetails = useState(loanRequirement);
    const isLoading = useHookstate<boolean>(true)

    const getFormReady = async () => {
      let rawData: Record<number, RetuenedArray> = {};

      try {
        if (Object.values(loanRequirement).length) {
          rawData = loanRequirement;
        } else {
          let response = await getInspectionBusinesData(applicationId);
          rawData = setStateFromBusinessDataAnalysisOfLoanRequirement({ busineeData: response });
        }

      } catch (error) {
      } finally {
        analysisOfLoanRequirementDetails.set(rawData);
        isLoading.set(false)
      }
    }

    useEffect(() => {
      getFormReady();
    }, [loanRequirement])

    const onDeleteRequirementItemRow = useCallback(
      (key: any) => {
        closeGlobalModal();
        let existingRowItems: any = analysisOfLoanRequirementDetails.attach(Downgraded).get();
        const dataKeys = Object.keys(existingRowItems);
        const newRowItems: any = {};

        dataKeys.forEach((element) => {
          if (element != key) {
            newRowItems[element] = existingRowItems[element];
          }
        });
        analysisOfLoanRequirementDetails.set(newRowItems);
        saveMicroLoanRequirementData("delete");
      },
      [analysisOfLoanRequirementDetails]
    );

    const saveMicroLoanRequirementData = async (actionType = "") => {
      const alert = {
        status: "error",
        message: `Something wrong with data saving!`,
      };

      try {
        let existingRowItems: any = analysisOfLoanRequirementDetails.attach(Downgraded).get();
        const dataKeys: any = Object.keys(existingRowItems);

        let obj = {
          applicationId: applicationId,
          data: {},
          total: 0,
          totalExistingEqualityContribution: 0,
          totalExistingBankContribution: 0,
          totalProposedEqualityContribution: 0,
          totalProposedBankContribution: 0,
        };

        if (actionType == "submit") {
          if (dataKeys.length < 1) {
            throw new Error(`Please add requirement items before saving!`);
          } else {
            let calculatedData = calculateCostData(dataKeys, existingRowItems) || {};

            if (Object.keys(calculatedData).length > 0) {
              obj.total = calculatedData?.total;
              obj.totalExistingEqualityContribution = calculatedData?.totalExistingEqualityContribution;
              obj.totalExistingBankContribution = calculatedData?.totalExistingBankContribution;
              obj.totalProposedEqualityContribution = calculatedData?.totalProposedEqualityContribution;
              obj.totalProposedBankContribution = calculatedData?.totalProposedBankContribution;
              obj.data = calculatedData?.data;
            }
          }
        } else {
          let calculatedData = calculateCostData(dataKeys, existingRowItems) || {};

          if (Object.keys(calculatedData).length > 0) {
            obj.total = calculatedData?.total;
            obj.totalExistingEqualityContribution = calculatedData?.totalExistingEqualityContribution;
            obj.totalExistingBankContribution = calculatedData?.totalExistingBankContribution;
            obj.totalProposedEqualityContribution = calculatedData?.totalProposedEqualityContribution;
            obj.totalProposedBankContribution = calculatedData?.totalProposedBankContribution;
            obj.data = calculatedData?.data;
          }
        }

        const { status, data }: any = await saveMicroLoanRequirements(obj);

        if (status == 1) {
          alert.status = "success";
          alert.message = "Loan requirements saved successfully!";
          analysisOfLoanRequirementDetails?.set(data?.data ?? {});
        }
      } catch (error: any) {
        alert.message = error.message;
      } finally {
        toast(alert);
      }
    };

    const calculateCostData = (dataKeys, existingRowItems) => {
      let obj: any = {
        total: 0,
        totalExistingEqualityContribution: 0,
        totalExistingBankContribution: 0,
        totalProposedEqualityContribution: 0,
        totalProposedBankContribution: 0,
        data: {},
      };

      let total: any = 0;
      let total_ExistingEquality: any = 0;
      let total_ExistingBank: any = 0;
      let total_ProposedEquality: any = 0;
      let total_ProposedBank: any = 0;

      dataKeys.map((key) => {
        total += parseFloat(existingRowItems[key].data?.totalCost);
        total_ExistingEquality += parseFloat(existingRowItems[key].data?.existingEquality);
        total_ExistingBank += parseFloat(existingRowItems[key].data?.existingBank);
        total_ProposedEquality += parseFloat(existingRowItems[key].data?.proposedEquality);
        total_ProposedBank += parseFloat(existingRowItems[key].data?.proposedBank);
        obj["data"][key] = {
          id: key,
          data: existingRowItems[key].data,
        };
      });

      obj.total = total;
      obj.totalExistingEqualityContribution = parseFloat(((total_ExistingEquality / total) * 100).toFixed(2));
      obj.totalExistingBankContribution = parseFloat(((total_ExistingBank / total) * 100).toFixed(2));
      obj.totalProposedEqualityContribution = parseFloat(((total_ProposedEquality / total) * 100).toFixed(2));
      obj.totalProposedBankContribution = parseFloat(((total_ProposedBank / total) * 100).toFixed(2));

      return obj;
    };
    const sanitizedLoanRequirementContributions = useMemo(() => {
      let formData: any = analysisOfLoanRequirementDetails.attach(Downgraded).get();

      let alreadySelectedItems = Object.values(formData).map(({ data }: any) => {

        return getLowerCaseString(data.description)
      });

      return loanRequirementContributions.reduce((sanitizedArr: { id: number; name: string }[], { id, name }) => {
        const overrideName: string = getLowerCaseString(name);
        if (alreadySelectedItems.includes(overrideName)) {
          return sanitizedArr
        }

        return [...sanitizedArr, { id, name }]
      }, [])
    }, [selectedRequirement, analysisOfLoanRequirementDetails.get()])

    if (isLoading.get()) return <CircularProgressComponent size={30} />

    return (
      <>
        <Grid container pt={2} pr={1.5} pb={2} pl={1.5}>
          <Stack>
            <Stack direction={"row"} flex={1}>
              <InputGrid visibility={1} variant="fullWidth">
                <SelectComponent
                  sx={select_basic}
                  label={"Add contribution"}
                  value={selectedRequirement}
                  values={sanitizedLoanRequirementContributions}
                  onChange={(e) => {
                    setSelectedRequirement(e.target.value);
                  }}
                  defaultPlaceholder={true}
                  disabled={disableStatus}
                />
              </InputGrid>
              <ButtonComponent iconbtn children={<AddCircleOutlineIcon />} onClick={() => addNewRequirementHandler(selectedRequirement, analysisOfLoanRequirementDetails)} disabled={disableStatus} />
            </Stack>
          </Stack>
          <Grid item xs={12} pt={2}>
            {<AnalysisLoanRequirement creditData={data} applicationId={applicationId} assignLoanRequirementList={analysisOfLoanRequirementDetails} callBackOnDelete={() => onDeleteRequirementItemRow} disableStatus={disableStatus} />}
          </Grid>
          <Stack direction={"column"} flex={1}>
            <Stack direction={"row-reverse"} style={{ backgroundColor: "white" }} pb={1}>
              <ButtonComponent startIcon={<DoneIcon />} title="SUBMIT" variant="contained" onClick={() => saveMicroLoanRequirementData("submit")} loadingbtn={true} color="info" disabled={disableStatus} />
            </Stack>
            <Divider sx={{ marginTop: 2 }}></Divider>
            <Paper sx={{ backgroundColor: "white", position: "sticky", top: "0pt", zIndex: 100, marginTop: 2, marginBottom: 2 }}>
              <Stack direction={"row"} justifyContent={"space-between"} p={2}>
                <Typography color={"gray"}>Comments ({filteredComentList.length})</Typography>
                <ButtonComponent variant="outlined" startIcon={<AddCircleOutlineIcon />} onClick={() => addCommentSection()} title={"Add Comment"} disabled={disableStatus} />
              </Stack>
            </Paper>
            {filteredComentList.map((comment, index) => (
              <Paper sx={{ marginBottom: 2 }}>
                <Stack direction={"row"} flex={1} m={2}>
                  <Stack direction={"column"} flex={1}>
                    <Stack direction={"row"} spacing={1}>
                      <Typography variant="subtitle1">{comment?.comment}</Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                      <Stack direction={"row"} spacing={1}>
                        <Typography variant="caption" color={"gray"}>
                          {userName(comment?.createdBy)}
                        </Typography>
                        {comment?.createdAt && <Typography variant="caption">on</Typography>}
                        {comment?.createdAt && (
                          <Typography variant="caption" color={"gray"}>
                            {dateFormatWithTimeChange(comment?.createdAt)}
                          </Typography>
                        )}
                      </Stack>
                      {loading.delete.get() === comment?.id || comment.id === -2 ? (
                        <CircularProgressComponent size={20} />
                      ) : (
                        <Tooltip title="Delete">
                          <IconButton aria-label="delete" onClick={() => deleteCommentDispatcher(comment?.id)} disabled={disableStatus}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
              </Paper>
            ))}
          </Stack>
        </Grid>
      </>
    );
  };

  const RetailContent = () => {
    return (
      <Stack>
        <AddLoanRequirementForm changeRequirementData={changeRequirementData} checkPrivilegeStatus={checkPrivilegeStatus} loanRequirementList={loanRequirementList} />
      </Stack>
    );
  };

  const Header = () => {
    return (
      <Stack px={2} style={{ backgroundColor: "#e6f0fa", minHeight: "42pt", position: "sticky", zIndex: 100, top: "0px" }} justifyContent={"center"}>
        <Typography className="basic-font basic-font-color-bold">Analysis of the Loan Requirement</Typography>
      </Stack>
    );
  };

  if (loading.details.get()) {
    return (
      <Stack style={{ backgroundColor: "#fff", height: "calc(100vh - 120pt)", overflowY: "hidden" }}>
        <Header />
        <Stack justifyContent={"center"} alignItems={"center"} height={"100%"}>
          <CircularProgressComponent size={30} sx={{ left: "50%", top: "50%" }} />
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack style={{ backgroundColor: "#fff", height: "calc(100vh - 120pt)" }}>
      <Header />
      {commercial ? <CommercialContent loanRequirement={loanRequirementList} disableStatus={checkPrivilegeStatus} /> : <RetailContent />}
    </Stack>
  );
};

export default AnalysisOfLoanRequirement;
