import { Downgraded } from "@hookstate/core";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Stack, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC, useEffect, useState } from "react";
import {
  DocumentSectionComponentPropsTypes,
} from "../../../configs/interfaces";
import { userDetails } from "../../../configs/mainStore";
import { documentKeys } from "../../../configs/revokeDocumentKeys";
import { submitBurrowerUpdate } from "../../../services/apiCalls";
import { panelExpandIconBox } from "../../../styles";
import { ButtonComponent } from "../../InputComponents/ButtonComponent/ButtonComponent";
import { addToaster } from "../GlobalToast";
import { NavigationButtons } from "../NavigationButtons/NavigationButtons";

export const DocumentUploadSectionWrapper: FC<DocumentSectionComponentPropsTypes> = ({
  title,
  children,
  onBack,
  onNext,
  noNext,
  noBack,
  keyCode,
  loading,
  onExpand,
  noBtn,
  subTxt,
  status,
  icon = true,
  totalFiles,
  numOfUploadedFiles,
  numOfMandatory,
  numOfUploadedMandatoryFiles,
  documentComponentId,
  personalData,
  creditFileData,
  onSyncSuccess,
  onSync,
  sectionLoading,
  disableAccordingToTab = false,
  isLoggedInUserNotTheAssignee = false,
  documentTypes,
  documentTypeId,
  onClickCreate,
}) => {
  const [Visibility, setVisibility] = useState(false);
  const [syncButton, setSyncButton] = useState(false);
  const [BtnLoading, setBtnLoading] = useState(false);
  const [revokeBtnPermission, setRevokeBtnPermission] = useState(false);

  const handleExpand = () => {
    onExpand && onExpand();
    setVisibility(true);
  };

  const crediData = creditFileData?.formData?.creditData;

  const { userId } = userDetails.attach(Downgraded).get();

  useEffect(() => {
    if (documentComponentId === "joint_borrower_documents") {
      if (personalData?.individualId === 0) {
        setSyncButton(true);
      } else {
        setSyncButton(false);
      }
    } else {
      setSyncButton(false);
    }
    setBtnLoading(sectionLoading);
  }, [sectionLoading]);

  useEffect(() => {
    if (documentTypes?.length > 0) {
      let setRevokePermissions = false;
      documentTypes.map((item: any) => {
        setRevokePermissions = documentKeys.includes(item?.documentKey) === true ? true : false;
      });

      setRevokeBtnPermission(setRevokePermissions);
    }
  }, [documentTypes]);

  const onSubmitSync = (e) => {
    onSync && onSync(e);
    setSyncButton(sectionLoading);
  };

  const onClick = async () => {
    setBtnLoading(true);
    onSubmitSync(true);
    const response = await submitBurrowerUpdate(
      creditFileData?.requestId,
      crediData?.type,
      crediData?.sector,
      crediData?.scheme,
      crediData?.applicantType,
      userId,
      "I",
      "jointBorrowerData"
    );
    onSubmitSync(false);
    setBtnLoading(false);

    if (response) {
      if (response?.status === 1) {
        onSyncSuccess(true);
        addToaster({
          status: "success",
          title: "Joint Borrower Updated",
          message: response?.data,
        });
      } else {
        addToaster({
          status: "error",
          title: "Joint Borrower Update Failed",
          message: response?.data,
        });
      }
    }
  };



  return (
    <Stack
      className={`${Visibility ? "template__panel--show" : "template__panel--hide"}`}
      key={keyCode}>
      <Stack direction={"row"} spacing={1}>
        <Stack>
          {numOfUploadedMandatoryFiles === numOfMandatory ? (
            <CheckCircleIcon sx={{ color: "#00AB71" }} />
          ) : (
            <ErrorIcon sx={{ color: "#ffdd00" }} />
          )}
        </Stack>
        <Stack flex={1} alignItems={"flex"}>
          {title && <Typography variant="subtitle2" textAlign={"start"} color={"#444444"}> {Visibility ? title : `${title.toString().slice(0, 40)}...`} </Typography>}
          {subTxt && <Typography variant="caption" color={"gray"} textAlign={"start"}> {subTxt} </Typography>}
        </Stack>

        <Stack direction={"row"} spacing={1}>
          <Stack direction={"row"} spacing={1}>
            <Typography variant="caption" className={totalFiles === numOfUploadedFiles ? "green-font" : "yellow-font"}>Total: {numOfUploadedFiles} / {totalFiles}</Typography>
            <Typography variant="caption" className={numOfUploadedMandatoryFiles === numOfMandatory ? "green-font" : "yellow-font"}>Mandatory: {numOfUploadedMandatoryFiles} / {numOfMandatory}</Typography>
          </Stack>
          <Stack direction={"row"}>
            <Box sx={panelExpandIconBox}>
              {syncButton ? (
                <ButtonComponent
                  startIcon={<RestartAltIcon />}
                  onClick={onClick}
                  title={"Sync"}
                  variant="contained"
                  disabled={BtnLoading || disableAccordingToTab || isLoggedInUserNotTheAssignee}
                  loadingbtn={true}
                  loading={BtnLoading}
                />
              ) : Visibility ? (
                <Tooltip title="Collapse section">
                  <KeyboardDoubleArrowUpIcon
                    className="basic-font-color"
                    onClick={() => setVisibility(false)}
                    fontSize="medium"
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Expand section">
                  <KeyboardDoubleArrowDownIcon
                    className="basic-font-color"
                    onClick={handleExpand}
                    fontSize="medium"
                  />
                </Tooltip>
              )}
            </Box>
          </Stack>
        </Stack>
      </Stack>

      {/* //Section */}
      <Stack className="mainSection_content--background">{children}</Stack>

      {/* //Navigation Buttons */}
      {!noBtn && (
        <Stack mt={2} direction="row-reverse" sx={{ justifyContent: 'space-between' }}>
          {/* No back button appear */}
          {noBack && (
            <Box sx={{ justifyContent: 'flex-end' }}>
              <NavigationButtons
                onNext={onNext}
                noBack
                loading={loading}
                disabled={disableAccordingToTab}
              />
            </Box>
          )}
          {documentTypeId === 8 ?
            <Box>
              <ButtonComponent disabled={false} variant="contained" loadingbtn={false} loading={false} onClick={onClickCreate} title={"Add Documents"} />
            </Box>
            : <></>
          }
        </Stack>
      )}
    </Stack>
  );

  // return (
  //   <div
  //     className={`${Visibility ? "template__panel--show" : "template__panel--hide"}`}
  //     key={keyCode}>
  //     <Box display="flex" alignItems="center">
  //       <Grid container columns={12}>
  //         <Grid container item xs={0.5} sm={0.5} direction="column" justifyContent="center">
  //           {numOfUploadedMandatoryFiles === numOfMandatory ? (
  //             <CheckCircleIcon sx={{ color: "#00AB71" }} />
  //           ) : (
  //             <ErrorIcon sx={{ color: "#ffdd00" }} />
  //           )}
  //         </Grid>
  //         <Grid container item xs={3} sm={3} direction="column" justifyContent="center">
  //           <Box>
  //             <Box className="mainSection__heading--bold">{title}</Box>

  //             <Box className="mainSection__description">
  //               <small>{subTxt ? subTxt : "sample dummy text for testing"}</small>
  //             </Box>
  //           </Box>
  //         </Grid>
  //         <Grid container item xs={5.5} sm={5.5} direction="column" justifyContent="center">
  //           <Box>
  //             <Box
  //               className={`document-upload-wrapper-text ${totalFiles === numOfUploadedFiles ? "green-font" : "yellow-font"
  //                 }`}
  //               sx={{ textAlign: "right" }}>
  //               <small className="basic-font opacity-7 font-size-14">
  //                 Total: {numOfUploadedFiles} / {totalFiles}
  //               </small>
  //             </Box>
  //           </Box>
  //         </Grid>
  //         <Grid container item xs={1.5} sm={1.5} direction="column" justifyContent="center">
  //           <Box>
  //             <Box
  //               className={`document-upload-wrapper-text ${numOfUploadedMandatoryFiles === numOfMandatory ? "green-font" : "red-font"
  //                 }`}
  //               sx={{ textAlign: "right" }}>
  //               <small className="basic-font opacity-7 font-size-14">
  //                 Mandatory: {numOfUploadedMandatoryFiles} / {numOfMandatory}
  //               </small>
  //             </Box>
  //           </Box>
  //         </Grid>
  //         <Grid container item xs={1.5} sm={1.5} direction="column" justifyContent="center">
  //           <Box sx={panelExpandIconBox}>
  //             {syncButton ? (
  //               <ButtonComponent
  //                 startIcon={<RestartAltIcon />}
  //                 onClick={onClick}
  //                 title={"Sync"}
  //                 variant="contained"
  //                 disabled={BtnLoading || disableAccordingToTab || isLoggedInUserNotTheAssignee}
  //                 loadingbtn={true}
  //                 loading={BtnLoading}
  //               />
  //             ) : Visibility ? (
  //               <Tooltip title="Collapse section">
  //                 <KeyboardDoubleArrowUpIcon
  //                   className="basic-font-color"
  //                   onClick={() => setVisibility(false)}
  //                   fontSize="medium"
  //                 />
  //               </Tooltip>
  //             ) : (
  //               <Tooltip title="Expand section">
  //                 <KeyboardDoubleArrowDownIcon
  //                   className="basic-font-color"
  //                   onClick={handleExpand}
  //                   fontSize="medium"
  //                 />
  //               </Tooltip>
  //             )}
  //           </Box>
  //         </Grid>
  //       </Grid>
  //     </Box>

  //     {/* //Section */}
  //     <div className="mainSection_content--background">{children}</div>

  //     {/* //Navigation Buttons */}
  //     {!noBtn && (
  //       <Stack mt={2} direction="row-reverse" sx={{ justifyContent: 'space-between' }}>
  //         {/* No back button appear */}
  //         {noBack && (
  //           <Box sx={{ justifyContent: 'flex-end' }}>
  //             <NavigationButtons
  //               onNext={onNext}
  //               noBack
  //               loading={loading}
  //               disabled={disableAccordingToTab}
  //             />
  //           </Box>
  //         )}
  //         {documentTypeId === 8 ?
  //           <Box>
  //             <ButtonComponent disabled={false} variant="contained" loadingbtn={false} loading={false} onClick={onClickCreate} title={"Add Documents"} />
  //           </Box>
  //           : <></>
  //         }
  //       </Stack>
  //     )}
  //   </div>
  // );
};
