import { Downgraded, useHookstate } from "@hookstate/core";
import { Box, Grid, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { creditData } from "../../../../../configs/mainStore";
import { underwritingPolicyValidations } from "../../../../../services/creditFileApiCall";
import { MainSectionsWrapper } from "../../../MainSectionsWrapper/MainSectionsWrapper";
import { ValidationResultContainer, ValidationResults } from "./FraudCheck";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";

export const UnderwritingPolicyCheck = () => {
  const creditFileData: any = useHookstate(creditData);
  const usersData = creditFileData?.attach(Downgraded)?.get();

  const [policyState, setPolicyStatus] = useState([]);
  const [status, setStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const catalog = [
      usersData?.formData?.creditData?.type,
      usersData?.formData?.creditData?.sector,
      usersData?.formData?.creditData?.scheme,
    ].join("-");
    const income = usersData?.formData?.incomeData?.personnelIncome || null;
    const applicationId = usersData.applicationId[0];

    const collateral = {
      ...usersData?.formData?.securityData,
      guarantors: {
        size: usersData?.formData?.securityData?.guarantors?.length || 0,
      },
    };

    validate(applicationId);
  }, []);

  const validate = async (applicationId) => {
    setIsLoading(true);
    const response = await underwritingPolicyValidations(applicationId);

    if (response) {
      setPolicyStatus(response);
    }

    const isError = response?.every((result) => result?.code == 1);
    setStatus(isError);
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <Grid
          container
          alignItems="center"
          sx={{
            paddingTop: "20px",
            paddingBottom: "20px",
            position: "relative",
          }}
        >
          <CircularProgressComponent size={30} sx={{ left: "50%", position: "absolute", zIndex: 100 }} />
        </Grid>
      ) : (
        policyState?.length === 0 ? (
          <Stack></Stack>
        ) : (
          <Box>
            <MainSectionsWrapper
              showSubText={false}
              showStatus={false}
              title="Underwriting Policy Check"
              keyCode={undefined}
              noBtn={true}
              decisionState={status}
            >
              <ValidationResultContainer results={policyState} containerWidth={6}>
                <ValidationResults result={policyState} />
              </ValidationResultContainer>
            </MainSectionsWrapper>
          </Box>
        )
      )}
    </>
  );
};
