import { addToaster, toast } from "../components/OtherComponents/GlobalToast";
import { endpoints } from "../configs/endpoints";
import Api from "./apiCallsHandler";
import CachingStorage from "./cacheStorage";

const api = Api.getApi();

//
// ──────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────── I ──────────
//   :::::: S E R V I C E   R E L A T E D   T O   L O A D   A N D   P R E P A R E   A P P L I C A T I O N   F O R M   S T R U C T U R E : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────
//

export const loadApplicationFormMeta = async () => {
  try {
    const response = await api.get(endpoints?.loadApplicationFormConfigMeta);
    return response?.data ?? {};
  } catch (error) {
    throw error;
  }
};

export const loadApplicationFrom = async (type, sector, scheme, applicantType, participantType = 1, formSetState: any = null, isNeedFullJson = false) => {
  try {

    const response = await api.get(endpoints?.loadApplicationForm, {
      type,
      sector,
      scheme,
      applicantType,
    });

    if (response?.status == -1) {
      return addToaster({
        status: "error",
        title: "Error",
        message: "Error on loading application form !",
      });
    }

    const formSet = response?.data || response;

    if (formSet?.length === 0) {
      return addToaster({
        status: "error",
        title: "Error",
        message: "Error on loading application form !",
      });
    }

    if (formSetState) {
      formSetState?.set(formSet);
    }

    if (isNeedFullJson) {
      return formSet;
    }

    const form = formSet?.filter((form) => form?.PARTICIPANT_TYPE == participantType);

    if (!form[0]) {
      return addToaster({
        status: "error",
        title: "Error",
        message: "Error on loading application form !",
      });
    }

    return JSON.parse(form[0]?.FORM_STRUCTURE); // Original file (from DB)

    // return applicationformStructureAWS;
  } catch (error) {
    console.error(error);
  }
};

export const applicantTypeStructure = {
  1: "primaryApplicant",
  2: "guarantor",
  3: "jb"
}

export const getApplicationFormStructure = async (type, sector, scheme, applicantType, participantType = null) => {
  try {

    if (!type || !sector || !scheme || !applicantType) {
      throw new Error("Required details missing.");
    }

    let catalogWithApplicantType = [type, sector, scheme, applicantType].join('-');

    const response = await CachingStorage.invoke(`${catalogWithApplicantType}_applicationFormStructure`,
      () => api.get(endpoints?.loadApplicationForm, { type, sector, scheme, applicantType }));


    if (!response) {
      throw new Error("There is no valid network response...!");
    }

    if (response.status === -1) {
      throw new Error("Application form Structure not found...!");
    }

    if (!response || !response.length) {
      throw new Error("Application form Structure not found...!");
    }

    let _participantTypeWiseForm = CachingStorage.read(`${catalogWithApplicantType}_participantType`);

    if (!_participantTypeWiseForm) {
      _participantTypeWiseForm = response.reduce((obj, item) => {
        return {
          ...obj,
          [applicantTypeStructure[item.PARTICIPANT_TYPE]]: JSON.parse(item.FORM_STRUCTURE)
        }
      }, {});

      CachingStorage.store(`${catalogWithApplicantType}_participantType`, _participantTypeWiseForm);
    }

    if (participantType) {
      return _participantTypeWiseForm[applicantTypeStructure[participantType]];
    }

    return _participantTypeWiseForm;

  } catch (error) {
    toast({ status: "error", message: "Somthing Went Wrong...!" });
    console.error(error);
  }
}


export const getPendingApplicationForm = async (requestId, applicationId = null) => {
  try {

    if (!requestId) {
      throw new Error("Required details missing.");
    }

    if (!CachingStorage.read(`pendingApplicationForm`)) {
      CachingStorage.clear(`applicationFormData`);
    }

    const slug = requestId ? `?requestId=${requestId}` : `?applicationId=${applicationId}`;

    let { APPLICANTION_DATA: mainApplicationData, APPLICANT_FORM_DATA: applicationFormData } = await CachingStorage.invoke(`pendingApplicationForm`,
      () => api.get(`${endpoints?.loadPendingApplication}${slug}`, {}));

    if (!CachingStorage.read(`applicationFormData`)) {
      applicationFormData = JSON.parse(applicationFormData);
      CachingStorage.store(`applicationFormData`, applicationFormData);
    }

    return CachingStorage.read(`applicationFormData`) || applicationFormData;

  } catch (error) { }
};
