import { Stack, Typography } from "@mui/material";
import { AddEditSmsPlaceholderTemplateComponent } from "../AddEditSmsPlaceholderTemplateComponent";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import CloseIcon from "@mui/icons-material/Close";
import { closeGlobalModal } from "../../../GlobalModal/GlobalModal";

export const CreatePlaceholdersModal = (params) => {
  const closeCondition = () => {
    closeGlobalModal();
  };

  const callBackOnAction = () => {
    params.callBackOnAction();
    closeGlobalModal();
  };

  return (
    <div>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <AddEditSmsPlaceholderTemplateComponent
          smsListChanged={params?.smsListChanged}
          editTemplateItem={params?.editTemplateItem}
          closeCondition={closeCondition}
          callBackOnAction={callBackOnAction}
        />
      </Stack>
    </div>
  );
};
