import { Downgraded, useHookstate } from "@hookstate/core";
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Paper, Stack, Switch, Typography, alpha, styled } from "@mui/material";
import { privilegeKeys } from "../../../../configs/constants/privilegeKeys";
import { userDetails } from "../../../../configs/mainStore";
import { isPrivilegeHave } from "../../../../services/utilService";
import { languages } from "../../../../utility/helpers/creditFileUtility";
import { ButtonComponent } from "../../../InputComponents/ButtonComponent/ButtonComponent";
import { SwitchComponent } from "../../../InputComponents/SwitchComponent/SwitchComponent";
import { TextBoxComponent } from "../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { closeGlobalModal } from "../../GlobalModal/GlobalModal";

const CreateWorkflowFormLayout = ({ selectData: data, onSave, isUpdate, loading, workflowName }) => {
  const { userPrivilege }: any = userDetails.attach(Downgraded).get();
  const selectData: any = useHookstate(data);
  const GreenSwitch = styled(Switch)(({ theme }: any) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#00AB71",
      "&:hover": {
        backgroundColor: alpha("#00AB71", theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#00AB71",
    },
  }));

  return <Paper elevation={0}>
    <Stack p={1}>
      <Stack direction="column">
        <Stack direction={"row"} mb={1} justifyContent={"space-between"}>
          <Typography color={"gray"} variant="body1">
            {isUpdate ? `Update Workflow` : "Create Workflow"}
          </Typography>
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              closeGlobalModal();
            }}
          />
        </Stack>

        <Typography color={"gray"} variant="caption">
          {isUpdate && `(${workflowName})`}
        </Typography>
      </Stack>
      <Divider></Divider>
      <Stack mt={2}>
        <Stack direction="row" alignItems="center" justifyContent={"space-between"} my={2} mb={4} border={1} borderRadius={2} p={1} borderColor={'lightgray'}>
          <Stack direction="row" alignItems="center">
            <SwitchComponent
              commonStatus={true}
              checked={selectData.isActive.get() ? true : false}
              onChange={(e) => {
                selectData.isActive.set(!selectData.isActive.get());
              }}
              disabled={loading}
            />
            <Typography color={"gray"} variant="subtitle2">
              {selectData.isActive.get() ? "Published" : "Unpublished"}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center">
            <GreenSwitch
              checked={selectData.workflowType.get() ? true : false}
              onChange={(e) => {
                selectData.workflowType.set(!selectData.workflowType.get());
              }}
              disabled={loading}
            />
            <Typography color={"gray"} variant="subtitle2">
              {selectData.workflowType.get() ? "Main Workflow" : "Sub Workflow"}
            </Typography>
          </Stack>
        </Stack>

        {languages()?.map((lang, index) => {
          return <Stack mb={2} spacing={1} direction={{ xs: "column" }}>
            <Stack>
              <Typography variant="subtitle2" color={"gray"}> {lang.name} Name </Typography>
              <TextBoxComponent value={selectData?.descriptions?.get()?.[lang.id]?.workflowName || ""} onChange={(e: any) => selectData.descriptions[lang.id]?.workflowName?.set(e.target.value)} multiline={true} disabled={loading} />
            </Stack>
            <Stack>
              <Typography variant="subtitle2" color={"gray"}> {lang.name} Description </Typography>
              <TextBoxComponent value={selectData?.descriptions?.get()?.[lang.id]?.workflowDescription || ""} onChange={(e: any) => selectData.descriptions[lang.id]?.workflowDescription?.set(e.target.value)} multiline={true} disabled={loading} sx={{ fontSize: 16 }} />
            </Stack>
            {index !== languages().length - 1 && <Divider sx={{ mt: 1 }} />}
          </Stack>
        })}

        <Divider></Divider>
        <Stack direction={"row-reverse"} mt={2}>
          <ButtonComponent size={"small"} title={isUpdate ? "Update Workflow" : "Add New Workflow"} variant="contained" onClick={(e) => onSave(e, isUpdate)} disabled={loading || !isPrivilegeHave(userPrivilege, privilegeKeys.CERATE_NEW_WF_BUTTON)} />
        </Stack>
      </Stack>
    </Stack>
  </Paper>;
};

export default CreateWorkflowFormLayout;
