import { Downgraded, useState } from "@hookstate/core";
import { Stack } from "@mui/material";
import { languages } from "../../../../utility/helpers/creditFileUtility";
import CreateWorkflowFormLayout from "./CreateWorkflowFormLayout";

const CreateWorkflowFormContainer = ({ onSave, selectWorkflow, loading }) => {
  const isUpdate = useState(false);

  isUpdate.set(!!selectWorkflow?.id);

  const onSaveData = async (e, isUpdate) => {
    // let res: any = {};
    // const data = JSON.parse(JSON.stringify(selectData.get()));

    // let workflowType = 1

    // if (selectData?.workflowType.get() === true) {
    //   workflowType = 1;
    // } else if (selectData?.workflowType.get() === false) {
    //   workflowType = 2;
    // }

    // data.workflowType = workflowType;

    // const descData = selectData?.descriptions.get();
    // const langData = Object.keys(descData).map((key) => ({
    //   lang: key,
    //   workflowName: descData[key]?.workflowName,
    //   workflowDescription: descData[key]?.workflowDescription,
    // }));
    // loading.set(true);

    // let isValid = false;
    // Object.keys(langData).forEach((key) => {
    //   let language = langData[key];
    //   Object.keys(language).forEach((key) => {
    //     if (language["workflowName"] && language["workflowDescription"]) {
    //       isValid = true;
    //     }
    //   });
    // });

    // if (isValid) {
    //   if (isUpdate) {
    //     res = await updateWorkflowMetaData({
    //       isActive: selectData.isActive.get(),
    //       descriptions: langData,
    //       workflowId: selectData.id.get(),
    //       workflowType: workflowType
    //     });
    //   } else {
    //     res = await addWorkflowMetaData({
    //       isActive: selectData.isActive.get(),
    //       descriptions: langData,
    //       workflowType: workflowType
    //     });
    //   }

    //   if (res?.status == responseSuccessCode) {
    //     onSave(data, res?.data?.id);
    //     addToaster({ status: "success", title: "Success", message: res?.data?.msg });

    //     try {
    //       if (isUpdate) {
    //         let obj = {
    //           isActive: selectData.isActive.get(),
    //           descriptions: langData,
    //           workflowId: selectData.id.get(),
    //         };
    //         createApplicationsHistory(0, `Edited existing workflow`, null, obj, "/audit/log/workflow/createworkflow");
    //       } else {
    //         let obj = {
    //           isActive: selectData.isActive.get(),
    //           descriptions: langData,
    //         };
    //         createApplicationsHistory(0, `Created a new workflow`, null, obj, "/audit/log/workflow/createworkflow");
    //       }
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   } else {
    //     addToaster({ status: "error", title: "Error", message: res?.data?.msg });
    //   }
    //   loading.set(false);
    // }
    // loading.set(false);
  };

  if (!onSave) {
    return <Stack></Stack>;
  }

  if (!isUpdate || !loading) {
    return <></>;
  }

  const workflowName = selectWorkflow?.descriptions?.["EN"]?.workflowName;


  return <CreateWorkflowFormLayout selectData={selectWorkflow} onSave={onSaveData} isUpdate={isUpdate.get()} loading={loading.get()} workflowName={workflowName} />;
};

export default CreateWorkflowFormContainer;

export const CreateWorkflowModal = ({ onSaved, selectWorkflow, loading }) => {
  let data: any = { descriptions: {} };

  let wfData: any = {
    descriptions: {}
  };

  if (selectWorkflow?.get) {
    wfData = selectWorkflow?.attach(Downgraded)?.get();
    const languagesArray: any = languages();

    let obj: any = {};
    languagesArray?.forEach((langData) => {
      const dataObject = wfData?.descriptions?.[langData] ? { ...wfData.descriptions[langData] } : { workflowName: "", workflowDescription: "" };
      obj[langData] = dataObject;
    });

    data = { descriptions: obj, ...wfData };
  } else {
    data.descriptions.EN = { workflowName: "", workflowDescription: "" }
  }

  return <CreateWorkflowFormContainer onSave={onSaved} selectWorkflow={data} loading={loading} ></CreateWorkflowFormContainer>
}
