import { Box, Grid } from "@mui/material";
import React, { FC } from "react";
import Dropzone from "react-dropzone";
import { documentStatusColors } from "../WorkflowComponents/utils";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { openGlobalModal } from "../GlobalModal/GlobalModal";

interface CribDocumentComponentProps {
  fileDetails: any;
}

export const CribDocumentComponent: FC<CribDocumentComponentProps> = ({ fileDetails }: any) => {
  const previewClicked = async () => {
    openGlobalModal({
      modalSize: "lg",
      title: "",
      bodyId: "document-data-preview",
      close: true,
      modalParams: {
        variant: "crib",
        cribDocId: fileDetails?.docId,
      },
    });
  };

  return (
    <Grid item xs={12} sm={2} md={2} p={1} style={{ maxWidth: "100%" }}>
      <Box
        style={{
          borderRadius: "5px",
          backgroundColor: `${documentStatusColors[1]?.color}3d`,
          margin: "auto",
          border: `1px solid ${documentStatusColors[1]?.color}`,
          textAlign: "center",
        }}>
        <Grid
          style={{ height: "125px", position: "relative" }}
          container
          item
          direction="row"
          sm={12}
          alignItems="center"
          justifyContent="center">
          <Grid item sm={12}>
            <FindInPageIcon
              style={{
                color: documentStatusColors[1]?.color,
                fontSize: 40,
                cursor: "pointer",
              }}
              onClick={previewClicked}
            />
          </Grid>
        </Grid>
      </Box>

      <h5 style={{ margin: "5px auto 0px auto", textAlign: "center" }}>
        CRIB - {fileDetails?.cribKey}
      </h5>
    </Grid>
  );
};
