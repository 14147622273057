import { Stack } from "@mui/material";
import { useState } from "react";
import { formatCurrency } from "../../../../../../../../services/currencyFormater";
import { OnDemandValueFormatter } from "../../../../../../../../services/onDemandValidators";
import { select_basic_ins } from "../../../../../../../../styles";
import { TextBoxComponent } from "../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";

export const TextBoxBuilder = ({ value, onChange, metaData }) => {
  const [valueInput, setValue] = useState(value[metaData?.dataKey]);

  return (
    <Stack pt={1} px={1}>
      <TextBoxComponent
        label={metaData?.title}
        value={valueInput ?? ""}
        className={select_basic_ins}
        onInput={OnDemandValueFormatter.alphaNumericSpaces}
        fullWidth={true}
        required={metaData?.render?.required ?? false}
        disabled={metaData?.render?.readOnly ? metaData?.render?.readOnly : false}
        onChange={(e) => {
          setValue(e.target.value);
          value[metaData?.dataKey] = e.target.value;
          onChange(metaData, value);
        }}
      />
    </Stack>
  );
};

export const TextBoxCurrencyBuilder = ({ value, onChange, metaData }) => {
  const [valueInput, setValue] = useState(value[metaData?.dataKey]);

  return (
    <Stack pt={1} px={1}>
      <TextBoxComponent
        label={metaData?.title}
        className={select_basic_ins}
        required={metaData?.render?.required ?? false}
        value={formatCurrency(valueInput ?? 0) ?? 0}
        fullWidth={true}
        onInput={OnDemandValueFormatter.numeric}
        disabled={metaData?.render?.readOnly ? metaData?.render?.readOnly : false}
        onChange={(e) => {
          setValue(e.target.value);
          value[metaData?.dataKey] = e.target.value;
          onChange(metaData, value);
        }}
      />
    </Stack>
  );
};

export const TextBoxYearBuilder = ({ value, onChange, metaData }) => {
  const [valueInput, setValue] = useState(value[metaData?.dataKey]);

  return (
    <Stack pt={1} px={1}>
      <TextBoxComponent
        label={metaData?.title}
        className={select_basic_ins}
        required={metaData?.render?.required ?? false}
        value={valueInput ?? 0}
        fullWidth={true}
        onInput={OnDemandValueFormatter.numeric}
        disabled={metaData?.render?.readOnly ? metaData?.render?.readOnly : false}
        onChange={(e) => {
          if (e.target.value >= 0 && e.target.value <= 2100) {
            setValue(e.target.value);
            value[metaData?.dataKey] = e.target.value;
            onChange(metaData, value);
          }
        }}
      />
    </Stack>
  );
};

export const TextBoxNumberBuilder = ({ value, onChange, metaData }) => {
  const [valueInput, setValue] = useState(value[metaData?.dataKey]);

  return (
    <Stack pt={1} px={1}>
      <TextBoxComponent
        label={metaData?.title}
        className={select_basic_ins}
        required={metaData?.render?.required ?? false}
        value={valueInput ?? 0}
        fullWidth={true}
        onInput={OnDemandValueFormatter.numeric}
        disabled={metaData?.render?.readOnly ? metaData?.render?.readOnly : false}
        onChange={(e) => {
          setValue(e.target.value);
          value[metaData?.dataKey] = e.target.value;
          onChange(metaData, value);
        }}
      />
    </Stack>
  );
};
