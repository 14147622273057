import { Downgraded, useState } from "@hookstate/core";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Grid, Paper } from "@mui/material";
import { Box } from "@mui/system";
import React, { FC } from "react";
import { documentUploadData } from "../../../../configs/stores/document-upload-store/documentUploadStore";
import { updateApplicationDocumentStatus } from "../../../../services/documentApis";

import CreditCardIcon from "@mui/icons-material/CreditCard";
import PersonIcon from "@mui/icons-material/Person";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import { FILE_STATUS } from "../../../../configs/constants/documentUploadKeys";
import { getUserDetailsById } from "../../../../services/userService";
import { dateFormatWithDate, dateFormatWithTimeChange } from "../../../../utility/fomatters";
import { ErrorMessageComponent } from "../../ErrorMessageComponent";
import { MainSectionsWrapper } from "../../MainSectionsWrapper/MainSectionsWrapper";
import { CircularProgressComponent } from "../../ProgressComponent/ProgressComponent";
import { DocumentPreviewHistoryRow, DocumentPreviewRow } from "./DocumentPreviewRow";

import { appSetting, userDetails } from "../../../../configs/mainStore";
import { documentKeys } from "../../../../configs/revokeDocumentKeys";
import { disableAccess, unclassifiedDisableAccess } from "../../../../utility/helpers/ApplicationMandatoryValidations/isSecurityDocUploadApprovedPermitted";
import { ButtonComponent } from "../../../InputComponents/ButtonComponent/ButtonComponent";
import { addToaster } from "../../GlobalToast";

export const DocumentPreviewComponent: FC = (params?: any) => {
  const applicationId = params?.applicationId;
  const appSettings: any = useState(appSetting);
  const { previewedDocument }: any = documentUploadData.attach(Downgraded).get();

  const { userId }: any = userDetails.attach(Downgraded).get();

  const [previewLoading, setPreviewLoading] = React.useState(false);
  const [documentBlobUrl, setDocumentBlobUrl] = React.useState<any>(null);
  const [documentContentType, setDocumentContentType] = React.useState<any>(null);

  const [previewHistoryLoading, setPreviewHistoryLoading] = React.useState(false);
  const [documentHistoryList, setHistoryList] = React.useState<any>([]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [updateLoading, setUpdateLoading] = React.useState(false);
  const [updateSuccess, setUpdateSuccess] = React.useState(false);
  const postDisbursementTabKeys: any = ["post_disbursements_documents", "post_condition_documents", "post_disbursement_checklist"];
  const isRevokePrivilages = documentKeys.includes(params.documentKey);
  const [isDisabled, setIsDisabled]: any = React.useState(params.disableStatus);

  let permission: any = isRevokePrivilages
    ? unclassifiedDisableAccess()
    : disableAccess({
      privilegeKeyforSec: "SEC_DOC_APPROVE_REJECT_DOC",
      privilegeKeyforOther: "DOC_MANAGE_SYSTEM_APPROVE_REJECT_DOCUMENT",
      documentComponentId: postDisbursementTabKeys.includes(params.documentComponentId) ? params.documentComponentId : previewedDocument?.documentId,
    });

  const previewClicked = (blobUrl) => {
    window.open(
      `${blobUrl}`,
      "targetWindow",
      `toolbar=no,
     location=no,
     status=no,
     menubar=no,
     scrollbars=yes,
     resizable=yes,
     width=500,
     height=500`
    );
  };

  const statusUpdateClicked = async (status) => {
    setUpdateLoading(true);
    const result = await updateApplicationDocumentStatus({
      applicationId: previewedDocument?.applicationId,
      documentId: previewedDocument?.documentId,
      participantId: previewedDocument?.individualId === undefined ? previewedDocument?.participantId : previewedDocument?.individualId,
      isPrimary: previewedDocument?.isPrimary,
      applicantType: previewedDocument?.applicantType,
      status: status,
      approvalFrom: userId,
    });

    if (result) {
      if (result?.updatedTime) {
        setUpdateSuccess(!updateSuccess);
        params.callBackOnAction();
      } else {
        addToaster({
          status: "warning",
          title: "Not Updated",
          message: result,
        });
      }
    } else {
      addToaster({
        status: "error",
        title: "Update Error",
        message: "Something went Wrong",
      });
    }
    setUpdateLoading(false);
  };

  const viewDocumentFile = () => {
    params.callBackOnAction(params.item.id);
  };

  return (
    <Paper elevation={0} sx={{ minHeight: "70vh" }}>
      {previewLoading ? (
        <></>
      ) : // <CircularProgressComponent size={30} sx={{ top: "50%", left: "50%", position: "absolute", zIndex: 100 }} />
        errorMessage ? (
          <ErrorMessageComponent headMessage={"Error!"} errorMessage={errorMessage} />
        ) : (
          <Grid container direction="column" sx={{ minHeight: "60vh" }}>
            <Grid container item spacing={2} direction="row" xs={12}>
              <Grid item xs={12} lg={5} style={{ borderRight: "1px solid #454c661a" }}>
                <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ backgroundColor: "#ffffff", borderRadius: "5px", textAlign: "center" }}>
                  <Grid item xs={6} justifyContent="center" style={{ padding: "30px 0" }}>
                    <ButtonComponent
                      startIcon={<VisibilityIcon />}
                      // endIcon={<SendIcon />}
                      title={"Preview Document"}
                      variant="contained"
                      onClick={() => {
                        viewDocumentFile();
                      }}
                      disabled={isDisabled}
                    // loadingbtn={true}
                    // loading={updateLoading}
                    />
                  </Grid>
                </Grid>
                {documentContentType === "image" && <img src={documentBlobUrl} style={{ width: "100%" }}></img>}
              </Grid>
              <Grid item xs={12} lg={7}>
                <Grid container item spacing={1} direction="row" xs={12}>
                  <Grid item xs={12} lg={12}>
                    <h4 className="basic-font" style={{ marginBottom: 0 }}>
                      Current File Details
                    </h4>
                    <hr style={{ borderTop: "1px solid #454c661a", marginTop: "0px", width: "100%" }} />
                    <Box sx={{ boxShadow: 1 }}>
                      {updateLoading ? (
                        <Grid container className="align-center row_list_item align-center" mt={2} columns={12}>
                          <Grid container item xs={12} sm={12} direction="column" justifyContent="center">
                            <CircularProgressComponent size={30} sx={{ zIndex: 100, margin: "auto" }} />
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid container className="align-center row_list_item align-center" mt={2} columns={24}>
                          <Grid container item xs={12} sm={11} direction="column" justifyContent="center">
                            <DocumentPreviewRow Icon={CreditCardIcon} subTxt={"Status"} mainTxt={FILE_STATUS[params?.item?.action]} status={params?.item?.action} />
                          </Grid>
                        </Grid>
                      )}
                      <Grid container className="align-center row_list_item align-center" mt={2} columns={24}>
                        <Grid container item xs={12} sm={11} direction="column" justifyContent="center">
                          <DocumentPreviewRow Icon={PersonIcon} subTxt={"Uploaded By"} mainTxt={params?.item?.createdBy ? getUserDetailsById(params?.item?.createdBy)[appSettings.get().language]?.name : "--"} status={params?.item?.action} />
                        </Grid>
                        <Grid container item xs={12} sm={13} direction="column" justifyContent="center">
                          <DocumentPreviewRow Icon={QueryBuilderIcon} subTxt={"Uploaded Date"} mainTxt={params?.item?.createdDate ? dateFormatWithTimeChange(params?.item?.createdDate) : "--"} status={params?.item?.action} />
                        </Grid>
                        <Grid container item xs={12} sm={11} direction="column" justifyContent="center" style={{ marginTop: "10px" }}>
                          <DocumentPreviewRow Icon={PersonIcon} subTxt={"Verified By"} mainTxt={params?.item?.approvalFrom ? getUserDetailsById(params?.item?.approvalFrom)[appSettings.get().language]?.name : "--"} status={params?.item?.action} />
                        </Grid>
                        <Grid container item xs={12} sm={13} direction="column" justifyContent="center" style={{ marginTop: "10px" }}>
                          <DocumentPreviewRow Icon={QueryBuilderIcon} subTxt={"Verified Date"} mainTxt={params?.item?.actionedDate ? dateFormatWithTimeChange(params?.item?.actionedDate) : "--"} status={params?.item?.action} />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container item spacing={1} direction="row" xs={12}>
                  {previewHistoryLoading ? (
                    <CircularProgressComponent size={30} sx={{ top: "50%", left: "50%", position: "absolute", zIndex: 100 }} />
                  ) : (
                    <Grid item xs={12} lg={12}>
                      <h4 className="basic-font" style={{ marginBottom: 0 }}>
                        File History
                      </h4>
                      <hr style={{ borderTop: "1px solid #454c661a", marginTop: "0px", width: "100%" }} />
                      {documentHistoryList.length < 1 ? (
                        <ErrorMessageComponent headMessage={"No Data"} errorMessage={"No History data"} showImg={false} />
                      ) : (
                        <Box style={{ maxHeight: "35vh", overflowY: "auto" }}>
                          {documentHistoryList.map((item, index) => {
                            return (
                              <MainSectionsWrapper title={item?.createdDate && dateFormatWithTimeChange(item?.createdDate)} subTitle="Click to get file details" keyCode={index} noBtn={true} icon={false} status={item?.status === 1 ? "success" : item?.status === 2 ? "error" : "info"} file={item} isDocument={true}>
                                <Grid container className="align-center row_list_item" mt={2} columns={24}>
                                  <DocumentPreviewHistoryRow subTxtOne="Uploaded By" mainTxtOne={item?.createdBy && getUserDetailsById(item?.createdBy)[appSettings.get().language]?.name} subTxtTwo="Uploaded Date" mainTxtTwo={item?.createdDate ? dateFormatWithDate(item?.createdDate) : "--"} />
                                  <DocumentPreviewHistoryRow subTxtOne="Verified By" mainTxtOne={item?.approvalFrom ? getUserDetailsById(item?.approvalFrom)[appSettings.get().language]?.name : "--"} subTxtTwo="Verified Date" mainTxtTwo={item?.actionedDate ? dateFormatWithDate(item?.actionedDate) : "--"} />
                                  <DocumentPreviewHistoryRow subTxtOne="Archive By" mainTxtOne={item?.archivedBy && getUserDetailsById(item?.archivedBy)[appSettings.get().language]?.name} subTxtTwo="Archive Date" mainTxtTwo={item?.archivedDate ? dateFormatWithDate(item?.archivedDate) : "--"} />
                                </Grid>
                              </MainSectionsWrapper>
                            );
                          })}
                        </Box>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
    </Paper>
  );
};
