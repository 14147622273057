import React from "react";
import { endWidgets } from "./Tools/widgetsList";
import { searchAndVerifyWidget } from "./Tools/SearchAndVerifyWidget";
import ChildBuilder from "./Tools/Builders/ChildrenBuilderMaster";
import { Stack } from "@mui/material";

export interface IntermediateFormBuilderProps {
  formChildren?: any;
  key?: string;
  type?: string;
  inspectionFormData?: any;
  setInspectionFormData?: any;
  metaData?: any;
  value?: any;
  onChange?: any;
  otherParams?: any;
}

const IntermediateFormBuilder: React.FC<IntermediateFormBuilderProps> = ({ formChildren, value, onChange, otherParams = {} }: IntermediateFormBuilderProps) => {
  const BuildContent = () => {
    return formChildren.map((alpha: any, index: number) => {
      return (
        <React.Fragment key={"IntermediateFormBuilder" + index}>
          <ChildBuilder formJsonData={alpha} value={value} onChange={onChange} key={alpha?.dataKey} otherParams={otherParams} />
        </React.Fragment>
      );
    });
  };

  return (
    <React.Fragment>
      <BuildContent />
    </React.Fragment>
  );
};

export default IntermediateFormBuilder;
