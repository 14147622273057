import React, { useEffect, useState } from "react";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { ButtonComponent } from "../../../InputComponents/ButtonComponent/ButtonComponent";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { closeGlobalModal } from "../../../OtherComponents/GlobalModal/GlobalModal";
import { closeBtn } from "../../../../styles";
import { CheckBoxComponent } from "../../../InputComponents/CheckBoxComponent/CheckBoxComponent";
import { extractWatchList, payload } from "../../../../configs/constants/contants";
import { saveNewWatchlist } from "../../../../services/configurationApis";
import { addToaster } from "../../../OtherComponents/GlobalToast";

const WatchListModal = (params) => {
  const [isCheckAll, setIsCheckAll]: any = useState(false);
  const [isCheckSelectAll, setIsCheckSelectAll]: any = useState(false);
  const [selectedIds, setSelectedIds]: any = useState({});

  const closeTask = () => {
    closeGlobalModal();
  };

  const toggleIdSelection = (sectionKey, id) => {
    setSelectedIds((prevIds) => {
      const sectionSelectedIds = { ...prevIds };
      if (!sectionSelectedIds[sectionKey]) {
        sectionSelectedIds[sectionKey] = [];
      }

      if (sectionSelectedIds[sectionKey].includes(id)) {
        sectionSelectedIds[sectionKey] = sectionSelectedIds[sectionKey].filter(
          (prevId) => prevId !== id
        );
      } else {
        sectionSelectedIds[sectionKey].push(id);
      }
      
      const isAllRowsChecked = extractWatchList
        .find((item) => item.sectionKey === sectionKey)
        ?.fields.every((field) => sectionSelectedIds[sectionKey].includes(field.key));
  
      setIsCheckAll((prevIsCheckAll) => ({
        ...prevIsCheckAll,
        [sectionKey]: isAllRowsChecked,
      }));
  
      return { ...sectionSelectedIds };
    });
  };

  const handleSelectAll = () => {
    const allIds = {};
    extractWatchList.forEach((section: any) => {
      const fieldKeys = section.fields.map((field) => field.key);
      allIds[section.sectionKey] = fieldKeys;
    });
  
    if (isCheckSelectAll) {
      setSelectedIds({});
      setIsCheckAll({});
    } else {
      setSelectedIds(allIds);
  
      const newIsCheckAll = {};
      extractWatchList.forEach((section: any) => {
        newIsCheckAll[section.sectionKey] = true;
      });
      setIsCheckAll(newIsCheckAll);
    }
  
    setIsCheckSelectAll(!isCheckSelectAll);
  };
  
  const handleSelectRow = (sectionKey) => {
    if (isCheckAll[sectionKey]) {
      setSelectedIds((prevIds) => {
        const sectionSelectedIds = { ...prevIds };
        sectionSelectedIds[sectionKey] = [];
        return { ...sectionSelectedIds };
      });
    } else {
      const allIds: any = extractWatchList?.find((item) => item.sectionKey === sectionKey)
        ?.fields.map((field) => field.key) || [];
      setSelectedIds((prevIds) => {
        const sectionSelectedIds = { ...prevIds };
        sectionSelectedIds[sectionKey] = allIds;
        return { ...sectionSelectedIds };
      });
    }
  
    setIsCheckAll((prevIsCheckAll) => {
      const newIsCheckAll = { ...prevIsCheckAll };
      newIsCheckAll[sectionKey] = !prevIsCheckAll[sectionKey];
  
      const isAllRowsChecked = extractWatchList
        .find((item) => item.sectionKey === sectionKey)
        ?.fields.every((field) => newIsCheckAll[sectionKey]);
  
      setIsCheckAll((prevIsCheckAll) => ({
        ...prevIsCheckAll,
        [sectionKey]: isAllRowsChecked,
      }));
  
      const areAllSectionsChecked = extractWatchList.every((section) => newIsCheckAll[section.sectionKey]);
      setIsCheckSelectAll(areAllSectionsChecked);
  
      return { ...newIsCheckAll };
    });
  };
  

  const saveWatchlist = async () => {
    const commonKeys = {};

    Object.keys(selectedIds).forEach(category => {
      selectedIds[category].forEach(selectedKey => {
        const isCommonKey = payload.every(item => selectedKey in item[category]);

        if (isCommonKey) {
          if (!commonKeys[selectedKey]) {
            commonKeys[selectedKey] = new Set();
          }

          const values = payload.map(item => item[category][selectedKey]);
          values.forEach(value => commonKeys[selectedKey].add(value));
        }
      });
    });

    Object.keys(commonKeys).forEach(key => {
      commonKeys[key] = Array.from(commonKeys[key]);
    });

    const result = await saveNewWatchlist(commonKeys);
    if (result?.id) {
      addToaster({
        status: "success",
        title: "Updated",
        message: `successfully updated !`,
      });
    } else {
      addToaster({
        status: "warning",
        title: "No Changes",
        message: "Something went wrong",
      });
    }

  };

  function extractValues(obj, value, payload) {
    const application = payload[0]?.[obj];
    const foundItem = application?.[value];

    if (foundItem !== undefined) {
      return foundItem;
    } else {
      return 'N/A';
    }
  }

  const extractItems = (sectionKey) => {
    const section = payload[0]?.[sectionKey];
    const items: any = [];
    const keys = ["guarantorDetails"];

    if (keys.includes(sectionKey)) {
      for (const [key, value] of Object.entries(section)) {
        if (["phoneNumber", "driverLicense", "mobile", "version"].includes(key)) {
          continue;
        }
        items.push(value);
      }
    }
    return items;
  }

  const BuildComponent = (obj) => {
    const sectionKey = obj?.data?.sectionKey || "";
    const items = extractItems(sectionKey);

    if (items.length > 0) {
      return (
        <>
          {items.map((guarantor, index) => (
            <div key={index}>
              <Stack
                direction="row"
                spacing={2}
                className="border-radius-5 basic-font basic-font-color-bold font-size-16"
                style={{ backgroundColor: "#e6f0fa", display: "flex" }}
                justifyContent="space-between"
                alignItems="center"
                paddingLeft={1}
              >
                <Typography>{obj?.data?.sectionLabel} - Guarantor {index + 1}</Typography>
                <Stack p={1} direction={"row"} alignItems={"center"}>
                  <CheckBoxComponent
                    checked={isCheckAll[sectionKey]}
                    onChange={() => handleSelectRow(sectionKey)}
                  />
                  <Stack>
                    <div className="basic-font-color font-size-14">Select All</div>
                  </Stack>
                </Stack>
              </Stack>
              <Grid container spacing={2} p={2} xs={12}>
                {obj?.data?.fields.map((value) => (
                  <Grid item xs={4} key={value.key}>
                    <Stack p={1} direction={"row"} alignItems={"center"}>
                      <CheckBoxComponent
                        checked={
                          selectedIds[sectionKey] &&
                          selectedIds[sectionKey].includes(value.key)
                        }
                        onChange={() => toggleIdSelection(sectionKey, value.key)}
                      />
                      <Stack>
                        <div className="basic-font basic-font-color font-size-14 align-left">
                          {value.label}
                        </div>
                        <div className="basic-font basic-font-color-bold font-size-18 align-left">
                          {guarantor[value.key]}
                        </div>
                      </Stack>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </div>
          ))}

        </>
      );
    } else {
      return (
        <>
          <Stack
            direction="row"
            spacing={2}
            className="border-radius-5 basic-font basic-font-color-bold font-size-16"
            style={{ backgroundColor: "#e6f0fa", display: "flex" }}
            justifyContent="space-between"
            alignItems="center"
            paddingLeft={1}
          >
            <Typography>{obj?.data?.sectionLabel}</Typography>
            <Stack p={1} direction={"row"} alignItems={"center"}>
              <CheckBoxComponent
                checked={isCheckAll[sectionKey]}
                onChange={() => handleSelectRow(sectionKey)}
              />
              <Stack>
                <div className="basic-font-color font-size-14">Select All</div>
              </Stack>
            </Stack>
          </Stack>
          <Grid container spacing={2} p={2} xs={12}>
            {obj?.data?.fields.map((value) => (
              <Grid item xs={4} key={value.key}>
                <Stack p={1} direction={"row"} alignItems={"center"}>
                  <CheckBoxComponent
                    checked={
                      selectedIds[sectionKey] &&
                      selectedIds[sectionKey].includes(value.key)
                    }
                    onChange={() => toggleIdSelection(sectionKey, value.key)}
                  />
                  <Stack>
                    <div className="basic-font basic-font-color font-size-14 align-left">
                      {value.label}
                    </div>
                    <div className="basic-font basic-font-color-bold font-size-18 align-left">
                      {extractValues(obj?.data?.sectionKey, value.key, payload)}
                    </div>
                  </Stack>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </>
      );
    }
  };

  return (
    <Box>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" paddingBottom={1}>
        <Typography>
          <div className="basic-font font-size-18 basic-font-color-bold">Select Items to Add to Watchlist</div>
        </Typography>
        <Stack p={1} direction={'row'} alignItems={'center'}>
          <CheckBoxComponent
            checked={isCheckSelectAll}
            onChange={handleSelectAll}
          />
          <Stack>
            <div className="basic-font basic-font-color font-size-14 align-left">{'Select All'}</div>
          </Stack>
        </Stack>
      </Stack>
      <Divider></Divider>
      <Stack paddingTop={2}>
        {extractWatchList.map((obj, index) => (
          <BuildComponent data={obj} key={index} />
        ))}
      </Stack>
      <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
        <ButtonComponent title="Close" startIcon={<CloseIcon />} variant="contained" onClick={closeTask} loadingbtn={true} style={closeBtn} />
        <ButtonComponent
          startIcon={<DoneIcon />}
          title="Save"
          variant="contained"
          onClick={saveWatchlist}
          loadingbtn={true}
          color="info"
        />
      </Stack>
    </Box>
  );
};

export default WatchListModal;
