import { Stack } from '@mui/material';
import React from 'react';
import { formatCurrency } from '../../../../../../../../services/currencyFormater';
import { ErrorMessageComponent } from '../../../../../../ErrorMessageComponent';
import ChangeDetector from '../../Helpers/ChangeDetecter/ChangeDetector';
import MainHeader from '../../Helpers/MainHeader/MainHeader';
import SubSectionHeader from '../../Helpers/SubSectionHeader/SubSectionHeader';
import TableBodyWrapper from '../../Helpers/TableContainer/TableBody/TableBodyWrapper';
import { TableColumnsElementWrapper, TableColumnsTextWrapper } from '../../Helpers/TableContainer/TableColumn/TableColumnsWrapper';
import { TableHeaderWrapperCustom } from '../../Helpers/TableContainer/TableHeader/TableHeaderWrapper';
import { TableRowWrapperRevaamp } from '../../Helpers/TableContainer/TableRow/TableRowWrapper';
import TableWrapper from '../../Helpers/TableContainer/TableWrapper/TableWrapper';
import { useRatioAnalysisContext } from './Stores/Context';

const RatioAnalysisVersionTwo = () => {
    const data = useRatioAnalysisContext();
    const values = Object.values(data);

    if (values.length === 0) {
        return <ErrorMessageComponent headMessage={""} errorMessage={"No Data Avaible to Show"} showImg={false} />
    }

    return (
        <React.Fragment>
            {
                Object.entries(data).map(([key, value]) => {
                    return <MainHeader title={value.title} onSubmit={undefined} key={value.title + "_" + key}>
                        <BuildContetnt key={key} dataKey={key} />
                    </MainHeader>
                })
            }
        </React.Fragment>
    )
}

export default React.memo(RatioAnalysisVersionTwo)

const BuildContetnt = ({ dataKey = "", params = {} }) => {
    const data: any = useRatioAnalysisContext();


    if (dataKey && !data[dataKey]["headerTitles"]) {
        return (
            <React.Fragment>
                {data[dataKey]["children"].map((child) => <BuildContetnt params={child} />)}
            </React.Fragment>
        )
    }

    return (
        <Stack pb={5}>
            <BuildTable toBeShowObjectParams={params} key={params["title"]} />
        </Stack>
    );
}

const BuildTable = ({ toBeShowObjectParams }) => {

    const { title, headerTitles, children = [] } = toBeShowObjectParams;

    const ValidateChildren = () => {

        if (!children.length) {
            return <ErrorMessageComponent headMessage={""} errorMessage={title + "- No Data Avaible to Show"} showImg={false} />
        }

        return <Stack pt={3}>
            <TableWrapper>

                <TableHeaderWrapperCustom headerArray={headerTitles} />

                <TableBodyWrapper>
                    {children?.map((item) => {

                        return <TableRowWrapperRevaamp index={item.title + item.key}>

                            <TableColumnsTextWrapper children={item.title} index={item.title} />
                            {
                                item?.children && item?.children.map((childItem) => {
                                    if (childItem.key.toLowerCase() === "change") return <TableColumnsElementWrapper index={childItem.value + "_" + childItem.key}>
                                        <ChangeDetector value={childItem.value} key={childItem.value} />
                                    </TableColumnsElementWrapper>

                                    let value = Number(childItem.value) ? formatCurrency(childItem.value.toFixed(2)) : "N/A";

                                    return <TableColumnsTextWrapper children={value} index={value} />
                                })
                            }

                        </TableRowWrapperRevaamp>
                    })}
                </TableBodyWrapper>

            </TableWrapper>
        </Stack>
    }

    return (
        <Stack >
            <SubSectionHeader
                key={title + "_" + "SubSectionHeader"}
                isNeedDropdown={false}
                title={title}
                onSubmit={undefined}
                dropDownProps={undefined}
            />
            <ValidateChildren key={title + "_" + "ValidateChildren"} />
        </Stack>
    )


}
