import { Downgraded, useState } from "@hookstate/core";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Grid, Stack, Tab, Tabs } from "@mui/material";
import React, { FC, useEffect } from "react";
import { bufferToBlobUrl } from "../../../../configs/base64Conversion";
import { ERROR_CODES } from "../../../../configs/constants/errorCode";
import { creditData, currentExpandSectionState, multipleDisbursementState } from "../../../../configs/mainStore";
import { getSectionDocuments, getSectionSingleDocument } from "../../../../services/creditFileApiCall";
import { select_basic } from "../../../../styles";
import { convertDisbursementCount } from "../../../../utility/util";
import { ButtonComponent } from "../../../InputComponents/ButtonComponent/ButtonComponent";
import { SelectComponent } from "../../../InputComponents/SelectComponent/SelectComponent";
import { addToaster } from "../../GlobalToast";
import { CircularProgressComponent } from "../../ProgressComponent/ProgressComponent";

export interface SecurityDoceumentsFileDocumentsViewSectionPropTypes {
  data?: any;
  applicationId: any;
  isGuarantor?: boolean;
  isJointBurrower?: boolean;
  guarantorJBSection?: any;
  securityDocumentKey?: string;
  isSecurityDocument?: boolean;
  setDisbursementLoanAmount?: any;
}

const SecurityDoceumentsFileDocumentsViewSection: FC<SecurityDoceumentsFileDocumentsViewSectionPropTypes> = ({
  data,
  applicationId,
  isGuarantor,
  isJointBurrower,
  guarantorJBSection,
  securityDocumentKey,
  isSecurityDocument,
  setDisbursementLoanAmount,
}) => {
  const creditFileData: any = useState(creditData);
  const creditDataResponse: any = creditFileData.attach(Downgraded).get();
  const primaryApplicant = creditDataResponse?.formData || [];
  const primaryApplicantIndividualID = primaryApplicant?.personalData?.individualId;

  const expanded: any = useState(currentExpandSectionState);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [singleDocIsLoading, setSingleDocIsLoading] = React.useState<string>("");
  const [progress, setProgress] = React.useState<number>(0);
  const [documentUrl, setDocumentUrl] = React.useState<any>({});

  const [nicSelectList, setNicSelectList] = React.useState<any[]>([]);
  const [nicSelectValue, setNicSelectValue] = React.useState<any>();

  const { formData } = creditFileData.attach(Downgraded).get();
  const getCreditData = formData?.creditData;

  const productCatalog = `${getCreditData?.type}-${getCreditData?.sector}-${getCreditData?.scheme}`;

  const entryId = data?.formData?.personalData?.individualId;

  const expandKey = isSecurityDocument ? securityDocumentKey : expanded?.get().key;
  const sectionKey = isGuarantor === true || isJointBurrower === true ? guarantorJBSection : expandKey;

  const multipleDisbursement: any = useState(multipleDisbursementState);
  const downgradedMultipleDisbursement = multipleDisbursement.attach(Downgraded).get();
  const [selectedTabIndex, setSelectedTabIndex]: any = React.useState<number>(0);

  const handleOnClick = (disbAmount: any, index: any) => {
    setDisbursementLoanAmount(disbAmount.disbursementAmount);
    setSelectedTabIndex(index);
  };

  const multipleDisbursementDocuments = () => {
    let tabList: any = [];
    let foundPending = false;

    Object.keys(downgradedMultipleDisbursement.disbSchedule).forEach((key) => {
      const disbursement = multipleDisbursement.disbSchedule.get()[key];

      if (!foundPending && (disbursement.status === "Pending" || disbursement.status === "Complete")) {
        tabList.push(downgradedMultipleDisbursement.disbSchedule[key]);

        if (disbursement.status === "Pending") {
          foundPending = true;
        }
      }
    });

    return tabList;
  };

  useEffect(() => {
    if (isSecurityDocument === true) {
      let nicSelectObj: any = [];
      const individualId = formData?.personalData?.individualId;

      nicSelectObj.push({
        id: individualId,
        name: `${formData?.personalData?.oldNic || formData?.personalData?.newNic} (Primary Applicant)`,
        participantKey: 1,
      });

      let jb_count = 1;

      formData?.jointBorrowerData?.map((item) => {
        nicSelectObj.push({
          id: item?.personalData?.individualId,
          name: `${item?.personalData?.oldNic || item?.personalData?.newNic} (Joint Borrower ${jb_count++})`,
          participantKey: 2,
        });
      });

      let gr_count = 1;
      formData?.securityData?.guarantors?.map((item) => {
        nicSelectObj.push({
          id: item?.personalData?.individualId,
          name: `${item?.personalData?.oldNic || item?.personalData?.newNic} (Guarantor ${gr_count++})`,
          participantKey: 3,
        });
      });

      setNicSelectList(nicSelectObj);
    }
  }, []);

  const previewClicked = async (blobUrl?: any) => {
    setSingleDocIsLoading(blobUrl);
    const params = {
      blobUrl,
    };
    try {
      const response = await getSectionSingleDocument(params, (progress) => {
        setProgress(progress);
      });

      if (response?.status === ERROR_CODES.success) {
        let url = bufferToBlobUrl(response?.data?.fileData?.data, response?.data?.contentType, (progress) => { });
        window.open(
          `${url}`,
          "targetWindow",
          `toolbar=no,
          location=no,
          status=no,
          menubar=no,
          scrollbars=yes,
          resizable=yes,
          width=500,
          height=500`
        );
        setSingleDocIsLoading("");
      } else {
        addToaster({
          status: "error",
          title: "Error",
          message: "No Document Found",
        });
        setSingleDocIsLoading("");
      }
    } catch (error) {
      addToaster({
        status: "error",
        title: "Error",
        message: "No Document Found",
      });
      setSingleDocIsLoading("");
    }
  };

  const BuildProgress = (progress: number) => {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <CircularProgressComponent variant={progress < 10 ? undefined : "determinate"} size={16} value={progress} />
      </Box>
    );
  };

  const getDocsOnChange = async (event) => {
    setNicSelectValue(event.target.value);

    let selectedParticipantKey: any = "";

    if (nicSelectList.length > 0) {
      selectedParticipantKey = nicSelectList.filter((obj) => obj.id == event.target.value)[0].participantKey;
    }

    setIsLoading(true);

    if (typeof getCreditData === "object" && getCreditData !== null) {
      const params = {
        sectionKey: sectionKey,
        productCatalog: productCatalog,
        applicationId: applicationId,
        entryId: event.target.value,
        participantKey: selectedParticipantKey,
        primaryApplicantIndividualID: primaryApplicantIndividualID,
      };

      const response = await getSectionDocuments(params);
      setDocumentUrl(response);
    }

    setIsLoading(false);
  };

  return (
    <>
      <Grid container p={1} style={{ backgroundColor: "#e6f0fa", display: "flex", alignItems: "center" }} mb={1}>
        <Grid
          item
          xs={1}
          sx={{ paddinRight: "1em" }}
          className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left"
        >
          Documents
        </Grid>
        {isSecurityDocument === true && (
          <Grid item xs={3}>
            <Stack spacing={1}>
              <SelectComponent
                sx={select_basic}
                label="Select NIC"
                value={nicSelectValue || ''}
                values={nicSelectList}
                onChange={(e) => getDocsOnChange(e)}
                defaultPlaceholder={true}
              />
            </Stack>
          </Grid>
        )}

        {isLoading ? (
          <CircularProgressComponent size={25} sx={{ marginLeft: "1em" }} />
        ) : (
          Array.isArray(documentUrl) &&
          documentUrl.length > 0 && (
            <Grid item xs={8} className="full-height full-width align-right" pr={1}>
              {documentUrl.map((obj: any, index) => {
                const title = obj?.documentName.toString().substring(0, 20);
                return (
                  <ButtonComponent
                    startIcon={singleDocIsLoading === obj?.filePath ? BuildProgress(progress) : <VisibilityIcon />}
                    title={title}
                    variant="outlined"
                    onClick={() => {
                      previewClicked(obj?.filePath);
                    }}
                    style={{ marginLeft: "10px" }}
                    disabled={isLoading}
                    loadingbtn={true}
                    loading={isLoading}
                    key={index}
                  />
                );
              })}
            </Grid>
          )
        )}
      </Grid>
      {securityDocumentKey === "loan-receipt" && downgradedMultipleDisbursement.isHaveMultipleDisbursement ? (
        <Grid container p={1} style={{ backgroundColor: "#e6f0fa", display: "flex", alignItems: "center" }} mb={1}>
          <Tabs
            orientation="horizontal"
            value={selectedTabIndex === -1 ? false : selectedTabIndex}
            scrollButtons="auto"
          >
            {multipleDisbursementDocuments().map((label: any, index: any) => (
              <Tab
                key={index}
                label={convertDisbursementCount[index + 1]}
                sx={{
                  textTransform: "none",
                  fontSize: "16px",
                  fontFamily: "Poppins, Medium",
                  alignItems: "flex-start",
                }}
                onClick={() => handleOnClick(label, index)}
              />
            ))}
          </Tabs>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};

export default SecurityDoceumentsFileDocumentsViewSection;
