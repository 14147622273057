import { Downgraded, useHookstate } from "@hookstate/core";
import { Stack, SxProps, TableCell, Theme, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { formatCurrency } from "../../../../../../../../../services/currencyFormater";
import { OnDemandValueFormatter } from "../../../../../../../../../services/onDemandValidators";
import { select_small } from "../../../../../../../../../styles";
import AutoCompleteProxyComponent from "../../../../../../../../InputComponents/AutoCompleteComponent/AutoCompleteProxyComponent";
import { TextBoxComponent } from "../../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";

interface TableColumnsWrapperProps {
  children: JSX.Element | string;
  index: string | number;
  style?: SxProps<Theme>;
  tableCell?: React.CSSProperties;
}

interface TableColumnsTextBoxWrapperProps {
  value: any;
  index: string | number;
  title: string;
  onChange?: any;
}
interface TableColumnsTextBoxWrapperRevaampProps {
  dataKey: string;
  index: string | number;
  year: string;
  formData: any;
  onChange: any;
  onInput?: any;
}

interface TableColumnsSelecttBoxWrapperRevaampProps {
  dataKey: string;
  index: string | number;
  options: any[]
}

export const TableColumnsElementWrapper: React.FC<TableColumnsWrapperProps> = ({ children, index }: TableColumnsWrapperProps) => {
  if (!index) {
    return <React.Fragment></React.Fragment>;
  }

  return (
    <TableCell key={index.toString() as string}>
      <Stack flex={1} width={"100%"} p={1}>
        {children}
      </Stack>
    </TableCell>
  );
};

export const TableColumnsTextWrapper: React.FC<TableColumnsWrapperProps> = ({ children, index, style, tableCell }: TableColumnsWrapperProps) => {
  if (!index) {
    return <React.Fragment></React.Fragment>;
  }

  let cellStyles: React.CSSProperties = tableCell ? tableCell : { width: '200px', borderBottom: "none" };

  return (
    <TableCell key={index.toString() as string} style={cellStyles} >
      <Stack flex={1} width={"100%"} p={1}>
        <Typography variant="subtitle1" color={"gray"} sx={style} px={2}>
          {children}
        </Typography>
      </Stack>
    </TableCell>
  );
};

export const TableColumnsTextBoxWrapper: React.FC<TableColumnsTextBoxWrapperProps> = ({ index, value, title }: TableColumnsTextBoxWrapperProps) => {
  if (!index) {
    return <React.Fragment></React.Fragment>;
  }

  return (
    <TableCell key={index.toString() as string}>
      <Stack flex={1} width={"100%"} p={1}>
        <Stack pt={1} px={1}>
          <TextBoxComponent label={title} value={formatCurrency(value?.get() || "")} onInput={OnDemandValueFormatter.numeric} fullWidth={true} onChange={(e) => value.set(e.target.value)} required={false} />
        </Stack>
      </Stack>
    </TableCell>
  );
};

export const BalanceSheetTableTextBox: React.FC<TableColumnsTextBoxWrapperProps> = ({ index, value, title, onChange }: TableColumnsTextBoxWrapperProps) => {
  const dataValue = useHookstate(value.attach(Downgraded).get());

  if (!index) {
    return <React.Fragment></React.Fragment>;
  }

  return (
    <TableCell key={index.toString() as string}>
      <Stack flex={1} width={"100%"} p={1}>
        <Stack pt={1} px={1}>
          <TextBoxComponent label={title} value={formatCurrency(dataValue.get())} onInput={OnDemandValueFormatter.numeric} fullWidth={true}
            onChange={(e) => {
              dataValue.set(e.target.value)
            }}
            onBlur={(e) => {
              onChange(e.target.value)
            }} required={false} />
        </Stack>
      </Stack>
    </TableCell>
  );
};

export const TableTextBoxWrapperRevaamp: React.FC<TableColumnsTextBoxWrapperProps> = ({ index, value, title, onChange }: TableColumnsTextBoxWrapperProps) => {
  if (!index) {
    return <React.Fragment></React.Fragment>;
  }

  return (
    <TableCell key={index.toString() as string}>
      <Stack flex={1} width={"100%"} p={1}>
        <Stack pt={1} px={1}>
          <TextBoxComponent label={title} value={formatCurrency(value?.get() || "")} onInput={OnDemandValueFormatter.numeric} fullWidth={true} onChange={(e) => onChange(e.target.value)} required={false} />
        </Stack>
      </Stack>
    </TableCell>
  );
};

export const TableColumnsTextBoxWrapperRevaamp: React.FC<TableColumnsTextBoxWrapperRevaampProps> = ({ index, formData, year, dataKey, onChange, onInput }: TableColumnsTextBoxWrapperRevaampProps) => {

  useEffect(() => {
    setValue(formData?.[year]?.[dataKey])
  }, [formData])

  const [value, setValue] = React.useState(formData?.[year]?.[dataKey] ?? "");

  if (!index) {
    return <React.Fragment></React.Fragment>;
  }

  return (
    <TableCell key={index.toString() as string} style={{ width: '200px' }}>
      <Stack flex={1} width={"100%"} p={1} >
        <Stack>
          <TextBoxComponent
            value={formatCurrency(value)}
            onInput={OnDemandValueFormatter.numeric}
            // fullWidth={true}
            className={select_small}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value, year, dataKey);
            }}
            required={false}
          />
        </Stack>
      </Stack>
    </TableCell>
  );
};

export const TableColumnsSelectBoxWrapperRevaamp = ({ index, value, title, options }) => {

  if (!index) {
    return <React.Fragment></React.Fragment>;
  }

  return (
    <TableCell key={index.toString() as string}>
      <Stack flex={1} width={"100%"} p={1}>
        <Stack pt={1} px={1} className="select_basic_for_auto">
          <AutoCompleteProxyComponent
            value={value.get() ?? ""}
            fullWidth={true}
            options={options}
            onChange={(e) => value.set(e ? e.id : null)}
            cls="select_basic_for_auto"
          />
        </Stack>
      </Stack>
    </TableCell>
  );
};

export const TableColumnTextBoxWrapperTypeString = ({ index, value, title }) => {

  if (!index) return <React.Fragment></React.Fragment>;

  return <TableCell key={index.toString() as string}>
    <Stack flex={1} width={"100%"} p={1}>
      <Stack pt={1} px={1}>
        <TextBoxComponent
          value={value.get() || ""}
          onInput={OnDemandValueFormatter.alphaNumericSpaces}
          // fullWidth={true}
          className={select_small}
          onChange={(e) => value.set(e.target.value)}
          required={false}
        />
      </Stack>
    </Stack>
  </TableCell>

}
