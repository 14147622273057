import { FC } from "react";
import { TextField } from "@mui/material";
import { TextBoxPropsTypes } from "../../../configs/interfaces";
import appStrings from "../../../configs/constants/appStrings";

export const TextBoxComponent: FC<TextBoxPropsTypes> = ({
  autoComplete,
  values,
  autoFocus,
  classes,
  color,
  defaultValue,
  disabled,
  error,
  FormHelperTextProps,
  fullWidth,
  helperText,
  id,
  inputProps,
  InputLabelProps,
  inputRef,
  label,
  margin,
  maxRows,
  minRows,
  multiline,
  name,
  onChange,
  placeholder,
  required,
  rows,
  select,
  SelectProps,
  Select,
  size,
  type,
  value,
  variant,
  style,
  sx,
  onBlur,
  onInput,
  className,
  InputProps,
  onSelect,
  onKeyDown,
}) => {
  return (
    <TextField
      onKeyDown={onKeyDown}
      onSelect={onSelect}
      onInput={onInput}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      classes={classes}
      InputProps={InputProps}
      color={color}
      defaultValue={defaultValue}
      disabled={disabled}
      error={error}
      FormHelperTextProps={FormHelperTextProps}
      fullWidth={fullWidth}
      helperText={helperText}
      id={id}
      inputProps={inputProps}
      InputLabelProps={InputLabelProps}
      inputRef={inputRef}
      label={label}
      margin={margin}
      maxRows={maxRows}
      minRows={minRows}
      multiline={multiline}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      required={required}
      rows={rows}
      select={select}
      SelectProps={SelectProps}
      size={size ? size : appStrings?.common?.small}
      type={type}
      value={value}
      variant={variant}
      style={style}
      sx={sx}
      onBlur={onBlur}
      className={className ? className : "text-box_basic"}
    />
  );
};
