import React, { createRef, forwardRef, useEffect, useImperativeHandle } from "react";
import { Downgraded, useState } from "@hookstate/core";

import { Box, Grid, Stack } from "@mui/material";

import { FieldSectionWrapper } from "../FieldSectionsWrapper.tsx/FieldSectionWrapper";
import { InputGrid } from "../InputGrid/InputGrid";
import { SelectComponent } from "../../InputComponents/SelectComponent/SelectComponent";
import { longSelect } from "../../../styles";

import { SingleImageUploadComponent } from "../DropzoneComponent/SingleImageUploadComponent";
import { documentUploadData } from "../../../configs/stores/document-upload-store/documentUploadStore";
import { checkIfAlreadyUploaded, checkIfAlreadyUploadedSecurityDoc, getDocumentDataById } from "../../../services/documentUploadService";
import { appSetting, creditData } from "../../../configs/mainStore";

import { privilegeKeys } from "../../../configs/constants/privilegeKeys";
import { isSecurityDocUploadApprovedPermitted, multipleDisbursementDocApprovedPermitted } from "../../../utility/helpers/ApplicationMandatoryValidations/isSecurityDocUploadApprovedPermitted";
import { getIsDisbursed } from "../../../utility/helpers/getIsDisbursed";
import { SecurityImageUploadComponent } from "../DropzoneComponent/SecurityImageUploadComponent";
import { CORE_BANK_KEYS } from "../../../configs/constants/contants";
import { getCoreBankData } from "../../../services/bankServiceInvorker";
import { convertNumberToWords } from "../../../utility/util";

interface ApplicationDocumentUploadSectionProps {
  primaryFiles?: any;
  applicantType?: any;
  applicationId?: any;
  participantId?: any;
  uploadedBy?: any;
  primaryUploadedFiles?: any;
  normalFiles?: any;
  normalUploadedFiles?: any;
  documentTypes?: any;
  uploadedDocumentTypes?: any;
  documentComponentId?: any;
  disbursed?: any;
  disableAccordingToTab?: any;
  isLoggedInUserNotTheAssignee?: any;
  reloadComponent?: any;
  isSecurityDocumentUpload?: any;
  currentWorkflowStageId?: any;
  creditFileData?: any;
  children?: any;
  multipleDisbursementDocument?: any;
}

export type Ref = any;

export const ApplicationDocumentUploadSection = forwardRef<Ref, ApplicationDocumentUploadSectionProps>(({ applicantType, applicationId, participantId, uploadedBy, documentTypes, uploadedDocumentTypes, documentComponentId, disableAccordingToTab = false, reloadComponent = false, disbursed, isLoggedInUserNotTheAssignee, creditFileData, children, multipleDisbursementDocument }: ApplicationDocumentUploadSectionProps, docUploadRef) => {
  const appSettings: any = useState(appSetting);

  const { documentDetailsList }: any = documentUploadData.attach(Downgraded).get();
  const [primaryFileList, setPrimaryFileList] = React.useState<any>([]);
  const [normalFileList, setNormalFileList] = React.useState<any>([]);
  const [selectedPrimaryFile, setSelectedPrimaryFile] = React.useState<any>();
  const [uploadedPrimaryFile, setUploadedPrimaryFile] = React.useState<any>();
  const [isFilesHave, setIsFilesHave] = React.useState(false);
  const [cribDataItems, setCribDataItems] = React.useState([]);
  const [cribItemsLoading, setCribItemsLoading] = React.useState<boolean>(false);
  const [disbursementScheduleData, setDisbursementScheduleData] = React.useState<any>([]);

  useEffect(() => {
    let tempDoc;

    let tempDocObj: any = {};

    setIsFilesHave(false);
    if (documentDetailsList) {
      setIsFilesHave(false);

      if (documentTypes.length > 0) {
        documentTypes.map((item: any) => {
          for (const obj in item) {
            if (item[obj]?.length > 0) {
              if (item[obj] && item[obj].map) {
                tempDocObj[obj] = item[obj].map((itemObj) => {
                  const matchingFile = uploadedDocumentTypes.find((item) => item.documentId === itemObj.documentId);

                  tempDoc = getDocumentDataById(documentDetailsList, itemObj);
                  return {
                    ...tempDoc,
                    ...{
                      individualId: itemObj.individualId,
                      isMandatory: itemObj.isMandatory,
                      ref: createRef(),
                      status: itemObj.status,
                      fileName: matchingFile ? matchingFile.fileName : "",
                    },
                  };
                });
              }
            }
          }
        });

        setNormalFileList(tempDocObj);

        setIsFilesHave(true);
      }

      normalFileList.sort((a, b) => (a.isMandatory > b.isMandatory ? -1 : b.isMandatory > a.isMandatory ? 1 : 0));
    }
  }, [documentDetailsList, documentTypes]);

  useImperativeHandle(docUploadRef, () => ({
    async normalDocumentUploadFunc() {
      const promises: any = [];

      for (const obj in normalFileList) {
        normalFileList[obj].map((item) => {
          promises.push(item.ref.current?.uploadSingleImg());
        });
      }

      multipleDisbursementDocument.map((item) => {
        promises.push(item.ref.current?.uploadSingleImg());
      });

      return await Promise.all(promises).then(() => {
        return true;
      });
    },
  }));

  const checkPrivilegeStatus = () => {
    let isDisabled = true;
    if (disbursed) {
      isDisabled = true;
    } else if (disableAccordingToTab) {
      isDisabled = true;
    } else {
      isDisabled = getIsDisbursed(privilegeKeys.DOC_MANAGE_SYSTEM_UPLOAD_REPLACE_DOCUMENT, disbursed) || isLoggedInUserNotTheAssignee;
    }
    return isDisabled;
  };

  const BuildNormalFileList = ({ file, index }) => {
    let { isHave, status } = checkIfAlreadyUploaded(file?.documentId, uploadedDocumentTypes, file?.individualId);

    const permissionToUploadDocs = isSecurityDocUploadApprovedPermitted(file.documentId, privilegeKeys.SEC_DOC_UPLOAD_SIGNED_DOCUMENTS);
    return (
      <SecurityImageUploadComponent
        fileDetails={file}
        uploadDataObject={{
          applicationId: applicationId,
          applicantType: applicantType,
          participantId: participantId,
          documentId: file?.documentId,
          uploadedBy: uploadedBy,
          isPrimary: false,
          individualId: file?.individualId,
          scheduleId: 1,
        }}
        isPreviewHave={isHave}
        status={status}
        permissionToUploadDocs={permissionToUploadDocs}
        documentComponentId={documentComponentId}
        reloadComponent={reloadComponent}
        creditFileData={creditFileData}
        index={index}
      />
    );
  };

  const BuildMultipleDisbursementComponent = ({ file, key }) => {
    let { isHave, status } = checkIfAlreadyUploadedSecurityDoc(file?.documentId, uploadedDocumentTypes, file?.individualId, file?.fileName, file?.scheduleId, file?.documentKey);

    const permissionToUploadDocs = multipleDisbursementDocApprovedPermitted(file?.disbStatus, privilegeKeys.SEC_DOC_UPLOAD_SIGNED_DOCUMENTS, file?.documentKey);

    return (
      <SecurityImageUploadComponent
        fileDetails={file}
        uploadDataObject={{
          applicationId: applicationId,
          applicantType: applicantType,
          participantId: participantId,
          documentId: file?.documentId,
          uploadedBy: uploadedBy,
          isPrimary: false,
          individualId: file?.individualId,
          scheduleId: file?.scheduleId,
        }}
        isPreviewHave={isHave}
        status={status}
        permissionToUploadDocs={permissionToUploadDocs}
        documentComponentId={documentComponentId}
        reloadComponent={reloadComponent}
        creditFileData={creditFileData}
        index={key}
      />
    );
  };

  return (
    <Box>
      {isFilesHave ? (
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12}>
            <Grid container rowSpacing={2}>
              {Object.keys(normalFileList).map((key: any) => (
                <Grid item xs={12} key={key}>
                  <Grid container rowSpacing={2}>
                    {normalFileList[key].map((item: any, i: number) => {
                      return <BuildNormalFileList key={i} file={item} index={key}></BuildNormalFileList>;
                    })}
                    {multipleDisbursementDocument?.map((item, i) => {
                      return <BuildMultipleDisbursementComponent file={item} key={key}></BuildMultipleDisbursementComponent>;
                    })}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <>Empty Document List</>
      )}
    </Box>
  );
});
