import { LoanTerms } from '../../../components/OtherComponents/LoanCalculatorComponent/Calculator/Calculator';
import {
  validatelength,
  validateLimit,
  validatemaximumAmount,
  validateMinimumAmount,
  validatePositiveNumber,
  validatePositiveNumberWithDecimalPlaces,
} from '../validation-rules/validationRules';

export const leadCreditInfoRevampValidationHandler = (creditDetails, productCatelogKeyData) => {

  const keyString = `${creditDetails?.productTypes?.id}-${creditDetails?.sectorTypes?.id}-${creditDetails?.scheme}`;

  const minLoanAmount = productCatelogKeyData?.[keyString]?.MIN_LOAN_AMOUNT;
  const maxLoanAmount = productCatelogKeyData?.[keyString]?.MAX_LOAN_AMOUNT;
  const gracePeriodLimit = productCatelogKeyData?.[keyString]?.GRACE_PERIOD;

  const minInterestRate = productCatelogKeyData?.[keyString]?.MIN_INTEREST_RATE;
  const maxInterestRate = productCatelogKeyData?.[keyString]?.MAX_INTEREST_RATE;

  const minLoanTerm = productCatelogKeyData?.[keyString]?.MIN_LOAN_TERM;
  const maxLoanTerm = productCatelogKeyData?.[keyString]?.MAX_LOAN_TERM;

  const gracePeriodVal = creditDetails?.gracePeriod && creditDetails?.scheme ? gracePeriodValidation("Grace Period", creditDetails?.gracePeriod, gracePeriodLimit) : null;

  const loanAmountVal =
    creditDetails?.loanAmount && creditDetails?.scheme
      ? loanAmountValidation('Loan Amount', creditDetails?.loanAmount, 2, minLoanAmount, maxLoanAmount)
      : null;

  const loanCycleVal =
    creditDetails?.loanCycles && creditDetails?.scheme
      ? LoanCycleValidation('Loan Cycle', creditDetails?.loanCycles, 1, 24)
      : null;

  const proposedRateVal =
    creditDetails?.interestRate && creditDetails?.scheme
      ? proposedRateValidation('Proposed Rate', creditDetails?.interestRate, 2, minInterestRate, maxInterestRate)
      : null;

  const loanTermVal =
    creditDetails?.loanTerms && creditDetails?.scheme
      ? loanTermValidation('Loan Tenor', creditDetails?.loanTerms, minLoanTerm, maxLoanTerm)
      : null;

  const leadCreditInfoValidationErrors: any = {};
  if (loanAmountVal) leadCreditInfoValidationErrors.loanAmount = loanAmountVal;
  if (loanCycleVal) leadCreditInfoValidationErrors.loanCycle = loanCycleVal;
  if (proposedRateVal) leadCreditInfoValidationErrors.proposedRate = proposedRateVal;
  if (loanTermVal) leadCreditInfoValidationErrors.loanTerm = loanTermVal;
  if (gracePeriodVal) leadCreditInfoValidationErrors.gracePeriod = gracePeriodVal;

  return Object.keys(leadCreditInfoValidationErrors).length > 0 ? leadCreditInfoValidationErrors : 1;

  //================== Lead Personal Info Erros Object - End ==================//
};

const loanAmountValidation = (filedName, value, decimals, minimimAmount, maximumAmount) => {
  // console.log('min => ', minimimAmount);
  // console.log('max => ', maximumAmount);

  const result: any[] = [];
  const checkAmount = validatePositiveNumberWithDecimalPlaces(filedName, value, decimals);
  const checkMinimum = validateMinimumAmount(filedName, value, minimimAmount);
  const checkMaximum = validatemaximumAmount(filedName, value, maximumAmount);
  const valdateLength = validatelength(filedName, value);
  if (checkAmount) result.push(checkAmount);
  if (checkMinimum) result.push(checkMinimum);
  if (checkMaximum) result.push(checkMaximum);
  if (valdateLength) result.push(valdateLength);

  return result.length > 0 ? result : null;
};

export const gracePeriodValidation = (fieldName,value, limit) => {
  const result: any[] = [];
  const checkLimit = validateLimit(fieldName, value, limit);
  if (checkLimit) result.push(checkLimit);
  return result.length > 0 ? result : null;
}

export const loanAmountValidationHelper = loanAmountValidation;

const LoanCycleValidation = (filedName, value, minimimAmount, maximumAmount) => {
  const result: any[] = [];
  const checkAmount = validatePositiveNumber(filedName, value);
  const checkMinimum = validateMinimumAmount(filedName, value, minimimAmount);
  const checkMaximum = validatemaximumAmount(filedName, value, maximumAmount);
  const valdateLength = validatelength(filedName, value);
  if (checkAmount) result.push(checkAmount);
  if (checkMinimum) result.push(checkMinimum);
  if (checkMaximum) result.push(checkMaximum);
  if (valdateLength) result.push(valdateLength);
  return result.length > 0 ? result : null;
};

export const loanCycleValidationHelper = LoanCycleValidation;

const proposedRateValidation = (filedName, value, decimals, minimimAmount, maximumAmount) => {
  const result: any[] = [];
  // const checkAmount = validatePositiveNumberWithDecimalPlaces(filedName, value, decimals);
  const checkMinimum = validateMinimumAmount(filedName, value, minimimAmount);
  const checkMaximum = validatemaximumAmount(filedName, value, maximumAmount);
  const valdateLength = validatelength(filedName, value);
  // if (checkAmount) result.push(checkAmount);
  if (checkMinimum) result.push(checkMinimum);
  if (checkMaximum) result.push(checkMaximum);
  if (valdateLength) result.push(valdateLength);
  return result.length > 0 ? result : null;
};

export const proposedRateValidationHelper = proposedRateValidation;

const loanTermValidation = (filedName, value, minimimAmount, maximumAmount) => {
  const result: any[] = [];
  const checkAmount = validatePositiveNumber(filedName, value);
  const valdateLength = validatelength(filedName, value);
  const checkMinimum = validateMinimumAmount(filedName, value, minimimAmount);
  const checkMaximum = validatemaximumAmount(filedName, value, maximumAmount);
  if (checkAmount) result.push(checkAmount);
  if (checkMinimum) result.push(checkMinimum);
  if (checkMaximum) result.push(checkMaximum);
  if (valdateLength) result.push(valdateLength);
  return result.length > 0 ? result : null;
};

export const loanTermValidationHelper = loanTermValidation;