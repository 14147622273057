import { LengthItemValidate } from "../../../../ApplicationForm/Sections/SecurityDetails/SecurityData/Helpers/LengthItemValidatedFn";
import { requetsDtlKeyMapping } from "../InspectionFormParking/CollateralInspections/Helpers/configs";

export const inspection_tab: string[] = ["inspection_details", "primary_applicant_inspections", "jb_inspections", "business_inspection", "collateral_inspections", "scorecard_questions", "inspection_other_questions", "inspection_sustainable_env_and_safeguard", "inspection_participation"];

export const inspectionTabReorder = (renderingTab, { securityData }) => {
  let index = 0;
  renderingTab = renderingTab.filter((tabKey: string) => {
    if (!inspection_tab.includes(tabKey)) {
      return tabKey;
    }
    index = index + 1;
  });

  if (index > 0) {
    renderingTab = [...renderingTab, ...inspection_tab];
  }

  const res: any = filterCollateralsIfNeeded(securityData);

  if (res.response === 0) {
    renderingTab = renderingTab.filter((tabKey: string) => tabKey !== "collateral_inspections");
  }

  return renderingTab;
};

export const filterCollateralsIfNeeded = (securityData: any, tabArray = []) => {
  try {
    let response = 0;

    for (let index = 0; index < Object.values(requetsDtlKeyMapping).length; index++) {
      let key: string | string[] = Object.values(requetsDtlKeyMapping)[index];

      key = key.split("_");
      let mainKey = key[0];
      let subKey = key[1];

      if (!securityData[mainKey] || !securityData[mainKey][subKey]) {
        if (tabArray.length > 0) {
          const verticalTabKey = Object.keys(requetsDtlKeyMapping)[index];
          tabArray = tabArray.filter((item: any) => item.id !== verticalTabKey);
        }
        continue;
      }

      const res: any[] = LengthItemValidate.validatedArray(securityData[mainKey][subKey]);

      if (res.length > 0) {
        response = 1;
        break;
      }
    }

    return {
      response,
      tabArray,
    };
  } catch (error) {
    return {
      response: 0,
      tabArray,
    };
  }
};

export const filterSubKeyFromArray = (securityData: any, tabArray = []) => {
  let securityObject = {};

  for (let index = 0; index < Object.values(requetsDtlKeyMapping).length; index++) {
    let key: string = Object.values(requetsDtlKeyMapping)[index];
    const verticalTabKey = Object.keys(requetsDtlKeyMapping)[index];

    let keyArry = key.split("_");
    let mainKey = keyArry[0];
    let subKey = keyArry[1];

    if (!securityData[mainKey] || !securityData[mainKey][subKey]) {
      tabArray = filterInvalidTabKey(tabArray, verticalTabKey);
      continue;
    }

    let filteredDataObject = LengthItemValidate.validatedArray(securityData[mainKey][subKey]);  

    if (filteredDataObject.length === 0){
      tabArray = filterInvalidTabKey(tabArray, verticalTabKey);
      continue;
    }

    securityObject = {
      ...securityObject,
      [verticalTabKey]: securityData[mainKey][subKey],
    };
  }

  return {
    tabArray,
    securityObject,
  };
};

const filterInvalidTabKey = (tabArray, verticalTabKey) => {
  if (tabArray.length > 0) {
    tabArray = tabArray.filter((item: any) => {
      if (item.id !== verticalTabKey) {
        return item;
      }
    });
  }

  return tabArray;
}
