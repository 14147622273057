import { Grid, Stack, Typography } from "@mui/material";

import { useState } from "@hookstate/core";
import BackupTableSharp from "@mui/icons-material/BackupTableSharp";
import ForkRightIcon from "@mui/icons-material/ForkRight";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import SmsFailedOutlinedIcon from "@mui/icons-material/SmsFailedOutlined";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import { workflowMasterData } from "../../../../../configs/mainStore";
import { getUserDetailsById } from "../../../../../services/userService";
import AppConfig from "../../../../../utility/AppConfig";
import { dateFormatWithTimeChange } from "../../../../../utility/fomatters";
import ContentExtractor from "../../../../../utility/helpers/ContentExtractor";
import { getUserDesignationFromJSON, userName } from "../../../../../utility/helpers/creditFileUtility";
import { openGlobalModal } from "../../../GlobalModal/GlobalModal";
import { edgeColors } from "../../../WorkflowComponents/utils";

const HistoryItemRow = ({ data, stageColors, revertHandled = (params) => { } }) => {
  const { wf_actions_all, wf_jobs, wf_stepTypes, wf_stages }: any = useState(workflowMasterData);

  const stepType = wf_stepTypes?.get()?.find((stepType) => stepType?.id == data?.nextStepType);
  const time = data.timeStamp && dateFormatWithTimeChange(data.timeStamp);
  const action = wf_actions_all?.get()?.find((act) => act?.id == data?.action);
  const stage = wf_stages?.get()?.find((stg) => stg?.id == data?.nextStage);
  const assignedUser = userName(data?.assignTo);
  const user = userName(data?.actionBy);
  const job = wf_jobs?.get()?.find((job) => job?.id == data?.nextJob);
  let note = data?.note;
  const stageId = stage?.id;

  note = data?.note;

  const { backgroundColor = "", buttonColor = "" } = stageColors?.[stageId] ?? { backgroundColor: "", buttonColor: "" };

  const showRevertStage = (stageDetails) => {
    openGlobalModal({
      modalSize: "sm",
      title: "",
      bodyId: "modal-application-stage-revert-nolog",
      close: false,
      modalParams: {
        data: stageDetails,
        payload: data,
        revertHandled,
        type: "workflowRevert"
      },
    });
  };

  return (
    <Grid container className="full-width border-radius-5" alignItems="center" p={1} style={{ background: `${backgroundColor}` }} key={data.sequence}>
      {stepType && stepType.stepTypeKey === "stage" && (
        <>
          <Grid item xs={1} className="align-center">
            <Stack justifyContent={"center"} alignItems={"center"} direction={"row"}>
              <Typography style={{ border: "solid 2px gray", width: "14pt", borderRadius: "16px", color: "gray", padding: "2px", marginRight: "10px", fontSize: "10pt" }}>{data.stageCount - data.idx}</Typography>
              <ForkRightIcon className="basic-font-color" />
              {AppConfig.control && <BackupTableSharp className="basic-font-color" onClick={() => showRevertStage(stepType)} />}
            </Stack>
          </Grid>
          <Grid item container xs={7}>
            <Grid item xs={3}>
              {action ? (
                <div
                  className="basic-font font-size-14 label border-radius-5 align-center"
                  style={{
                    backgroundColor: edgeColors[action?.actionKey]?.color,
                    color: "white",
                    margin: 2,
                  }}
                >
                  {action && action.name}
                </div>
              ) : null}
            </Grid>
            <Grid item xs={4}>
              <ContentExtractor text={stage?.id}>
                <div
                  className="basic-font font-size-14 label border-radius-5 align-center"
                  style={{
                    backgroundColor: `${buttonColor}`,
                    color: "white",
                    margin: 2,
                    minWidth: "100% ",
                  }}
                >
                  {stage && stage.name}
                  {AppConfig.control && <Typography variant="caption" mx={2}>({stage.id})</Typography>}
                </div>
              </ContentExtractor>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={4}>
              <ContentExtractor text={data?.actionBy}>
                <div className="basic-font font-size-16 basic-font-color" style={{ margin: 2 }}>
                  {user}
                  {AppConfig.control && <Typography variant="caption" mx={2}>({data?.actionBy})</Typography>}
                </div>
              </ContentExtractor>
              <div className="basic-font font-size-14 basic-font-color" style={{ margin: 2 }}>
                {/* {data?.actionBy ? getUserDesignation(getUserDetailsById(data?.actionBy)?.designation) : "Designation Not Available"} */}
                {data?.actionBy ? getUserDesignationFromJSON(getUserDetailsById(data?.actionBy)) : "Designation Not Available"}
              </div>
              <div className="align-left basic-font font-size-14 basic-font-color" style={{ margin: 2 }}>
                {time && time}
              </div>
            </Grid>
          </Grid>
        </>
      )}
      {stepType && stepType.stepTypeKey === "job" && (
        <>
          <Grid item xs={1} className="align-center">
            {job?.key === "email" && <MarkEmailReadOutlinedIcon className="basic-font-color" />}
            {job?.key === "sms" && <SmsFailedOutlinedIcon className="basic-font-color" />}
            {job?.key === "ir" && <WorkHistoryOutlinedIcon className="basic-font-color" />}
          </Grid>
          <Grid item container xs={7}>
            <Grid item xs={4}>
              <div
                className="basic-font font-size-14 label border-radius-5 align-center"
                style={{
                  backgroundColor: edgeColors[action?.actionKey]?.color,
                  color: "white",
                  margin: 2,
                }}
              >
                {action && action.name}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                className="basic-font font-size-14 label border-radius-5 align-center"
                style={{
                  backgroundColor: "#2196f3",
                  color: "white",
                  margin: 2,
                }}
              >
                {job && job.name}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="basic-font font-size-16 basic-font-color"> {user} </div>
            </Grid>
          </Grid>
        </>
      )}

      {
        <Grid item xs={4}>
          <ContentExtractor text={data?.assignTo}>
            <div className="basic-font font-size-16 basic-font-color"> {assignedUser} {AppConfig.control && <Typography variant="caption" mx={2}>({data?.assignTo})</Typography>}</div>
          </ContentExtractor>
          <div className="basic-font font-size-14 basic-font-color" style={{ margin: 2 }}>
            {/* {assignedUser ? getUserDesignation(getUserDetailsById(data?.assignTo)?.designation) : "Designation Not Available"} */}
            {assignedUser ? getUserDesignationFromJSON(getUserDetailsById(data?.assignTo)) : "Designation Not Available"}
          </div>
          <div className="align-left basic-font font-size-14 basic-font-color" style={{ margin: 2 }}>
            {time && time}
          </div>
        </Grid>
      }
      <Grid item xs={1}></Grid>
      <Stack flex={1}>
        <Typography className="basic-font-color-bold" style={{ margin: 2 }}>
          Comment :
        </Typography>
        {<Typography variant="body1" className="basic-font-color" style={{
          display: "inline-block",
          whiteSpace: "pre-line",
          flexWrap: "wrap",
          wordWrap: "break-word",
        }}>{note}</Typography>}
      </Stack>
    </Grid>
  );
};

export default HistoryItemRow;
