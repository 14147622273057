import moment from "moment";
import { generateUniqueId, getCopy } from "../../../../../../../../../utility/util";
import { InspectionProps } from "../OnSubmitInspections/SaveChangesInspection";

export const inspection = {
  borrowers: {},
  summery: {},
  business: {},
  participation: {},
  collateral: {},
  scoreCard: {},
  otherQuestions: {},
  sustainableEnvironmentalAndSafeguard: {},
};

export const inspectionState: InspectionProps = JSON.parse(JSON.stringify(inspection))
export const inspetionId = null;

export const sectionDataKey = {
  primaryApplicant: "borrowers",
  jointBorrower: "borrowers",
  summery: "summery",
  participation: "participation",
  collateral: "collateral",
  business: "business",
  sustainableEnvironmentalAndSafeguard: "sustainableEnvironmentalAndSafeguard"
};

export const businessAssertOtherDetails = {
  assetCategory: "",
  assertType: "",
  description: "",
  assertLiquidity: "",
  assertTimeFrame: "",
  forcedSaleValue: 0,
  marketValue: "",
  assertAge: "",
  assertMorgaged: 0,
  morgageInstitute: "",
  morgageValue: "",
  needtoDepreciate: 0,
  assertLifeTime: "",
  rateofdepreciation: "",
  depreciationExpense: "",
};

const businessAssertOtherDetailsState = JSON.parse(JSON.stringify(businessAssertOtherDetails));

export const businessAssertOtherDetailsYears = {
  [Number(moment().year()) + 1]: [getCopy({ ...businessAssertOtherDetailsState, dataId: generateUniqueId() })],
  [Number(moment().year())]: [getCopy({ ...businessAssertOtherDetailsState, dataId: generateUniqueId() })],
  [Number(moment().year()) - 1]: [getCopy({ ...businessAssertOtherDetailsState, dataId: generateUniqueId() })],
}

export const salesandPurchaseInformationofFastMovingItemsOtherdata = {
  item: "",
  buyingPrice: "",
  sellingPrice: "",
  profit: "",
  dataId: generateUniqueId(),
};

const salesandPurchaseInformationofFastMovingItemsOtherdataState = getCopy(salesandPurchaseInformationofFastMovingItemsOtherdata);

export const salesandPurchaseInformationofFastMovingItemsdata = {
  busineeCategory: "",
  profit: "",
  cashInTill: "",
  recommendedProfit: "",
  totalProfit: 0,
  dataId: generateUniqueId(),
  otherDetails: getCopy([salesandPurchaseInformationofFastMovingItemsOtherdataState]),
};

const salesandPurchaseInformationofFastMovingItemsdataState = getCopy(salesandPurchaseInformationofFastMovingItemsdata);

export const businessLiabilityExpensesDetails = {
  liabilityType: "",
  bankorInstitute: "",
  amountofTheFacility: "",
  grantedDate: "",
  obligationType: "",
  debtee: "",
  installmentFrequency: "",
  installmentAmount: "",
  numberofInstallmentsLeft: "",
  currentOutstanding: "",
  securityCategoryLoanLease: "",
  securityType: "",
  valuation: "",
  statusOfLoan: "",
  moreInfoOnRepaymentLoan: "",
  type: 1,
};

export const businessLiabilityExpensesOverDraft = {
  liabilityType: "",
  bankorInstitute: "",
  limitOfOverDraft: "",
  outStanding: "",
  expiryDate: "",
  securityCategoryOverDraft: "",
  numberofInstallmentsLeft: "",
  installmentAmount: "",
  type: 2,
};

const businessLiabilityExpensesState = JSON.parse(JSON.stringify(businessLiabilityExpensesDetails));

export const businessLoanLeaseDefault = {
  [Number(moment().year()) + 1]: [],
  [Number(moment().year())]: [],
  [Number(moment().year()) - 1]: [],
}

export const businessOverDraftDefault = {
  [Number(moment().year()) + 1]: [getCopy({ ...businessLiabilityExpensesOverDraft, dataId: generateUniqueId() })],
  [Number(moment().year())]: [getCopy({ ...businessLiabilityExpensesOverDraft, dataId: generateUniqueId() })],
  [Number(moment().year()) - 1]: [getCopy({ ...businessLiabilityExpensesOverDraft, dataId: generateUniqueId() })],
}

export const businessIncome = {
  sourceOfIncome: "",
  frequency: "",
  income: "",
  annualBusinessIncome: "",
  cashBasisIncome: "",
  crediBasisIncome: "",
};

const businessIncomeState = JSON.parse(JSON.stringify(businessIncome));

export const businessIncomeYears = {
  [Number(moment().year()) + 1]: [getCopy({ ...businessIncomeState, dataId: generateUniqueId() })],
  [Number(moment().year())]: [getCopy({ ...businessIncomeState, dataId: generateUniqueId() })],
  [Number(moment().year()) - 1]: [getCopy({ ...businessIncomeState, dataId: generateUniqueId() })],
}

export const businessExpenses = {
  expenseType: "",
  frequency: "",
  expense: "",
  annualExpense: "",
  cashBasisExpense: "",
  crediBasisExpense: "",
};

const businessExpensesState = JSON.parse(JSON.stringify(businessExpenses));

export const businessExpensesYears = {
  [Number(moment().year()) + 1]: [getCopy({ ...businessExpensesState, dataId: generateUniqueId() })],
  [Number(moment().year())]: [getCopy({ ...businessExpensesState, dataId: generateUniqueId() })],
  [Number(moment().year()) - 1]: [getCopy({ ...businessExpensesState, dataId: generateUniqueId() })],
}

export const deductionsCostsOfGoods = {
  deductionsCOGType: "",
  frequency: "",
  deductionsCOG: "",
  annualDeductionsCOG: "",
  cashBasisDeductionsCOG: "",
  crediBasisDeductionsCOG: "",
};

const deductionsCostsOfGoodsState = JSON.parse(JSON.stringify(deductionsCostsOfGoods));

export const deductionsCostsOfGoodsYears = {
  [Number(moment().year()) + 1]: [getCopy({ ...deductionsCostsOfGoodsState, dataId: generateUniqueId() })],
  [Number(moment().year())]: [getCopy({ ...deductionsCostsOfGoodsState, dataId: generateUniqueId() })],
  [Number(moment().year()) - 1]: [getCopy({ ...deductionsCostsOfGoodsState, dataId: generateUniqueId() })],
}

export const stock = {
  type: "",
  description: "",
  amount: "",
  numberOfDays: "",
};

const stockState = JSON.parse(JSON.stringify(stock));

export const debtors = {
  period: "",
  description: "",
  amount: "",
  numberOfDays: "",
};

const debtorsState = JSON.parse(JSON.stringify(debtors));

export const creditors = {
  period: "",
  description: "",
  amount: "",
  numberOfDays: "",
};

const creditorsState = JSON.parse(JSON.stringify(creditors));

export const defaultExtractEmployeType = { employeeType: "", permanent: "", contract: "" };

const extractEmployeTypeState = JSON.parse(JSON.stringify(defaultExtractEmployeType));

const employementDetails = {
  extractEmployeType: [extractEmployeTypeState],
  extractAgeRange: {
    noOfFemale: {
      belowFourty: "",
      aboveFourty: "",
      belowSixteen: "",
      betweenSixteenAndTwentyFive: "",
      betweenTwentySixAndThirtyFive: "",
      betweenThirtySixAndFortyFive: "",
      aboveFourtyFive: ""
    },
    noOfMale: {
      belowFourty: "",
      aboveFourty: "",
      belowSixteen: "",
      betweenSixteenAndTwentyFive: "",
      betweenTwentySixAndThirtyFive: "",
      betweenThirtySixAndFortyFive: "",
      aboveFourtyFive: ""
    },
  },
};

export const markettingArrangement = {
  isMarketingArrangementAvailable: 1,
  markettingArrangement: "",
  comment: "",
};

const businessInspectionDetails = {
  salesandPurchaseInformationofFastMovingItemsOtherdata: getCopy([salesandPurchaseInformationofFastMovingItemsdataState]),
  totaleBusinessAssertValue: "",
  totaleBusinessAssertOtherDetails: getCopy(businessAssertOtherDetailsYears),
  totaleBusinessLiabilityExpenses: "",
  businessLiabilityExpenses: getCopy(businessLoanLeaseDefault),
  totalBusinessIncome: "",
  businessIncome: getCopy(businessIncomeYears),
  totalBusinessExpense: "",
  businessExpenses: getCopy(businessExpensesYears),
  deductionsCostsOfGoods: getCopy(deductionsCostsOfGoodsYears),
  stock: [stockState],
  debtors: [debtorsState],
  creditors: [creditorsState],
  employementDetails,
  markettingArrangement,
};

export const defaultBusinessState = JSON.parse(JSON.stringify(businessInspectionDetails));

const businessInspectionDetailsState = JSON.parse(JSON.stringify(businessInspectionDetails));

export const inspectionOfficerDetails = {
  inspectionOfficer: "",
  inspectionId: "",
  purposeOfInspection: "",
  date: null,
  time: null,
  recommendation: "",
};

const inspectionOfficerDetailsstr = JSON.parse(JSON.stringify(inspectionOfficerDetails));

export const inspectionOfficerDetailsState = [JSON.parse(JSON.stringify(inspectionOfficerDetailsstr))];

export const scoreCardQuestionDefault: any = {
  agricultureModelIndividual: {},
  agricultureModelJointB: {},
  agricultureModelBusiness: {},
  manufactureTradeAndServiceIndividual: {},
  manufactureTradeAndServiceJointB: {},
  manufactureTradeAndServiceBusiness: {},
  cultivationModel: {},
  otherThancultivationModel: {},
};

export const scoreCardSection = {
  // 0: "agricultureModelIndividual",
  // 1: "agricultureModelJointB",
  // 2: "agricultureModelBusiness",
  // 3: "manufactureTradeAndServiceIndividual",
  // 4: "manufactureTradeAndServiceJointB",
  // 5: "manufactureTradeAndServiceBusiness",
  0: "cultivationModel",
  1: "otherThancultivationModel",
};

export const inspectionFormData = { businessInspectionDetailsState };

export const yearArrayObject = {
  prevYear: Number(moment().year()) - 1,
  currentYear: Number(moment().year()),
  nextYear: Number(moment().year()) + 1
};

