import { useState } from "@hookstate/core";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { Box, Grid, Stack } from "@mui/material";
import React, { createRef, useEffect } from "react";
import { ERROR_CODES } from "../../../../../../configs/constants/errorCode";
import { userDetails, workflowMasterData } from "../../../../../../configs/mainStore";
import {
  applicationTimelineApi,
  saveReferData
} from "../../../../../../services/creditFileApiCall";
import { getUsersByGroupId } from "../../../../../../services/userPrivilegeService";
import { closeBtn } from "../../../../../../styles";
import { responseSuccessCode } from "../../../../../../utility/other";
import { referStatus } from "../../../../../../utility/util";
import { ButtonComponent } from "../../../../../InputComponents/ButtonComponent/ButtonComponent";
import ModalInputComponent from "../../../../../InputComponents/ModalInputComponent/ModalInputComponent";
import { SelectComponent } from "../../../../../InputComponents/SelectComponent/SelectComponent";
import { TextBoxComponent } from "../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { closeGlobalModal } from "../../../../GlobalModal/GlobalModal";
import { addToaster } from "../../../../GlobalToast";
import { CircularProgressComponent } from "../../../../ProgressComponent/ProgressComponent";

const OfficerCauReferModal = (params) => {
  const initialData = { note: "", user: "" };
  const showErrors = useState(false);
  const { wf_system_users, wf_groups }: any = useState(workflowMasterData);
  const referState: any = useState(initialData);
  const loginUser = useState(userDetails);
  const referId = params?.data?.referId;

  const docUploadRef: any = createRef();

  const [isLoading, setIsLoading] = React.useState<any>(false);
  const [isDropDownUserListLoading, setDropdownUserListLoading] = React.useState<any>(false);
  const [isDropDownGroupListLoading, setDropdownGroupListLoading] = React.useState<any>(false);
  const [dropdownUserList, setDropdownUserList] = React.useState<any>([]);
  const [referGroupsList, setReferGroupsList] = React.useState<any>([]);
  const [referGroup, setReferGroup] = React.useState<any>([]);
  const [headOfficer, setHeadOfficer] = React.useState<any>(false);
  const [headOfficerList, setHeadOfficerList] = React.useState<any>(false);
  const [referalGroupIDs, setReferalGroupIDs] = React.useState<any>({});
  const [referalIDLists, setReferralIDLists] = React.useState<any>({});

  const [refresh, setRefresh] = React.useState(false);
  const [fileList, setFileList] = React.useState<any>([
    {
      //TODO LEGAL DOC GET FROM DB
      ref: docUploadRef,
      isMandatory: true,
      allowedFormats: "application/pdf",
      maxFileSize: 4000000,
      documentKey: "",
    },
  ]);

  const saveRefer = async () => {
    setIsLoading(true);
    const errorState = validateSave();
    if (errorState) {
      showErrors.set(true);
    } else {
      const referStat = Object.keys(referStatus)?.find((sts) => referStatus[sts]?.key == "active");
      const data = {
        applicationId: params?.applicationId,
        createdBy: loginUser?.userId?.get(),
        workflowId: params?.currentWorkflow,
        stageId: params?.currentWorkflowStage,
        referStatus: referStat,
        referType: referGroup,
        cauOfficer: true,
        referId: referId || null,
        ...referState.get(),
      };

      const response = await saveReferData(data);

      if (response?.status == responseSuccessCode) {
        addToaster({
          status: "success",
          title: "Success",
          message: response?.data?.msg,
        });
      } else {
        addToaster({
          status: "error",
          title: "Error",
          message: response?.data?.msg,
        });
      }

      const referenceId =
        params?.cauAdminmodal === true ? params?.data?.referId : response?.data?.data?.referId;
      const actionType =
        referGroup === referalIDLists?.legalGroupId
          ? "ASSIGN_TO_LEGAL_USER"
          : "ASSIGN_TO_RISK_USER";

      const appTimelineParams = {
        referenceId,
        applicationId: params?.applicationId,
        actionedBy: loginUser?.userId?.get(),
        assignee: referState?.user?.get(),
        actionNote: referState?.note?.get() || "",
        actionType: actionType,
      };

      const applicationTimelineApiResponse = await applicationTimelineApi(appTimelineParams);

      if (applicationTimelineApiResponse?.status === ERROR_CODES.success) {
        addToaster({
          status: "success",
          title: "Success",
          message: applicationTimelineApiResponse?.data?.message,
        });
      } else {
        addToaster({
          status: "error",
          title: "Error",
          message: "Timeline Update Failed",
        });
      }

      closeGlobalModal();
    }
    setIsLoading(false);
  };

  const closeRefer = () => {
    closeGlobalModal();
  };

  const validateSave = () => {
    return referState.note.get() === "" || !referState.user.get();
  };

  const resetError = () => {
    if (showErrors.get()) {
      showErrors.set(false);
    }
  };

  const getReferGroupId = async (groupID) => {
    setDropdownUserListLoading(true);
    let usersListData = await getUsersByGroupId(groupID);

    const correspondingValues = Object.keys(usersListData).map((key) => {
      const id = parseInt(key);
      const object = wf_system_users?.find((obj) => obj?.get().id === id);
      return {
        name: object?.fullName,
        id: object?.id,
      };
    });

    setDropdownUserList(correspondingValues);
    setDropdownUserListLoading(false);
  };

  useEffect(() => {
    (async () => {
      setDropdownGroupListLoading(true);
      const referalIDList = params?.cauReferalData;
      let tempUserGroupList: any = [];
      wf_groups?.get()?.map((item: any) => {
        if (item?.id === referalIDList?.cau_hub_officer) {
          tempUserGroupList.push({ name: item?.name, id: item?.id });
        }
      });

      setReferGroupsList(tempUserGroupList);
      setDropdownGroupListLoading(false);
    })();
  }, []);

  const onClickAddBtn = () => {
    let tempFileList = fileList;
    tempFileList.push({
      //TODO LEGAL DOC GET FROM DB
      ref: docUploadRef,
      isMandatory: false,
      allowedFormats: "application/pdf",
      maxFileSize: 4000000,
      documentKey: "",
    });

    setFileList(tempFileList);
    setRefresh(!refresh);
  };

  return (
    <Box>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <div className="basic-font font-size-18 basic-font-color-bold">Create Referral- CAU</div>
      </Stack>

      <Grid container spacing={2} mt={1} className="modal-text-field">
        <Grid container item xs={12}>
          <ModalInputComponent
            title="Note"
            component={
              <TextBoxComponent
                value={referState?.note?.get()}
                onChange={(e) => {
                  resetError();
                  referState.note.set(e.target.value);
                }}
                variant="outlined"
                size="small"
                multiline={true}
                minRows={4}
                inputProps={{ maxLength: 100 }}
              />
            }
            isMandatory={true}
            showErrors={showErrors.get() && referState.note.get() === ""}
          />
        </Grid>
        {isDropDownGroupListLoading ? (
          <CircularProgressComponent size={20} />
        ) : (
          <Grid container item xs={12}>
            <ModalInputComponent
              title="Refer Type"
              component={
                <SelectComponent
                  onChange={(e) => {
                    resetError();
                    setReferGroup(e.target.value);
                    getReferGroupId(e.target.value);
                  }}
                  values={referGroupsList}
                  // value={referState?.user?.get()}
                  size="small"
                  label={"Select Refer Type"}
                />
              }
              isMandatory={true}
              showErrors={showErrors.get() && !referState.user.get()}
            />
          </Grid>
        )}
        {isDropDownUserListLoading ? (
          <CircularProgressComponent size={20} />
        ) : (
          dropdownUserList?.length > 0 && (
            <Grid container item xs={12}>
              <ModalInputComponent
                title="User"
                component={
                  <SelectComponent
                    onChange={(e) => {
                      resetError();
                      referState.user.set(e.target.value);
                    }}
                    values={dropdownUserList}
                    value={referState?.user?.get()}
                    size="small"
                    label={"Select User"}
                  />
                }
                isMandatory={true}
                showErrors={showErrors.get() && !referState.user.get()}
              />
            </Grid>
          )
        )}
      </Grid>

      <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
        <ButtonComponent
          title="Close"
          startIcon={<CloseIcon />}
          variant="contained"
          onClick={closeRefer}
          loadingbtn={true}
          style={closeBtn}
        />
        <ButtonComponent
          startIcon={<DoneIcon />}
          title="Save"
          variant="contained"
          onClick={saveRefer}
          disabled={isLoading}
          loadingbtn={true}
          color="info"
        />
      </Stack>
    </Box>
  );
};

export default OfficerCauReferModal;
