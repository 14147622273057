import { Downgraded, useState } from "@hookstate/core";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Box, Grid, Stack, Tooltip } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { creditData, formStructureSet, selectedApplicationReferenceToEditState, userDetails } from "../../../../../configs/mainStore";
import { loanProtectInsurance } from "../../../../../configs/stores/securityDataSubSection/securityDataSubSections.module";
import { saveAppFormDataJson } from "../../../../../services/apiCalls";
import { applicationFormSectionDetailsDelete } from "../../../../../services/applicationApis";
import { deleteMultipleInputItem, resetInputItems } from "../../../../../services/multipleInputDelete";
import { disableAccess } from "../../../../../utility/helpers/ApplicationMandatoryValidations/isSecurityDocUploadApprovedPermitted";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import { LoanProtectionInsurance } from "../../../ApplicationForm/Sections/SecurityDetails/SecurityData/PaperDocs/LoanProtectInsuran/LoanProtectInsuran";
import { ErrorMessageComponent } from "../../../ErrorMessageComponent";
import { closeGlobalModal, openGlobalModal } from "../../../GlobalModal/GlobalModal";
import { addToaster } from "../../../GlobalToast";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";
import { collateralApplicationFormGetter, getMandatoryFields, handleMandatory } from "./collateral-apis/CollateralApplicationFormGetter";
import HeaderCollateral from "./helpers/CollateralHeaderComponent";
import { collateralValidationErrorArray } from "./validations/validations";

export const CreditLoanProtectionInsurance = ({ data, applicationId, mainTabData = { isAppraisal: false }, isMainContactDetails = true, isRawVersion, apiCallBack, innerComponentData, tabsToEdit }) => {
  const { t: translate } = useTranslation();
  const [loanArr, setLoanArr]: any = React.useState([]);
  const [fullObj, setFullObj]: any = React.useState({});
  const [count, setCount]: any = React.useState(1);
  const [loading, setLoading]: any = React.useState(false);
  const requestId = data?.requestId;

  const creditDetailsState: any = useState(creditData);
  const { disbursed }: any = creditDetailsState.attach(Downgraded).get();
  const [fields, setFields]: any = React.useState({});
  const { paperDocs } = creditDetailsState?.formData?.securityData;
  const { userId }: any = userDetails.attach(Downgraded).get();
  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;
  const exisitingData = useState<Array<any>>([]);
  const loanProtectionExistingData = data?.formData?.securityData?.paperDocs?.loanProtection ?? [];
  const [fetching, setfetching] = React.useState<boolean>(false);
  const selectedApplicationReferenceId = useState(selectedApplicationReferenceToEditState);
  const appFormSet = useState(formStructureSet);

  let itemIndex = 0;
  const checkPrivilegeStatus = disableAccess({ isTabInEditString: tabsToEdit.includes(innerComponentData?.id) });

  const handleGetApplicationData = async () => {
    await getApplicationForm();
  };

  useEffect(() => {
    setfetching(true);
    handleGetApplicationData()
      .then(() => {
        setExisitingData();
      })
      .then(() => {
        if (data?.formData?.securityData?.paperDocs) {
          setFullObj(JSON.parse(JSON.stringify(data?.formData?.securityData?.paperDocs)));
        } else setFullObj([]);
      });
    setfetching(false);
  }, []);

  const setExisitingData = () => {
    if (loanProtectionExistingData) {
      exisitingData.set(JSON.parse(JSON.stringify(loanProtectionExistingData)));
    }
  };

  const getApplicationForm = async () => {
    const key = "loanProtection";
    const response = await collateralApplicationFormGetter(key, appFormSet);
    if (response) {
      setFields(response);
    } else setFields({});
  };

  const handleFieldsAdding = () => {
    let paperDocs = {
      ...JSON.parse(JSON.stringify(loanProtectInsurance)),
      itemIndex: 0,
      entryId: 0,
    };

    try {
      if (exisitingData.get()?.length > 0) {
        paperDocs["itemIndex"] = exisitingData.get()?.length;
        paperDocs["entryId"] = exisitingData.get()?.length;
      }
      let temArray: Array<any> = [];
      temArray = exisitingData.get() ? [...exisitingData.get(), paperDocs] : [paperDocs];

      exisitingData.set(JSON.parse(JSON.stringify(temArray)));
    } catch (error) { }
  };

  const LOAN_PROTECTION_MANDATORY = [...getMandatoryFields(fields)];

  const handleSave = async (props) => {
    setLoading(true);
    const mandatoryRes = handleMandatory(exisitingData.attach(Downgraded).get(), LOAN_PROTECTION_MANDATORY);

    if ("loanProtection" in fullObj) {
      delete fullObj.loanProtection;
    }

    const finalArr = exisitingData.filter(element => element && element !== null).map((element, index) => ({
      type: "paperDocs_loanProtection",
      itemIndex: index,
      entryId: index,
      ...element.get(),
    }));

    const finalObj = {
      paperDocs: {
        loanProtection: finalArr,
        ...fullObj,
      },
    };

    const errorArray = collateralValidationErrorArray("paperDocs", "loanProtection", finalObj);

    const lastValidation = Object.values(props).length > 0 ? [...errorArray, ...mandatoryRes] : [];

    if (lastValidation?.length > 0) {
      addToaster({
        status: "error",
        title: "Mandatory Validation Error",
        message: lastValidation.join("\n"),
      });
      setLoading(false);
    } else {
      const res = await saveAppFormDataJson(requestId, "securityData", finalObj);

      setLoading(false);
      addToaster({
        status: res?.status === 1 ? "success" : res?.error ? "warning" : "error",
        title: res?.status === 1 ? "Updated" : res?.error ? "Not Updated" : "Error",
        message: res?.status === 1 ? "Security Details Updated" : res?.error ? "Update Error" : "Something went Wrong!",
      });

      if (res?.status === 1) {
        paperDocs.set({ ...finalObj.paperDocs });
      }
    }
  };

  const deleteDispatcher = async (type: any = "", index: any = "", selectedSecurityTypeItem: any = {}, securityType: any = "") => {
    if (type && type == "all") {
      openGlobalModal({
        modalSize: "sm",
        title: "Are you sure ?",
        bodyId: "app-section-delete-confirm",
        close: false,
        modalParams: {
          textMessage: "This will delete all collaterals except guarantors!.",
          callBackOnAction: () => onDeleteSection(type, index),
        },
      });
    } else {
      onDeleteSection(type, index, selectedSecurityTypeItem, securityType);
    }
  };

  const removeFullState = () => {
    // For now there is no full state delete button on collateral then this will be activated later if needed
  };

  const onDeleteSection = async (type: any = "", index: any = "", selectedSecurityTypeItem: any = {}, securityType: any = "") => {
    if (type) {
      closeGlobalModal();
    }

    let securityTypes: any[] = securityType.split(":") || [];

    try {
      let dataObj: any = {};

      if (type) {
        dataObj = {
          applicationId: applicationId,
          sectionTravers: `securityData.${securityTypes[0]}.${securityTypes[1]}`,
        };
      } else {
        dataObj = {
          applicationId: applicationId,
          sectionTravers: `securityData.${securityTypes[0]}.${securityTypes[1]}[${index}]`,
        };
      }

      await applicationFormSectionDetailsDelete(dataObj);

      deleteMultipleInputItem(selectedSecurityTypeItem);

      delete data?.formData?.securityData?.paperDocs?.loanProtection?.[index]

      addToaster({
        status: "success",
        title: "Deleted!",
        message: `${securityTypes[2]} deleted successfully!`,
      });

    } catch (error) {
      addToaster({
        status: "error",
        title: "Error",
        message: `Error ${securityTypes[2]} deletion, try again shortly!`,
      });
    }
  };

  return (
    <>
      {fetching ? (
        <Grid
          container
          alignItems="center"
          sx={{
            paddingTop: "20px",
            paddingBottom: "20px",
            position: "relative",
          }}
        >
          <CircularProgressComponent size={30} sx={{ left: "50%", position: "absolute", zIndex: 100 }} />
        </Grid>
      ) : (
        <>
          <div className="full-width full-height" style={{ position: "relative" }}>
            {checkPrivilegeStatus ? <Stack style={{ width: "100%", height: "100vh", position: "absolute", left: 0, top: 0, backgroundColor: "255, 255, 255, 0.1", zIndex: 1000 }}></Stack> : <></>}
            {isMainContactDetails && (
              // <Header handleFieldsAdding={handleFieldsAdding} />
              <HeaderCollateral loading={loading} handleFieldsAdding={handleFieldsAdding} requestDtlData={exisitingData} collateralApplicationForm={fields} handleSave={handleSave} headerTitle={"Loan Protection Insurance"} />
            )}

            <React.Suspense fallback={<div>Loading...!</div>}>
              {/* Security Form */}
              <Grid container className="inner-component-height">
                <Stack spacing={1} m={1} className="full-width">
                  {exisitingData?.length > 0 ? (
                    exisitingData.map((item: any, key: number) => {
                      if (!item.get()) return <></>;
                      const elementKeys = Object.keys(item);
                      if (!elementKeys.includes("removedItem")) {
                        itemIndex = itemIndex + 1;
                        return (
                          <Box key={`loanProtection_${itemIndex * key * itemIndex}`} display="flex" alignItems="center" mb={1}>
                            <Box style={{ width: "100%" }}>
                              <LoanProtectionInsurance doc={item} fields={fields} index={itemIndex} isNotNeedComponentTitle={true} />
                            </Box>
                            <Box>
                              <Box pb={3}>
                                <Tooltip title={"Reset"}>
                                  <Box>
                                    <ButtonComponent
                                      iconbtn={true}
                                      onClick={() => {
                                        resetInputItems(item, "paperDocs", "loanProtection");
                                      }}
                                      style={{ border: "1px solid gray" }}
                                      size="small"
                                    >
                                      <RestartAltIcon />
                                    </ButtonComponent>
                                  </Box>
                                </Tooltip>
                              </Box>
                              <Box pt={3}>
                                <Tooltip title={"Delete"}>
                                  <Box>
                                    <ButtonComponent iconbtn children={<DeleteIcon />} onClick={() => deleteDispatcher("", key, item, "paperDocs:loanProtection:Paper Docs Loan Protection")} />
                                  </Box>
                                </Tooltip>
                              </Box>
                            </Box>
                          </Box>
                        );
                      } else {
                        return <></>;
                      }
                    })
                  ) : (
                    <ErrorMessageComponent headMessage={"No data available"} errorMessage={""} />
                  )}
                </Stack>
              </Grid>
            </React.Suspense>
          </div>
        </>
      )}
    </>
  );
};
