import { useState } from "@hookstate/core";
import { Divider, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { addApplicationGroups } from "../../../../../../services/applicationApis";
import { getApplicationsGroupsSelection } from "../../../../../../services/bankServiceInvorker";
import { OnDemandValueFormatter } from "../../../../../../services/onDemandValidators";
import { ButtonComponent } from "../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { ChipsComponent } from "../../../../../InputComponents/ChipsComponent/ChipsComponent";
import { TextBoxComponent } from "../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import {
  closeGlobalModal,
  openGlobalModal,
} from "../../../../GlobalModal/GlobalModal";
import { toast } from "../../../../GlobalToast";
import { CircularProgressComponent } from "../../../../ProgressComponent/ProgressComponent";

const GroupSelection = ({ applicationId }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const applicationGroup: any = useState({});

  useEffect(() => {
    getApplicationsGroups();
  }, []);

  const Header = ({ title }) => {
    return (
      <Stack
        px={2}
        style={{
          backgroundColor: "#e6f0fa",
          minHeight: "42pt",
          position: "sticky",
          zIndex: 100,
          top: "0px",
        }}
        justifyContent={"center"}
      >
        <Typography className="basic-font basic-font-color-bold">
          {title}
        </Typography>
      </Stack>
    );
  };

  const getApplicationsGroups = async () => {
    try {
      const group = await getApplicationsGroupsSelection(applicationId);
      applicationGroup.set(group);
    } catch (error) {
      toast({ status: "error", message: "Error while fetching data" });
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Paper>
        <Header title={"Group Selection"} />
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"100px"}
        >
          <CircularProgressComponent size={30} />
        </Stack>
      </Paper>
    );
  }

  const onSave = async () => {
    try {
      let { data } = await addApplicationGroups({ applicationGroup });
      applicationGroup.set(data);
      toast({
        status: "success",
        message: "Application group updated successfully",
      });
    } catch (error) {
      toast({ status: "error", message: "Error while fetching data" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Paper>
      <Header title={"Group Selection"} />
      <Stack direction={"row"}>
        <Stack p={2} flex={1} mb={5} mt={2} spacing={2}>
          <Typography className="basic-font basic-font-color-bold">
            Group Selection
          </Typography>
          <SelectionComponent
            label={"Search by CIF"}
            onSave={onSave}
            cif={applicationGroup?.cif}
            meta={applicationGroup?.meta}
            applicationId={applicationId}
          />
        </Stack>
        <Divider orientation="vertical" flexItem />

        <Stack p={2} flex={1} mb={5} mt={2} spacing={2}>
          {Object.keys(applicationGroup?.meta?.get()).length > 0 && (
            <Stack spacing={2}>
              <Typography className="basic-font basic-font-color-bold">
                Member Selection
              </Typography>
              <MemberSelectionComponent
                label="Search By NIC/CIF"
                applicationId={applicationId}
                onSave={onSave}
                members={applicationGroup.members}
              />
            </Stack>
          )}
        </Stack>
      </Stack>
    </Paper>
  );
};

const SelectionComponent = ({ label, onSave, meta, cif, applicationId }) => {
  const handleSearch = () => {
    openGlobalModal({
      modalSize: "md",
      title: "",
      bodyId: "group-selection-modal",
      close: false,
      modalParams: {
        cif: cif?.get(),
        applicationId,
        handleListItemClick: itemClicked,
      },
    });
  };

  const itemClicked = (item: any) => {
    meta.set(item);
    cif.set(item.CIF);
    closeGlobalModal();
  };

  return (
    <Stack spacing={2} direction={"column"}>
      <Stack direction={"row"} spacing={2}>
        <Stack flex={1}>
          <TextBoxComponent
            label={label}
            value={cif.get() ?? ""}
            onInput={OnDemandValueFormatter.alphaNumeric}
            fullWidth={true}
            onChange={(e: any) => {
              cif.set(e.target.value);
            }}
          />
        </Stack>
        <Stack flex={1} direction={"row"}>
          <ButtonComponent
            title="Search"
            variant="contained"
            onClick={handleSearch}
            color="info"
            disabled={!cif?.get() ? true : false}
            startIcon={""}
          />
          <Stack flex={1}></Stack>
        </Stack>
      </Stack>
      {Object.keys(meta?.get()).length > 0 && (
        <Stack direction={"column"} spacing={2}>
          <Stack>
            <Typography>Selected Group: </Typography>
          </Stack>
          <Stack direction={"row"} spacing={2}>
            <Stack flex={1}>
              <ChipsComponent
                label={
                  <Typography variant="subtitle2">
                    {meta?.get()?.initialInFull}
                  </Typography>
                }
              ></ChipsComponent>
            </Stack>
            <Stack flex={1} direction={"row"}>
              <ButtonComponent
                title="Assign"
                variant="contained"
                onClick={onSave}
                color="info"
                disabled={false}
                startIcon={""}
              />
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

const MemberSelectionComponent = ({
  label,
  onSave,
  members,
  applicationId,
}) => {
  const [cif, setCIF] = React.useState("");

  const handleSearch = () => {
    openGlobalModal({
      modalSize: "md",
      title: "",
      bodyId: "group-selection-modal",
      close: false,
      modalParams: {
        cif,
        applicationId,
        handleListItemClick: itemClicked,
      },
    });
  };

  const itemClicked = (item: any) => {
    const _members = members?.get() || [];
    if (!_members?.find((x: any) => x.CIF === item.CIF)) {
      members.set([..._members, item]);
    }
    closeGlobalModal();
  };

  return (
    <Stack spacing={2} direction={"column"}>
      <Stack direction={"row"} spacing={2}>
        <Stack flex={1}>
          <TextBoxComponent
            label={label}
            value={cif}
            fullWidth={true}
            onInput={OnDemandValueFormatter.alphaNumeric}
            inputProps={{ maxLength: 12 }}
            onChange={(e) => {
              setCIF(e.target.value);
            }}
          />
        </Stack>
        <Stack flex={1} direction={"row"}>
          <ButtonComponent
            title="Search"
            variant="contained"
            onClick={handleSearch}
            color="info"
            disabled={!cif ? true : false}
          />
          <Stack flex={1}></Stack>
        </Stack>
      </Stack>

      {(members?.get() || [])?.map((member: any, index: any) => (
        <>
          <Stack>
            <Typography variant="body1">Selected Members: </Typography>
          </Stack>
          <Stack direction={"column"} key={index}>
            <Stack direction={"row"}>
              <Typography variant="body1">{member?.initialInFull}</Typography>
            </Stack>
            <Stack direction={"row"} mt={2} gap={2}>
              <Typography variant="body2">CIF: {member?.CIF}</Typography>
              <Typography variant="body2">NIC: {member?.NIC}</Typography>
            </Stack>
            <Divider></Divider>
          </Stack>
        </>
      ))}
      <Stack direction={"row"} justifyContent="flex-end">
        {(members?.get() || []).length > 0 && (
          <ButtonComponent
            title="Save"
            variant="contained"
            onClick={onSave}
            color="info"
            disabled={false}
            startIcon={""}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default GroupSelection;
