import { Downgraded } from "@hookstate/core";
import { singleCustomerViewDetails } from "../../../configs/mainStore";
import { validateWithStructure } from "./structureValidation";
import { messageMappings } from "./messageMapping";
import isProductOf from "../../AthamaruProductBusinessDetails/AthamaruComponentsValidator";
import AppConfig from "../../AppConfig";

export const singleCustomerViewDetailsValidator = (structure, state, isGuarantor = false, isJointBorrower = false, isSecuritySectionNext?) => {
  let singleCustomerViewData = singleCustomerViewDetails.attach(Downgraded).get();
  let finalKeyArr: any = [];
  let errorArray = validateWithStructure(structure, state);

  if (isProductOf({ primaryApplicant: !(isGuarantor || isJointBorrower) })) {
    if (singleCustomerViewData?.dorinDoraAccount === "" || singleCustomerViewData?.dorinDoraAccount === "" || singleCustomerViewData?.dorinDoraAccount === null || singleCustomerViewData?.dorinDoraAccount === undefined) {
      try {
        if (AppConfig.config.athamaruProductDorinDoraAccMandtorySetting !== 0) {
          errorArray.push("dorinDoraAccount");
        }
      } catch (e) {}
    }
  }

  if (errorArray?.length > 0) {
    finalKeyArr = errorArray;
  }

  const preparedErrorMessages = messageMappings(finalKeyArr);

  return preparedErrorMessages;
};
