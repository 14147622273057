import {
  Box,
  Button,
  Stack,
  Switch,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import React from "react";
import { TextBoxComponent } from "../../InputComponents/TextBoxComponent/TextBoxComponent";
import { CMTextInput } from "../CommunicationService/CMTextInput";
import { closeGlobalModal } from "../GlobalModal/GlobalModal";
import { useHookstate, useState } from "@hookstate/core";

const ScorecardTemplateModal = ({ handleSave, product }) => {
  const [scorecard, setScorecard] = React.useState(product);


  const GreenSwitch = styled(Switch)(({ theme }: any) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#00AB71",
      "&:hover": {
        backgroundColor: alpha("#00AB71", theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#00AB71",
    },
  }));

  const handleChange = (e) => {
    if (e.target.name === "isActive") {
      setScorecard((prev) => ({
        ...prev,
        [e.target.name]: scorecard.isActive === 1 ? 0 : 1,
      }));
    } else {
      setScorecard((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };

  return (
    <>
      <Stack>
        <CMTextInput
          desc={"Published"}
          component={
            <GreenSwitch
              name="isActive"
              checked={scorecard.isActive === 1 ? true : false}
              onChange={handleChange}
            />
          }
        />

        <CMTextInput
          desc={"Name"}
          component={
            <TextBoxComponent
              name={"name"}
              value={scorecard.name}
              fullWidth={true}
              onChange={handleChange}
            />
          }
        />
        <CMTextInput
          desc={"Description"}
          component={
            <TextBoxComponent
              name={"description"}
              value={scorecard.description}
              fullWidth={true}
              onChange={handleChange}
            />
          }
        />

        <Stack mt={1} direction={"row"} justifyContent={"flex-end"} spacing={1}>
          <Button onClick={() => handleSave(scorecard)}>Save</Button>
          <Button
            onClick={() => {
              closeGlobalModal();
            }}
          >
            Close
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default ScorecardTemplateModal;
