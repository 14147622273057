import { createState } from "@hookstate/core";
import {
  ApplicationformStructure,
  FieldIdNameListObjectType,
  RuleIdActiveTypes,
  WorkflowIdNameListObjectType,
} from "../configs/interfaces";
import * as applicationForm from "./stores/applicationForm";
import * as applicationManagement from "./stores/applicationManagement";
import * as documentManagement from "./stores/documentManagement";

// data models
import Product from "../components/OtherComponents/DecisionEngineComponent/model/product";
import RuleByLangData from "../components/OtherComponents/DecisionEngineComponent/model/ruleByLangData";
import RuleIdName from "../components/OtherComponents/DecisionEngineComponent/model/ruleIdName";
import { ProductCatalog } from "../utility/CustomHooks/useProductCatalogData";
import {
  innerSecurityNavigationList,
  // innerNavigationList,
  navigationList,
} from "./constants/creditFileConfigs";
import { defaultAppSettings } from "./constants/defaultAppSettings";
import { mandatoryValidationErrorMessages, sectionExpandState } from "./stores/applicationForm";

const languageState = {
  preferredLanguage: "ar",
  languageArr: [
    { id: null, name: "None" },
    { id: 1, name: "English" },
    { id: 2, name: "සිංහල" },
    { id: 3, name: "தமிழ்" },
  ],
};

const applicationFormErrors = {};

export const excludesTabs = ["documents", "task_check_list", "pre_disbursement_updated"];

export const errorStateStructure = {
  "primary-applicant-details": "pending",
  "credit-details": "pending",
  "single-customer-view-details": "pending",
  "eduction-details": "pending",
  "employment-details": "pending",
  "contact-details": "pending",
  "address-details": "pending",
  "education-details": "pending",
  "income-details": "pending",
  "expenses-details": "pending",
  "bank-details": "pending",
  "assets-details": "pending",
  // 'security-details': 'pending',
  // 'joint-borrower-details': 'pending',
  "analysis-of-the-loan-requirement": "pending",
  "risk-conditions-general-facts": "pending",
  "inquiry-of-obligations": "pending",
  "reports-previous-borrowings": "pending",
  "business-details": "pending",
  "life-insurance-details": "pending",
};

export const applicationFormSectionError = {
  ...errorStateStructure,
  "security-details": {
    "paper-doc": "pending",
    guarantor: [],
  },
  "joint-borrower-details": [],
  "security-details-parent": "pending",
  "joint-borrower-details-parent": "pending",
};

const applicationFormErrorSectionIndex = 0;

const masterDataState = {};

const newLeadScreen = 0;

const userDetailState = {
  userId: "",
  accessToken: "",
  authenticate: 0,
  userDla: null,
  userPrivilege: null,
  userDetails: null,
  userInGroups: null,
};

const fetchingState = {
  isFetching: 0,
};

const renderingState = {
  renderId: "manage-applications",
  renderParams: {},
};

const productCatalogState: ProductCatalog = {
  type: "",
  sector: "",
  scheme: "",
  applicantType: "",
};

const appraisalConditionState = {
  isAppraisal: false,
};

const productTypeMasterData = {
  productCatelogWithKeys: {},
  productTypes: [],
  productSectors: [],
  productSchemes: [],
  productApplicants: [],
  productTypesMap: {},
  productSectorsMap: {},
  productSchemesMap: {},
  productApplicantsMap: {},
  productCatelogData: {},
  dropDowns: {},
  definitionMap: {},
};

const productDataMapping = {
  productMapping: [],
  applicantMapping: [],
  hierarchy: [],
};

const interestRateData = {
  ir_mappedData: [],
  ir_factors_list: [],
};

const appSettingState = {
  ...defaultAppSettings,
};

const productTypeMasterDataInt = {
  productTypesInt: [],
  productSectorsInt: [],
  productSchemesInt: [],
  productTypesMapInt: [],
  productSectorsMapInt: {},
  productSchemesMapInt: [],
};

const productTreeState = {
  changedTree: [],
};

const applicationFormsCreator = {
  formDetails: {},
  formId: null,
  participantType: 1,
  forms: [],
  isEditMode: false,
  selectedLoanName: "",
  enableEditOtherUsers: true,
  multipleFormExistence: false,
};

const randomIds = [];

export const creditFileData = {
  name: "",
  address: "",
  nic: "",
  gender: "",
  mobile: "",
  amount: "",
  repayment: "",
  caseOfficer: "",
  LoanCycle: "",
  applicationId: "",
  product: "",
  segment: "",
  scheme: "",
  formData: {},
  reverts: 0,
  requestId: "",
  currentAssignee: -1,
  originationBranch: "",
  currentWFStage: "",
  tabs: [],
  disbursed: 0,
  isAmountBelowTwoPointFive: false,
  isAmountAboeTwoPointFiveAndEqual: false,
};

const currentWorkflowStageData = {};

const creditFileNavigation = {
  selectMainTab: "",
  selectInnerTab: "",
  navigationList: navigationList,
  // innerNavigationList: innerNavigationList,
  innerNavigationListRendering: {},
  navigationListCopy: [],
};

const tradeCreditFileNavigation = {
  navigationList: [],
};

const securityFileNavigation = {
  selectMainTab: "",
  selectInnerTab: "",
  securityNavigationList: innerSecurityNavigationList,
  // innerNavigationList: innerNavigationList,
  innerNavigationListRendering: {},
  innerSecurityNavigationCopy: [],
};

const tabValidationState = {};
const branchState = {
  institutions: [],
  rdbBranches: {},
  branchedByInstitutions: {},
  branchListByInstitutionCode: {},
  bankList: {},
  branchMap: {},
  zoneMap: {},
  districtMap: {},
};

const postalCodeState = {};
const decisionRules = {};

const leadButtonVisibility = false;

const workflowStateVisibility = null;
const scoreCardGroupStateVisibility = null;

const toolBarLeadButtonVisibility = false;

const adminOfficerDashboardIds = {
  renderId: "cau-officer",
};

const activeInactiveChargesIds = {
  renderId: "active",
};

let chargesSelectionAction = {
  trigger: null,
};

const noObligation = false;

const summaryData = {
  applicationId: "",
  cribAmount: 0,
  tralCalculatorTermValue: 0,
};

const createLetterDocumentObject = {};

const reportFilterMasterData = {};

const commonSearchSelections = {
  applicationId: "",
  workflowStage: "",
  province: "",
  district: "",
  branch: "",
  type: "",
  sector: "",
  scheme: "",
  applicantType: "",
  name: "",
  nic: "",
  mobile: "",
  status: "",
  assignedUser: "",
  actionedUser: "",
  approvedBy: "",
  fromDate: null,
  toDate: null,
  cid: "",
  workflowStatus: "",
  ccuOfficer: "",
  ccuManager: "",
};

const multipleDisbursement = {
  isHaveMultipleDisbursement: false,
  disbSchedule: {},
  nofDisbursemnet: 0,
  currentDisbursement: 0,
  securityDocuments: ["RECEIPT"],
};

const athmaruCatalogKeyState = "";
const getUnderWrittingPolicyConfigs = {};

const totalAnnualLiabilityValue = 0.0;
const productStageDocumentPermissions = {};
let taskActionUpdated = false;

const priorCommentsState = "";
const reportConfig: any = {};

const apiJobSubList: any = {};
const workflowActionFormData: any = [];
const workflowIdGlobal: any = [];
const currentCreditFileTabState: number = 1;
const inspectionFormStore: any = {};

const scoreCardGroupFormData: any = [];
const applicationformStructure: ApplicationformStructure = {};
//---------------- Common ------------------//
export * from "./stores/master-data-store/MasterDataStore";
export const languageDetails = createState(languageState);
export const masterDataDetails = createState(masterDataState);
export const userDetails = createState(userDetailState);
export const fetchingDetails = createState(fetchingState);
export const renderingDetails = createState(renderingState);
export const isAppraisalState = createState(appraisalConditionState);
export const productDetails = createState(productTypeMasterData);
export const productMappingDetails = createState(productDataMapping);
export const appSetting = createState(appSettingState);
export const productDetailsInt = createState(productTypeMasterDataInt);
export const productTreeDetails = createState(productTreeState);
export const appFormEditDetails = createState(applicationFormsCreator);
export const randomIdsDetails = createState(randomIds);
export const branchDetails = createState(branchState);
export const postalCodeDetails = createState(postalCodeState);
export const decisionRuleTypes = createState(decisionRules);
export const leadButtonVisibilityState = createState(leadButtonVisibility);
export const workflowStateVisibilityState = createState(workflowStateVisibility);
export const scoreCardGroupStateVisibilityState = createState(scoreCardGroupStateVisibility);
export const toolBarLeadButtonVisibilityState = createState(toolBarLeadButtonVisibility);
export const adminOfficerDashboardState = createState(adminOfficerDashboardIds);
export const activeInactiveChargesState = createState(activeInactiveChargesIds);
export const totalAnnualLiabilityAmount = createState(totalAnnualLiabilityValue);
export const productStageDocumentPermissionsState = createState(productStageDocumentPermissions);
export const taskActionUpdatedState = createState(taskActionUpdated);
export const athmaruCatalogKey = createState(athmaruCatalogKeyState);

//---------------- Application Manager -----------//
export const appFormDetails = createState(applicationManagement.appFromBuilder);

//---------------- Workflow Management -----------//
export * from "./stores/workflow-stores/workflowStore";

//---------------- Decision Engine Management ----------//
/** @listens ruleCrud */
const rules: object = {};
const updatedRuleList: Array<RuleIdActiveTypes> = [];

const selectedProductCatalogKey: string = "";
const selectedFieldData: any = {};
const floatingWidgetStateModel: any = { show: false, mode: "comments", payload: {} };
const argsTypesDataObject: any = { form: [], config: [], master: [] };
const products: Array<Product> = [];
const fieldsDetails: any = {};
const selectedCatalogFieldIdActiveList: any = [];
const selectedFieldIdActiveListObject: any = {};
const ruleIdNameList: Array<RuleIdName> = [];
const changeDetectionRuleCrud: boolean = false;
const selectedRuleDataObject: RuleByLangData = {
  ruleId: -1,
  ruleString: "",
  isActive: false,
  ruleType: -1,
  isClientValidator: false,
  args: [], // {index:0, data:{type:"", field:""}}
  descriptionByLang: {},
};
const formReRendereKeyDefault: number = 0;

export const productStore = createState(products);
export const ruleStore = createState(rules);
export const fieldsDetailsStore = createState(fieldsDetails);
export const ruleIdNameListStore = createState(ruleIdNameList);
export const argsTypesDataObjectStore = createState(argsTypesDataObject);
export const selectedRuleDataObjectStore = createState(selectedRuleDataObject);
export const changeDetectionRuleCrudStore = createState(changeDetectionRuleCrud);

/** @listens Field ruleMap */
const changeDetectionRuleMap: boolean = false;
export const selectedFieldDataStore = createState(selectedFieldData);
export const selectedCatalogFieldIdActiveListStore = createState(selectedCatalogFieldIdActiveList);
export const selectedFieldRuleIdActiveListObjectStore = createState(selectedFieldIdActiveListObject);
export const changeDetectionRuleMapStore = createState(changeDetectionRuleMap);
export const selectedProductCatalogKeyStore = createState(selectedProductCatalogKey);
export const productCatalog = createState(productCatalogState);
export const formReRendereKey = createState(formReRendereKeyDefault);

/** @listens wrokflowRuleMap decision engine */

const selectedWorkflowId: number | null = null;
const selectedWorkflowTerm: string = "";
const productWorkflowCatalogKey: string = "";
const changeDetectionWorkflowRuleMap: boolean = false;
const productWorkflowList: any = {};
const loadedWorkflowIdNameListObject: WorkflowIdNameListObjectType = {};
const loadedWorkflowRuleList: any = {
  1: { ruleId: 1, ruleName: "rule one", isActive: true },
  2: { ruleId: 1, ruleName: "rule two", isActive: false },
  3: { ruleId: 1, ruleName: "rule tree", isActive: true },
};

export const selectedWorkflowIdStore = createState(selectedWorkflowId);
export const selectedWorkflowTermStore = createState(selectedWorkflowTerm);
export const productWorkflowCatalogKeyStore = createState(productWorkflowCatalogKey);
export const changeDetectionWorkflowRuleMapStore = createState(changeDetectionWorkflowRuleMap);
export const productWorkflowListStore = createState(productWorkflowList);
export const loadedWorkflowRuleListStore = createState(loadedWorkflowRuleList);
export const loadedWorkflowIdNameListObjectStore = createState(loadedWorkflowIdNameListObject);

/** @listens fieldRuleMap decision engine */

const selectedFieldId: number | null = null;
const selectedFieldTerm: string = "";
const productFieldCatalogKey: string = "";
const changeDetectionFieldRuleMap: boolean = false;
const productFieldList: any = {};
const loadedFieldIdNameListObject: FieldIdNameListObjectType = {};
const loadedFieldRuleList: any = {
  1: { ruleId: 1, ruleName: "rule one", isActive: true },
  2: { ruleId: 1, ruleName: "rule two", isActive: false },
  3: { ruleId: 1, ruleName: "rule tree", isActive: true },
};
const selectedApplicationReferenceToEdit: any = "";
export const selectedApplicationReferenceToEditState = createState(selectedApplicationReferenceToEdit);

export const selectedFieldIdStore = createState(selectedFieldId);
export const selectedFieldTermStore = createState(selectedFieldTerm);
export const productFieldCatalogKeyStore = createState(productFieldCatalogKey);
export const changeDetectionFieldRuleMapStore = createState(changeDetectionFieldRuleMap);
export const productFieldListStore = createState(productFieldList);
export const loadedFieldRuleListStore = createState(loadedFieldRuleList);
export const loadedFieldIdNameListObjectStore = createState(loadedFieldIdNameListObject);

/** @listens ruleSelector decision engine rule map */
export const updatedRuleListStore = createState(updatedRuleList);

//---------------- Document Management ----------------//
export const productCatalogDetails = createState(documentManagement.productCatalogDetailsState);
export const documentTypeDetails = createState(documentManagement.documentTypeState);
// ------------------- Interest Rate Model -----------//
export const interestRateMappedData = createState(interestRateData);

//---------------- Application Form -------------//
export const personalDetails = createState(applicationForm.personalDetailState);
export const creditDetails = createState(applicationForm.creditDetailsState);
export const singleCustomerViewDetails = createState(applicationForm.singleCustomerViewDetails);
export const contactDetails = createState(applicationForm.contactDetailState);
export const addressDetails = createState(applicationForm.addressDetailsState);
export const educationDetails = createState(applicationForm.educationDetailsState);
export const employedDetails = createState(applicationForm.employedDetailState);
export const businessDetails = createState(applicationForm.businessDetailState);

export const applicationSections = {
  personalDetails,
  creditDetails,
  singleCustomerViewDetails,
  contactDetails,
  addressDetails,
  educationDetails,
  employedDetails,
  businessDetails,
}
// export const selfEmployedDetails = createState(
//   applicationForm.selfEmployedDetailsState
// );
export const incomeDetails = createState(applicationForm.incomeDetailsState);
export const expenseDetails = createState(applicationForm.expenseDetailsState);
export const assetsDetails = createState(applicationForm.assetsDetailsState);
export const lifeInsuranceDetails = createState(applicationForm.lifeInsuranceDetailsState);
export const bankDetails = createState(applicationForm.bankDetailsState);
export const guarantorDetails = createState(applicationForm.guarantorDetailsState);
export const joinBorrowerDetails = createState(applicationForm.jointBorrowerDetailsState);
export const taxDetails = createState(applicationForm.taxDetailsState);
export const analysisOfLoanRequirementDetails = createState(applicationForm.analysisOfLoanRequirementDataState);
export const risksConditionsAndGeneralFactsDetails = createState(applicationForm.risksConditionsAndGeneralFactsState);
export const inquiryOfObligationsDetails = createState(applicationForm.inquiryOfObligationsState);
export const previousBorrowingsDetails = createState(applicationForm.previousBorrowingsState);
export const securityDetails: any = createState(applicationForm.securityDetailState);
export const agricultureDetails: any = createState(applicationForm.agricultureDetailsState);

export const isPartialForm = createState(applicationForm.isPartialFrom);
export const appStateDetails = createState(applicationForm.appState);
export const formBuilderSavingDetails = createState(applicationForm.appFormBuilderSavingState);
export const newLeadScreenState = createState(newLeadScreen);
export const appFormRequestId = createState(applicationForm.appFormRequestId);
export const latestApplicationData = createState(applicationForm?.latestApplicationDataState);
export const lastEditedSection = createState(applicationForm.lastEditedSectionState);
export const creditData = createState(creditFileData);
export const currentWorkflowStageDetails = createState(currentWorkflowStageData);
export const navigationTabsCreditData = createState(creditFileNavigation);
export const navigationTabsTradeCreditData = createState(tradeCreditFileNavigation);
export const securityFileNavigationData = createState(securityFileNavigation);
export const sectionExpandData = createState(sectionExpandState);
// export const sectionExpandDataTest = createState(applicationForm.sectionExpandStatet);
export const tabValidationData = createState(tabValidationState);
export const floatingWidgetState = createState(floatingWidgetStateModel);

// Application Form Error State
export const applicationFormErrorsState = createState(applicationFormErrors);
export const applicationFormSectionErrorState = createState(applicationFormSectionError);
export const applicationFormErrorSectionIndexState = createState(applicationFormErrorSectionIndex);

export const submitLoading = createState(false);
export const formStructure: any = createState([]);
export const formStructureSet: any = createState({});
export const mandatoryValidations: any = createState({});
export const mandatoryValidationErrorMessagesState: any = createState(mandatoryValidationErrorMessages);
export const currentExpandSectionState: any = createState(applicationForm.currentExpandSection);

// export const actionChargesSelection = createState(chargesSelectionAction);

export const actionChargesSelection = createState(chargesSelectionAction);
export const summaryAdditionalDetails = createState(summaryData);
export const letterDocumentStructure = createState(createLetterDocumentObject);
export const reportFilterMasterdataState = createState(reportFilterMasterData);
export const commonSearchSelectionState = createState(commonSearchSelections);
export const getUnderWrittingPolicyConfigsState = createState(getUnderWrittingPolicyConfigs);
export const userSettings = () => JSON.parse(localStorage.getItem("settings") ?? "{}");
export const priorComments = createState(priorCommentsState);
export const reportConfigState = createState(reportConfig);
export const multipleDisbursementState = createState(multipleDisbursement);
export const apiJobSubListState = createState(apiJobSubList);
export const workflowActionFormDataState = createState(workflowActionFormData);
export const workflowIdGlobalState = createState(workflowIdGlobal);
export const currentCreditFileTab = createState(currentCreditFileTabState);
export const inspectionFormState = createState(inspectionFormStore);
export const scoreCardGroupFormDataState = createState(scoreCardGroupFormData);

// Micro Appraisal Default States
const chequeReturnsDefault = {};
const loanDetailsCribDefault = {};
const currentAccountDefault = {};
const bankGuaranteeDefault = {};

export const microChequeReturnsDetails = createState(chequeReturnsDefault);
export const microLoanDetailsCribDetails = createState(loanDetailsCribDefault);
export const microCurrentAccountDetails = createState(currentAccountDefault);
export const microBankGuaranteeDetails = createState(bankGuaranteeDefault);

// Charge Template Product Mapping
const chargeProductTemplateConfigDefault = {};

export const chargeProductTemplateConfigDetails = createState(chargeProductTemplateConfigDefault);
// Application Basic Details from CIF
const selectedCIFDataObj = {};
const selectedCIFDataObjJB = {};
const selectedCIFDataObjGR = {};

export const selectedCIFDetails = createState(selectedCIFDataObj);
export const selectedCIFDetailsJB = createState(selectedCIFDataObjJB);
export const selectedCIFDetailsGR = createState(selectedCIFDataObjGR);

// Analysis loan applicant Comments
const analysisOfLoanApplicantComments = [];
export const analysisOfLoanApplicantCommentDetails = createState(analysisOfLoanApplicantComments);
export const applicationformStructureState = createState(applicationformStructure);
