import { Downgraded, useState } from "@hookstate/core";
import { Grid, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { bufferToBlobUrl } from "../../../../../configs/base64Conversion";
import { creditData, userDetails } from "../../../../../configs/mainStore";
import { getInsurancePolicyDocumentFile, getInsurancePolicyDocuments } from "../../../../../services/creditFileApiCall";
import { getIsDisbursed } from "../../../../../utility/helpers/getIsDisbursed";
import { compare } from "../../../../../utility/other";
import { ErrorMessageComponent } from "../../../ErrorMessageComponent";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";
import InnerSectionHeaderComponent from "../InnerSectionHeaderComponent";
import InsurancePolicyDocumentItemRow from "./InsurancePolicyDocumentItemRow";

export const previewBtnClicked = async (docId) => {
  const resultFile = await getInsurancePolicyDocumentFile(docId);

  if (resultFile?.fileData?.data) {
    const fileBuffer = resultFile?.fileData.data;
    const contentType = resultFile?.contentType;

    const blobUrl = bufferToBlobUrl(fileBuffer, contentType);

    window.open(
      `${blobUrl}`,
      "targetWindow",
      `toolbar=no,
         location=no,
         status=no,
         menubar=no,
         scrollbars=yes,
         resizable=yes,
         width=500,
         height=500`
    );
  }
};

const ReviewInsurancePolicyDocumentsList = ({ data, applicationId, currentApplicationWfData, innerComponentData, tabsToEdit }) => {
  const riskList: any = useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const creditDetailsState: any = useState(creditData);
  const { disbursed }: any = creditDetailsState.attach(Downgraded).get();

  const { userId }: any = userDetails.attach(Downgraded).get();
  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      if (applicationId) {
        const data = await getInsurancePolicyDocuments(applicationId);
        if (data && data.length > 0) {
          const sortedList = data.sort((a, b) => compare(b, a, "id"));
          riskList.set(sortedList);
        }
      }
      setIsLoading(false);
    })();
  }, []);

  const callBackOnAction = (newRisk) => {
    const risk = JSON.parse(JSON.stringify(riskList.get()));
    const sortedList = [...risk, newRisk]?.sort((a, b) => compare(b, a, "id"));

    riskList.set(sortedList);
  };

  const checkPrivilegeStatus = () => {
    let isDisabled = true;
    if (tabsToEdit.includes(innerComponentData?.id)) {
      isDisabled = true;
    } else {
      isDisabled = getIsDisbursed(null, disbursed) || isLoggedInUserNotTheAssignee;
    }
    return isDisabled;
  };

  return (
    <div className="full-width full-height">
      <InnerSectionHeaderComponent innerComponentData={innerComponentData} applicationId={applicationId} currentApplicationWfData={currentApplicationWfData} callBackOnAction={callBackOnAction} modalSize="lg" disabled={checkPrivilegeStatus()} />
      <Grid container className="inner-component-height">
        <Stack spacing={1} m={1} className="full-width">
          {isLoading ? (
            <CircularProgressComponent
              size={30}
              sx={{
                marginTop: "20px",
                marginBottom: "20px",
                marginLeft: "auto",
                marginRight: "auto",
                zIndex: 100,
              }}
            />
          ) : riskList && riskList.length > 0 ? (
            riskList?.get()?.map((data, index) => <InsurancePolicyDocumentItemRow data={data} key={data?.id} previewBtnClicked={previewBtnClicked} />)
          ) : (
            <ErrorMessageComponent headMessage={"No data available"} errorMessage={""} />
          )}
        </Stack>
      </Grid>
    </div>
  );
};

export default ReviewInsurancePolicyDocumentsList;
