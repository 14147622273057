import { Downgraded } from "@hookstate/core";
import { Paper, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CORE_BANK_KEYS } from "../../../../../configs/constants/contants";
import { MASTER_DATA_KEYS as MD_KEYS } from "../../../../../configs/constants/masterDataKeys";
import { privilegeKeys } from "../../../../../configs/constants/privilegeKeys";
import { creditData, userDetails } from "../../../../../configs/mainStore";
import { getCoreBankData, setCoreBankData } from "../../../../../services/bankServiceInvorker";
import AppConfig from "../../../../../utility/AppConfig";
import MasterDataProvider from "../../../../../utility/MasterDataProvider";
import { getIsDisbursed } from "../../../../../utility/helpers/getIsDisbursed";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import { SelectComponent } from "../../../../InputComponents/SelectComponent/SelectComponent";
import { addToaster } from "../../../GlobalToast";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";
import DirrectSellingAgentCode from "../DirectSellingAgentCode/DirectSellingAgentCode";
import VillageLeader from "../DirectSellingAgentCode/VillageLeader";

const MarketingChannelCode = ({ data, applicationId, currentApplicationWfData, innerComponentData }) => {
  const { userId }: any = userDetails.attach(Downgraded).get();
  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;
  const [selectedAccount, setSelectedAccount]: any = useState("");
  const creditFileData: any = creditData.attach(Downgraded).get();
  const disbursed = creditFileData.disbursed;
  const [isLoading, setIsLoading] = React.useState(true);
  const [refresh, setRefresh] = React.useState(false);
  const [individualId, setIndividualId] = React.useState("");
  const [nic, setNic] = React.useState("");
  const masterDataStore = MasterDataProvider.provideMasterData();
  const referAccountList = masterDataStore?.getMasterData(MD_KEYS?.MARKETTING_CHANNEL_CODE);
  const { currentProductCatalogKey } = creditFileData;
  const { athamaruCatalog } = AppConfig.config;

  useEffect(() => {
    if (creditFileData) {
      if (creditFileData?.formData?.personalData?.oldNic) {
        setNic(creditFileData?.formData?.personalData?.oldNic);
      } else if (creditFileData?.formData?.personalData?.newNic) {
        setNic(creditFileData?.formData?.personalData?.newNic);
      }
      setIndividualId(creditFileData?.formData?.personalData?.individualId);
    }
  }, [creditFileData]);

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      if (individualId) {
        const selectedAccountObj = await getCoreBankData(
          applicationId,
          // CORE_BANK_KEYS.CIF,
          CORE_BANK_KEYS.MARKETTING_CHANNEL_CODE
        );

        if (selectedAccountObj) {
          setSelectedAccount(selectedAccountObj);
        }
      }
      setIsLoading(false);
    })();
  }, [individualId, refresh]);

  const handleSave = async () => {
    setIsLoading(true);

    const res = await setCoreBankData(applicationId, CORE_BANK_KEYS.MARKETTING_CHANNEL_CODE, selectedAccount);

    if (res?.message) {
      addToaster({
        status: "success",
        title: "Success",
        message: res?.message,
      });
    } else {
      addToaster({
        status: "error",
        title: "Error",
        message: "Not Updated",
      });
    }

    setIsLoading(false);
  };

  const onChangeAccount = (value) => {
    setSelectedAccount(value);
  };

  const Header = () => {
    return <Stack px={2} style={{ backgroundColor: "#e6f0fa", minHeight: "42pt", position: "sticky", zIndex: 100, top: "0px" }} justifyContent={"center"}>
      <Typography className="basic-font basic-font-color-bold">
        Marketing Channel Code</Typography>
    </Stack>;
  }

  if (isLoading) {
    return <Paper>
      <Stack style={{ height: "300px" }}>
        <Header />
        <Stack style={{ height: "100%", width: "100%" }} justifyContent={"center"} alignItems={"center"}>
          <CircularProgressComponent size={30} />
        </Stack>
      </Stack>
    </Paper>;
  }

  return (
    <Paper>
      <Stack>
        <Header />
        <Stack flex={1} p={2}>
          <Stack flex={1} direction={"column"} spacing={5}>
            <Stack direction={"row"} spacing={5} >
              <Stack direction={"row"} width={"15%"}>
                <Typography variant="subtitle2" color={"gray"} width={"150pt"}> Marketing Channel Code</Typography>
              </Stack>
              <Stack width={"25%"} direction={"row"} gap={2} alignItems={"center"} >
                <SelectComponent
                  label={"Select Marketing Channel Code"}
                  defaultPlaceholder="Select Marketing Channel Code"
                  value={selectedAccount}
                  values={referAccountList}
                  onChange={(e) => {
                    onChangeAccount(e.target.value);
                  }}
                  disabled={getIsDisbursed(null, disbursed) || isLoading || isLoggedInUserNotTheAssignee}
                />
                <ButtonComponent
                  title={"Save"}
                  variant="contained"
                  onClick={handleSave}
                  style={{
                    maxHeight: "40px",
                  }}
                  disabled={getIsDisbursed(privilegeKeys.MARKETING_CHANNEL_CODE_SAVE, disbursed) || isLoggedInUserNotTheAssignee}
                />
              </Stack>
            </Stack>

            {selectedAccount === 99 ? (
              <Stack direction={"row"} spacing={5} >
                <DirrectSellingAgentCode data={data} applicationId={applicationId} />
              </Stack>
            ) : (
              <></>
            )}

            {
              athamaruCatalog !== currentProductCatalogKey &&

              <Stack direction={"row"} spacing={5}>
                <VillageLeader data={data} applicationId={applicationId} />
              </Stack>
            }
          </Stack>
        </Stack>
      </Stack>
    </Paper >
  );
};

export default MarketingChannelCode;
