import { Downgraded } from "@hookstate/core";
import { Grid, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { creditData } from "../../../../../configs/mainStore";
import { getApplicationScorecardList } from "../../../../../services/creditFileApiCall";
import isProductOf from "../../../../../utility/AthamaruProductBusinessDetails/AthamaruComponentsValidator";
import MasterDataProvider from "../../../../../utility/MasterDataProvider";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";
import { ErrorMessageComponent } from "../UserCreditComponents/ErrorMessageComponent";
import AthamaruScoreCardLayout from "./AthamaruScoreCardLayout";
import RetailScoreCardLayout from "./RetailScoreCardLayout";

const ScoreCardLayout = ({ applicationId }) => {
    const creditFileData: any = creditData.attach(Downgraded).get();
    const masterDataStore = MasterDataProvider.provideMasterData();
    const [scoreCardData, setScoreCardData] = useState<any>([]);
    const [totalScore, setTotalScore] = useState<any>(null);
    const [grade, setGrade] = useState<any>({});
    const [cribRecode, setCribDetails] = useState<any>();
    const [isLoading, setIsLoading] = useState<any>();

    useEffect(() => {
        const getCribDetails = async () => {
            setIsLoading(true);
            const scoreCardDataResult = await getApplicationScorecardList(applicationId);

            if (Object.keys(scoreCardDataResult).length > 0) {
                const scoreDataObject = scoreCardDataResult?.scoreCardData;
                let array: any = [];

                Object.keys(scoreDataObject).map((item) => {
                    array[item] = scoreDataObject[item];
                });

                setScoreCardData(array);
                setTotalScore(scoreCardDataResult?.totalScore);
                setGrade(scoreCardDataResult?.grade);
            }
        };

        getCribDetails().then((res) => {
            setCribDetails(res);
        });
    }, []);

    useEffect(() => {
        if (creditFileData) {
            var timer = setTimeout(() => {
                setIsLoading(true);
                setIsLoading(false);
            }, 1000);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [creditFileData, cribRecode]);

    const ToggelScoreCardLayout = () => {
        const props = { isLoading, scoreCardData, masterDataStore };

        if (isProductOf()) {
            return <AthamaruScoreCardLayout {...props} />
        }

        return <RetailScoreCardLayout {...props} />
    }

    return (
        <div className="full-width inner-component-full-height">
            <Grid container p={1}>
                <Grid container>
                    {isLoading ? (
                        <CircularProgressComponent
                            size={30}
                            sx={{
                                marginTop: "20px",
                                marginBottom: "20px",
                                marginLeft: "auto",
                                marginRight: "auto",
                                zIndex: 100,
                            }}
                        />
                    ) : grade.length !== "" ? (
                        <Grid container>
                            <Grid container p={1} style={{ backgroundColor: `${grade?.color}3d` }}
                                className="border-radius-5">
                                <div className="basic-font basic-font-color-bold font-size-16 align-left">Overall
                                    Score
                                </div>
                            </Grid>
                            <Grid container p={1} className="data-box">
                                <Grid container item p={1} alignItems="center" justifyContent="center">
                                    <Grid item xs={3}>
                                        <Grid item>
                                            <Stack className=" align-center">
                                                <div
                                                    className={`basic-font basic-font-color opacity-7 font-size-12`}>{"Total Score"}</div>
                                                <div className={`basic-font font-size-28`}
                                                    style={{ color: grade?.color }}>
                                                    {totalScore && totalScore.toFixed(2)}
                                                </div>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Grid item>
                                            <Stack className=" align-center">
                                                <div
                                                    className={`basic-font basic-font-color opacity-7 font-size-12`}>{"Grade"}</div>
                                                <Avatar round={true} value={grade?.name} size={"40px"}
                                                    color={grade?.color} />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <ErrorMessageComponent headMessage={"No data available"} errorMessage={""} />
                    )}
                </Grid>
                <ToggelScoreCardLayout />
            </Grid>
        </div>
    );
};

export default ScoreCardLayout;
