import { useHookstate } from '@hookstate/core';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../../../../../../../../../../services/currencyFormater';
import TableBodyWrapper from '../../../../../Helpers/TableContainer/TableBody/TableBodyWrapper';
import { TableColumnsTextBoxWrapperRevaamp, TableColumnsTextWrapper } from '../../../../../Helpers/TableContainer/TableColumn/TableColumnsWrapper';
import { CustomTableHeaderWrapperForBalanceSheet } from '../../../../../Helpers/TableContainer/TableHeader/TableHeaderWrapper';
import { TableRowWrapper } from '../../../../../Helpers/TableContainer/TableRow/TableRowWrapper';
import TableWrapper from '../../../../../Helpers/TableContainer/TableWrapper/TableWrapper';
import { viewMode } from '../../../../../Stores/MicroAppraisalStore';

const FinancialPositionOfCompanyGeneralizedTable = ({
    label,
    descriptionList,
    defaultObject,
    profitAndLossAccountsTitleArray,
    onChange,
    getAllCalculations

}) => {
    const { t: translate } = useTranslation();
    const viewModeType = useHookstate(viewMode);
    const isSummaryViewMode = (viewModeType.get() === "SUMMARY");

    const getAllCalculationValues = (year, mainDataKey) => {

        if (!getAllCalculations) return 0;

        else if (!getAllCalculations[mainDataKey]) return 0;

        else if (!getAllCalculations[mainDataKey][year]) return 0;

        else return Number(getAllCalculations[mainDataKey][year]);
    }

    const createAutoPopulatedValue = (year, description) => {

        if (year?.["isAutoPopulatedColumns"] && description.type === "autoPopulated") {
            return getAllCalculationValues(year.dataKey, description.dataKey);
        }
        else if (year?.["isAutoPopulatedColumns"]) {
            return Number(defaultObject?.[year.dataKey]?.[description.dataKey] || 0);
        }

        else return getAllCalculationValues(year.dataKey, description.dataKey);
    }

    return (
        <Box>
            <TableWrapper>
                <CustomTableHeaderWrapperForBalanceSheet
                    firstColTitle={translate(label)}
                    dataObject={profitAndLossAccountsTitleArray} />
                <TableBodyWrapper>
                    {descriptionList.map((item: any) => {

                        return (
                            <TableRowWrapper
                                key={"TableRowWrapper" + item.title + item.dataKey} >

                                <TableColumnsTextWrapper key={item.title} index={item.title + item.dataKey} children={item.title} />

                                {profitAndLossAccountsTitleArray.map((year) => {
                                    if ((item.type === "textBox" && !year?.["isAutoPopulatedColumns"])) {
                                        return (
                                            <>
                                                {
                                                    isSummaryViewMode ?
                                                        <TableColumnsTextWrapper key={item.title + "TableColumnsTextBoxWrapper"} index={item.title + item.dataKey} children={formatCurrency(defaultObject?.[year.dataKey]?.[item.dataKey] || 0)} />
                                                        :
                                                        <TableColumnsTextBoxWrapperRevaamp
                                                            key={year.dataKey + item.title + item.dataKey}
                                                            index={year.dataKey}
                                                            formData={defaultObject}
                                                            year={year.dataKey}
                                                            dataKey={item.dataKey}
                                                            onChange={onChange}
                                                        />
                                                }
                                            </>
                                        );
                                    } else if (item.type === "autoPopulated" || year?.["isAutoPopulatedColumns"]) {
                                        return (
                                            <TableColumnsTextWrapper
                                                key={year.dataKey + item.title + item.dataKey}
                                                index={year.dataKey}
                                                children={formatCurrency(createAutoPopulatedValue(year, item))} />
                                        );
                                    }
                                    return <></>
                                })}
                            </TableRowWrapper>
                        );
                    })}
                </TableBodyWrapper>
            </TableWrapper>
        </Box>
    )
}

export default FinancialPositionOfCompanyGeneralizedTable
