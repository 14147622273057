import { Downgraded, useHookstate } from '@hookstate/core';
import { Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { creditData } from '../../../../../configs/mainStore';
import { disbursementAPIValidations } from '../../../../../services/creditFileApiCall';
import { MainSectionsWrapper } from '../../../MainSectionsWrapper/MainSectionsWrapper';
import { ValidationResultContainer, ValidationResults } from './FraudCheck';
import { CircularProgressComponent } from '../../../ProgressComponent/ProgressComponent';

export const DisbursementAPICheck = () => {
  const creditFileData: any = useHookstate(creditData);
  const usersData = creditFileData?.attach(Downgraded)?.get();

  const [result, setResult] = useState([]);
  const [status, setStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const applicationId = usersData.applicationId[0];

    validate(applicationId);
  }, []);

  const validate = async (applicationId) => {
    setIsLoading(true);
    const response = await disbursementAPIValidations(applicationId);

    if (response) {
      setResult(response);
    }

    const isError = response?.every((result) => result?.code == 1);
    setStatus(isError);
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <Grid
          container
          alignItems="center"
          sx={{
            paddingTop: "20px",
            paddingBottom: "20px",
            position: "relative",
          }}
        >
          <CircularProgressComponent size={30} sx={{ left: "50%", position: "absolute", zIndex: 100 }} />
        </Grid>
      ) : (
        result?.length === 0 ? (
          <Stack></Stack>
        ) : (
          <MainSectionsWrapper
            showSubText={false}
            showStatus={false}
            title="Disbursement Validation"
            keyCode={undefined}
            noBtn={true}
            decisionState={status}
          >
            <ValidationResultContainer results={result} containerWidth={6}>
              <ValidationResults result={result} />
            </ValidationResultContainer>
          </MainSectionsWrapper>
        )
      )}
    </>
  );
};
