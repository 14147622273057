import { useState } from "@hookstate/core";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { Box, Grid, Stack } from "@mui/material";
import React, { createRef, useEffect } from "react";
import { ERROR_CODES } from "../../../../../../configs/constants/errorCode";
import { userDetails, workflowMasterData } from "../../../../../../configs/mainStore";
import { getUserGroupsForReports } from "../../../../../../services/applicationApis";
import {
  applicationTimelineApi,
  createCauHeadAssign,
  getOfficerManagerGroupId,
  uploadCauReferenceFiles,
} from "../../../../../../services/creditFileApiCall";
import { getUsersByGroupId } from "../../../../../../services/userPrivilegeService";
import { closeBtn } from "../../../../../../styles";
import { userName } from "../../../../../../utility/helpers/creditFileUtility";
import { referStatus } from "../../../../../../utility/util";
import { ButtonComponent } from "../../../../../InputComponents/ButtonComponent/ButtonComponent";
import ModalInputComponent from "../../../../../InputComponents/ModalInputComponent/ModalInputComponent";
import { RadioButtonComponent } from "../../../../../InputComponents/RadioButtonComponent/RadioButtonComponent";
import { SelectComponent } from "../../../../../InputComponents/SelectComponent/SelectComponent";
import { TextBoxComponent } from "../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { FileUploadComponent } from "../../../../DropzoneComponent/FileUploadComponent";
import { closeGlobalModal } from "../../../../GlobalModal/GlobalModal";
import { addToaster } from "../../../../GlobalToast";

const OfficerHeadReferManagerModal = (params) => {
  const initialData = { note: "", user: "" };
  const showErrors = useState(false);
  const { wf_system_users, wf_groups }: any = useState(workflowMasterData);
  const referState: any = useState(initialData);
  const loginUser = useState(userDetails);
  const userData = params?.userData;
  const cauReferalData = params?.cauReferalData;
  //const userGroupId = userData?.groupId;
  const userGroupId = 39;

  const [isLoading, setIsLoading] = React.useState<any>(false);
  const [dropdownUserList, setDropdownUserList] = React.useState<any>([]);
  const [referGroupsList, setReferGroupsList] = React.useState<any>([]);
  const [referGroup, setReferGroup] = React.useState<any>([]);
  const [headOfficer, setHeadOfficer] = React.useState<any>(false);
  const [headOfficerList, setHeadOfficerList] = React.useState<any>(false);
  const [headOfficerValue, setHeadOfficerValue] = React.useState<any>(null);
  const [assignToAdminOpinion, setAssignToAdminOpinion] = React.useState<any>(null);

  const docUploadRef: any = createRef();

  const [refresh, setRefresh] = React.useState(false);
  const [fileList, setFileList] = React.useState<any>([
    {
      //TODO LEGAL DOC GET FROM DB
      ref: docUploadRef,
      isMandatory: true,
      allowedFormats: "application/pdf",
      maxFileSize: 4000000,
      documentKey: "",
    },
  ]);

  const radioButtonGroup = [
    {
      id: 1,
      name: "Yes",
      label: "Yes",
      value: true,
    },
    {
      id: 0,
      name: "No",
      label: "No",
      value: false,
    },
  ];

  useEffect(() => {
    (async function () {
      setIsLoading(true);

      let tempUserList = {};
      const res = await getOfficerManagerGroupId(params?.data?.referType);

      const uniqueRes = new Set(res);

      if (res) {
        tempUserList = Array.from(uniqueRes.values()).map((obj) => {
          return {
            name: userName(obj),
            id: obj,
          };
        });

        setHeadOfficerList(tempUserList);
      }
      setIsLoading(false);
    })();
  }, []);

  const saveRefer = async () => {
    const errorState = validateSave();
    if (errorState) {
      showErrors.set(true);
    } else {
      const promises: any = [];

      fileList.map((item: any) => {
        promises.push(item.ref.current?.uploadSingleImg());
      });

      const referStat = Object.keys(referStatus)?.find((sts) => referStatus[sts]?.key == "active");
      const data = {
        applicationId: params?.applicationId,
        createdBy: loginUser?.userId?.get(),
        workflowId: params?.currentWorkflow,
        stageId: params?.currentWorkflowStage,
        referStatus: referStat,
        referType: referGroup,
        cauOfficer: true,
        referId: params?.referId,
        assignToAdminOpinion: assignToAdminOpinion,
        assignee: assignToAdminOpinion === false ? params?.data?.createdBy : referState.user.get(),
        ...referState.get(),
      };

      const res = await createCauHeadAssign(data);
      if (res?.status?.code === 1) {
        addToaster({
          status: "success",
          title: "Success",
          message: res?.msg,
        });
        const referenceId = params?.data?.referId;
        const referenceType = params?.data?.referType;
        const appTimelineParams = {
          referenceId,
          applicationId: params?.applicationId,
          actionedBy: loginUser?.userId?.get(),
          assignee: headOfficerValue,
          actionNote: referState?.note?.get() || "",
          actionType: "ASSIGN_TO_CAU_HEAD",
        };
        const applicationTimelineApiResponse = await applicationTimelineApi(appTimelineParams);
        if (applicationTimelineApiResponse?.status === ERROR_CODES.success) {
          addToaster({
            status: "success",
            title: "Success",
            message: applicationTimelineApiResponse?.data?.message,
          });
        } else {
          addToaster({
            status: "error",
            title: "Error",
            message: "Timeline Update Failed",
          });
        }
        // calling the modal callback passing necessary arguments
        if (params.callBackOnAction && params.callBackOnAction instanceof Function) {
          params.callBackOnAction(res?.data);
        }
      } else {
        addToaster({
          status: "error",
          title: "Error",
          message: res?.data?.msg,
        });
      }
      closeGlobalModal();
    }
  };

  const closeRefer = () => {
    closeGlobalModal();
  };

  const validateSave = () => {
    return referState.note.get() === "";
  };

  const resetError = () => {
    if (showErrors.get()) {
      showErrors.set(false);
    }
  };

  const getReferGroupId = async (groupID) => {
    let usersListData = await getUsersByGroupId(groupID);

    const correspondingValues = Object.keys(usersListData).map((key) => {
      const id = parseInt(key);
      const object = wf_system_users?.find((obj) => obj?.get().id === id);
      return {
        name: object?.fullName,
        id: object?.id,
      };
    });

    setDropdownUserList(correspondingValues);
  };

  useEffect(() => {
    (async () => {
      const referalIDList = await getUserGroupsForReports();
      let tempUserGroupList: any = [];
      wf_groups?.get()?.map((item: any) => {
        if (
          item?.id === referalIDList?.legalGroupId ||
          item?.id === referalIDList?.riskGroupId ||
          item?.id === referalIDList?.insDepGroupId
        ) {
          tempUserGroupList.push({ name: item?.name, id: item?.id });
        }
      });

      setReferGroupsList(tempUserGroupList);
    })();
  }, []);

  const onClickAddBtn = () => {
    let tempFileList = fileList;
    tempFileList.push({
      //TODO LEGAL DOC GET FROM DB
      ref: docUploadRef,
      isMandatory: false,
      allowedFormats: "application/pdf",
      maxFileSize: 4000000,
      documentKey: "",
    });

    setFileList(tempFileList);
    setRefresh(!refresh);
  };

  return (
    <Box>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <div className="basic-font font-size-18 basic-font-color-bold">Create Referral</div>
      </Stack>

      <Grid container spacing={2} mt={1} className="modal-text-field">
        <Grid container item xs={12}>
          <ModalInputComponent
            title="Note"
            component={
              <TextBoxComponent
                value={referState?.note?.get()}
                onChange={(e) => {
                  resetError();
                  referState.note.set(e.target.value);
                }}
                variant="outlined"
                size="small"
                multiline={true}
                minRows={4}
                inputProps={{ maxLength: 100 }}
              />
            }
            isMandatory={true}
            showErrors={showErrors.get() && referState.note.get() === ""}
          />
        </Grid>
        <Grid container item xs={12}>
          <ModalInputComponent
            title="Require Head of Credit Admmin Opinion"
            component={
              // <TextBoxComponent
              //   value={referState?.note?.get()}
              //   onChange={(e) => {
              //     resetError();
              //     referState.note.set(e.target.value);
              //   }}
              //   variant="outlined"
              //   size="small"
              //   multiline={true}
              //   minRows={4}
              //   inputProps={{ maxLength: 100 }}
              // />
              radioButtonGroup.map((item) => {
                return (
                  <>
                    <RadioButtonComponent
                      checked={assignToAdminOpinion === item?.value}
                      value={item?.value}
                      name={item?.name}
                      onChange={() => setAssignToAdminOpinion(item?.value)} // onChange={() => selectedFrom.set(element)}
                    />
                    <span className={"user-preference-label"}>{item?.label}</span>
                  </>
                );
              })
            }
            isMandatory={true}
            showErrors={showErrors.get() && referState.note.get() === ""}
          />
        </Grid>
        {assignToAdminOpinion === true && headOfficerList && (
          <Grid container item xs={12}>
            <ModalInputComponent
              title="Head of Credit Admin"
              component={
                <SelectComponent
                  onChange={(e) => {
                    resetError();
                    setHeadOfficerValue(e.target.value);
                    referState.user.set(e.target.value);
                  }}
                  values={headOfficerList}
                  value={headOfficerValue}
                  size="small"
                  label={"Select Head of Credit Admin"}
                />
              }
              isMandatory={true}
              showErrors={showErrors.get() && !referState.user.get()}
            />
          </Grid>
        )}

        {/* <Grid item xs={12} md={12} className="">
          <Grid
            container
            item
            // spacing={1}
            direction="row"
            sm={12}>
            <div
              className="basic-font basic-font-color font-size-16 font-weight-100"
              style={{ marginBottom: 0, marginTop: 0, marginRight: 10 }}>
              Upload File(s)
            </div>
            <Grid item sm={4}>
              <AddCircleIcon
                style={{ color: "gray", fontSize: 25, cursor: "pointer" }}
                onClick={() => {
                  onClickAddBtn();
                }}
              />
            </Grid>
          </Grid>
        </Grid> */}

        {fileList.map((item, i) => {
          return (
            <FileUploadComponent
              fileDetails={item}
              uploadDataObject={{
                applicationId: params?.applicationId,
                referenceId: params?.referId,
                docIndex: i,
                documentId: cauReferalData?.data?.cau_document_id,
                createdBy: loginUser?.userId?.get(),
                assignToAdminOpinion: assignToAdminOpinion,
                assignee: assignToAdminOpinion === false ? params?.data?.assignee : "",
              }}
              isPreviewHave={false}
              fileUploadApi={uploadCauReferenceFiles}
            />
          );
        })}
      </Grid>

      <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
        <ButtonComponent
          title="Close"
          startIcon={<CloseIcon />}
          variant="contained"
          onClick={closeRefer}
          loadingbtn={true}
          style={closeBtn}
        />
        <ButtonComponent
          startIcon={<DoneIcon />}
          title="Save"
          variant="contained"
          onClick={saveRefer}
          loadingbtn={true}
          color="info"
        />
      </Stack>
    </Box>
  );
};

export default OfficerHeadReferManagerModal;
