import { useEffect, useState } from 'react';
import { CircularProgressComponent } from '../../../../../../ProgressComponent/ProgressComponent';
import { getRatioAnalysis } from '../../Api/AppraisalApi';
import MainHeader from '../../Helpers/MainHeader/MainHeader';
import RatioAnalysisVersionTwo from './RatioAnalysisVersionTwo';
import { RatioAnalysisContext } from './Stores/Context';

const RatioAnalysisContainer = ({ applicationId }) => {
    const [ratioAnalysisData, setRatioAnalysisData] = useState<Object>({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getRatioData();
    }, []);

    const getRatioData = async () => {
        try {
            let response: any = await getRatioAnalysis(applicationId);

            if (response) {
                setRatioAnalysisData({ ...response });
            }
        } catch (error) {
            new Error(`${error} | Micro | RatioAnalysisContainer| getRatioData`);
        } finally {
            setIsLoading(false)
        }
    }

    if (isLoading) <CircularProgressComponent sx={{ left: '50%', top: '50%' }} />

    return (
        <MainHeader title={"Ratio Analysis"} onSubmit={undefined}>
            <RatioAnalysisContext.Provider value={ratioAnalysisData} >
                <RatioAnalysisVersionTwo />
                {/* <ChangeDetector value={0} /> */}
            </RatioAnalysisContext.Provider>
        </MainHeader>
    )
}

export default RatioAnalysisContainer
