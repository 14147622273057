import { Box, Button, Grid, Stack } from "@mui/material";
import React from "react";
import { ButtonComponent } from "../../../InputComponents/ButtonComponent/ButtonComponent";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { useState } from "@hookstate/core";
import { TextBoxComponent } from "../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { userDetails, workflowMasterData } from "../../../../configs/mainStore";
import { closeGlobalModal } from "../../../OtherComponents/GlobalModal/GlobalModal";
import { addToaster } from "../../../OtherComponents/GlobalToast";
import { responseSuccessCode } from "../../../../utility/other";
import ModalInputComponent from "../../../InputComponents/ModalInputComponent/ModalInputComponent";
import { closeBtn } from "../../../../styles";
import AutoCompleteProxyComponent from "../../../InputComponents/AutoCompleteComponent/AutoCompleteProxyComponent";
import { signatureRejectReasons } from "../../../../configs/constants/contants";

const SignatureVerifyModal = (params) => {
  const [reasonList, setReasonList]: any[] = React.useState(signatureRejectReasons || []);
  const initialData = { status: "", reason: "", comment: "" };
  const signatureState: any = useState(initialData);
  const showErrors = useState(false);
  const { wf_system_users }: any = useState(workflowMasterData);
  const loginUser = useState(userDetails);
  const [isRejectSelected, setIsRejectSelected]: any = React.useState(false);

  const submitSignatureVerify = async () => {
    // const errorState = validateSave();
    // if (errorState) {
    //   showErrors.set(true);
    // } else {
    //   const taskStat = Object.keys(taskStatus)?.find((sts) => taskStatus[sts]?.key == "open");
    //   const data = {
    //     applicationId: params?.applicationId,
    //     createdBy: loginUser?.userId?.get(),
    //     workflowId: params?.currentWorkflow,
    //     stageId: params?.currentWorkflowStage,
    //     taskStatus: taskStat,
    //     ...taskState.get(),
    //   };
    //   const res = await saveTaskData(data);
    //   if (res?.status === responseSuccessCode) {
    //     addToaster({
    //       status: "success",
    //       title: "Success",
    //       message: res?.data?.msg,
    //     });
    //     // calling the modal callback passing necessary arguments
    //     if (params.callBackOnAction && params.callBackOnAction instanceof Function) {
    //       params.callBackOnAction(res?.data?.data);
    //     }
    //   } else {
    //     addToaster({
    //       status: "error",
    //       title: "Error",
    //       message: res?.data?.msg,
    //     });
    //   }
    //   closeGlobalModal();
    // }
  };

  const closeTask = () => {
    closeGlobalModal();
  };

  // const validateSave = () => {
  //   return signatureState.reason.get() === "" || signatureState.comment.get() === "" || signatureState.status.get() === "";
  // };

  const resetError = () => {
    if (showErrors.get()) {
      showErrors.set(false);
    }
  };

  const showRejectReason = (type) => {
    if (type < 1) {
      setIsRejectSelected(false);
    } else {
      setIsRejectSelected(true);
    }
  };

  return (
    <Box>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <div className="basic-font font-size-18 basic-font-color-bold">Provide Approval</div>
      </Stack>

      <Grid container spacing={2} mt={1} className="modal-text-field">
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-around" }} mb={3}>
              <Button onClick={() => showRejectReason(0)} variant="contained" color="success">
                Approve Signature
              </Button>
              <Button onClick={() => showRejectReason(1)} variant="contained" color="error">
                Reject Signature
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Grid container item xs={12}>
                {isRejectSelected && (
                  <Grid item xs={12} mb={2}>
                    <ModalInputComponent
                      title="Reject Reason"
                      component={
                        <AutoCompleteProxyComponent
                          options={reasonList}
                          fullWidth={true}
                          onChange={(e) => {
                            resetError();
                            return signatureState.reason.set(e ? e.id : null);
                          }}
                          label={"Select Reason"}
                          value={signatureState?.reason?.get()}
                          cls="select_basic"
                          size="small"
                        />
                      }
                      isMandatory={true}
                      showErrors={showErrors.get() && !signatureState.reason.get()}
                    />
                  </Grid>
                )}

                <Grid item xs={12} mb={2}>
                  <ModalInputComponent
                    title="Comment"
                    component={
                      <TextBoxComponent
                        value={signatureState?.comment?.get()}
                        onChange={(e) => {
                          resetError();
                          signatureState.comment.set(e.target.value);
                        }}
                        variant="outlined"
                        size="small"
                        multiline={true}
                        maxRows={4}
                        rows={4}
                        inputProps={{ maxLength: 4000 }}
                      />
                    }
                    isMandatory={true}
                    showErrors={showErrors.get() && signatureState.comment.get() === ""}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
        <ButtonComponent title="Close" startIcon={<CloseIcon />} variant="contained" onClick={closeTask} loadingbtn={true} style={closeBtn} />
        <ButtonComponent
          startIcon={<DoneIcon />}
          title="Save"
          variant="contained"
          onClick={submitSignatureVerify}
          // disabled={disableSave()}
          loadingbtn={true}
          color="info"
        />
      </Stack>
    </Box>
  );
};

export default SignatureVerifyModal;
