import { useHookstate } from "@hookstate/core";
import { Paper, Stack, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { workflowMasterData } from "../../../../../configs/mainStore";
import { getHistoryDataForApplication, getStageColors } from "../../../../../services/creditFileApiCall";
import { userName } from "../../../../../utility/helpers/creditFileUtility";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";

const Timeline = ({ applicationId, currentApplicationWfData, innerComponentData }) => {
    let lock = false;
    const { wf_stages: stages }: any = useHookstate(workflowMasterData);
    const { applicationSubmittedDate } = currentApplicationWfData;
    const [loading, setLoading]: any = useState(true);
    const [items, setItems]: any = useState([]);
    const stageMap = stages.get()?.reduce((acc, stage) => {
        acc[stage.id] = stage.name;
        return acc;
    }, {});
    const [stageColors, setStageColors]: any = React.useState([]);
    const [selectedIndex, setSelectedIndex]: any = React.useState(-1);

    useEffect(() => {
        prepareDataSource();

        return () => {
            lock = true;
        }
    }, []);

    const prepareDataSource = async () => {
        const [stages = [], data] = await Promise.all([getStageColors(), getHistoryDataForApplication(applicationId)]);
        setStageColors(stages);
        calcTimeLines(data);
    }

    const calcTimeLines = (data) => {
        if (!data || !(data instanceof Array)) {
            return;
        }

        data = data.reverse();

        let startDate = new Date(applicationSubmittedDate);

        const list = data.reduce((acc, item) => {
            const data = { ...item };
            const recordedDate = new Date(data.timeStamp);
            const duration = recordedDate.getTime() - startDate.getTime();
            data.duration = duration / (3600 * 1000)
            acc[acc.length] = data;
            startDate = recordedDate;
            return acc;
        }, []);

        setItems(list);

        setLoading(false);
    }

    if (loading) {
        return <Stack flex={1} justifyContent={'center'} alignItems={'center'} height={"200px"}><CircularProgressComponent
            size={30}
        /></Stack>;
    }

    return <Stack spacing={2} padding={2} style={{ backgroundColor: "white" }}>
        {/* <InnerSectionHeaderComponent innerComponentData={innerComponentData} applicationId={applicationId} currentApplicationWfData={currentApplicationWfData} disabled={true} /> */}
        <Paper style={{ margin: 0 }}>
            <Stack direction={"row"} spacing={0.2} style={{ top: "0px", position: "sticky", zIndex: 100, backgroundColor: "white" }}>
                {items.map(obj => <Stack key={obj.ExecSequence} flex={obj.duration} style={{ backgroundColor: stageColors?.[obj.stage]?.backgroundColor ?? "red", cursor: "pointer", userSelect: "none", border: obj.ExecSequence === selectedIndex ? 'solid 2px lightBlue' : 'none' }} alignItems={"center"} justifyContent={"center"} onClick={(evt) => setSelectedIndex(obj.ExecSequence)}>
                    <Tooltip
                        title={<Stack>
                            <Stack>{stageMap?.[obj.stage] || ""}</Stack>
                            <Stack>{Number(obj.duration / 24).toFixed(0)} days</Stack>
                        </Stack>}
                        sx={{ ":hover": "background: 'white'" }}
                    >
                        <Stack height={30} width={"100%"} alignItems={"center"} justifyContent={"center"}>
                            <Typography variant="caption" fontSize={"6pt"}> {Number(obj.duration).toFixed(0)} </Typography>
                        </Stack>
                    </Tooltip>
                </Stack>)}
            </Stack>
        </Paper>
        <Stack>
            {
                items.map((obj) => <Stack direction={"row"} spacing={2} py={1} style={{ border: obj.ExecSequence === selectedIndex ? 'solid 2px lightBlue' : 'none' }}>
                    <Stack direction={"row"} spacing={1} flex={1}>
                        <Typography variant="caption" color={"gray"} width={40}> {`${Number(obj.duration / 24) < 1 ? Number(obj.duration).toFixed(0) + 'h' : Number(obj.duration / 24).toFixed(0) + 'd'}`}</Typography>
                        <Typography variant="caption" flex={1}> {stageMap?.[obj.stage] || ""} </Typography>
                    </Stack>
                    <Stack flex={2}>
                        <Typography variant="caption" flex={1}> Action By {userName(obj.actionBy)} </Typography>
                    </Stack>
                </Stack>)
            }
        </Stack>
    </Stack>;
}

export default Timeline;