import { State, useState } from "@hookstate/core";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { Grid, Stack } from "@mui/material";
import React from "react";
import { formatCurrency } from "../../../../../../../../services/currencyFormater";
import { OnDemandValueFormatter } from "../../../../../../../../services/onDemandValidators";
import { getCopy } from "../../../../../../../../utility/util";
import { ButtonComponent } from "../../../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { DeleteButton } from "../../../../../../../InputComponents/MultipleInputFields/MultipleInputFieldsExtends";
import { SelectComponent } from "../../../../../../../InputComponents/SelectComponent/SelectComponent";
import { TextBoxComponent } from "../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { FieldSectionWrapper } from "../../../../../../FieldSectionsWrapper.tsx/FieldSectionWrapper";
import { SubSectionsWrapper } from "../../../../../../SubSectionsWrapper/SubSectionsWrapper";
import GridDataTable from "../../../FormBuilder/Tools/Helpers/Components/GridDataTable";
import { periods } from "../../../FormBuilder/Tools/Helpers/inspectionConstants";
import { businessDetailsState } from "../../../FormBuilder/Tools/Helpers/inspectionStores/dataStore";
import { creditors } from "../../../FormBuilder/Tools/Helpers/inspectionStores/inspectionMainStore";

export const WorkingCapitalCreditors = ({ otherParams }) => {
  const [openModal, setOpenModal] = React.useState(false);
  const businessDetails: any = useState(businessDetailsState);

  const data = businessDetails;
  const { isAmountBelowTwoPointFive, isAmountAboeTwoPointFiveAndEqual } = otherParams;

  const handleReset = (itemIndex: number, stateData: State<any>) => {
    stateData.set(prev => ({ ...prev, ...getCopy(creditors) }))
  }

  return (
    <Grid container>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <SubSectionsWrapper>
          <FieldSectionWrapper>
            <GridDataTable
              openModal={openModal}
              setOpenModal={setOpenModal}
              sourceArray={data?.creditors}
              defaultDataObject={JSON.parse(JSON.stringify(creditors))}
              mandatoryKeys={[]}
              headerTitle={["Description", "Period", "Amount", "Number of Days"]}
            >
              {data?.creditors
                ?.filter((itemObj) => !itemObj?.removedItem?.get())
                .map((items, index) => (
                  <Grid item xs={12} md={12}>
                    <Grid container gap={1}>
                      <Grid item xs={2}>
                        <TextBoxComponent
                          label={"Description"}
                          required={isAmountAboeTwoPointFiveAndEqual === true ? true : false}
                          value={items?.description?.get()}
                          fullWidth={true}
                          onChange={(e) => items?.description?.set(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <SelectComponent
                          values={periods}
                          required={isAmountAboeTwoPointFiveAndEqual === true ? true : false}
                          value={items?.period?.get()}
                          onChange={(e) => items?.period?.set(e.target.value)}
                          label="Period"
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextBoxComponent
                          label={"Amount"}
                          required={isAmountAboeTwoPointFiveAndEqual === true ? true : false}
                          value={formatCurrency(items?.amount?.get())}
                          onInput={OnDemandValueFormatter.numeric}
                          fullWidth={true}
                          onChange={(e) => items?.amount?.set(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextBoxComponent
                          label={"Number of Days"}
                          required={isAmountAboeTwoPointFiveAndEqual === true ? true : false}
                          value={items?.numberOfDays?.get()}
                          fullWidth={true}
                          onChange={(e) => {
                            if (e.target.value >= 0 && e.target.value <= 500) {
                              items?.numberOfDays?.set(e.target.value);
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Stack direction={"row"}>
                          <ButtonComponent iconbtn children={<RotateLeftIcon />} onClick={() => handleReset(index, items)} />
                          {index !== 0 && <DeleteButton selectedItem={items} index={index} />}
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </GridDataTable>
          </FieldSectionWrapper>
        </SubSectionsWrapper>
      </Grid>
    </Grid>
  );
};
