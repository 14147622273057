import React, { useEffect } from 'react';

import { Box, Button, Grid, Stack } from '@mui/material';

import { TextBoxComponent } from '../../../../InputComponents/TextBoxComponent/TextBoxComponent';
import { CMTextInput } from '../../../CommunicationService/CMTextInput';
import { ButtonComponent } from '../../../../InputComponents/ButtonComponent/ButtonComponent';
import { closeGlobalModal } from '../../../GlobalModal/GlobalModal';
import { Downgraded, useState } from '@hookstate/core';
import { appSetting, userDetails, workflowActionModal } from '../../../../../configs/mainStore';

import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { updateApplicationWorkflowStage } from '../../../../../services/workflowApiCalls';
import { addToaster } from '../../../GlobalToast';
import { SelectComponent } from '../../../../InputComponents/SelectComponent/SelectComponent';
import { getWorkflowStageById } from '../../../../../services/workflowServices';
import MasterDataProvider from '../../../../../utility/MasterDataProvider';
import { addFundReleaseApproveData } from '../../../../../services/creditFileApiCall';
import { fundReleaseStatusColors } from '../../../WorkflowComponents/utils';
import { closeBtn } from '../../../../../styles';

export const FundReleaseApprovalModal = (params) => {
  const appSettings: any = useState(appSetting);
  const { userId }: any = userDetails.attach(Downgraded).get();

  const masterDataStore = MasterDataProvider.provideMasterData();

  const [dropdownList, setDropdownList] = React.useState([]);

  const [approvalStatus, setApprovalStatus] = React.useState('');
  const [comment, setComment] = React.useState('');

  const [isLoading, setIsLoading] = React.useState<any>(false);

  const [errorMessage, setErrorMessage] = React.useState<any>({ status: '', comment: '' });

  useEffect(() => {
    setDropdownList(masterDataStore?.getMasterData('FUND_APPROVAL_STATUS').get());
  }, []);

  const onSubmit = async () => {
    let isValid = true;

    let tempError = errorMessage;
    if (comment === '') {
      tempError = { ...tempError, ...{ comment: 'Required!' } };
      isValid = false;
    }

    if (approvalStatus === '') {
      tempError = { ...tempError, ...{ status: 'Required!' } };
      isValid = false;
    }

    setErrorMessage(tempError);

    if (isValid) {
      setIsLoading(true);

      const result = await addFundReleaseApproveData(params?.applicationId, approvalStatus, comment, userId);

      closeGlobalModal();

      if (result?.msg) {
        addToaster({
          status: 'success',
          title: 'Updated',
          message: 'Fund Release Approval Comment Added',
        });

        params?.callBackOnAction();
      } else if (result?.error) {
        addToaster({
          status: 'warning',
          title: 'Not Updated',
          message: 'Update Error',
        });
      } else {
        addToaster({
          status: 'error',
          title: 'Error',
          message: 'Something went Wrong!',
        });
      }

      setIsLoading(false);
    }
  };

  const onChangeField = (value) => {
    setComment(value);

    if (value === '') {
      setErrorMessage({ ...errorMessage, ...{ comment: 'Required!' } });
    } else {
      setErrorMessage({ ...errorMessage, ...{ comment: '' } });
    }
  };

  const onChangeStatus = (value: any) => {
    setApprovalStatus(value);

    if (value === '') {
      setErrorMessage({ ...errorMessage, ...{ status: 'Required!' } });
    } else {
      setErrorMessage({ ...errorMessage, ...{ status: '' } });
    }
  };

  return (
    <Box>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <div className="basic-font font-size-18 basic-font-color-bold">{'Fund Release Approval'}</div>
      </Stack>

      <Grid container spacing={1} mt={1} rowSpacing={1} className="modal-text-field">
        <Grid item xs={12} md={12} className="">
          {dropdownList?.length < 4 ? (
            <Box style={{ marginTop: 8, marginBottom: 8 }}>
              <div
                className="basic-font basic-font-color font-size-16 font-weight-100"
                style={{ marginBottom: 0, marginTop: 0 }}
              >
                Status
                <Grid container className="align-center" columns={24}>
                  {dropdownList?.map((item: any) => {
                    let color = fundReleaseStatusColors[item?.id]?.color
                      ? fundReleaseStatusColors[item?.id]?.color
                      : fundReleaseStatusColors['default']?.color;
                    return (
                      <Grid container item xs={6} sm={6} direction="column" justifyContent="center">
                        <div
                          className={`basic-font font-size-14 fund-release-chip`}
                          style={
                            approvalStatus === item?.id
                              ? { color: 'white', backgroundColor: color }
                              : { color: color, backgroundColor: `${color}3d` }
                          }
                          onClick={() => {
                            onChangeStatus(item?.id);
                          }}
                        >
                          {item?.name}
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            </Box>
          ) : (
            <SelectComponent
              label={'Approval Status'}
              value={approvalStatus}
              values={dropdownList}
              onChange={(e) => {
                onChangeStatus(e.target.value);
              }}
              disabled={isLoading}
            />
          )}
          {errorMessage?.status !== '' && (
            <Box>
              <span className="error font-size-14">{errorMessage?.status}</span>
            </Box>
          )}
        </Grid>

        <Grid item xs={12} md={12} className="">
          <CMTextInput
            desc={'Comment'}
            component={
              <TextBoxComponent
                onChange={(e) => {
                  onChangeField(e.target.value);
                }}
                multiline={true}
                minRows={6}
                value={comment}
                disabled={isLoading}
              />
            }
            error={errorMessage?.comment !== '' ? true : false}
          />
        </Grid>
      </Grid>
      <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
        <ButtonComponent
          title="CLOSE"
          startIcon={<CloseIcon />}
          variant="contained"
          onClick={() => {
            closeGlobalModal();
          }}
          disabled={isLoading}
          loadingbtn={true}
          loading={isLoading}
          style={closeBtn}
        />
        <ButtonComponent
          startIcon={<DoneIcon />}
          title="SUBMIT"
          variant="contained"
          onClick={() => {
            onSubmit();
          }}
          disabled={isLoading}
          loadingbtn={true}
          loading={isLoading}
          color="info"
        />
      </Stack>
    </Box>
  );
};
