import React, { FC, useEffect, createRef } from "react";
import { Card, Grid } from "@mui/material";
import { SubSectionsWrapper } from "../SubSectionsWrapper/SubSectionsWrapper";
import { DocumentUploadSectionWrapper } from "../MainSectionsWrapper/DocumentUploadSectionWrapper";
import { appSetting, userDetails } from "../../../configs/mainStore";
import { Downgraded, useState } from "@hookstate/core";
import { openGlobalModal } from "../GlobalModal/GlobalModal";
import { DisbursementDocumentUpload } from "../MainSectionsWrapper/DisbursementDocumentUpload";

interface DisbursementDocumentUploadSubSectionProps {
  sectionObj?: any;
  applicantType?: any;
  applicationId?: any;
  participantId?: any;
  uploadedFiles?: any;
  documentTypes?: any;
  uploadedDocumentTypes?: any;
  documentSectionName?: any;
  mandatoryCount?: any;
  uploadedMandatoryCount?: any;
  documentComponentId?: any;
  personalData?: Object;
  creditFileData?: any;
  onSyncSuccess?: any;
  onSync?: any;
  sectionLoading?: Boolean;
  disbursed?: any;
  disableAccordingToTab?: any;
  refreshComponent?: any;
  reloadComponent?: any;
  isLoggedInUserNotTheAssignee?: any;
  disbIndex?: any;
  documentTypeId?: any;
}

export const DisbursementDocumentUploadSubSection: FC<DisbursementDocumentUploadSubSectionProps> = ({
  applicationId,
  applicantType,
  documentTypes,
  uploadedDocumentTypes,
  documentSectionName,
  mandatoryCount,
  uploadedMandatoryCount,
  participantId,
  documentComponentId,
  personalData,
  creditFileData,
  onSyncSuccess,
  onSync,
  sectionLoading,
  disbursed,
  disableAccordingToTab = false,
  reloadComponent,
  isLoggedInUserNotTheAssignee,
  disbIndex,
  documentTypeId,
}: any) => {
  const userDetailsData = useState(userDetails);
  const docUploadRef: any = createRef();
  const [sectionMeta, setSectionMeta] = React.useState<any>([]);
  const [isUploading, setIsUploading] = React.useState(false);
  const [sectionTitle, setSectionTitle] = React.useState<any>("");
  const [dataArray, setDataArray]: any = React.useState([]);
  const [documentArray, setDocumentArray] = React.useState<any>("");
  const [uploadedList, setUploadedList] = React.useState<any>([]);

  let extractAttachedDocuments = {
    invoke: () => {

    }
  }

  const submitBtnClick = async () => {
    const normalDocUploadResult = await docUploadRef?.current?.normalDocumentUploadFunc();
    if (normalDocUploadResult === true) {
      reloadComponent(true);
    }
  };

  useEffect(() => {
    if (personalData) {
      setSectionTitle(
        personalData?.initialsInFull
          ? `${documentSectionName} - ${personalData?.initialsInFull}`
          : documentSectionName
      );
    } else {
      setSectionTitle(sectionMeta?.desc ? `${sectionMeta?.desc} Documents` : documentSectionName);
    }
  });

  const onClickCreate = () => {
    const existingDocuments = extractAttachedDocuments.invoke();
    openGlobalModal({
      modalSize: "sm",
      title: "",
      bodyId: "add-documents",
      close: false,
      modalParams: {
        dataArray,
        documentTypes,
        disbIndex,
        applicationId,
        setDocumentArray,
        documentArray,
        existingDocuments
      }
    });
  };

  return (
    <DocumentUploadSectionWrapper
      title={sectionTitle}
      keyCode={1}
      noBack={true}
      loading={isUploading}
      onNext={() => {
        submitBtnClick();
      }}
      totalFiles={documentTypes.length}
      numOfUploadedFiles={uploadedDocumentTypes.length}
      numOfMandatory={mandatoryCount}
      numOfUploadedMandatoryFiles={uploadedMandatoryCount}
      subTxt={
        sectionMeta?.desc
          ? `Upload ${sectionMeta?.desc} Documents`
          : `Upload ${documentSectionName}`
      }
      documentComponentId={documentComponentId}
      personalData={personalData}
      creditFileData={creditFileData}
      onSyncSuccess={onSyncSuccess}
      onSync={onSync}
      sectionLoading={sectionLoading}
      disableAccordingToTab={disableAccordingToTab}
      documentTypes={documentTypes}
      documentTypeId={documentTypeId}
      onClickCreate={onClickCreate}
    >
      <SubSectionsWrapper title="">
        <DisbursementDocumentUpload ref={docUploadRef} disbIndex={disbIndex} documentTypes={documentTypes} documentArray={documentArray} setDocumentArray={setDocumentArray} setUploadedList={setUploadedList} applicationId={applicationId} extractAttachedDocuments={extractAttachedDocuments} reloadComponent={reloadComponent}/>
      </SubSectionsWrapper>
    </DocumentUploadSectionWrapper>
  );
};
