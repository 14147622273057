import { useHookstate } from '@hookstate/core';
import CloseIcon from "@mui/icons-material/Close";
import MinimizeIcon from "@mui/icons-material/Minimize";
import { Divider, Stack, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { floatingWidgetState } from './configs/mainStore';

const FloatingWidget = (props) => {
    const [className, setClassName] = useState("floating-widget");
    const [minimize, setMinimize] = useState(false);
    const [dragPosition, setDragPosition]: any = useState(null);
    const _floatingWidgetState: any = useHookstate(floatingWidgetState);
    const nodeRef = useRef(null);

    if (!_floatingWidgetState.show.get()) {
        return <Stack></Stack>;
    }

    return (
        <Draggable onStop={() => { setMinimize(false); setDragPosition(null); }} position={dragPosition} defaultPosition={{ x: 0, y: 0 }} ref={nodeRef}>
            <div className={className} style={{ width: props.width || '300px' }}>
                {/* {props.children ? props.children :
                    <> */}
                <Stack direction={'column'} px={1}>
                    <Stack direction={'row'} justifyContent={'space-between'} mb={1}> <Typography>{props.title}</Typography>
                        <Stack direction="row" spacing={1}>
                            <MinimizeIcon
                                onClick={() => {
                                    setMinimize(true);
                                    setDragPosition({ x: 0, y: 0 });
                                }}
                            />
                            <CloseIcon
                                onClick={() => {
                                    _floatingWidgetState.show.set(false);
                                }}
                            />
                        </Stack>
                    </Stack>
                </Stack>
                <Divider></Divider>
                <Stack direction={'column'} height={minimize ? '0px' : '400px'} p={2} style={{ overflow: 'hidden', overflowY: 'auto' }}>
                    {props.children}
                </Stack>
                {/* </>
                } */}
            </div>
        </Draggable>
    );
};

export const FloatingWidgetImage = (props) => {
    const [className, setClassName] = useState("floating-widget");
    const [minimize, setMinimize] = useState(false);
    const [dragPosition, setDragPosition]: any = useState(null);
    const nodeRef = useRef(null);

    return (
        <Draggable onStop={() => setMinimize(false)} position={dragPosition} defaultPosition={{ x: 0, y: 0 }} ref={nodeRef}>
            <div className={className}><Stack direction={'column'} width={'300px'} px={1}>
                <Stack direction={'row'} justifyContent={'space-between'} mb={1}> <Typography>File Issues </Typography>
                    <Stack direction="row" spacing={1}>
                        <MinimizeIcon
                            onClick={() => {
                                setMinimize(true);
                                setDragPosition({ x: 0, y: 0 });
                            }}
                        />
                        <CloseIcon
                            onClick={() => {
                            }}
                        />
                    </Stack>
                </Stack>
            </Stack>
                <Divider></Divider>
                <Stack direction={'column'} height={minimize ? '0px' : '400px'} px={1}>
                    <div id="img-zoomer-box">
                        <img src="https://www.codehim.com/demo/zoom-image-on-mouseover-using-javascript/img/photo.avif" id="img-1" alt="Zoom Image on Mouseover" width={'280px'} height={'380px'} />
                        <div id="img-2"></div>
                    </div>
                </Stack>
            </div>
        </Draggable>
    );
};

export default FloatingWidget;