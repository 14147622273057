import { Stack, Typography } from "@mui/material";

export const SubSectionHeaderBuilder = ({ metaData }) => {
  return (
    <Stack direction={"row"} width={"100%"} sx={{ backgroundColor: "gray" }}>
      <Typography variant="subtitle2" color={"white"} p={1}>
        {metaData?.title}
      </Typography>
    </Stack>
  );
};
