import { Box, Grid, Stack, Typography } from "@mui/material";
// import { closeBtn } from "../../../styles";
// import { ButtonComponent } from "../../InputComponents/ButtonComponent/ButtonComponent";
// import { closeGlobalModal } from "../GlobalModal/GlobalModal";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { ButtonComponent } from "../../components/InputComponents/ButtonComponent/ButtonComponent";
import { closeGlobalModal } from "../../components/OtherComponents/GlobalModal/GlobalModal";
import { closeBtn } from "../../styles";

const StepSectionChangesConfirmationModal = (params) => {
  const discardChanges = () => {
    closeGlobalModal();
  };
  const confirmSaveChanges = () => {
    params.callBackOnAction();
  };
  return (
    <Box>
      <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
        <Grid container columns={12}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" fontWeight={100}>
              Do you want to save the unsaved details?
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }} mt={3}>
            <ButtonComponent title="Cancel" startIcon={<CloseIcon />} variant="contained" onClick={discardChanges} loadingbtn={true} style={closeBtn} />
            <Box ml={0.5} mr={0.5}></Box>
            <ButtonComponent startIcon={<DoneIcon />} title="Yes" variant="contained" onClick={() => confirmSaveChanges()} loadingbtn={true} color="info" />
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
};

export default StepSectionChangesConfirmationModal;
