import { Downgraded } from "@hookstate/core";
import { Box, Stack, Grid } from "@mui/material";
import React, { useState } from "react";
import { followUpChecklistStatus } from "../../../../../configs/constants/contants";
import { appSetting, userDetails } from "../../../../../configs/mainStore";
import { addFollowupChecklistItem } from "../../../../../services/creditFileApiCall";
import { OnDemandLengthValidator } from "../../../../../services/onDemandValidators";
import { closeBtn } from "../../../../../styles";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import { TextBoxComponent } from "../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { CMTextInput } from "../../../../OtherComponents/CommunicationService/CMTextInput";
import { closeGlobalModal } from "../../../../OtherComponents/GlobalModal/GlobalModal";
import { addToaster } from "../../../../OtherComponents/GlobalToast";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";

const FollowUpChecklistModal = (params) => {
  const appSettings: any = useState(appSetting);
  const { userId }: any = userDetails.attach(Downgraded).get();

  const [item, setItem] = React.useState("");
  const [isLoading, setIsLoading] = React.useState<any>(false);
  const [errorMessage, setErrorMessage] = React.useState<any>({ item: "" });

  const onSubmit = async () => {
    let isValid = true;

    let tempError = errorMessage;
    if (item === "") {
      tempError = { ...tempError, ...{ item: "Required!" } };
      isValid = false;
    }

    setErrorMessage(tempError);

    if (isValid) {
      setIsLoading(true);

      const result = await addFollowupChecklistItem(
        params?.applicationId,
        item,
        followUpChecklistStatus.pending,
        userId
      );

      closeGlobalModal();

      if (result?.msg) {
        addToaster({
          status: "success",
          title: "Updated",
          message: "Check List Item Added",
        });

        params?.callBackOnAction();
      } else if (result?.error) {
        addToaster({
          status: "warning",
          title: "Not Updated",
          message: "Not Updated",
        });
      } else {
        addToaster({
          status: "error",
          title: "Error",
          message: "Something went Wrong!",
        });
      }

      setIsLoading(false);
    }
  };

  const onChangeField = (value) => {
    setItem(value);

    if (value === "") {
      setErrorMessage({ ...errorMessage, ...{ item: "Required!" } });
    } else {
      setErrorMessage({ ...errorMessage, ...{ item: "" } });
    }
  };

  return (
    <Box>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <div className="basic-font font-size-18 basic-font-color-bold">
          {"Add Check List Item"}
        </div>
      </Stack>

      <Grid
        container
        spacing={1}
        mt={1}
        rowSpacing={1}
        className="modal-text-field"
      >
        <Grid item xs={12} md={12} className="">
          <CMTextInput
            desc={"Check List Item"}
            component={
              <TextBoxComponent
                onChange={(e) => {
                  onChangeField(e.target.value);
                }}
                multiline={true}
                minRows={3}
                value={item}
                disabled={isLoading}
                onInput={OnDemandLengthValidator.followUpCheckList}
              />
            }
            error={errorMessage?.item !== "" ? true : false}
          />
        </Grid>
      </Grid>

      <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
        <ButtonComponent
          title="CLOSE"
          startIcon={<CloseIcon />}
          variant="contained"
          onClick={() => {
            closeGlobalModal();
          }}
          disabled={isLoading}
          loadingbtn={true}
          loading={isLoading}
          style={closeBtn}
        />
        <ButtonComponent
          startIcon={<DoneIcon />}
          title="SUBMIT"
          variant="contained"
          onClick={() => {
            onSubmit();
          }}
          disabled={isLoading}
          loadingbtn={true}
          loading={isLoading}
          color="info"
        />
      </Stack>
    </Box>
  );
};

export default FollowUpChecklistModal;
