import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { formatCurrency } from "../../../../../../../../../services/currencyFormater";
import { OnDemandValueFormatter } from "../../../../../../../../../services/onDemandValidators";
import { ButtonComponent } from "../../../../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { TextBoxComponent } from "../../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import AlertDialogBox from "../../../../../../../AlertComponent/AlertComponent";

const GridDataTable = ({ label, openModal, setOpenModal, sourceArray, children, defaultDataObject, mandatoryKeys, actionReset, headerTitle, removeComponent = false, showControllers = true, addButtonInvisible = false }: any) => {
  const [showControllersSub, setShowControllersSub] = React.useState(false);
  const [amount, setAmount] = useState(0);
  const [numberOfDays, setNumberOfDays] = useState(0);

  useEffect(() => {
    setShowControllers();
  }, []);

  useEffect(() => {
    let amount = 0;
    let numberOfDays = 0;

    sourceArray
      .filter((item) => !item["removedItem"].get())
      .forEach((item) => {
        amount += parseFloat(item.amount.get()) ? parseFloat(item.amount.get()) : 0;
        numberOfDays += parseFloat(item.numberOfDays.get()) ? parseFloat(item.numberOfDays.get()) : 0;
      });
    setAmount(amount);
    setNumberOfDays(numberOfDays);
  }, [sourceArray]);

  const onValuesReset = () => {
    setOpenModal(true);
  };

  const elementKeys: any = sourceArray?.length > 0 ? Object.keys(sourceArray[0]) : null;

  const enableAddNewItem =
    sourceArray?.length > 0 &&
    sourceArray?.every((element) => {
      let enable = true;

      if (!Object.keys(element).includes("removedItem")) {
        for (let key = 0; key < elementKeys?.length; key++) {
          if (mandatoryKeys.includes(elementKeys[key])) {
            if (element[elementKeys[key]]?.get()?.length === 0 || element[elementKeys[key]]?.get() === null) {
              enable = false;

              break;
            }
          }
        }
      }

      return enable;
    });

  const onNewFieldAdding = () => {
    if (!addButtonInvisible) {
      sourceArray.merge([defaultDataObject]);
    }
  };

  const setShowControllers = () => {
    if (sourceArray?.length === 0) return false;

    const isAllItemRemoved = sourceArray?.every((item) => {
      return JSON.parse(JSON.stringify(item.value)).hasOwnProperty("removedItem");
    });
    setShowControllersSub(!isAllItemRemoved ? true : false);
    return !isAllItemRemoved ? true : false;
  };

  const handleCancel = () => {
    setOpenModal(false);
  };

  const handleOk = () => {
    sourceArray?.set([JSON.parse(JSON.stringify(defaultDataObject))]);
    if (actionReset) {
      actionReset();
    }
    setOpenModal(false);
  };

  return (
    <>
      <Grid container rowSpacing={1.5}>
        <Grid item xs={12} md={12}>
          <Grid container gap={1}>
            {headerTitle.map((title) => (
              <Grid item xs={2}>
                <Typography>{title}</Typography>
              </Grid>
            ))}
            <Grid item xs={2}>
              <Stack direction={"row"}>
                <ButtonComponent iconbtn children={<AddCircleOutlineIcon />} onClick={onNewFieldAdding} />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        {children}
        <Grid item xs={12} md={12}>
          <Grid container gap={1}>
            <Grid item xs={4} md={4} style={{ marginRight: "8px" }}>
              <TextBoxComponent style={{ backgroundColor: "#D3D3D3", fontWeight: "bold" }} label={"Total"} value={""} fullWidth={true} onChange={(e) => { }} disabled={true} />
            </Grid>
            <Grid item xs={2} md={2}>
              <TextBoxComponent style={{ backgroundColor: "#D3D3D3", fontWeight: "bold" }} label={""} value={formatCurrency(amount)} onInput={OnDemandValueFormatter.numeric} onChange={(e) => { }} disabled={true} />
            </Grid>
            <Grid item xs={2} md={2}>
              <TextBoxComponent style={{ backgroundColor: "#D3D3D3", fontWeight: "bold" }} label={""} value={numberOfDays} fullWidth={true} onInput={OnDemandValueFormatter.numeric} onChange={(e) => { }} disabled={true} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AlertDialogBox open={openModal} handleClose={handleCancel} handleOk={handleOk} mainTitle={""} subTitle={"If you select OK, you will be lost every thing that you entered."} description={"Select OK to continue."} />
    </>
  );
};

export default GridDataTable;
