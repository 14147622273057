import { Box, Button, Stack, TextField } from "@mui/material";
import React, { useEffect, useMemo, useRef } from "react";
import { useJsApiLoader, GoogleMap, Marker, Autocomplete } from "@react-google-maps/api";
import Skeleton from "@mui/material/Skeleton";
import PlaceIcon from "@mui/icons-material/Place";
import AppConfig from "../../../../../../../../../utility/AppConfig";
import { ButtonComponent } from "../../../../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { select_basic } from "../../../../../../../../../styles";

const GoogleMapFC = ({ metaData, value, onChange }) => {
  const authKey: string = AppConfig.config["googleMapAuthenticateKey"] ?? null;
  const center: any = { lat: 6.927079, lng: 79.861244 };
  const [currentLocation, setCurrentLocation] = React.useState(center);
  const [map, setMap]: any = React.useState(/**@type google.maps.Map */);
  const [autocomplete, setAutocomplete]: any = React.useState(null);
  const originRef: any = useRef();
  const serchedRef: any = useRef();
  const [address, setAddress] = React.useState("");

  useEffect(() => {
    if (value["googleMapLocationData"]) {
      console.log(value["googleMapLocationData"]["dirrections"])
      setCurrentLocation(value["googleMapLocationData"]["dirrections"] ?? center);
      setAddress(value["googleMapLocationData"]["address"] ?? "");
    }

    if (!value["googleMapLocationData"] && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentLocation({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    }

  }, [])


  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: authKey,
    libraries: ["places"],
  });

  const onLoad = (autocomplete) => setAutocomplete(autocomplete);

  const onPlaceChanged = () => {
    if (autocomplete !== null) {

      const place = autocomplete.getPlace();

      if (place.geometry && place.geometry.location) {
        setAddress(place.formatted_address);
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        setCurrentLocation({ lat: latitude, lng: longitude });
      } else {
        console.error("Location not found for the selected place.");
      }
    } else {
      console.error("Autocomplete is not loaded yet!");
    }
  };

  const submitCurrentLocation = () => {
    map.panTo(currentLocation);

    const googleMapLocationData = {
      dirrections: currentLocation,
      address,
    };

    value = {
      ...value,
      googleMapLocationData
    }

    onChange(metaData, value);

  };

  if (!isLoaded) {
    return <Skeleton animation="wave" />;
  }

  return (
    <Stack position={"relative"} flexDirection={"row"} width={"65vw"} height={"70vh"} justifyContent={"center"} overflow={"auto"}>
      <Box position={"absolute"} sx={{ display: "flex", backgroundColor: "Menu", padding: "5px", width: "100%", justifyContent: "center" }} zIndex={1}>
        <Stack direction={"row"} gap={2} alignItems={"center"}>
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged} ref={originRef}>
              <TextField value={address} onChange={(e) => setAddress(e.target.value)} id="autocomplete" label="URL to The Location" variant="outlined" fullWidth ref={serchedRef} style={select_basic} />
            </Autocomplete>
          </Stack>
          <Stack>
            <ButtonComponent startIcon={<PlaceIcon />} title="Search" variant="outlined" onClick={submitCurrentLocation} color="info" />
          </Stack>
        </Stack>
      </Box>
      <Stack height={"100%"} width={"100%"}>
        <GoogleMap center={currentLocation} zoom={15} mapContainerStyle={{ width: "100%", height: "100%" }} options={{ zoomControl: false, streetViewControl: false, fullscreenControl: false, mapTypeControl: false }} onLoad={(map: any) => setMap(map)}>
          <Marker position={currentLocation} />
        </GoogleMap>
      </Stack>

    </Stack>
  );
};

export default GoogleMapFC;
