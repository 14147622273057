import CloseIcon from "@mui/icons-material/Close";
import { Stack, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { bufferToBlobUrl } from "../../../../../configs/base64Conversion";
import { creditData } from "../../../../../configs/mainStore";
import { getProductDocumentFileDataByDocumentParticipant } from "../../../../../services/documentApis";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";

const DocumentToolTip = ({ applicantType = 1, index = 0, documentId, children }) => {
    const [open, setOpen] = React.useState(false);
    const applicationData: any = creditData.get() || {};
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [offset, setOffset] = useState({ x: 0, y: 0 });

    const handleDragStart = (e) => {
        const rect = e.target.getBoundingClientRect();
        setOffset({
            x: e.clientX - rect.left,
            y: e.clientY - rect.top,
        });
    };

    const handleDrag = (e) => {
        if (e.clientX === 0 && e.clientY === 0) return;

        setPosition({
            x: e.clientX - offset.x,
            y: e.clientY - offset.y,
        });
    };

    const handleDragEnd = (e) => {
        setPosition({
            x: e.clientX - offset.x,
            y: e.clientY - offset.y,
        });
    };

    const BuildContent = ({ handleTooltipClose }) => {
        const [loading, setLoading] = React.useState(true);
        const [document, setDocument]: any = React.useState({});
        const { applicationId } = applicationData;
        let { individualId = 0 } = applicationData?.formData?.personalData || {};
        if (applicantType === 2) { // joint borrower
            individualId = applicationData?.formData?.jointBorrowerData?.[index || 0]?.personalData?.individualId || 0;
        } else if (applicantType === 3) { // guarantor
            individualId = applicationData?.formData?.securityData?.guarantors?.[index || 0]?.personalData?.individualId || 0;
        }

        useEffect(() => {
            if (applicationId) {
                loadImageData();
            }
        }, [applicationId]);

        const loadImageData = async () => {
            let documentDetails = {
                applicationId: applicationId?.[0] || applicationId,
                documentId,
                participantId: individualId
            }
            setLoading(true);
            const result = await getProductDocumentFileDataByDocumentParticipant(documentDetails, (progress) => {
                console.log(progress);
            });

            if (result?.fileData && result?.contentType) {
                try {
                    const fileBuffer = result?.fileData.data;
                    const contentType = result?.contentType;
                    const blobUrl = bufferToBlobUrl(fileBuffer, contentType);
                    const data = { url: blobUrl, metaData: result?.fileMeta, contentType: contentType };
                    setDocument(data);
                } catch (error) {

                }
            }

            setLoading(false);
        }


        if (loading) {
            return <CircularProgressComponent size={24} />
        }

        return <Stack bgcolor={"white"} width={"300px"} height={"400px"} style={{ borderRadius: "5px" }}>
            <Stack direction={"row"} justifyContent={"space-between"} bgcolor={"gray"} zIndex={100} pl={1}>
                <Typography flex={1}>NIC</Typography>
                <CloseIcon
                    onClick={handleTooltipClose}
                />
            </Stack>
            <Stack flex={1}>
                <iframe onClick={handleTooltipClose} src={`${document.url}#toolbar=0`} width="100%" height="100%" loading="eager" allowFullScreen style={{
                    border: 0,
                }} />
            </Stack>
        </Stack>;
    }

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const handleTooltipClose = () => {
        setOpen(false);
    };

    return <Tooltip
        PopperProps={{
            disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={open}
        onClick={e => e.stopPropagation()}
        disableFocusListener
        disableHoverListener
        draggable={true}
        onDragStart={handleDragStart}
        onDrag={handleDrag}
        onDragEnd={handleDragEnd}
        style={{
            position: 'absolute',
            left: `${position.x}px`,
            top: `${position.y}px`,
            width: '100px',
            height: '100px',
            backgroundColor: 'skyblue',
            cursor: 'grab',
        }}
        disableTouchListener title={
            <Stack style={{ cursor: "pointer", boxShadow: "0 0 0 1px rgba(0,0,0,0.05), 0 8px 12px 0 rgba(0,0,0,0.15)", borderRadius: "5px" }} mx={-2} my={-2}>
                <BuildContent handleTooltipClose={handleTooltipClose} />
            </Stack>
        } arrow>
        <Stack style={{ cursor: "pointer" }} onClick={() => open ? handleTooltipClose() : handleTooltipOpen()}>
            {children}
        </Stack>
    </Tooltip>
}

export default DocumentToolTip;