import React, { useEffect } from "react";
import {
  Paper,
  Grid,
  Stack,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";

import { appSetting, workflowMasterData } from "../../../../../configs/mainStore";
import { dateFormatWithDate, dateFormatWithTimeChange } from "../../../../../utility/fomatters";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import { createButton } from "../../../../../styles";
import DetailItem from "../DetailItem";
import { openGlobalModal } from "../../../GlobalModal/GlobalModal";
import ForwardIcon from "@mui/icons-material/Forward";
import { referAction, referStatus } from "../../../../../utility/util";

import PersonIcon from "@mui/icons-material/Person";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import EmailIcon from "@mui/icons-material/Email";
import AddIcon from "@mui/icons-material/Add";
import HomeIcon from "@mui/icons-material/Home";
import { MASTER_DATA_KEYS } from "../../../../../configs/constants/masterDataKeys";
import { getUserDetailsById } from "../../../../../services/userService";
import PreviewIcon from "@mui/icons-material/Preview";
import { cribStatusColors } from "../../../WorkflowComponents/utils";
import { IconRowComponent } from "../IconRowComponent";
import {
  getReferList,
  getInsurancePolicyDocuments,
  getInsurancePolicyDocumentFile,
} from "../../../../../services/creditFileApiCall";
import { compare } from "../../../../../utility/other";
import { useState } from "@hookstate/core";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";
import { ErrorMessageComponent } from "../../../ErrorMessageComponent";
import { bufferToBlobUrl } from "../../../../../configs/base64Conversion";

const InsurancePolicyDocumentsList = ({ applicationId }) => {
  const appSettings: any = useState(appSetting);

  const [isLoading, setIsLoading] = React.useState(false);
  const [riskList, setRiskList] = React.useState([]);
  const [errorMessage, setErrorMessage] = React.useState("");

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      if (applicationId) {
        const data = await getInsurancePolicyDocuments(applicationId);

        if (data && data.length > 0) {
          const sortedList = data.sort((a, b) => compare(b, a, "referId"));
          setRiskList(sortedList);
        } else {
          setErrorMessage("No Insurance Policy Documents");
        }
      }
      setIsLoading(false);
    })();
  }, []);

  const previewBtnClicked = async (docId) => {
    const resultFile = await getInsurancePolicyDocumentFile(docId);

    if (resultFile?.fileData?.data) {
      const fileBuffer = resultFile?.fileData.data;
      const contentType = resultFile?.contentType;

      const blobUrl = bufferToBlobUrl(fileBuffer, contentType);

      window.open(
        `${blobUrl}`,
        "targetWindow",
        `toolbar=no,
           location=no,
           status=no,
           menubar=no,
           scrollbars=yes,
           resizable=yes,
           width=500,
           height=500`
      );
    }
  };

  return (
    <Grid container className="full-width" alignItems="center">
      {isLoading ? (
        <CircularProgressComponent
          size={30}
          sx={{
            marginTop: "30px",
            marginLeft: "auto",
            marginRight: "auto",
            zIndex: 100,
          }}
        />
      ) : errorMessage ? (
        <ErrorMessageComponent headMessage={"Empty"} errorMessage={errorMessage} />
      ) : (
        <Grid container p={1} className="data-box" style={{ backgroundColor: "#e8ecf1" }} m={2}>
          <Grid
            container
            pt={1}
            pr={1}
            pl={1}
            pb={2}
            className="full-width"
            columns={24}
            alignItems="center">
            <Grid container item sm={24} style={{ textAlign: "left" }}>
              <Grid item sm={24} mb={2}>
                <div className="basic-font basic-font-color opacity-7 font-size-12 mb-2">
                  Insurance Policy Documents
                </div>
              </Grid>
              <Grid item sm={24}>
                <div className="basic-font basic-font-color-bold font-size-14">
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow className="basic-font font-size-12">
                          <TableCell align="left">
                            <b>#</b>
                          </TableCell>
                          <TableCell align="left">
                            <b>Title</b>
                          </TableCell>
                          <TableCell align="left">
                            <b>Created</b>
                          </TableCell>
                          <TableCell align="left">
                            <b>Created By</b>
                          </TableCell>
                          <TableCell align="left"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {riskList?.map((col: any, k) => {
                          return (
                            <TableRow
                              key={k}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}>
                              <TableCell
                                component="th"
                                scope="row"
                                align="left"
                                className="basic-font opacity-7 font-size-12">
                                {k + 1}
                              </TableCell>
                              <TableCell align="left" className="basic-font opacity-7 font-size-12">
                                {col?.title ? col?.title : "-"}
                              </TableCell>
                              <TableCell align="left" className="basic-font opacity-7 font-size-12">
                                {col?.createdDate
                                  ? dateFormatWithTimeChange(col?.createdDate)
                                  : "--"}
                              </TableCell>
                              <TableCell className="basic-font opacity-7 font-size-12">
                                {col?.createdBy
                                  ? getUserDetailsById(col?.createdBy)[appSettings.get().language]
                                      ?.name
                                  : "--"}
                              </TableCell>
                              <TableCell align="right">
                                <PreviewIcon
                                  onClick={() => {
                                    previewBtnClicked(col?.id);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    color: "#1976d2",
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default InsurancePolicyDocumentsList;
