import { Downgraded, useState } from "@hookstate/core";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { downloadInspectionForm, getFilledPendingInpectionFormData } from "../../../../../../../services/apiCalls";
import CachingStorage from "../../../../../../../services/cacheStorage";
import { GlobalLogger } from "../../../../../../../utility/GlobalLogger";
import MasterDataProvider from "../../../../../../../utility/MasterDataProvider";
import { printFormTemplate } from "../../../../../../../utility/util";
import ErrorBoundary from "../../../../../../ErrorHandleComponent/ErrorBoundary";
import { toast } from "../../../../../GlobalToast";
import FormBuilder from "../../FormBuilder";
import {
  InspectionProps,
  SaveInspectionChanges
} from "../../FormBuilder/Tools/Helpers/OnSubmitInspections/SaveChangesInspection";
import { inspectionValidationMandtoryHandler } from "../../FormBuilder/Tools/Helpers/Validations/InspectionValidationHandler";
import { ApplicationFormKey, SectionKey } from "../../FormBuilder/Tools/Helpers/inspectionKeyEnums";
import {
  businessDetailsState,
  inspectionForm,
  inspetionIdState
} from "../../FormBuilder/Tools/Helpers/inspectionStores/dataStore";
import { defaultBusinessState } from "../../FormBuilder/Tools/Helpers/inspectionStores/inspectionMainStore";

const BusinessInspection = ({ data, applicationId, innerComponentData, tabsToEdit }) => {
  const { t: translate } = useTranslation();
  const businessDetails: any = useState(businessDetailsState);
  const { formData } = data;
  const currentBusinessType = formData?.businessData?.currentBusiness[0];
  const inspetionId = useState<any>(inspetionIdState);
  const masterDataStore = MasterDataProvider.provideMasterData();
  const isLoading = useState<boolean>(true);
  const inspectionFormState: any = useState<InspectionProps>(inspectionForm);

  const currentYear = new Date().getFullYear();
  const yearsArray = [currentYear - 1, currentYear, currentYear + 1].map(String);

  const getBusinessAddressData = () => {
    const { businessData } = formData;
    let businessAddress: any = "";
    const currentBusiness = businessData?.currentBusiness[0]; // For now we got only one business

    const { addressLine1, addressLine2, addressLine3 } = currentBusiness?.address;

    businessAddress = [addressLine1, addressLine2, addressLine3].map((element) => element).join(", ");

    return {
      businessAddress,
    };
  };

  let toBeSubmittedFormDetails: any = {
    businessName: currentBusinessType?.nameOfTheBusiness,
    businessType: masterDataStore.getMasterDataDescription("TYPE_OF_BUSINESS", currentBusinessType?.typeOfBusiness),
    businessAddress: getBusinessAddressData().businessAddress,
    businessRegNo: currentBusinessType.businessRegNo,
    sector: masterDataStore.getMasterDataDescription("BUSINESS_SECTOR", currentBusinessType?.businessSector),
    subSector: masterDataStore.getMasterDataDescription("BUSINESS_SUB_SECTOR", currentBusinessType?.businessSubSector),
    shareHoldersDetails: currentBusinessType?.shareholderDetails,
    partnershipDetails: currentBusinessType?.partnershipDetails ?? [],
    directorDetails: currentBusinessType?.directorDetails ?? [],
    individualDetails: currentBusinessType?.individualDetails ?? [],
    typeOfBusiness: currentBusinessType?.typeOfBusiness,
    applicationId,
  };
  const [formDetails, setFormDetails]: any = React.useState(toBeSubmittedFormDetails);

  const otherParams: any = useMemo(() => {
    return {
      formData,
      applicationId,
      innerComponentData,
      tabsToEdit,
      isAmountBelowTwoPointFive: data?.isAmountBelowTwoPointFive,
      isAmountAboeTwoPointFiveAndEqual: data?.isAmountAboeTwoPointFiveAndEqual,
      isLoading: isLoading.get()
    };
  }, [applicationId, formData, isLoading]);

  useEffect(() => {
    inspectionFormData();

    return () => {
      businessDetails.set(defaultBusinessState);
      setFormDetails({});
    };
  }, []);

  const inspectionFormData = async () => {
    try {
      const { response }: { data: Object; status: number } | any = await getFilledPendingInpectionFormData(
        applicationId
      );

      let { ID: inspectionId = null, INSPECTION_DATA: inspectionData = {} } = response;

      try {
        inspectionData = JSON.parse(inspectionData);
      } catch (error) { }

      let { business: data = {} } = inspectionData;

      inspectionFormState.business.set(data);

      if (Object.keys(data).length > 0) {
        const setBusinessState = {
          salesandPurchaseInformationofFastMovingItemsOtherdata:
            data.salesandPurchaseInformationofFastMovingItemsOtherdata,
          totaleBusinessAssertValue: data.totaleBusinessAssertValue,
          totaleBusinessAssertOtherDetails: data.totaleBusinessAssertOtherDetails,
          totaleBusinessLiabilityExpenses: data.totaleBusinessLiabilityExpenses,
          businessLiabilityExpenses: data.businessLiabilityExpenses,
          totalBusinessIncome: data.totalBusinessIncome,
          businessIncome: data.businessIncome,
          deductionsCostsOfGoods: data.deductionsCostsOfGoods,
          totalBusinessExpense: data.totalBusinessExpense,
          businessExpenses: data.businessExpenses,
          stock: data.stock,
          debtors: data.debtors,
          creditors: data.creditors,
          employementDetails: data.employementDetails,
          markettingArrangement: data.markettingArrangement,
        };

        setFormDetails((prev) => ({ ...data, ...prev, inspectionId }));
        inspetionId.set(inspectionId);
        businessDetails.set(setBusinessState);
      }
    } catch (error) {
      toast({ status: "error", message: "Something went wrong while fetching data...!" });
    } finally {
      isLoading.set(false)
    }
  };

  const onChange = useCallback(
    (metaData: any, value: any) => {
      setFormDetails(value);
    },
    [formDetails]
  );

  const onSubmit = useCallback(async () => {
    const businessDetailsDowngraded = businessDetails.attach(Downgraded).get();

    const theDataTobeSubmitted = {
      applicationId,
      ...formDetails,
      ...businessDetailsDowngraded,
      inspetionId: inspetionId.get(),
    };

    let errorArray: Array<any> = inspectionValidationMandtoryHandler(
      ApplicationFormKey.businessInspection,
      theDataTobeSubmitted,
      SectionKey.businessInspection
    );

    if (errorArray && errorArray.length > 0) {
      const preparedErrorMessagges = errorArray.join("\n");
      return toast({ status: "error", message: `${preparedErrorMessagges}` });
    }

    const respose: any = await SaveInspectionChanges(
      theDataTobeSubmitted,
      SectionKey.businessInspection,
      inspectionFormState,
      applicationId
    );

    if (respose.status == 1) {

      CachingStorage.clear(`${applicationId}-appraisal-inpections-data`);
      return toast({ status: "success", message: "Successfully Submitted Inpection Form" });
    }
    else return toast({ status: "error", message: "Something went wrong while Submitting Inspection Form..!" });
  }, [formDetails]);

  const downloadInspectionFormTemplate = useCallback(async () => {
    try {
      printFormTemplate(downloadInspectionForm, SectionKey.businessInspection);
    } catch (error: any) {
      GlobalLogger.log("business inspection", error.message);
    }
  }, []);

  // if (isLoading.get()) {
  //   return (
  //     <Box sx={{ display: "flex", width: 100, justifyContent: "center", alignItems: "center" }}>
  //       <CircularProgress />
  //     </Box>
  //   );
  // }

  return (
    <ErrorBoundary>
      <FormBuilder
        applicationDataObject={formDetails}
        onChange={onChange}
        formId={ApplicationFormKey.businessInspection}
        title={translate("businessInspection")}
        onSubmit={onSubmit}
        onDownload={downloadInspectionFormTemplate}
        otherParams={otherParams}
      />
    </ErrorBoundary>
  );
};

export default BusinessInspection;
