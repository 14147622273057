import { Stack, TableCell, Typography } from "@mui/material";
import React, { memo } from "react";

interface TableColumnsWrapperProps {
  children?: JSX.Element;
  index: string | number;
  data?: any;
  style?: any;
  dataKey?: any;
  onchangeHandler?: any;
  dataIndex?: any;
}

const TableColumnsTextWrapperAnyNew: React.FC<TableColumnsWrapperProps> = ({
  children,
  index,
  data = {},
  style = {},
  dataKey = "",
  dataIndex,
  onchangeHandler = () => {},
}: TableColumnsWrapperProps) => {
  if (!index) {
    return <React.Fragment></React.Fragment>;
  }

  return (
    <TableCell key={index.toString() as string}>
      <Stack direction={"column"} flex={1} width={"100%"} p={1}>
        <Stack sx={style ?? {}}>
          <Typography variant="subtitle1" color={"gray"}>
            {children}
          </Typography>
        </Stack>
      </Stack>
    </TableCell>
  );
};

export default memo(TableColumnsTextWrapperAnyNew);
