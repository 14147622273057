import { Downgraded } from "@hookstate/core";
import { LengthItemValidate } from "../../../components/OtherComponents/ApplicationForm/Sections/SecurityDetails/SecurityData/Helpers/LengthItemValidatedFn";
import { creditDetails } from "../../../configs/mainStore";
import {
  fdAgainstLoansDefault,
  immovableMachineryEquipementDefault,
  lifeInsuranceDefault,
  loanProtectInsurance,
  machineryEquipmentDefault,
  pledgeDefault,
  propertyDefault,
  sharesDefault,
  stockAsGoldDefault,
  treasuryBondDefault,
  vehicleDefault,
  warehouseReceiptDefault,
} from "../../../configs/stores/securityDataSubSection/securityDataSubSections.module";
import AppConfig from "../../AppConfig";
import { handleArray } from "./structureValidation";

export const securityDetails = (
  structure,
  state,
) => {
  const securityApplicationObject = {
    loanProtectInsurance: loanProtectInsurance,
    fdAgainstLoans: fdAgainstLoansDefault,
    warehouseReceipt: warehouseReceiptDefault,
    shares: sharesDefault,
    treasuryBond: treasuryBondDefault,
    lifeInsurance: lifeInsuranceDefault,
    machineryEquipment: machineryEquipmentDefault,
    pledge: pledgeDefault,
    vehicle: vehicleDefault,
    stockAsGold: stockAsGoldDefault,
    property: propertyDefault,
    immovableMachineryEquipement: immovableMachineryEquipementDefault,
    sectionErrorStatus: "pending",
  };
  let { type, sector, scheme } = creditDetails.attach(Downgraded).get();
  let pureGuarantorData = state?.guarantors.attach(Downgraded).get();
  let underWrittingPolicyConfigs = AppConfig.config.underWrittingPolicyConfigs ?? {};
  let preparedErrorMessages: any = [];
  const currentProductCatalog = [type, sector, scheme].join('-');

  let collateralValidationConfig = underWrittingPolicyConfigs?.[currentProductCatalog]?.["collateral"] ?? {};

  // let errorArrayKey = collateralDetailsValidator(structure, state, securityApplicationObject);

  pureGuarantorData = Array.isArray(pureGuarantorData) ? LengthItemValidate.validatedArray(pureGuarantorData) : []

  let validatedGuarantor: boolean = false;


  if (collateralValidationConfig && Object.keys(collateralValidationConfig).length > 0) {
    let isNeedGuarantors: boolean = collateralValidationConfig?.guarantors?.size > 0 ?? false;
    isNeedGuarantors = pureGuarantorData.length ? true : isNeedGuarantors;
    const isNeedValidateObject = collaterlValidationConfig(state, collateralValidationConfig);
    const isNeedValidateCollateral: boolean = isNeedValidateObject?.movable || isNeedValidateObject?.immovable || isNeedValidateObject?.paperDocs;
    const errorArray = validateSubCollateralFields(state);

    if (isNeedValidateCollateral) {
      if (errorArray.length === 12) {
        preparedErrorMessages.push("Collateral Details Must be Included.!");
      }
    }

    if (isNeedGuarantors) {
      if (pureGuarantorData.length === 0) {
        preparedErrorMessages.push("Guarantors Details Must be Included.!");
      } else {
        validatedGuarantor = true;
      }
    }

    return { preparedErrorMessages, validatedGuarantor };
  } else {
    const errorArray = validateSubCollateralFields(state);

    if (pureGuarantorData.length === 0 && errorArray.length === 12) {
      preparedErrorMessages.push("Guarantors Details or Collateral Details Must be Included.!");
    }

    if (pureGuarantorData.length) {
      validatedGuarantor = true;
    }

    return { preparedErrorMessages, validatedGuarantor };
  }
};

/*
          ### HELPING COMPONENTS ###
 */
export const deleteElementsFromArray = (targetArry, deleteArry) => {
  targetArry = targetArry.filter((inElement) => {
    let element = deleteArry && deleteArry.length > 0 && deleteArry.forEach((element) => element === inElement);
    return inElement !== element;
  });
  return targetArry;
};

export const addElementsToArray = (targetArry, stateObject, addingArry) => {
  if (addingArry && addingArry.length > 0) {
    addingArry.forEach((element) => {
      if (
        stateObject[element] === "" ||
        stateObject[element] === null ||
        stateObject[element] === undefined ||
        stateObject[element] === "null"
      ) {
        targetArry.push(element);
      }
    });
    return targetArry;
  } else return targetArry;
};

const isArrayHasProperties = (arr) => {
  return arr.length > 0;
};

/*                           ####### COLLATERAL DETAILS VALIDATIONS #######                           */
/* THIS ONE WORKING ACCORDING TO THE STRUCTURE AND BUTTON TRIGGERS */
export const collateralDetailsValidator = (structure, state, securityApplicationObject) => {
  const stateMainObjKeys = getStateMainObjKeys(state);
  let finalKeyArr = [];

  for (const mainKey of stateMainObjKeys) {
    const stateSubObjKeys = getStateSubObjKeys(state, mainKey);
    if (isArrayHasProperties(stateSubObjKeys)) {
      for (const subKey of stateSubObjKeys) {
        let errorArray = handleArray(structure, state[mainKey][subKey], securityApplicationObject[subKey], true);
        errorArray = validateSubKey(subKey, state, mainKey, errorArray);
        if (errorArray?.length > 0) {
          finalKeyArr = finalKeyArr.concat(errorArray);
        }
      }
    }
  }

  return finalKeyArr;
};

const getStateMainObjKeys = (state) => {
  return Object.keys(state).filter((objKey) => objKey === "movable" || objKey === "immovable" || objKey === "paperDocs");
};

const getStateSubObjKeys = (state, mainKey) => {
  return Object.keys(state[mainKey]).filter((objs) => isArrayHasProperties(state[mainKey][objs]));
};

const validateSubKey = (subKey, state, mainKey, errorArray) => {
  LengthItemValidate.validatedArray(state[mainKey][subKey]).forEach((obj) => {

    switch (subKey) {
      case "lifeInsurance":
        // Custom validation for lifeInsurance if needed
        break;
      case "fdAgainstLoans":
        errorArray = validateFDAgainstLoans(obj, errorArray);
        break;
      case "pledge":
        errorArray = validatePledge(obj, errorArray);
        break;
      case "immovableMachineryEquipement":
      case "machineryEquipment":
      case "vehicle":
        errorArray = validateEquipment(obj, subKey, errorArray);
        break;
      case "property":
        errorArray = validateProperty(obj, errorArray);
        break;
      default:
        break;
    }
  });

  return errorArray;
};

const validateFDAgainstLoans = (obj, errorArray) => {
  errorArray = validateActionField(obj, "actionAutoInstallmentRecoveryRequired", ["fdRecoveryAccountNumber", "fdAccountName"], errorArray);
  errorArray = validateActionField(obj, "actionIsCash", ["fdAmountByCash"], errorArray);
  errorArray = validateActionField(obj, "actionIsBankTransfer", ["fdamountByTransfer", "fdAccountNumberSavings"], errorArray);
  return errorArray;
};

const validatePledge = (obj, errorArray) => {
  errorArray = validateActionField(obj, "actionOwnershipOfWarehouses", [
    "pledgeLeaseAgreementNumber",
    "pledgeDateOfLeaseAgreement",
    "pledgeCustomerType",
    "pledgeRegistrationNumber"
  ], errorArray, true);
  errorArray = validateActionField(obj, "actionInsuranceAvailable", [
    "insuranceCompanyName",
    "insurancePolicyNumber",
    "insuredAmount",
    "policyExpiryDate"
  ], errorArray);
  errorArray = validateActionField(obj, "actionWarehouseDetailsRequired", [
    "pledgeWarehouseAddress",
    "pledgeCity",
    "pledgeDistrict",
    "pledgeConditionOfTheWarehouse",
    "pledgeApproximateDistanceFromTheBranch"
  ], errorArray);
  errorArray = validateActionField(obj, "actionStocksUnderBankLock", ["pledgeKeyNumber"], errorArray);
  return errorArray;
};

const validateEquipment = (obj, subKey, errorArray) => {
  errorArray = validateActionField(obj, "actionInsuranceAvailable", [
    "insuranceCompanyName",
    "insurancePolicyNumber",
    "insuredAmount",
    "policyExpiryDate"
  ], errorArray);
  errorArray = validateActionField(obj, "actionWarrantyAvailable", [
    "natureWarranty",
    "dealersName",
    "warrantyStartDate",
    "warrantyEndDate"
  ], errorArray);
  errorArray = validateActionField(obj, "actionDuplicateKeyReceived", ["duplicateKeyNumber"], errorArray);
  return errorArray;
};

const validateProperty = (obj, errorArray) => {
  return validateActionField(obj, "actionInsuranceAvailable", [
    "insuranceCompanyName",
    "insurancePolicyNumber",
    "insuredAmount",
    "policyExpiryDate"
  ], errorArray);
};

const validateActionField = (obj, actionField, fieldsArray, errorArray, negate = false) => {
  if (obj[actionField].get() === true || obj[actionField].get() === false) {
    if (obj[actionField].get() === !negate) {
      errorArray = addElementsToArray(errorArray, obj, fieldsArray);
    } else {
      errorArray = deleteElementsFromArray(errorArray, fieldsArray);
    }
  }
  return errorArray;
};

type CollateralType = 'movable' | 'immovable' | 'paperDocs';

const collaterlValidationConfig = (state, collateralValidationConfig): Record<CollateralType, boolean> => {
  const stateMainObjKeys = getStateMainObjKeys(state);
  let isNeedValidate: Record<CollateralType, boolean> | any = {};
  stateMainObjKeys.forEach((key: string) => {
    isNeedValidate[key] = !!collateralValidationConfig?.[key];
  });
  return isNeedValidate;
};

const validateSubCollateralFields = (state) => {
  const stateMainObjKeys = getStateMainObjKeys(state);
  let errorMessage: string[] = [];
  stateMainObjKeys.forEach((mainKey) => {
    Object.keys(state[mainKey]).forEach((subKey: string) => {
      if (!state[mainKey][subKey].length || state[mainKey][subKey].filter((obj) => !("removedItem" in obj)).length === 0) {
        errorMessage.push(subKey);
      }
    });
  });
  return errorMessage;
};


const findElementsInArray = (mainArray: Array<any>, itemId: string) => {
  return mainArray.includes(itemId);
};
