import { useState } from "@hookstate/core";
import { Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TabPanelProps } from "../../../../../../../configs/interfaces";
import { downloadInspectionForm, getFilledPendingInpectionFormData } from "../../../../../../../services/apiCalls";
import { GlobalLogger } from "../../../../../../../utility/GlobalLogger";
import { printFormTemplate } from "../../../../../../../utility/util";
import ErrorBoundary from "../../../../../../ErrorHandleComponent/ErrorBoundary";
import { addToaster, toast } from "../../../../../GlobalToast";
import FormBuilder from "../../FormBuilder";
import { InspectionProps, SaveInspectionChanges } from "../../FormBuilder/Tools/Helpers/OnSubmitInspections/SaveChangesInspection";
import { inspectionMessageMappingsFromTitle } from "../../FormBuilder/Tools/Helpers/Validations/Helpers/MessageMapping";
import { inspectionValidationMandtoryHandler } from "../../FormBuilder/Tools/Helpers/Validations/InspectionValidationHandler";
import { ApplicationFormKey, SectionKey } from "../../FormBuilder/Tools/Helpers/inspectionKeyEnums";
import { inspectionForm, inspetionIdState } from "../../FormBuilder/Tools/Helpers/inspectionStores/dataStore";
import { scoreCardQuestionDefault } from "../../FormBuilder/Tools/Helpers/inspectionStores/inspectionMainStore";

const ScoreCardQuestions = ({ data, applicationId, innerComponentData, tabsToEdit }) => {
  const { t: translate } = useTranslation();
  const [currentKey, setCurrentKey] = React.useState(0);
  const inspectionFormState: any = useState<InspectionProps>(inspectionForm);
  const inspetionId = useState<any>(inspetionIdState);
  const [formDetails, setFormDetails]: any = React.useState(scoreCardQuestionDefault);
  const currentScoreCard: string = currentKey == 0 ? ApplicationFormKey.cultivationModel : ApplicationFormKey.otherThancultivationModel;
  const currentSectionKey: string = "otherThancultivationModel";
  const { formData } = data;
  const isLoading = useState<boolean>(true);

  const otherParams: any = {
    formData,
    applicationId,
    innerComponentData,
    tabsToEdit,
  };

  useEffect(() => {
    const preLoader = async () => {
      getExistingInspectionForm();
    };
    preLoader();
  }, []);


  const getExistingInspectionForm = async () => {
    try {
      const { response }: { data: Object, status: number } | any = await getFilledPendingInpectionFormData(applicationId);

      let { ID: inspectionId = null, INSPECTION_DATA: inspectionData = {} } = response;

      try {
        inspectionData = JSON.parse(inspectionData);
      } catch (error) { }

      let { scoreCard: data = {} } = inspectionData;

      inspectionFormState.scoreCard.set(data);
      inspetionId.set(inspectionId);

      if (Object.values(data).length > 0) {
        setFormDetails(data);
      }


    } catch (error) {
      new Error(`${error}`);
      toast({
        status:
          "error", message: "Something went wrong while fetching data...!"
      })
    } finally {
      isLoading.set(false);
    }
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentKey(newValue);
  };

  const downloadInspectionFormTemplate = async () => {
    try {
      printFormTemplate(downloadInspectionForm, SectionKey.scoreCardQuestion);
    } catch (error: any) {
      GlobalLogger.log("inspection form", error.message);
    }
  };

  const onChange = (metData, value) => {
    setFormDetails({
      ...formDetails,
      [currentSectionKey]: value,
    });
  };

  const onSubmit = async () => {
    const theDataTobeSubmitted = {
      ...formDetails,
    };

    let errorArray: Array<any> = inspectionValidationMandtoryHandler(currentScoreCard, formDetails[currentSectionKey], currentSectionKey);

    if (errorArray && errorArray.length > 0) {
      const preparedErrorMessagges = inspectionMessageMappingsFromTitle(errorArray).join("\n");
      return addToaster({
        status: "error",
        title: "Mandatory Validation Error",
        message: `${preparedErrorMessagges}`,
      });
    }

    const respose: any = await SaveInspectionChanges(theDataTobeSubmitted, SectionKey.scoreCardQuestion, inspectionFormState, applicationId);

    if (respose.status == 1) {
      return addToaster({
        status: "success",
        title: "SuccessFull",
        message: "Successfully Submitted Inpection Form",
      });
    } else {
      return addToaster({
        status: "error",
        title: "Somthing went wrong..!",
        message: "Something went wrong while Submitting Inspection Form..!",
      });
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Tabs value={currentKey} onChange={handleChange} aria-label="basic tabs example" variant="scrollable" scrollButtons="auto">
          <Tab label="Micro Loan - Other than Cultivation Model" {...a11yProps(0)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={currentKey} index={0}>
        <ErrorBoundary>
          <FormBuilder applicationDataObject={formDetails.otherThancultivationModel} onChange={onChange} formId={ApplicationFormKey.otherThancultivationModel} title={translate("scoreCardQuestion.otherThanCultivationModal")} onSubmit={onSubmit} onDownload={downloadInspectionFormTemplate} otherParams={otherParams} />
        </ErrorBoundary>
      </CustomTabPanel>
    </Box>
  );
};

export default ScoreCardQuestions;

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
