import { Downgraded, useState } from "@hookstate/core";
import { Grid, Paper, Stack } from "@mui/material";
import { Box } from "@mui/system";
import React, { FC, useEffect } from "react";
import { bufferToBlobUrl } from "../../../configs/base64Conversion";
import { documentUploadData } from "../../../configs/stores/document-upload-store/documentUploadStore";
import { getProductDocumentFileDataByDocumentParticipant, getProductDocumentFileHistoryDataByDocumentParticipant, updateApplicationDocumentStatus } from "../../../services/documentApis";
import VisibilityIcon from "@mui/icons-material/Visibility";

import CreditCardIcon from "@mui/icons-material/CreditCard";
import PersonIcon from "@mui/icons-material/Person";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import { DocumentPreviewHistoryRow, DocumentPreviewRow } from "./DocumentPreviewRow";
import { MainSectionsWrapper } from "../MainSectionsWrapper/MainSectionsWrapper";
import { CircularProgressComponent } from "../ProgressComponent/ProgressComponent";
import { FILE_STATUS } from "../../../configs/constants/documentUploadKeys";
import { getUserDetailsById } from "../../../services/userService";
import { dateFormatWithDate, dateFormatWithTimeChange } from "../../../utility/fomatters";
import { ErrorMessageComponent } from "../ErrorMessageComponent";

import { ButtonComponent } from "../../InputComponents/ButtonComponent/ButtonComponent";
import { applicationDocumentStatus } from "../../../configs/constants/contants";
import { addToaster } from "../GlobalToast";
import { appSetting, multipleDisbursementState, userDetails } from "../../../configs/mainStore";
import { disableAccess, multipleDisbursementDocApprovedPermitted, unclassifiedDisableAccess } from "../../../utility/helpers/ApplicationMandatoryValidations/isSecurityDocUploadApprovedPermitted";
import { documentKeys } from "../../../configs/revokeDocumentKeys";
import { privilegeKeys } from "../../../configs/constants/privilegeKeys";

export const DocumentPreviewComponent: FC = (params?: any) => {
  const appSettings: any = useState(appSetting);
  const { previewedDocument }: any = documentUploadData.attach(Downgraded).get();

  const { userId }: any = userDetails.attach(Downgraded).get();

  const [previewLoading, setPreviewLoading] = React.useState(true);
  const [documentBlobUrl, setDocumentBlobUrl] = React.useState<any>(null);
  const [documentMeta, setDocumentMeta] = React.useState<any>(null);
  const [documentContentType, setDocumentContentType] = React.useState<any>(null);

  const [previewHistoryLoading, setPreviewHistoryLoading] = React.useState(true);
  const [documentHistoryList, setHistoryList] = React.useState<any>([]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [updateLoading, setUpdateLoading] = React.useState(false);
  const [updateSuccess, setUpdateSuccess] = React.useState(false);
  const postDisbursementTabKeys: any = ["post_disbursements_documents", "post_condition_documents", "post_disbursement_checklist"];
  const fileDetails = params?.fileDetails;
  const isRevokePrivilages = documentKeys.includes(params.documentKey);
  const multipleDisbursement: any = useState(multipleDisbursementState);
  const [progress, setProgress] = React.useState<number>(0);

  let isHavePermission = () => {
    const documentList = multipleDisbursement?.securityDocuments.attach(Downgraded).get();

    if (documentList != undefined && documentList != null) {
      if (multipleDisbursement.isHaveMultipleDisbursement && documentList?.includes(fileDetails?.documentKey)) {
        return multipleDisbursementDocApprovedPermitted(fileDetails?.disbStatus, privilegeKeys.SEC_DOC_APPROVE_REJECT_DOC, fileDetails?.documentKey);
      }
    }

    if (isRevokePrivilages) {
      return unclassifiedDisableAccess();
    }

    return disableAccess({
      privilegeKeyforSec: "SEC_DOC_APPROVE_REJECT_DOC",
      privilegeKeyforOther: "DOC_MANAGE_SYSTEM_APPROVE_REJECT_DOCUMENT",
      documentComponentId: postDisbursementTabKeys.includes(params.documentComponentId) ? params.documentComponentId : previewedDocument?.documentId,
    });
  };

  useEffect(() => {
    (async () => {
      setPreviewLoading(true);

      // const result = await getProductDocumentFileDataByDocumentParticipant({
      //   applicationId: previewedDocument?.applicationId,
      //   documentId: previewedDocument?.documentId,
      //   participantId:
      //     previewedDocument?.individualId === undefined
      //       ? previewedDocument?.participantId
      //       : previewedDocument?.individualId,
      //   variant: params?.variant ? params?.variant : null,
      //   cribDocId: params?.cribDocId,
      //   fileName: fileDetails.fileName
      // });

      let documentDetails = {
        applicationId: previewedDocument?.applicationId,
        documentId: previewedDocument?.documentId,
        participantId: previewedDocument?.individualId === undefined ? previewedDocument?.participantId : previewedDocument?.individualId,
        variant: params?.variant ? params?.variant : null,
        cribDocId: params?.cribDocId,
        priorApprovalDocId: params?.priorApprovalDocId,
        fileName: fileDetails?.fileName,
      };

      const result = await getProductDocumentFileDataByDocumentParticipant(documentDetails, (progress) => {
        setProgress(progress);
      });

      if (result?.fileData && result?.contentType) {
        const fileBuffer = result?.fileData.data;
        const contentType = result?.contentType;

        const blobUrl = bufferToBlobUrl(fileBuffer, contentType);

        setDocumentBlobUrl(blobUrl);
        setDocumentMeta(result?.fileMeta);
        setDocumentContentType(contentType.split("/")[0]);
      } else {
        setErrorMessage("File not Found!");
      }

      setPreviewLoading(false);
    })();
    (() => {
      setPreviewHistoryLoading(true);
      getProductDocumentFileHistoryDataByDocumentParticipant({
        applicationId: previewedDocument?.applicationId,
        documentId: previewedDocument?.documentId,
        participantId: previewedDocument?.individualId === undefined ? previewedDocument?.participantId : previewedDocument?.individualId,
        isPrimary: previewedDocument?.isPrimary,
      }).then((result) => {
        if (result) {
          setHistoryList(result);
        } else {
          setErrorMessage("Something went Wrong!");
        }
        setPreviewHistoryLoading(false);
      });
    })();
  }, [updateSuccess]);

  const previewClicked = (blobUrl) => {
    window.open(
      `${blobUrl}`,
      "targetWindow",
      `toolbar=no,
     location=no,
     status=no,
     menubar=no,
     scrollbars=yes,
     resizable=yes,
     width=500,
     height=500`
    );
  };

  const statusUpdateClicked = async (status) => {
    setUpdateLoading(true);
    const result = await updateApplicationDocumentStatus({
      applicationId: previewedDocument?.applicationId,
      documentId: previewedDocument?.documentId,
      participantId: previewedDocument?.individualId === undefined ? previewedDocument?.participantId : previewedDocument?.individualId,
      isPrimary: previewedDocument?.isPrimary,
      applicantType: previewedDocument?.applicantType,
      status: status,
      verifiedBy: userId,
      fileName: fileDetails?.fileName,
    });

    if (result) {
      if (result?.updatedTime) {
        setUpdateSuccess(!updateSuccess);
        params.callBackOnAction();
      } else {
        addToaster({
          status: "warning",
          title: "Not Updated",
          message: result,
        });
      }
    } else {
      addToaster({
        status: "error",
        title: "Update Error",
        message: "Something went Wrong",
      });
    }
    setUpdateLoading(false);
  };

  const BuildProgress = (progress: number) => {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={"500px"}>
        <CircularProgressComponent variant={progress < 10 ? undefined : "determinate"} size={28} value={progress} />
      </Box>
    );
  };

  return (
    <Paper elevation={0} sx={{ minHeight: "70vh" }}>
      {errorMessage ? (
        <ErrorMessageComponent headMessage={"Error!"} errorMessage={errorMessage} />
      ) : (
        <Grid container direction="column" sx={{ minHeight: "60vh" }}>
          <Grid container item spacing={2} direction="row" xs={12}>
            <Grid item xs={12} lg={5} style={{ borderRight: "1px solid #454c661a" }}>
              {/* <Box
                  dangerouslySetInnerHTML={{
                    __html: `<iframe src=${documentBlobUrl} style="width: 100%;height: 500px;border: none;" />`,
                  }}
                /> */}
              <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ backgroundColor: "#ffffff", borderRadius: "5px", textAlign: "center" }}>
                <Grid item xs={6} justifyContent="center" style={{ padding: "30px 0" }}>
                  {/* <ButtonComponent
                    title={'Preview Document'}
                    onClick={() => {
                      previewClicked(documentBlobUrl);
                    }}
                    style={previewBtnStyles}
                    iconbtn
                    size="small"
                    startIcon={<VisibilityIcon />}
                  /> */}
                  {previewLoading ? (
                    BuildProgress(progress)
                  ) : (
                    <ButtonComponent
                      // startIcon={<VisibilityIcon />}
                      startIcon={<VisibilityIcon />}
                      // endIcon={<SendIcon />}
                      title={"Preview Document"}
                      variant="contained"
                      onClick={() => {
                        previewClicked(documentBlobUrl);
                      }}
                      // disabled={updateLoading}
                      // loadingbtn={true}
                      // loading={updateLoading}
                    />
                  )}
                </Grid>
              </Grid>
              {documentContentType === "image" && <img src={documentBlobUrl} style={{ width: "100%" }}></img>}
            </Grid>
            <Grid item xs={12} lg={7}>
              <Grid container item spacing={1} direction="row" xs={12}>
                <Grid item xs={12} lg={12}>
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <h4 className="basic-font" style={{ marginBottom: 0 }}>
                      Current File Details
                    </h4>
                    {previewLoading && (
                      <Stack pt={2}>
                        <CircularProgressComponent size={20} />
                      </Stack>
                    )}
                  </Stack>
                  <hr style={{ borderTop: "1px solid #454c661a", marginTop: "0px", width: "100%" }} />
                  <Box sx={{ boxShadow: 1 }}>
                    <Grid container className="align-center row_list_item align-center" mt={2} columns={24}>
                      <Grid container item xs={12} sm={11} direction="column" justifyContent="center">
                        <DocumentPreviewRow Icon={CreditCardIcon} subTxt={"Status"} mainTxt={FILE_STATUS[documentMeta?.status]} status={documentMeta?.status} />
                      </Grid>
                      {documentMeta?.status === applicationDocumentStatus.pending ? (
                        <>
                          {previewLoading || updateLoading ? (
                            <Stack pt={2} direction={"row-reverse"} flex={1}>
                              <CircularProgressComponent size={20} />
                            </Stack>
                          ) : (
                            <>
                              <Grid container item xs={12} sm={6} direction="column" justifyContent="center">
                                <div
                                  className={`basic-font font-size-14 approve-chip ${isHavePermission() ? "privilege-disable" : ""}`}
                                  onClick={() => {
                                    statusUpdateClicked(applicationDocumentStatus.approved);
                                  }}
                                >
                                  Approve
                                </div>
                              </Grid>
                              <Grid container item xs={12} sm={6} direction="column" justifyContent="center">
                                <div
                                  className={`basic-font font-size-14 reject-chip ${isHavePermission() ? "privilege-disable" : ""}`}
                                  onClick={() => {
                                    statusUpdateClicked(applicationDocumentStatus.rejected);
                                  }}
                                >
                                  Reject
                                </div>
                              </Grid>
                            </>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </Grid>

                    <Grid container className="align-center row_list_item align-center" mt={2} columns={24}>
                      <Grid container item xs={12} sm={11} direction="column" justifyContent="center">
                        <DocumentPreviewRow Icon={PersonIcon} subTxt={"Uploaded By"} mainTxt={documentMeta?.uploadedBy ? getUserDetailsById(documentMeta?.uploadedBy)[appSettings.get().language]?.name : "--"} status={documentMeta?.status} />
                      </Grid>
                      <Grid container item xs={12} sm={13} direction="column" justifyContent="center">
                        <DocumentPreviewRow Icon={QueryBuilderIcon} subTxt={"Uploaded Date"} mainTxt={documentMeta?.uploadedDate ? dateFormatWithTimeChange(documentMeta?.uploadedDate) : "--"} status={documentMeta?.status} />
                      </Grid>
                      <Grid container item xs={12} sm={11} direction="column" justifyContent="center" style={{ marginTop: "10px" }}>
                        <DocumentPreviewRow Icon={PersonIcon} subTxt={"Verified By"} mainTxt={documentMeta?.verifiedBy ? getUserDetailsById(documentMeta?.verifiedBy)[appSettings.get().language]?.name : "--"} status={documentMeta?.status} />
                      </Grid>
                      <Grid container item xs={12} sm={13} direction="column" justifyContent="center" style={{ marginTop: "10px" }}>
                        <DocumentPreviewRow Icon={QueryBuilderIcon} subTxt={"Verified Date"} mainTxt={documentMeta?.verifiedDate ? dateFormatWithTimeChange(documentMeta?.verifiedDate) : "--"} status={documentMeta?.status} />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
              <Grid container item spacing={1} direction="row" xs={12}>
                <Grid item xs={12} lg={12}>
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <h4 className="basic-font" style={{ marginBottom: 0 }}>
                      File History
                    </h4>
                    {previewHistoryLoading && (
                      <Stack pt={2}>
                        <CircularProgressComponent size={20} />
                      </Stack>
                    )}
                  </Stack>
                  <hr style={{ borderTop: "1px solid #454c661a", marginTop: "0px", width: "100%" }} />
                  {documentHistoryList && documentHistoryList.length < 1 ? (
                    <ErrorMessageComponent headMessage={"No Data"} errorMessage={"No History data"} showImg={false} />
                  ) : (
                    <Box style={{ maxHeight: "35vh", overflowY: "auto" }}>
                      {documentHistoryList &&
                        documentHistoryList.map((item, index) => {
                          return (
                            <MainSectionsWrapper title={item?.uploadedDate && dateFormatWithTimeChange(item?.uploadedDate)} subTitle="Click to get file details" keyCode={index} noBtn={true} icon={false} status={item?.status === 1 ? "success" : item?.status === 2 ? "error" : "info"} file={item} isDocument={true}>
                              <Grid container className="align-center row_list_item" mt={2} columns={24}>
                                <DocumentPreviewHistoryRow subTxtOne="Uploaded By" mainTxtOne={item?.uploadedBy && getUserDetailsById(item?.uploadedBy)[appSettings.get().language]?.name} subTxtTwo="Uploaded Date" mainTxtTwo={item?.uploadedDate ? dateFormatWithDate(item?.uploadedDate) : "--"} />
                                <DocumentPreviewHistoryRow subTxtOne="Verified By" mainTxtOne={item?.verifiedBy ? getUserDetailsById(item?.verifiedBy)[appSettings.get().language]?.name : "--"} subTxtTwo="Verified Date" mainTxtTwo={item?.verifiedDate ? dateFormatWithDate(item?.verifiedDate) : "--"} />
                                <DocumentPreviewHistoryRow subTxtOne="Archive By" mainTxtOne={item?.archivedBy && getUserDetailsById(item?.archivedBy)[appSettings.get().language]?.name} subTxtTwo="Archive Date" mainTxtTwo={item?.archivedDate ? dateFormatWithDate(item?.archivedDate) : "--"} />
                              </Grid>
                            </MainSectionsWrapper>
                          );
                        })}
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};
