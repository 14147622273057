import { Downgraded } from "@hookstate/core";
import Axios from "axios";
import URL from "../configs/connection";
import { FORBIDDEN_CODE } from "../configs/constants/contants";
import { ERROR_CODES } from "../configs/constants/errorCode";
import { userDetails } from "../configs/mainStore";
import Api from "./apiCallsHandler";
import { createApplicationsHistory } from "./applicationHistoryApi";
import CachingStorage from "./cacheStorage";
import { userSessionDelete } from "./sessionService";

const api = Api.getApi();

const fundReleaseApprovedListUrl = `${URL.REACT_APP_BACKEND_HOST}/los/fund-release-data?applicationId=`;
const addFundReleaseApprovedUrl = `${URL.REACT_APP_BACKEND_HOST}/los/fund-release-data`;
const creditAuditListUrl = `${URL.REACT_APP_BACKEND_HOST}/los/credit-audit-data?applicationId=`;
const addCreditAuditUrl = `${URL.REACT_APP_BACKEND_HOST}/los/credit-audit-data`;
const followupChecklistUrl = `${URL.REACT_APP_BACKEND_HOST}/los/followup-checklist?applicationId=`;
const addFollowupChecklistUrl = `${URL.REACT_APP_BACKEND_HOST}/los/followup-checklist`;
const followupChecklistStatusChangeUrl = `${URL.REACT_APP_BACKEND_HOST}/los/followup-checklist-status-change`;
const cribRecordListUrl = `${URL.REACT_APP_BACKEND_HOST}/los/application-crib-records?applicationId=`;
const scoreCardListUrl = `${URL.REACT_APP_BACKEND_HOST}/los/applications/scorecards?applicationId=`;
const cribRecordDocFileUrl = `${URL.REACT_APP_BACKEND_HOST}/los/application-crib-record-image?docId=`;
const saveApplicationTrailCalUrl = `${URL.REACT_APP_BACKEND_HOST}/los/save-trail-cal-other-charges-list`;
const getApplicationTrailCalUrl = `${URL.REACT_APP_BACKEND_HOST}/los/trail-cal-result?applicationId=`;
const getRepaymentMethodsUrl = `${URL.REACT_APP_BACKEND_HOST}/los/repayment-methods?languageId=`;
const getApplicationOfferLetterDataUrl = `${URL.REACT_APP_BACKEND_HOST}/los/offer-letter-data?applicationId=`;
const activityHistoryListUrl = `${URL.REACT_APP_BACKEND_HOST}/los/activity-history?applicationId=`;
const riskRecordDocFileUrl = `${URL.REACT_APP_BACKEND_HOST}/los/application-risk-record-image?docId=`;
const auditRecordDocFileUrl = `${URL.REACT_APP_BACKEND_HOST}/los/audit/document?docId=`;
const insurancePolicyDocFileUrl = `${URL.REACT_APP_BACKEND_HOST}/los/insurance-policy-doc-file?docId=`;
const taskChecklistUrl = `${URL.REACT_APP_BACKEND_HOST}/los/task-checklist?applicationId=`;
const addTaskChecklistUrl = `${URL.REACT_APP_BACKEND_HOST}/los/task-checklist`;
const taskChecklistStatusChangeUrl = `${URL.REACT_APP_BACKEND_HOST}/los/task-checklist-status-change`;
const productPredisbursementChecklistUrl = `${URL.REACT_APP_BACKEND_HOST}/los/product-predisbursement-checklist`;
const productPredisbursementCategories = `${URL.REACT_APP_BACKEND_HOST}/los/product-predisbursement-categories`;
const offerLetterConditionsUrl = `${URL.REACT_APP_BACKEND_HOST}/los/products/conditions`;
const productPredisbursementCategoriesAndSaved = `${URL.REACT_APP_BACKEND_HOST}/los/product-predisbursement-categories-saved`;
const getProductDataVerificationChecklistUrl = `${URL.REACT_APP_BACKEND_HOST}/los/product-data-verification-checklist`;
const productPredisbursementStatusChangeUrl = `${URL.REACT_APP_BACKEND_HOST}/los/predisbursement-checklist-status-change`;
const productPredisbursementUnpublish = `${URL.REACT_APP_BACKEND_HOST}/los/predisbursement-checklist-remove`;
const productDataVerificationStatusChangeUrl = `${URL.REACT_APP_BACKEND_HOST}/los/data-verification-checklist-status-change`;
const getPredisbursementList = `${URL.REACT_APP_BACKEND_HOST}/los/predisbursement-checklist?applicationId=`;
const addPredisbursementList = `${URL.REACT_APP_BACKEND_HOST}/los/predisbursement-checklist`;
const createOfferLetterConditionUrl = `${URL.REACT_APP_BACKEND_HOST}/los/products/conditions`;
const getDataverifiedList = `${URL.REACT_APP_BACKEND_HOST}/los/data-verification-checklist?applicationId=`;
const legalRecordDocFileUrl = `${URL.REACT_APP_BACKEND_HOST}/los/application-legal-record-image?docId=`;

const productDocumentChecklistUrl = `${URL.REACT_APP_BACKEND_HOST}/los/product-document-checklist`;
const securityDocumentChecklistUrl = `${URL.REACT_APP_BACKEND_HOST}/los/checklist/security`;
const productDocumentStatusChangeUrl = `${URL.REACT_APP_BACKEND_HOST}/los/document-checklist-status-change`;
const getDocumentList = `${URL.REACT_APP_BACKEND_HOST}/los/document-checklist?applicationId=`;
const getPostDisbursementDocumentList = `${URL.REACT_APP_BACKEND_HOST}/los/post-disbursement-checklist`;
const postDisbursementDocumentStatusChange = `${URL.REACT_APP_BACKEND_HOST}/los/post-disbursement-checklist-status-change`;

//workflow decision engine validations
const fraudCheck = `${URL.REACT_APP_BACKEND_HOST}/los/validate/fraud`;
const underwritingPolicyCheck = `${URL.REACT_APP_BACKEND_HOST}/los/validate/underwriting-policy`;
const validateDisbursementAPIs = `${URL.REACT_APP_BACKEND_HOST}/los/validate/disbursement`;
const validateDecisions = `${URL.REACT_APP_BACKEND_HOST}/decision-engine/validates/decisions`;

const letterDocumentsDataApiUrl = `${URL.REACT_APP_BACKEND_HOST}/los/letter-document-data?applicationId=`;
const letterDocumentsSaveDataApiUrl = `${URL.REACT_APP_BACKEND_HOST}/los/letter-document-save-data`;

const invokeNewTC = `${URL.REACT_APP_BACKEND_HOST}/bank-service/invoke/tc`;

const createCauHeadAssignApi = `${URL.REACT_APP_BACKEND_HOST}/los/create-cau-head-assign`;
const getCauOfficerAssignActionsApi = `${URL.REACT_APP_BACKEND_HOST}/los/get-cau-officer-action-taken`;
const getReferalCauOfficerGroupApi = `${URL.REACT_APP_BACKEND_HOST}/los/get-referal-cau-officer-group`;
const getOfficerManagerOwnerGroupIDApi = `${URL.REACT_APP_BACKEND_HOST}/los/get-referal-cau-manager-owner-id`;

const getApplicationHistoryFields = `${URL.REACT_APP_BACKEND_HOST}/los/applications/history/fields`;
const notificationsApiUrl = `${URL.REACT_APP_BACKEND_HOST}/los/notifications`;
const saveChecklistStatus = `${URL.REACT_APP_BACKEND_HOST}/los/checklists/verification`;
const addDisbursementChecklistUrl = `${URL.REACT_APP_BACKEND_HOST}/los/disbursement-checklist`;
const disbursementChecklistUrl = `${URL.REACT_APP_BACKEND_HOST}/los/disbursement-checklist`;
const disbursementChecklistStatusChangeUrl = `${URL.REACT_APP_BACKEND_HOST}/los/disbursement-checklist-status-change`;
const inspectionScoreCard = `${URL.REACT_APP_BACKEND_HOST}/los/products/scorecards`;
const scoreCardData = `${URL.REACT_APP_BACKEND_HOST}/los/products/scoreCardData`;

const applicationCribNote = `${URL.REACT_APP_BACKEND_HOST}/los/applications/crib/note`;
const cribDocumentServiceRequest = `${URL.REACT_APP_BACKEND_HOST}/los/applications/crib/document`;

const detachSubflowReferralUrl = `${URL.REACT_APP_BACKEND_HOST}/los/subflow/referral/detach`;

const subworkflowMetaUrl = `${URL.REACT_APP_BACKEND_HOST}/los/subrowkflow/meta`;

/** Credit file API */
export const getApplicationData = async (applicationId) => {
  try {
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/get-application-data`, {
      applicationId: applicationId,
    });

    return data;
  } catch (error) { }
};

export const getHistoryDataForApplication = async (applicationId) => {
  try {
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/get-history-data`, {
      applicationId: applicationId,
    });
    return data;
  } catch (error) { }
};

export const saveTaskData = async (data) => {
  try {
    const res = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/add-task`, data);
    return res;
  } catch (error) { }
};

export const addTasks = async (data) => {
  try {
    const res = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/tasks`, data);

    return res;
  } catch (error) { }
};

export const getTaskList = async (applicationId) => {
  try {
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/get-task-list`, {
      applicationId: applicationId,
    });
    return data;
  } catch (error) { }
};

export const updateTask = async (data) => {
  try {
    const res = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/update-task`, data);
    return res || [];
  } catch (error) { }
};

export const getFundReleaseApprovedList = async (applicationId) => {
  try {
    const data = await api.get(`${fundReleaseApprovedListUrl}${applicationId}`, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const addFundReleaseApproveData = async (applicationId, status, comment, approvedBy) => {
  try {
    const res = await api.post(`${addFundReleaseApprovedUrl}`, {
      applicationId,
      status,
      comment,
      approvedBy,
    });

    if (res.status === ERROR_CODES.success) {
      const statusResponse = status === 1 ? "Approved" : "Rejected";

      const payload = {
        applicationId,
        status,
        comment,
        approvedBy,
      };

      createApplicationsHistory(
        applicationId,
        `Fund release approval (${statusResponse})`,
        null,
        payload,
        "/los/fund-release-data"
      );
      return { msg: res?.data };
    } else if (res.status === ERROR_CODES.fail) {
      return { error: res?.data };
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getCreditAuditList = async (applicationId) => {
  try {
    const data = await api.get(`${creditAuditListUrl}${applicationId}`, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const addCreditAuditData = async (applicationId, comment, authorizedBy) => {
  try {
    const res = await api.post(`${addCreditAuditUrl}`, {
      applicationId,
      comment,
      authorizedBy,
    });

    if (res.status === ERROR_CODES.success) {
      return { msg: res?.data };
    } else if (res.status === ERROR_CODES.fail) {
      return { error: res?.data };
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getFollowUpChecklistList = async (applicationId) => {
  try {
    const data = await api.get(`${followupChecklistUrl}${applicationId}`, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getDisbursementChecklistList = async (applicationId, type, subType) => {
  try {
    const data = await api.get(`${disbursementChecklistUrl}`, {
      applicationId: applicationId,
      type: type,
      subType: subType,
    });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const addDisbursementChecklistItem = async (applicationId, item, status, type, subType, createdBy) => {
  try {
    const res = await api.post(`${addDisbursementChecklistUrl}`, {
      applicationId,
      item,
      status,
      type,
      subType,
      createdBy,
    });

    if (res.status === ERROR_CODES.success) {
      return { msg: res?.data };
    } else if (res.status === ERROR_CODES.fail) {
      return { error: res?.data };
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const addFollowupChecklistItem = async (applicationId, item, status, createdBy) => {
  try {
    const res = await api.post(`${addFollowupChecklistUrl}`, {
      applicationId,
      item,
      status,
      createdBy,
    });

    if (res.status === ERROR_CODES.success) {
      return { msg: res?.data };
    } else if (res.status === ERROR_CODES.fail) {
      return { error: res?.data };
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const saveReferData = async (data) => {
  try {
    const res = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/add-refer`, data);

    if (res?.status === ERROR_CODES.success) {
      createApplicationsHistory(data?.applicationId, `Refferal added - ${data?.note}`, null, data, "/los/add-refer");
    }

    return res;
  } catch (error) { }
};

export const getReferList = async (applicationId) => {
  try {
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/get-refer-list`, {
      applicationId: applicationId,
    });
    return data;
  } catch (error) { }
};

export const getNoteList = async (applicationId) => {
  try {
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/get-note-list`, {
      applicationId: applicationId,
    });
    return data;
  } catch (error) { }
};

export const saveNoteData = async (data) => {
  try {
    const res = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/add-note`, data);
    return res;
  } catch (error) { }
};

export const deleteNoteData = async (noteId) => {
  try {
    const res = await api.delete(`${URL.REACT_APP_BACKEND_HOST}/los/notes?noteId=${noteId}`);
    return res;
  } catch (error) {
    return null;
  }
};

export const getConditionList = async (applicationId: any, excludeString: any = "", conditionType?: any) => {
  try {
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/get-condition-list`, {
      applicationId: applicationId,
      excludeString: excludeString,
      conditionType: conditionType,
    });
    return data;
  } catch (error) {
    return null;
  }
};

export const saveConditionData = async (data) => {
  try {
    const res = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/add-condition`, data);
    return res;
  } catch (error) {
    return null;
  }
};

export const getRiskList = async (applicationId?: any, decisionId?: any, documentsList?: boolean) => {
  try {
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/get-risk-list`, {
      applicationId: applicationId,
      decisionId: decisionId,
      documentsList: Boolean(documentsList),
    });
    return data;
  } catch (error) { }
};

export const getSubflowDocuments = async (applicationId?: any, groupId?: any, documentsList?: boolean) => {
  try {
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/get-subflow-documents-list`, {
      applicationId: applicationId,
      groupId: groupId
    });
    return data;
  } catch (error) {
    return null;
  }
};

export const getAuditList = async (applicationId?: any, decisionId?: any, documentsList?: boolean) => {
  try {
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/audits/documents/list`, {
      applicationId: applicationId,
      decisionId: decisionId,
      documentsList: Boolean(documentsList),
    });
    return data;
  } catch (error) { }
};

export const getReviewDocumentData = async (applicationId: any, type: any, id: any) => {
  try {
    const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/review/document/data`, {
      applicationId: applicationId,
      type: type,
      id: id,
    });

    return response.data;
  } catch (error) { }
};

export const getInsurancePolicyDocuments = async (applicationId?: any) => {
  try {
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/get-insurance-policy-list`, {
      applicationId: applicationId,
    });
    return data;
  } catch (error) { }
};
export const saveRiskData = async (data) => {
  try {
    const res = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/add-risk`, data);
    return res;
  } catch (error) { }
};
export const getLegalList = async (applicationId?: any, decisionId?: any) => {
  try {
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/get-legal-list`, {
      applicationId: applicationId,
      decisionId: decisionId,
    });
    return data;
  } catch (error) { }
};

export const getPriorApprovalDocument = async (id) => {
  try {
    const data = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/prior/approval/document`, {
      id: id,
    });

    return data?.data;
  } catch (error) { }
};

export const saveLegalData = async (data) => {
  try {
    const res = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/add-legal`, data);
    return res;
  } catch (error) { }
};

export const saveInspectionData = async (data) => {
  try {
    const res = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/add-inspection`, data);
    return res;
  } catch (error) { }
};

export const saveInsuranceDocumentPolicy = async (data) => {
  try {
    const res = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/insurance-policy/add`, data);
    return res || [];
  } catch (error) { }
};

export const updateRefer = async (data) => {
  try {
    const res = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/update-refer`, data);
    return res || [];
  } catch (error) { }
};

export const followupChecklistItemStatusChange = async (applicationId: any, id: any, isChecked: any) => {
  try {
    const data = await api.patch(`${followupChecklistStatusChangeUrl}`, {
      applicationId,
      id,
      status: isChecked ? 0 : 1,
    });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const disbursementChecklistItemStatusChange = async (applicationId: any, id: any, isChecked: any) => {
  try {
    const data = await api.patch(`${disbursementChecklistStatusChangeUrl}`, {
      applicationId,
      id,
      status: isChecked ? 0 : 1,
    });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getCribRecordList = async (applicationId) => {
  try {
    const data = await api.get(`${cribRecordListUrl}${applicationId}`, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getScoreCard = async (data) => {
  try {
    const response = await api.get(`${inspectionScoreCard}`, data);

    if (response.status === ERROR_CODES.success) {
      return response?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getScoreCardData = async (applicationId) => {
  try {
    const response = await api.get(`${scoreCardData}`, { applicationId: applicationId });

    if (response.status === ERROR_CODES.success) {
      return response?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getApplicationScorecardList = async (applicationId) => {
  try {
    const data = await api.get(`${scoreCardListUrl}${applicationId}`, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getScoreCardDataList = async (applicationId) => {
  try {
    const data = await api.get(
      `${URL.REACT_APP_BACKEND_HOST}/trade-credit-service/score-card-data?applicationId=${applicationId}`,
      []
    );

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) { }
};

export const uploadCribFile = async (uploadDataObject, file, setFileUploadProgress) => {
  try {
    const accessToken: any = sessionStorage.getItem("accessToken");

    let formData = new FormData();
    formData.append("applicationParticipantId", uploadDataObject?.applicationParticipantId);
    formData.append("cribRecordKey", uploadDataObject?.cribRecordKey);
    formData.append("dateReceived", uploadDataObject?.dateReceived);
    formData.append("uploadedBy", uploadDataObject?.uploadedBy);
    formData.append("cribStatus", uploadDataObject?.cribStatus);
    formData.append("applicationId", uploadDataObject?.applicationId);
    formData.append("documentId", uploadDataObject?.documentId);
    formData.append("arrearsStatus", uploadDataObject?.arrearsStatus);

    formData.append("file", file);

    const result = await Axios.post(`${URL.REACT_APP_DOCUMENT_UPLOAD}/document-upload/crib-document-upload`, formData, {
      headers: {
        Authorization: `bearer ${accessToken}`,
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (data) => {
        setFileUploadProgress(Math.round((100 * data.loaded) / data.total));
      },
    });

    if (result) {
      if (result?.data?.status === ERROR_CODES.success) {
        // add to application history when uploading the crib document
        createApplicationsHistory(
          uploadDataObject?.applicationId,
          `Crib file upload (${uploadDataObject?.applicantType}) (${uploadDataObject?.cribRecordKey})`,
          null,
          uploadDataObject,
          "/crib-document-upload"
        );

        return { status: true };
      } else {
        let message;
        if (result?.data?.error) {
          message = result?.data?.error;
        } else {
          message = "Something went wrong";
        }
        return { status: false, message };
      }
    }
  } catch (error: any) {
    if (error?.response?.status === FORBIDDEN_CODE) {
      userSessionDelete();
    }

    return { status: false, message: "Something went wrong" };
  }
};

export const getCribRecordDocFile = async (docId) => {
  try {
    const data = await api.get(`${cribRecordDocFileUrl}${docId}`, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getRenderingTabStages = async () => {
  try {
    const res = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/get-tab-rendering-stages`);
    return res || [];
  } catch (error) { }
};

export const getRenderingTabStagesById = async (workflowId) => {
  try {
    const res = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/get-tab-rendering-stage`, {
      workflowId,
    });
    return res || {};
  } catch (error) { }
};

export const getApplicationTabsById = async (applicationId) => {
  try {
    const res = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/get-application-tabs`, {
      applicationId,
    });
    return res || { data: [] };
  } catch (error) {
    return { data: [] };
  }
};

export const updateApplicationTrailCalData = async (
  applicationId,
  trailParams,
  trailOutput,
  otherFees,
  meta = null
) => {
  try {
    const res = await api.post(`${saveApplicationTrailCalUrl}`, {
      applicationId,
      trailParams,
      trailOutput,
      otherFees,
      meta,
    });

    if (res.status === ERROR_CODES.success) {
      return { msg: res?.data };
    } else if (res.status === ERROR_CODES.fail) {
      return { error: res?.data };
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getTrailCalData = async (applicationId) => {
  try {
    const data = await api.get(`${getApplicationTrailCalUrl}${applicationId}`, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getRepaymentMethods = async (languageId) => {
  try {
    const data = await api.get(`${getRepaymentMethodsUrl}${languageId}`, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getPredisbursmentList = async (applicationId) => {
  try {
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/get-pre-disbursement`, {
      applicationId: applicationId,
    });
    return data;
  } catch (error) { }
};

export const savePredisbursmentData = async (data) => {
  try {
    const res = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/add-pre-disbursement`, data);
    return res;
  } catch (error) { }
};

export const updatePredisbursmentData = async (data) => {
  try {
    const res = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/update-pre-disbursement`, data);
    return res;
  } catch (error) { }
};

export const updateConditionData = async (data) => {
  try {
    const res = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/update-condition`, data);
    return res;
  } catch (error) { }
};

export const deleteConditionData = async (objectId) => {
  try {
    const res = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/delete-condition`, {
      id: objectId,
    });
    return res;
  } catch (error) { }
};

export const getApplicationOfferLetterData = async (applicationId) => {
  try {
    const data = await api.get(`${getApplicationOfferLetterDataUrl}${applicationId}`, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const saveAppraisalData = async (data) => {
  try {
    const res = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/add-appraisal-section`, data);
    return res;
  } catch (error) { }
};

export const getAppraisalData = async (params) => {
  try {
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/get-appraisal-section`, {
      ...params,
    });
    return data;
  } catch (error) { }
};

export const getAnalysisOfLoanRequirementDetails = async (params) => {
  try {
    const { data } = await api.get(
      `${URL.REACT_APP_BACKEND_HOST}/los/analysisofloanrequirement?applicationId=${params?.applicationId}`
    );
    return data;
  } catch (error) { }
};

export const saveMicroLoanRequirements = async (data) => {
  try {
    const res = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/analysisofloanrequirement`, data);
    return res;
  } catch (error) { }
};

export const getAnalysisLoanRequirementCommentsData = async (params) => {
  try {
    const { data } = await api.get(
      `${URL.REACT_APP_BACKEND_HOST}/los/analysisofloanrequirement?applicationId=${params?.applicationId}&section=comments`
    );
    return data;
  } catch (error) { }
};

export const saveLoanRequirementCommentData = async (data) => {
  try {
    const res = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/analysisofloanrequirement/comments`, data);
    return res;
  } catch (error) { }
};

export const deleteLoanRequirementComment = async (data) => {
  try {
    const res = await api.delete(
      `${URL.REACT_APP_BACKEND_HOST}/los/analysisofloanrequirement/comments?id=${data?.id}&applicationId=${data?.applicationId}`
    );
    return res;
  } catch (error) { }
};

export const deleteAbilityProposedLoanComment = async ({ id, applicationId }) => {
  try {
    const res = await api.delete(
      `${URL.REACT_APP_BACKEND_HOST}/los/appraisals/abilitytoproposeloan/comments?id=${id}&applicationId=${applicationId}`
    );
    return res;
  } catch (error) { }
};

export const getChequeReturnsDetails = async (params) => {
  try {
    const { data } = await api.get(
      `${URL.REACT_APP_BACKEND_HOST}/los/appraisals/liability-analysis/cheque-returns?applicationId=${params?.applicationId}`
    );
    return data;
  } catch (error) { }
};

export const saveChequeReturnsDetails = async (data) => {
  try {
    const res = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/appraisals/liability-analysis/cheque-returns`, data);
    return res;
  } catch (error) { }
};

export const getLoanCribDetails = async (params) => {
  try {
    const { data } = await api.get(
      `${URL.REACT_APP_BACKEND_HOST}/los/appraisals/liability-analysis/crib-details?applicationId=${params?.applicationId}`
    );
    return data;
  } catch (error) { }
};

export const saveLoanCribDetails = async (data) => {
  try {
    const res = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/appraisals/liability-analysis/crib-details`, data);
    return res;
  } catch (error) { }
};

export const getCurrentAccountDetails = async (params) => {
  try {
    const { data } = await api.get(
      `${URL.REACT_APP_BACKEND_HOST}/los/appraisals/liability-analysis/current-accounts?applicationId=${params?.applicationId}`
    );
    return data;
  } catch (error) { }
};

export const saveCurrentAccountDetails = async (data) => {
  try {
    const res = await api.post(
      `${URL.REACT_APP_BACKEND_HOST}/los/appraisals/liability-analysis/current-accounts`,
      data
    );
    return res;
  } catch (error) { }
};

export const getBankGuaranteeDetails = async (params) => {
  try {
    const { data } = await api.get(
      `${URL.REACT_APP_BACKEND_HOST}/los/appraisals/liability-analysis/bank-guarantees?applicationId=${params?.applicationId}`
    );
    return data;
  } catch (error) { }
};

export const saveBankGuaranteeDetails = async (data) => {
  try {
    const res = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/appraisals/liability-analysis/bank-guarantees`, data);
    return res;
  } catch (error) { }
};

export const getMarketAnalysisColumns = async (applicationId) => {
  try {
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/appraisals/market-analysis`, { applicationId });
    return data;
  } catch (error) { }
};

export const getDetailsOfAnalysis = async (applicationId, columnName = "") => {
  try {
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/appraisals/market-analysis`, {
      applicationId,
      columnName,
    });
    return data;
  } catch (error) { }
};

export const insertDetailsOfAnalysis = async (applicationId, columnName, insertObject) => {
  try {
    const data = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/appraisals/market-analysis`, {
      applicationId,
      columnName,
      insertObject,
    });
    return data;
  } catch (error) { }
};

export const getRatioAnalysis = async (applicationId) => {
  try {
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/appraisals/analysis/ratios`, { applicationId });
    return data;
  } catch (error) { }
};

export const getActivityHistoryList = async (applicationId) => {
  try {
    const data = await api.get(`${activityHistoryListUrl}${applicationId}`, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const uploadRiskFile = async (uploadDataObject, file, setFileUploadProgress) => {
  try {
    const accessToken: any = sessionStorage.getItem("accessToken");

    let formData = new FormData();
    formData.append("title", uploadDataObject?.title);
    formData.append("description", uploadDataObject?.description);
    formData.append("createdBy", uploadDataObject?.createdBy);
    formData.append("applicationId", uploadDataObject?.applicationId);
    formData.append("workflowId", uploadDataObject?.workflowId);
    formData.append("stageId", uploadDataObject?.stageId);
    formData.append("documentId", uploadDataObject?.documentId);
    formData.append("referralId", uploadDataObject?.referralId);
    formData.append("approvalFrom", uploadDataObject?.approvalFrom);
    formData.append("subFlowId", uploadDataObject?.subFlowId);
    formData.append("referralSeqId", uploadDataObject?.referralSeqId);

    formData.append("file", file);

    const result = await Axios.post(`${URL.REACT_APP_DOCUMENT_UPLOAD}/document-upload/risk-document-upload`, formData, {
      headers: {
        Authorization: `bearer ${accessToken}`,
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (data) => {
        setFileUploadProgress(Math.round((100 * data.loaded) / data.total));
      },
    });

    if (result) {
      if (result?.data?.status === ERROR_CODES.success) {
        // add to application history when uploading the risk document
        // createApplicationsHistory(
        //   uploadDataObject?.applicationId,
        //   `Risk document upload \"${uploadDataObject?.title}\"`,
        //   null,
        //   uploadDataObject,
        //   "/risk-document-upload"
        // );

        return { status: true, data: result?.data?.data };
      } else {
        let message;
        if (result?.data?.error) {
          message = result?.data?.error;
        } else {
          message = "Something went wrong";
        }
        return { status: false, message };
      }
    }
  } catch (error: any) {
    if (error?.response?.status === FORBIDDEN_CODE) {
      userSessionDelete();
    }

    return { status: false, message: "Something went wrong" };
  }
};
export const uploadAuditFile = async (uploadDataObject, file, setFileUploadProgress) => {
  try {
    const accessToken: any = sessionStorage.getItem("accessToken");

    let formData = new FormData();
    formData.append("title", uploadDataObject?.title);
    formData.append("description", uploadDataObject?.description);
    formData.append("createdBy", uploadDataObject?.createdBy);
    formData.append("applicationId", uploadDataObject?.applicationId);
    formData.append("workflowId", uploadDataObject?.workflowId);
    formData.append("stageId", uploadDataObject?.stageId);
    formData.append("documentId", uploadDataObject?.documentId);
    formData.append("referralId", uploadDataObject?.referralId);
    formData.append("approvalFrom", uploadDataObject?.approvalFrom);

    formData.append("file", file);

    const result = await Axios.post(
      `${URL.REACT_APP_DOCUMENT_UPLOAD}/document-upload/audits/documents/upload`,
      formData,
      {
        headers: {
          Authorization: `bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (data) => {
          setFileUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      }
    );

    if (result) {
      if (result?.data?.status === ERROR_CODES.success) {
        // add to application history when uploading the risk document
        createApplicationsHistory(
          uploadDataObject?.applicationId,
          `Audit document upload \"${uploadDataObject?.title}\"`,
          null,
          uploadDataObject,
          "/audits/documents/upload"
        );

        return { status: true, data: result?.data?.data };
      } else {
        let message;
        if (result?.data?.error) {
          message = result?.data?.error;
        } else {
          message = "Something went wrong";
        }
        return { status: false, message };
      }
    }
  } catch (error: any) {
    if (error?.response?.status === FORBIDDEN_CODE) {
      userSessionDelete();
    }

    return { status: false, message: "Something went wrong" };
  }
};

export const uploadApprovalFile = async (uploadDataObject, file, setFileUploadProgress) => {
  let { userId }: any = userDetails.attach(Downgraded).get();

  try {
    const accessToken: any = sessionStorage.getItem("accessToken");

    let formData = new FormData();
    formData.append("createdBy", userId);
    formData.append("applicationId", uploadDataObject?.applicationId);
    formData.append("documentId", uploadDataObject?.documentId);
    formData.append("status", uploadDataObject?.status);
    formData.append("docIndex", uploadDataObject?.docIndex);

    formData.append("file", file);

    const result = await Axios.post(
      `${URL.REACT_APP_DOCUMENT_UPLOAD}/document-upload/lead/approval/document/upload`,
      formData,
      {
        headers: {
          Authorization: `bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (data) => {
          setFileUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      }
    );

    if (result) {
      if (result?.data?.status === ERROR_CODES.success) {
        // add to application history when uploading the risk document
        // createApplicationsHistory(uploadDataObject?.applicationId, `Audit document upload \"${uploadDataObject?.title}\"`, null, uploadDataObject, "/audits/documents/upload");

        return { status: true, data: result?.data?.data };
      } else {
        let message;
        if (result?.data?.error) {
          message = result?.data?.error;
        } else {
          message = "Something went wrong";
        }
        return { status: false, message };
      }
    }
  } catch (error: any) {
    if (error?.response?.status === FORBIDDEN_CODE) {
      userSessionDelete();
    }

    return { status: false, message: "Something went wrong" };
  }
};

export const uploadLegalFile = async (uploadDataObject, file, setFileUploadProgress) => {
  try {
    const accessToken: any = sessionStorage.getItem("accessToken");

    let formData = new FormData();

    formData.append("title", uploadDataObject?.title);
    formData.append("description", uploadDataObject?.description);
    formData.append("createdBy", uploadDataObject?.createdBy);
    formData.append("applicationId", uploadDataObject?.applicationId);
    formData.append("workflowId", uploadDataObject?.workflowId);
    formData.append("stageId", uploadDataObject?.stageId);
    formData.append("documentId", uploadDataObject?.documentId);
    formData.append("referralId", uploadDataObject?.referralId);
    formData.append("approvalFrom", uploadDataObject?.approvalFrom);
    formData.append("subFlowId", uploadDataObject?.subFlowId);
    formData.append("referralSeqId", uploadDataObject?.referralSeqId);

    formData.append("file", file);

    const result = await Axios.post(
      `${URL.REACT_APP_DOCUMENT_UPLOAD}/document-upload/legal-document-upload`,
      formData,
      {
        headers: {
          Authorization: `bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (data) => {
          setFileUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      }
    );

    if (result) {
      if (result?.data?.status === ERROR_CODES.success) {
        // add to application history when uploading the legal document
        // createApplicationsHistory(
        //   uploadDataObject?.applicationId,
        //   `Legal document upload \"${uploadDataObject?.title}\"`,
        //   null,
        //   uploadDataObject,
        //   "/legal-document-upload"
        // );

        return { status: true, data: result?.data?.data };
      } else {
        let message;
        if (result?.data?.error) {
          message = result?.data?.error;
        } else {
          message = "Something went wrong";
        }
        return { status: false, message };
      }
    }
  } catch (error: any) {
    if (error?.response?.status === FORBIDDEN_CODE) {
      userSessionDelete();
    }

    return { status: false, message: "Something went wrong" };
  }
};

export const uploadInspectionFile = async (uploadDataObject, file, setFileUploadProgress) => {
  try {
    const accessToken: any = sessionStorage.getItem("accessToken");

    let formData = new FormData();

    formData.append("id", uploadDataObject?.id);
    formData.append("applicationId", uploadDataObject?.applicationId);
    formData.append("inspectionId", uploadDataObject?.inspectionId);
    formData.append("participantId", uploadDataObject?.participantId);
    formData.append("documentCatalog", uploadDataObject?.documentCatalog);
    formData.append("docIndex", uploadDataObject?.docIndex);
    formData.append("documentId", uploadDataObject?.documentId);
    formData.append("createdBy", uploadDataObject?.createdBy);
    formData.append("file", file);

    const result = await Axios.post(
      `${URL.REACT_APP_DOCUMENT_UPLOAD}/document-upload/inspection-document-upload`,
      formData,
      {
        headers: {
          Authorization: `bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (data) => {
          setFileUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      }
    );

    if (result) {
      if (result?.status === ERROR_CODES.success) {
        return { status: result?.status, data: result?.data };
      } else {
        let message;
        if (result?.data?.error) {
          message = result?.data?.error;
        } else {
          message = "Something went wrong";
        }
        return { status: result?.status, message };
      }
    }
  } catch (error: any) {
    if (error?.response?.status === FORBIDDEN_CODE) {
      userSessionDelete();
    }

    return { status: -1, message: "Something went wrong" };
  }
};

export const uploadInsurancePolicyDocument = async (uploadDataObject, file, setFileUploadProgress) => {
  try {
    const accessToken: any = sessionStorage.getItem("accessToken");

    let formData = new FormData();
    formData.append("title", uploadDataObject?.title);
    formData.append("description", uploadDataObject?.description);
    formData.append("createdBy", uploadDataObject?.createdBy);
    formData.append("applicationId", uploadDataObject?.applicationId);
    formData.append("workflowId", uploadDataObject?.workflowId);
    formData.append("stageId", uploadDataObject?.stageId);
    formData.append("documentId", uploadDataObject?.documentId);
    formData.append("referralId", uploadDataObject?.referralId);
    formData.append("approvalFrom", uploadDataObject?.approvalFrom);
    formData.append("subFlowId", uploadDataObject?.subFlowId);
    formData.append("referralSeqId", uploadDataObject?.referralSeqId);

    formData.append("file", file);

    const result = await Axios.post(
      `${URL.REACT_APP_DOCUMENT_UPLOAD}/document-upload/insurance-policy-document-upload`,
      formData,
      {
        headers: {
          Authorization: `bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (data) => {
          setFileUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      }
    );

    if (result) {
      if (result?.data?.status === ERROR_CODES.success) {
        // add to application history when uploading the legal document
        createApplicationsHistory(
          uploadDataObject?.applicationId,
          `Insurance Policy document upload`,
          null,
          uploadDataObject
        );

        return { status: true, data: result?.data?.data };
      } else {
        let message;
        if (result?.data?.error) {
          message = result?.data?.error;
        } else {
          message = "Something went wrong";
        }
        return { status: false, message };
      }
    }
  } catch (error: any) {
    if (error?.response?.status === FORBIDDEN_CODE) {
      userSessionDelete();
    }

    return { status: false, message: "Something went wrong" };
  }
};

export const uploadCauReferenceFiles = async (uploadDataObject, file, setFileUploadProgress) => {
  try {
    const accessToken: any = sessionStorage.getItem("accessToken");

    let formData = new FormData();

    formData.append("applicationId", uploadDataObject?.applicationId);
    formData.append("legalId", uploadDataObject?.legalId);
    formData.append("docIndex", uploadDataObject?.docIndex);
    formData.append("documentId", uploadDataObject?.documentId);
    formData.append("createdBy", uploadDataObject?.createdBy);

    formData.append("file", file);

    const result = await Axios.post(`${URL.REACT_APP_DOCUMENT_UPLOAD}/document-upload/cau-officer-document`, formData, {
      headers: {
        Authorization: `bearer ${accessToken}`,
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (data) => {
        setFileUploadProgress(Math.round((100 * data.loaded) / data.total));
      },
    });

    if (result) {
      if (result?.data?.status === ERROR_CODES.success) {
        // add to application history when uploading the legal document
        // createApplicationsHistory(
        //   uploadDataObject?.applicationId,
        //   `Legal document upload \"${uploadDataObject?.title}\"`,
        //   null,
        //   uploadDataObject,
        //   "/cau-officer-document"
        // );

        return { status: true, data: result?.data?.data };
      } else {
        let message;
        if (result?.data?.error) {
          message = result?.data?.error;
        } else {
          message = "Something went wrong";
        }
        return { status: false, message };
      }
    }
  } catch (error: any) {
    if (error?.response?.status === FORBIDDEN_CODE) {
      userSessionDelete();
    }

    return { status: false, message: "Something went wrong" };
  }
};

export const uploadCauHubDepDoc = async (uploadDataObject, file, setFileUploadProgress) => {
  try {
    const accessToken: any = sessionStorage.getItem("accessToken");

    let formData = new FormData();

    formData.append("title", uploadDataObject?.title);
    formData.append("description", uploadDataObject?.description);
    formData.append("createdBy", uploadDataObject?.createdBy);
    formData.append("applicationId", uploadDataObject?.applicationId);
    formData.append("workflowId", uploadDataObject?.workflowId);
    formData.append("stageId", uploadDataObject?.stageId);
    formData.append("documentId", uploadDataObject?.documentId);
    formData.append("referralId", uploadDataObject?.referralId);
    formData.append("approvalFrom", uploadDataObject?.approvalFrom);
    formData.append("subFlowId", uploadDataObject?.subFlowId);
    formData.append("referralSeqId", uploadDataObject?.referralSeqId);

    formData.append("file", file);

    const result = await Axios.post(
      `${URL.REACT_APP_DOCUMENT_UPLOAD}/document-upload/cau-document-upload`,
      formData,
      {
        headers: {
          Authorization: `bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (data) => {
          setFileUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      }
    );

    if (result) {
      if (result?.data?.status === ERROR_CODES.success) {
        // add to application history when uploading the legal document
        // createApplicationsHistory(
        //   uploadDataObject?.applicationId,
        //   `Legal document upload \"${uploadDataObject?.title}\"`,
        //   null,
        //   uploadDataObject,
        //   "/legal-document-upload"
        // );

        return { status: true, data: result?.data?.data };
      } else {
        let message;
        if (result?.data?.error) {
          message = result?.data?.error;
        } else {
          message = "Something went wrong";
        }
        return { status: false, message };
      }
    }
  } catch (error: any) {
    if (error?.response?.status === FORBIDDEN_CODE) {
      userSessionDelete();
    }

    return { status: false, message: "Something went wrong" };
  }
};

export const getRiskRecordDocFile = async (docId) => {
  try {
    const data = await api.get(`${riskRecordDocFileUrl}${docId}`, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};
export const getAuditRecordFile = async (docId) => {
  try {
    const data = await api.get(`${auditRecordDocFileUrl}${docId}`, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getInsurancePolicyDocumentFile = async (docId) => {
  try {
    const data = await api.get(`${insurancePolicyDocFileUrl}${docId}`, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getLegalRecordDocFile = async (docId) => {
  try {
    const data = await api.get(`${legalRecordDocFileUrl}${docId}`, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getFraudValidations = async (applicationId) => {
  try {
    const data = await api.post(`${fraudCheck}`, { applicationId, validationRule: "fraudCheck" });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const underwritingPolicyValidations = async (applicationId) => {
  try {
    const data = await api.post(`${underwritingPolicyCheck}`, {
      applicationId,
      validationRule: "underwritingPolicy",
    });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const activeDecisionValidations = async (applicationId, section) => {
  try {
    const { data } = await api.post(`${validateDecisions}?section=${section}`, {
      applicationId,
    });

    if (data) {
      return data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const disbursementAPIValidations = async (applicationId) => {
  try {
    if (!applicationId) {
      return;
    }
    const data = await api.post(`${validateDisbursementAPIs}`, {
      applicationId,
      validationRule: "disbursement",
    });

    return data?.data ?? [];
  } catch (error) {
    return [];
  }
};

export const getTaskChecklistList = async (applicationId) => {
  try {
    const data: any = await api.get(`${taskChecklistUrl}${applicationId}`, []);
    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const addTaskChecklistItem = async (applicationId, item, status, createdBy) => {
  try {
    const res = await api.post(`${addTaskChecklistUrl}`, {
      applicationId,
      item,
      status,
      createdBy,
    });

    if (res.status === ERROR_CODES.success) {
      return { msg: res?.data };
    } else if (res.status === ERROR_CODES.fail) {
      return { error: res?.data };
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const taskChecklistItemStatusChange = async (applicationId: any, id: any, isChecked: any) => {
  try {
    const data = await api.patch(`${taskChecklistStatusChangeUrl}`, {
      applicationId,
      id,
      status: isChecked ? 0 : 1,
    });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getProductPredisbursementChecklist = async (productId, productSectorId, productSchemeId) => {
  try {
    const data = await api.get(`${productPredisbursementChecklistUrl}`, {
      productId: productId,
      sectorId: productSectorId,
      schemeId: productSchemeId,
    });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getProductPredisbursementCategoriesWithSaved = async (applicationId) => {
  try {
    const data = await api.get(`${productPredisbursementCategoriesAndSaved}?applicationId=${applicationId}`);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getProductPredisbursementCategories = async (applicationId) => {
  try {
    const data = await api.get(`${productPredisbursementCategories}?applicationId=${applicationId}`);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getOfferLetterConditions = async (applicationId) => {
  try {
    const data = await api.get(`${offerLetterConditionsUrl}?applicationId=${applicationId}`);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getProductDataVerificationChecklist = async (productId, productSectorId, productSchemeId) => {
  try {
    const data = await api.get(`${getProductDataVerificationChecklistUrl}`, {
      productId: productId,
      sectorId: productSectorId,
      schemeId: productSchemeId,
    });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const predisbursementChecklistItemStatusChange = async (
  applicationId: any,
  id: any,
  isChecked: any,
  item: any,
  completedBy: any
) => {
  try {
    const data = await api.put(`${productPredisbursementStatusChangeUrl}`, {
      applicationId,
      id,
      status: isChecked ? 0 : 1,
      item,
      completedBy,
    });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const predisbursementChecklistItemRemove = async (id: any) => {
  if (!id) {
    return null;
  }
  try {
    const data = await api.post(`${productPredisbursementUnpublish}`, {
      id,
    });

    if (data.status === ERROR_CODES.success) {
      createApplicationsHistory(
        data.data[0]?.APPLICATION_ID,
        `Pre Disbursment conditions updated`,
        null,
        data,
        "/los/predisbursement-checklist"
      );
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const dataVerificationCheckListItemStatusChange = async (
  applicationId: any,
  id: any,
  isChecked: any,
  item: any,
  completedBy: any
) => {
  try {
    const data = await api.put(`${productDataVerificationStatusChangeUrl}`, {
      applicationId,
      id,
      status: isChecked ? 0 : 1,
      item,
      completedBy,
    });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getPredisbursementChecklistList = async (applicationId) => {
  try {
    const data = await api.get(`${getPredisbursementList}${applicationId}`, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getDataVerificationChecklistList = async (applicationId) => {
  try {
    const data = await api.get(`${getDataverifiedList}${applicationId}`, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getProductDocumentChecklist = async (applicationId, productId, productSectorId, productSchemeId, type) => {
  try {
    const data = await api.get(`${productDocumentChecklistUrl}`, {
      productId: productId,
      sectorId: productSectorId,
      schemeId: productSchemeId,
      type: type,
      applicationId: applicationId,
    });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getSecurityDocumentChecklist = async (
  applicationId,
  productId,
  productSectorId,
  productSchemeId,
  type
) => {
  try {
    const data = await api.get(`${securityDocumentChecklistUrl}`, {
      productId: productId,
      sectorId: productSectorId,
      schemeId: productSchemeId,
      type: type,
      applicationId: applicationId,
    });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getPostDisbursementChecklist = async (
  applicationId,
  productId,
  productSectorId,
  productSchemeId,
  documentType
) => {
  try {
    const data = await api.get(`${getPostDisbursementDocumentList}`, {
      productId: productId,
      sectorId: productSectorId,
      schemeId: productSchemeId,
      documentType: documentType,
      applicationId: applicationId,
    });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const postDisbursementChecklistItemStatusChange = async (
  applicationId: any,
  id: any,
  isChecked: any,
  item: any,
  completedBy: any,
  individualId: any,
  type: any
) => {
  try {
    const data = await api.put(`${postDisbursementDocumentStatusChange}`, {
      applicationId,
      id,
      status: isChecked ? 0 : 1,
      item,
      completedBy,
      individualId,
      type,
    });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const documentChecklistItemStatusChange = async (
  applicationId: any,
  id: any,
  isChecked: any,
  item: any,
  completedBy: any,
  individualId?: any
) => {
  try {
    const data = await api.put(`${productDocumentStatusChangeUrl}`, {
      applicationId,
      id,
      status: isChecked ? 0 : 1,
      item,
      completedBy,
      individualId,
    });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getDocumentChecklistList = async (applicationId) => {
  try {
    const data = await api.get(`${getDocumentList}${applicationId}`, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const createPredisbursementChecklist = async (data) => {
  try {
    const res = await api.post(`${addPredisbursementList}`, data);

    if (res.status === ERROR_CODES.success) {
      createApplicationsHistory(
        data?.applicationId,
        `Pre Disbursment conditions add`,
        null,
        data?.predisbursementList,
        "/los/predisbursement-checklist"
      );
      return { msg: res?.data };
    } else if (res.status === ERROR_CODES.fail) {
      return { error: res?.data };
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getPostDisbursementDocumentChecklist = async (
  applicationId,
  productId,
  productSectorId,
  productSchemeId,
  type
) => {
  try {
    const data = await api.get(`${securityDocumentChecklistUrl}`, {
      productId: productId,
      sectorId: productSectorId,
      schemeId: productSchemeId,
      type: type,
      applicationId: applicationId,
    });

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const createOfferLetterConditions = async (data) => {
  try {
    const res = await api.post(`${createOfferLetterConditionUrl}`, data);

    if (res.status === ERROR_CODES.success) {
      return { msg: res?.data };
    } else if (res.status === ERROR_CODES.fail) {
      return { error: res?.data };
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getLetterDocumentsData = async (applicationId) => {
  try {
    const data = await api.get(`${letterDocumentsDataApiUrl}${applicationId}`, []);

    if (data.status === ERROR_CODES.success) {
      return data?.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const saveLetterDocumentsData = async (data) => {
  try {
    const res = await api.post(`${letterDocumentsSaveDataApiUrl}`, data);

    return res;
  } catch (error) {
    return null;
  }
};

export const getGroupownersByGroupId = async (groupId) => {
  try {
    const res = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/get-group-owners`, {
      groupId: groupId,
    });
    if (res?.status === 1) {
      return res?.data;
    }
  } catch (error) { }
};

export const createCauHeadReferal = async (groupId) => {
  try {
    const res = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/create-cau-head-referal`, {
      groupId: groupId,
    });
    if (res?.status === 1) {
      return res?.data;
    }
  } catch (error) { }
};

export const getInvokeTC = async (data) => {
  try {
    const res = await api.post(`${invokeNewTC}`, data);
    return res;
  } catch (error) {
    return null;
  }
};

export const getCauOfficerAssignActions = async (data) => {
  try {
    const res = await api.get(`${getCauOfficerAssignActionsApi}`, {
      referId: data,
    });
    if (res?.status?.code === 1) {
      return res?.data;
    }
  } catch (error) {
    return null;
  }
};

export const createCauHeadAssign = async (data) => {
  try {
    const res = await api.post(`${createCauHeadAssignApi}`, data);

    return res;
  } catch (error) {
    return null;
  }
};

export const getReferalCauOfficerGroup = async (productScheme) => {
  try {
    const res = await api.get(`${getReferalCauOfficerGroupApi}`, {
      productScheme: productScheme,
    });

    if (res?.status?.code === ERROR_CODES.success) {
      return {
        data: res?.data,
        status: res?.status?.code,
      };
    } else {
      return {
        data: res?.data,
        status: res?.status?.code,
      };
    }
  } catch (errpr) {
    return null;
  }
};

export const getOfficerManagerGroupId = async (groupId) => {
  try {
    const res = await api.get(`${getOfficerManagerOwnerGroupIDApi}`, {
      groupId,
    });

    if (res?.status === 1) {
      return res?.data;
    }
  } catch (errpr) {
    return null;
  }
};

export const uploadCauOfficerFile = async (uploadDataObject, file, setFileUploadProgress) => {
  try {
    const accessToken: any = sessionStorage.getItem("accessToken");

    let formData = new FormData();

    formData.append("referalId", uploadDataObject?.referalId);
    formData.append("documentId", uploadDataObject?.documentId);

    formData.append("file", file);

    const result = await Axios.post(`${URL.REACT_APP_DOCUMENT_UPLOAD}/document-upload/cau-head-file-upload`, formData, {
      headers: {
        Authorization: `bearer ${accessToken}`,
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (data) => {
        setFileUploadProgress(Math.round((100 * data.loaded) / data.total));
      },
    });

    if (result) {
      if (result?.data?.status === ERROR_CODES.success) {
        // add to application history when uploading the legal document
        createApplicationsHistory(
          uploadDataObject?.applicationId,
          `Credit Admin document reference upload`,
          null,
          uploadDataObject
        );

        return { status: true, data: result?.data };
      } else {
        let message;
        if (result?.data?.error) {
          message = result?.data?.error;
        } else {
          message = "Something went wrong";
        }
        return { status: false, message };
      }
    }
  } catch (error: any) {
    if (error?.response?.status === FORBIDDEN_CODE) {
      userSessionDelete();
    }

    return { status: false, message: "Something went wrong" };
  }
};

export const getGroupList = async () => {
  try {
    const res = await CachingStorage.invoke("get-group-list", () =>
      api.get(`${URL.REACT_APP_BACKEND_HOST}/los/get-group-list`)
    );
    return res;
  } catch (error) {
    return null;
  }
};

export const getSectionDocuments = async (params: any) => {
  try {
    const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/get-application-section-document`, params);

    if (response?.status === ERROR_CODES.success) {
      return response?.data;
    }
  } catch (error) {
    return null;
  }
};

export const getSectionSingleDocument = async (params: any, progress) => {
  try {
    const response = await api.get(
      `${URL.REACT_APP_BACKEND_HOST}/los/get-application-section-single-document`,
      params,
      undefined,
      progress
    );

    return response;
  } catch (error) {
    return null;
  }
};

export const applicationTimelineApi = async (params?: any) => {
  try {
    const response = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/applications/timeline`, params);

    return response;
  } catch (error) {
    return null;
  }
};

export const getApplicationTimelineApi = async (params?: any) => {
  try {
    const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/applications/timeline`, params);

    return response;
  } catch (error) {
    return null;
  }
};

export const getApplicationHistoryDetails = async (params?: any) => {
  try {
    const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/applications/history/details`, params);

    return response;
  } catch (error) {
    return null;
  }
};

export const getApplicationHistoryFilteredData = async (params?: any) => {
  try {
    const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/applications/history`, params);

    return response;
  } catch (error) {
    return null;
  }
};

export const getApplicationHistoryFieldsData = async (params?: any) => {
  try {
    const response = await api.post(getApplicationHistoryFields, params);
    if (response.status === 1) {
      return response.data;
    }
    return { old: {}, new: {} };
  } catch (error) {
    return null;
  }
};

export const getNotifications = async (params?: any) => {
  try {
    const response = await api.get(notificationsApiUrl, params);
    if (response.status === 1) {
      return response?.data?.data;
    }
  } catch (error) {
    return null;
  }
};

export const updateNotifications = async (params?: any) => {
  try {
    const response = await api.patch(notificationsApiUrl, params);
    return response;
  } catch (error) {
    return null;
  }
};

export const saveVerificationChecklistStatus = async (params?: any) => {
  try {
    const response = await api.post(saveChecklistStatus, params);
    return response;
  } catch (error) {
    return null;
  }
};

export const getTradeCreditApplicationData = async (applicationId) => {
  try {
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/trade-credit-service/credit-details`, {
      applicationId: applicationId,
    });
    return data;
  } catch (error) { }
};

export const getLeadApprovalAmount = async (params) => {
  try {
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/lead/approval/limit`, params);
    return data;
  } catch (error) {
    return null;
  }
};

export const updateApplicationResponse = async (params) => {
  try {
    const response = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/lead/approval`, params);

    return response;
  } catch (error) {
    return null;
  }
};

export const updatePromissoryAmount = async (params) => {
  try {
    const response = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/update-promissory-amount`, params);

    return response;
  } catch (error) {
    return null;
  }
};

export const getStageColors = async () => {
  try {
    const data = await CachingStorage.invoke("getStageColors", () =>
      api.get(`${URL.REACT_APP_BACKEND_HOST}/los/workflow/stages/decorations`)
    );
    if (data) {
      return data;
    }
    return;
  } catch (error) { }
};

export const getCribNote = async (payload) => {
  try {
    if (!payload.applicationId) {
      throw new Error("Application id can not be empty.");
    }
    let url = applicationCribNote;
    if (payload.applicationId) {
      url = url + "?applicationId=" + payload.applicationId;
    }
    if (payload.participantId) {
      url = url + "&participantId=" + payload.participantId;
    }
    const { status, data } = await api.get(url);
    if (status === 1) {
      return data;
    }
    throw new Error("Failed to process request.");
  } catch (error) {
    throw error;
  }
};

export const addCribNote = async (payload) => {
  try {
    if (!payload.applicationId) {
      throw new Error("Application id can not be empty.");
    }
    let url = applicationCribNote;
    const { status, data } = await api.post(url, payload);
    if (status === 1) {
      return data;
    }
    throw new Error("Failed to process request.");
  } catch (error) {
    throw error;
  }
};

export const editCribNote = async (payload) => {
  try {
    if (!payload.applicationId) {
      throw new Error("Application id can not be empty.");
    }
    let url = applicationCribNote;
    const { status, data } = await api.post(url, payload);
    if (status === 1) {
      return data;
    }
    throw new Error("Failed to process request.");
  } catch (error) {
    throw error;
  }
};

export const getAssociatedLoans = async (applicationId) => {
  try {
    const data = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/loan/associations?applicationId=${applicationId}`);
    if (data) {
      return data;
    }
    return;
  } catch (error) { }
};

export const getReferSubFlowList = async (applicationId, groupId = 0) => {
  try {
    let params: any = {
      applicationId: applicationId,
    };

    if (groupId && groupId !== 0) {
      params = {
        applicationId: applicationId,
        groupId: groupId,
      };
    }

    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/workflow-engine/subworkflow/application`, {
      ...params,
    });
    return data;
  } catch (error) { }
};

export const getSubFlowNextAction = async (data) => {
  try {
    const { data: _response, status: _status } = await api.get(
      `${URL.REACT_APP_BACKEND_HOST}/workflow-engine/subworkflow/next/action`,
      {
        ...data,
      }
    );

    if (_status === 1) {
      return _response;
    }

    return {};
  } catch (error) { }
};

export const updateSubFlowReferral = async (data) => {
  try {
    const response = await api.patch(`${URL.REACT_APP_BACKEND_HOST}/workflow-engine/subworkflow/update/referral`, {
      ...data,
    });

    return response;
  } catch (error) { }
};

export const subFlowReferralReverts = async (data) => {
  try {
    const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/workflow-engine/subworkflow/referral/reverts`, {
      ...data,
    });

    return response;
  } catch (error) { }
};

export const subflowReferralHistory = async (data) => {
  try {
    const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/workflow-engine/subworkflow/referral/history`, {
      ...data,
    });

    return response;
  } catch (error) { }
};

export const detachSubflowReferral = async (params) => {
  try {
    const data = await api.patch(`${detachSubflowReferralUrl}`, { ...params });

    if (data.status === ERROR_CODES.success) {
      return data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getSubflowMetaData = async (params) => {
  try {
    const data = await api.get(`${subworkflowMetaUrl}`, { ...params });

    return data?.data;
  } catch (error) {
    return null;
  }
}

export const deleteCribDocument = async (params) => {
  try {
    const data = await api.delete(`${cribDocumentServiceRequest}`, { ...params });

    return data?.data;
  } catch (error) {
    return null;
  }
}