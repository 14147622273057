import { Downgraded } from "@hookstate/core";
import Axios from "axios";
import URL from "../configs/connection";
import { endpoints } from "../configs/endpoints";
import { userDetails } from "../configs/mainStore";
import { loadStructureNew } from "../configs/structure";
import Api from "./apiCallsHandler";
import CachingStorage from "./cacheStorage";
const api = Api.getApi();

export const getProductsDetails = async () => {
  return await loadStructureNew();
};

export const getImages = (token, id) => {
  return Axios.get(`${URL.REACT_APP_DOCUMENT_UPLOAD}document-upload/getImages`, {
    headers: {
      token: token,
      applicantId: id,
    },
  });
};

export const getApplications = async (params) => {
  const { userId, userInGroups }: any = userDetails.attach(Downgraded).get();

  if (userId) {
    params.userId = userId;
  }

  if (userInGroups && userInGroups instanceof Array && userInGroups.length > 0) {
    for (const group of userInGroups) {
      if (group.groupName === "System Admin") {
        params.usePermittedBranch = 0;
      }
    }
  }

  try {
    if (Object.keys(params).length > 0) {
      return await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/applications`, {
        params,
      });
    } else {
      const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/applications`);
      return response;
    }
  } catch (error) { }
};

export const getApplicationsPriorityList = async (params) => {
  const { userId }: any = userDetails.attach(Downgraded).get();

  if (userId) {
    params.userId = userId;
  }

  try {
    if (Object.keys(params).length > 0) {
      const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/applications/priority/extract`, {
        params,
      });
      return response;
    } else {
      const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/applications`);
      return response;
    }
  } catch (error) { }
};

export const getLeads = async (params) => {
  const { userId }: any = userDetails.attach(Downgraded).get();

  if (userId) {
    params.userId = userId;
  }

  try {
    if (Object.keys(params).length > 0) {
      const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/leads`, {
        params,
      });
      return response;
    } else {
      const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/leads`);
      return response;
    }
  } catch (error) { }
};

export const getReferrals = async (params) => {
  const { userId }: any = userDetails.attach(Downgraded).get();

  if (userId) {
    params.userId = userId;
  }

  try {
    if (Object.keys(params).length > 0) {
      const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/application-referrals`, {
        params,
      });
      return response;
    } else {
      const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/application-referrals`);
      return response;
    }
  } catch (error) { }
};

export const getTasks = async (params) => {
  const { userId }: any = userDetails.attach(Downgraded).get();

  if (userId) {
    params.userId = userId;
  }

  try {
    if (Object.keys(params).length > 0) {
      const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/application-tasks`, {
        params,
      });
      return response;
    } else {
      const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/application-tasks`);
      return response;
    }
  } catch (error) { }
};

export const getInitiatedByOwn = async (params) => {
  const { userId }: any = userDetails.attach(Downgraded).get();

  if (userId) {
    params.userId = userId;
  }

  try {
    if (Object.keys(params).length > 0) {
      const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/applications-by-own`, {
        params,
      });
      return response;
    } else {
      const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/applications-by-own`);
      return response;
    }
  } catch (error) { }
};

export const getPriorDetails = async (groupId, status, loggedInUser) => {
  // let { userId }: any = userDetails.attach(Downgraded).get();
  try {
    const response = await api.get(
      `${URL.REACT_APP_BACKEND_HOST}/los/prior/approvals?groupId=${groupId}&&status=${status}&&loggedInUser=${loggedInUser}`
    );
    if (response) {
      return response;
    } else {
      return { status: -1 };
    }
  } catch (error) {
    console.error(error);
  }
};

export const getPriorDocumentList = async () => {
  // let { userId }: any = userDetails.attach(Downgraded).get();
  try {
    const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/prior/approval/application/document`);
    if (response) {
      return response;
    } else {
      return { status: -1 };
    }
  } catch (error) {
    console.error(error);
  }
};

export const getApplicationLeadData = async (requestId) => {
  try {
    const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/applications/request?requestId=${requestId}`);
    if (response) {
      return response;
    } else {
      return { status: -1 };
    }
  } catch (error) {
    console.error(error);
  }
};

export const createPriorDetails = async (param = {}) => {
  let { userId }: any = userDetails.attach(Downgraded).get();
  if (!param || Object.keys(param).length === 0) {
    return { status: -1 };
  }

  try {
    const response = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/prior/approvals?userId=${userId}`, { ...param });
    if (response) {
      return response;
    } else {
      return { status: -1 };
    }
  } catch (error) {
    console.error(error);
  }
};

export const getInitatedByChannel = async (params) => {
  const { userId }: any = userDetails.attach(Downgraded).get();

  if (userId) {
    params.userId = userId;
  }

  try {
    if (Object.keys(params).length > 0) {
      const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/trade-credit-service/applications/channel`, {
        params,
      });
      return response;
    }
  } catch (error) {
    console.error(error);
  }
};

export const getInitiatedByBranch = async (params) => {
  const userState: any = userDetails.attach(Downgraded).get();

  if (userState?.userDetails?.branch) {
    params.branch = userState?.userDetails?.branch;
  }

  try {
    if (Object.keys(params).length > 0) {
      const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/applications-by-branch`, {
        params,
      });
      return response;
    } else {
      const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/applications-by-branch`);
      return response;
    }
  } catch (error) { }
};

export const getApplicationsVisibleToBranch = async (obj) => {
  const { groupId, userId, currentPage, pageSize, filterType, payload } = obj;
  const params = { ...obj.selection };
  let startIndex = "";
  let endIndex = "";

  if (payload) {
    startIndex = payload?.startIndex;
    endIndex = payload?.endIndex;
  }
  try {
    const stages = params?.workflowStage
      ? [params?.workflowStage]
      : filterType == 2
        ? [22, 21]
        : filterType == 3
          ? [28]
          : [];
    if (groupId) {
      const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/applications-to-branch`, {
        params,
        groupId,
        userId,
        currentPage: currentPage,
        pageSize: pageSize,
        workflowStage: stages,
        startIndex,
        endIndex
      });
      return response;
    } else {
      const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/applications-to-branch`);
      return response;
    }
  } catch (error) { }
};

export const getDisbursedApplication = async (obj) => {
  const { groupId, userId, currentPage, pageSize, filterType, minIndex, maxIndex } = obj;
  const params = { ...obj.selection };
  try {
    const stages = params?.workflowStage
      ? [params?.workflowStage]
      : filterType == 2
        ? [22, 21]
        : filterType == 3
          ? [28]
          : [];
    const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/disbursed-application-search`, {
      ...params,
      groupId,
      userId,
      currentPage: currentPage,
      pageSize: pageSize,
      workflowStage: stages,
      startIndex: minIndex,
      endIndex: maxIndex,
    });
    return response;
  } catch (error) { }
};

export const getApplicationsChangeAssignee = async (payload, startIndex, endIndex) => {
  try {
    const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/applications-change-assignee`, {
      groupId: payload.group,
      permittedBranch: payload.branch,
      userId: payload.user,
      hub: payload.hub,
      startIndex,
      endIndex,
      branch: payload.branch
    });
    return response;
  } catch (error) { }
};

export const getTaskAssigneeRequest = async (applicationId) => {
  try {
    const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/task/assignee/request`, {
      applicationId,
    });
    return response;
  } catch (error) { }
};

export const saveTaskAssignee = async (payload) => {
  try {
    const response = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/task/assignee/request`, payload);
    return response;
  } catch (error) { }
};

export const getFilteredApplications = async (params, currentPage, pageSize, filterType) => {
  // filterType : 1=> no specific filters , 2=> rejected pr withdrawn application 3=> disbursed application
  // TODO stage is retrieved directly and this is a temporary solution, should replace with key : by adding key column to stage table

  const { userInGroups }: any = userDetails.attach(Downgraded).get();

  try {
    const stages = params?.workflowStage
      ? [params?.workflowStage]
      : filterType == 2
        ? [22, 21]
        : filterType == 3
          ? [28]
          : [];

    if (userInGroups && userInGroups instanceof Array && userInGroups.length > 0) {
      for (const group of userInGroups) {
        if (group.groupName === "System Admin") {
          params.usePermittedBranch = 0;
        }
      }
    }

    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/filtered-applications`, {
      ...params,
      status: 2,
      currentPage: currentPage,
      pageSize: pageSize,
      workflowStage: stages,
    });
    return data;
  } catch (error) { }
};

export const getFilteredWithdrawnApplicationsList = async (params, currentPage, pageSize, filterType) => {
  try {
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/filtered/withdrawn/applications`, {
      ...params,
      status: 2,
      currentPage: currentPage,
      pageSize: pageSize,
    });
    return data;
  } catch (error) { }
};

export const getStatusReportApplications = async (params, currentPage, pageSize, filterType) => {
  // filterType : 1=> no specific filters , 2=> rejected pr withdrawn application 3=> disbursed application
  // TODO stage is retrieved directly and this is a temporary solution, should replace with key : by adding key column to stage table

  const { userInGroups }: any = userDetails.attach(Downgraded).get();

  try {
    const stages = params?.workflowStage
      ? [params?.workflowStage]
      : filterType == 2
        ? [22, 21]
        : filterType == 3
          ? [28]
          : [];

    if (userInGroups && userInGroups instanceof Array && userInGroups.length > 0) {
      for (const group of userInGroups) {
        if (group.groupName === "System Admin") {
          params.usePermittedBranch = 0;
        }
      }
    }

    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/status/report/applications`, {
      ...params,
      status: 2,
      currentPage: currentPage,
      pageSize: pageSize,
      workflowStage: stages,
    });
    return data;
  } catch (error) { }
};

export const getFilteredScoreCardReports = async (params, currentPage, pageSize, filterType) => {
  const { userInGroups }: any = userDetails.attach(Downgraded).get();

  try {
    const stages = params?.workflowStage
      ? [params?.workflowStage]
      : filterType == 2
        ? [22, 21]
        : filterType == 3
          ? [28]
          : [];

    if (userInGroups && userInGroups instanceof Array && userInGroups.length > 0) {
      for (const group of userInGroups) {
        if (group.groupName === "System Admin") {
          params.usePermittedBranch = 0;
        }
      }
    }

    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/scorecard/reports`, {
      ...params,
      status: 2,
      currentPage: currentPage,
      pageSize: pageSize,
      workflowStage: stages,
    });
    return data;
  } catch (error) { }
};

export const getLeadFilteredApplications = async (params, currentPage, pageSize, filterType, status) => {
  const { userInGroups }: any = userDetails.attach(Downgraded).get();

  try {
    const stages = params?.workflowStage
      ? [params?.workflowStage]
      : filterType == 2
        ? [22, 21]
        : filterType == 3
          ? [28]
          : [];

    if (userInGroups && userInGroups instanceof Array && userInGroups.length > 0) {
      for (const group of userInGroups) {
        if (group.groupName === "System Admin") {
          params.usePermittedBranch = 0;
        }
      }
    }

    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/filtered-applications`, {
      ...params,
      currentPage: currentPage,
      pageSize: pageSize,
      workflowStage: stages,
      status: status,
    });
    return data;
  } catch (error) { }
};

export const getPendingSubmissionApplications = async (params, currentPage, pageSize, filterType, status) => {
  const { userInGroups }: any = userDetails.attach(Downgraded).get();

  try {
    const stages = params?.workflowStage
      ? [params?.workflowStage]
      : filterType == 2
        ? [22, 21]
        : filterType == 3
          ? [28]
          : [];

    if (userInGroups && userInGroups instanceof Array && userInGroups.length > 0) {
      for (const group of userInGroups) {
        if (group.groupName === "System Admin") {
          params.usePermittedBranch = 0;
        }
      }
    }

    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/pendingApplication`, {
      ...params,
      currentPage: currentPage,
      pageSize: pageSize,
      workflowStage: stages,
      status: status,
      mode: "advanced-search"
    });
    return data;
  } catch (error) { }
};

export const getProductCode = async (params) => {
  try {
    const productId = params.productId;
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/applications/products/details?id=${productId}`);
    return data;
  } catch (error) { }
};

export const updateProductCode = async (params) => {
  try {
    const body = {
      productId: params.productId,
      paymentFactor: params.paymentFactor,
    };
    const { data } = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/applications/products/details`, body);
    return data;
  } catch (error) { }
};

export const updateProductDetails = async (params) => {
  try {
    if (!params.productId) {
      throw new Error("Product ID required.");
    }
    //   {
    //     "productId": 42,
    //     "minimumLoanAmount": 100000,
    //     "maximumLoanAmount": 4000000,
    //     "minimumInterestRate": 10,
    //     "maximumInterestRate": 10,
    //     "minimumLoanTerm": 10,
    //     "maximumLoanTerm": 10,
    //     "productCode": 10,
    //     "regularPaymentFactor": 10,
    //     "salaryPropotionFactor": 50
    // }
    const body = {
      ...params,
      productId: params.productId,
    };
    const { data } = await api.put(`${URL.REACT_APP_BACKEND_HOST}/los/products/meta`, body);
    return data;
  } catch (error) { }
};

export const getProductInfo = async (params) => {
  try {
    if (!params) {
      throw Error("params can not be empty");
    }

    const { data } = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/applications/products/info`, params);
    return data;
  } catch (error) { }
};

export const getUserGroupsForReports = async (params = null) => {
  try {
    return (
      (await CachingStorage.invoke("reports", () => api.get(`${URL.REACT_APP_BACKEND_HOST}/los/masterdata/reports`))) ??
      {}
    );
  } catch (error) { }
};

export const postUserGroupsForReports = async (params) => {
  try {
    const response = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/masterdata/reports`, {
      ...params,
    });

    return response;
  } catch (error) { }
};

export const getSystemMasterConfigs = async (params) => {
  try {
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/masterdata/configs`, {
      ...params,
    });

    return data;
  } catch (error) {
    return null;
  }
};

export const getCauReferData = async () => {
  try {
    const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/cau/refer`);

    return response;
  } catch (error) { }
};

export const getPriorApprovalMetaList = async (applicationId) => {
  try {
    const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/prior/approvals?applicationId=${applicationId}`);

    return response;
  } catch (error) { }
};

export const deleteAllJointBorrowersFromDTL = async (params) => {
  try {
    const response = await api.post(
      `${URL.REACT_APP_BACKEND_HOST}/los/update/application/json?type=jointBorrowerData&requestId=${params.requestId}`,
      {}
    );

    return response;
  } catch (error) { }
};

export const applicationFormSectionDetailsDelete = async (params) => {
  try {
    if (!params.applicationId) {
      throw new Error("Application ID required.");
    } else if (!params.sectionTravers) {
      throw new Error("Missing required data.");
    }

    const body = {
      ...params,
    };

    const { data } = await api.put(`${URL.REACT_APP_BACKEND_HOST}/los/application/delete/subsection`, body);
    return data;
  } catch (error) { }
};

export const getDgmUsersList = async () => {
  try {
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/lead/approvals/users`, {});
    return data;
  } catch (error) { }
};

export const saveCreditData = async (creditDetails, requestId) => {
  try {
    const response = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/lead/credit/details`, {
      creditDetails,
      requestId,
    });
    return response;
  } catch (error) { }
};

export const getLeadCreditData = async (requestId) => {
  try {
    const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/lead/credit/details`, { requestId });
    return response.data;
  } catch (error) { }
};

export const unlockApplication = async (applicationId) => {
  try {
    const response = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/applications/unlock`, { applicationId });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getApplicationsSections = async () => {
  try {
    const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/products/applications/structure/fields`);
    if (response.status === 1) {
      return response.data;
    }
  } catch (error) { }
};

export const getApplicationJson = async (applicationId) => {
  try {
    const url = endpoints.applicationJsonData;
    if (!applicationId) return null;
    const res = await api.get(url + applicationId);
    if (res.status === 1) {
      return res.data;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const updateApplicationJson = async (applicationId, data) => {
  try {
    const url = endpoints.applicationJsonData;
    if (!applicationId) return null;
    const res = await api.post(url + applicationId, data);
    if (res.status === 1) {
      return res.data;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getReferalSubworkflow = async (catalogKey) => {
  try {
    const url = endpoints.getSubWorkflows;
    if (!catalogKey) return null;
    const res = await api.get(url, { catalogKey });
    if (res.status === 1) {
      return res.data;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const createReferalSubworkflowReferral = async (data) => {
  try {
    const url = endpoints.createSubWorkflowsReferral;
    if (!data) return null;
    const res = await api.post(url, data);
    return res;
  } catch (error) {
    return null;
  }
};

export const getApplicationsVisibleToGroup = async (params, groupIds = [], currentPage, pageSize) => {
  try {
    if (groupIds) {
      try {
        const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/applications-to-branch`, {
          ...params,
          groupIds,
          currentPage,
          pageSize
        });
        return response;
      } catch (error) { }
    }
  } catch (error) { }
}

export const saveWorkFlowJobTrigger = async (params) => {

  try {
    const response = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/workflow/stage/job/triggers`, { params })
    return response;
  } catch (error) { }
}

export const deleteWorkFlowJobTriggerById = async (templateId) => {

  try {
    const response = await api.delete(`${URL.REACT_APP_BACKEND_HOST}/los/workflow/stage/job/triggers`, { templateId })
    return response;
  } catch (error) { }
}

export const getTemplateList = async (params) => {

  try {
    const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/workflow/stage/job/triggers`, { ...params })
    return response;
  } catch (error) { }
}

export const addApplicationGroups = async ({ applicationGroup }) => {
  try {
    const response = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/applications/groups`, { ...applicationGroup.get() })
    return response;
  } catch (error) {
    throw error;
  }
}

export const fetchApplicationHistoryData = async ({ oldNic, newNic }) => {
  try {
    const { data = [] } = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/application/references`, { oldNic, newNic });
    return data;
  } catch (error) {
    throw error;
  }
}

export const fetchApplicationDataCloneService = async ({ payload }) => {
  try {
    const { data = [] } = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/application/data/clone`, { ...payload });
    return data;
  } catch (error) {
    throw error;
  }
}