import { Downgraded, useHookstate } from "@hookstate/core";
import CloseIcon from "@mui/icons-material/Close";
import { Divider, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { ERROR_CODES } from "../../../../configs/constants/errorCode";
import {
  getProductPreferences,
  getStageConfigs,
  updateProductPreferences,
  updateStageConfigs
} from "../../../../services/productApis";
import { ButtonComponent } from "../../../InputComponents/ButtonComponent/ButtonComponent";
import { CheckBoxComponent } from "../../../InputComponents/CheckBoxComponent/CheckBoxComponent";
import { closeGlobalModal } from "../../GlobalModal/GlobalModal";
import { addToaster } from "../../GlobalToast";
import { CircularProgressComponent } from "../../ProgressComponent/ProgressComponent";
import { AssignTabControlContainer } from "../WorkflowOverview/AssignTabControlContainer";
import WorkflowStageOrderConfig from "../WorkflowOverview/WorkflowStageOrderConfig";
import StageConfiguration from "./StageConfiguration";

const payload = {
  startStage: null,
  disbursementStage: null,
  endStages: [],
  productId: 0,
  workflowId: null,
};

const ProductPreferences = (params) => {
  const [isChecked, setIsChecked] = React.useState<any>(false);
  const [isLoading, setIsLoading] = React.useState<any>(false);
  const stage: any = useHookstate(payload);
  const tab: any = useHookstate(1);
  const [updating, setUpdating]: any = React.useState(false);

  const options = useHookstate([
    { "title": "Preferences", "selected": true, id: 1 },
    { "title": "Stage Order", "selected": false, id: 2 },
    { "title": "Tab Config", "selected": false, id: 3 },
  ]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const productDataPreferences = await getProductPreferences(params.id);

      if (!!productDataPreferences) {
        setIsChecked(productDataPreferences?.DISB_TYPE == 1 ? true : false);
      }
      loadStageConfigs();
      setIsLoading(false);
    })();
  }, []);

  const closeCondition = () => {
    closeGlobalModal();
  };

  const handleAssignButtonClick = async () => {
    let dataPayload = stage.attach(Downgraded).get();

    dataPayload["startStage"] = parseInt(dataPayload.startStage);
    dataPayload["endStages"] = dataPayload.endStages.join(",") || "";
    dataPayload["disbursementStage"] = parseInt(dataPayload.disbursementStage);
    dataPayload["workflowId"] = parseInt(params.workflowId);
    dataPayload["productId"] = parseInt(dataPayload.productId);

    let alertPayload = {};
    try {
      setUpdating(true);
      stage.payload.get();
      const result = await updateStageConfigs(dataPayload);
      if (result.status === 1) {
        alertPayload = {
          status: "success",
          title: "Saved",
          message: `Stage Configs successfully saved !`,
        };
      }
    } catch (error) {
      alertPayload = {
        status: "error",
        title: "Error",
        message: "Something went wrong in updating Stage Configs !",
      };
    } finally {
      setUpdating(false);
    }
    return true;
  };

  const updatePreferences = async () => {
    try {
      setIsLoading(true);
      const payload = {
        status: !isChecked ? 1 : 0,
        id: params.id,
      };
      const updateData = await updateProductPreferences(payload);

      if (updateData?.status === ERROR_CODES.success) {
        setIsChecked(!isChecked);
        addToaster({
          status: "success",
          title: "Updated",
          message: "Preferences Updated",
        });
      }
    } catch (error) {
      addToaster({
        status: "error",
        title: "Error",
        message: "Couldn't update preferences",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const loadStageConfigs = async () => {
    try {
      setIsLoading(true);
      const defaultConfigs = await getStageConfigs(params.workflowId);
      let configs = {
        startStage: defaultConfigs.startStage,
        disbursementStage: defaultConfigs.disbursementStage,
        endStages: defaultConfigs?.endStages?.split(",")?.map((item) => {
          return parseInt(item);
        }),
        productId: defaultConfigs.productId
      }

      stage.set(configs);
    } catch (error) {
      throw new Error("Failed to fetch records.");
    } finally {
      setIsLoading(false);
    }
  };

  const BasicPreferences = () => {
    return <Stack spacing={2}>
      <Stack direction={"row"} justifyContent={"space-between"} className={"data-box"}>
        <Typography
          className="align-center font-size-12 basic-font-color"
          alignSelf={"center"}
          marginLeft={2}
        >
          Multiple Disbursement{" "}
        </Typography>
        {isLoading ? (
          <Stack direction={"row"}>
            <CircularProgressComponent size={30} />
          </Stack>
        ) : (
          <Stack direction={"row"}>
            <CheckBoxComponent
              onChange={updatePreferences}
              checked={isChecked}
            />
          </Stack>
        )}
      </Stack>
      <Stack direction="column" className={"data-box"} py={1}>
        <Stack direction={"row"}>
          <Typography
            className="align-center font-size-12 basic-font-color"
            alignSelf={"center"}
            marginLeft={2}
            variant="caption"
          >
            Configure Start, Disburse and Lodge Stages
          </Typography>
        </Stack>
        <Stack direction={"row"}>
          <StageConfiguration payload={stage} loading={isLoading} />
        </Stack>
      </Stack>
      <Stack direction="row" spacing={2} justifyContent="end" sx={{ pt: 4 }}>
        <ButtonComponent
          title="Close"
          variant="contained"
          onClick={closeCondition}
          color="info"
        />
        <ButtonComponent
          title="Assign"
          variant="contained"
          onClick={handleAssignButtonClick}
          color="info"
        />
      </Stack>
    </Stack>;
  }


  const TabPanel = () => {
    const handleSelection = (selection) => {
      const list = options.attach(Downgraded).get();
      list.forEach((item) => {
        item.selected = false;
        if (selection.title === item.title) {
          item.selected = true;
        }
      });
      options.set(list);
      tab.set(selection.id);
    };

    return <Stack direction={"row"} height={"36px"}>
      {(options.get() || []).map((item, index) => <Stack key={index} flex={1}><ButtonComponent variant={item.selected ? "outlined" : "none"} title={item.title} onClick={() => handleSelection(item)} /></Stack>)}
    </Stack>;
  }

  return <Stack height={"calc(100vh - 120px)"} style={{ overflow: "hidden" }}>
    <Stack direction={"row"} alignItems={"center"}>
      <Stack flex={1}>
        <Typography
          sx={{ pb: 2, pt: 2 }}
        >
          Set Preferences for - {params?.value?.name || ""}
        </Typography>
      </Stack>
      <CloseIcon
        onClick={() => {
          closeGlobalModal();
        }}
        style={{
          fontSize: 24,
          cursor: "pointer"
        }}
      />
    </Stack>
    <Divider></Divider>
    <TabPanel></TabPanel>
    <Divider></Divider>
    <Stack display={tab.get() === 1 ? "flex" : "none"} flex={1}><BasicPreferences /></Stack>
    <Stack display={tab.get() === 2 ? "flex" : "none"} flex={1}>{tab.get() === 2 && <WorkflowStageOrderConfig payload={params} />}</Stack>
    <Stack display={tab.get() === 3 ? "flex" : "none"} flex={1}>{tab.get() === 3 && <AssignTabControlContainer value={params} />}</Stack>
  </Stack>;
};

export default ProductPreferences;
