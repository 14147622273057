import React, { FC, createRef, useEffect } from "react";



import { Downgraded, useState } from "@hookstate/core";
import { appSetting, userDetails } from "../../../configs/mainStore";
import { DocumentUploadSectionWrapper } from "../MainSectionsWrapper/DocumentUploadSectionWrapper";
import { SubSectionsWrapper } from "../SubSectionsWrapper/SubSectionsWrapper";
import { ApplicationDocumentUploadSection } from "./ApplicationDocumentUploadSection";

interface ProductDocumentUploadSubSectionProps {
  sectionObj?: any;
  applicantType?: any;
  applicationId?: any;
  participantId?: any;
  uploadedFiles?: any;
  documentTypes?: any;
  uploadedDocumentTypes?: any;
  documentSectionName?: any;
  mandatoryCount?: any;
  uploadedMandatoryCount?: any;
  documentComponentId?: any;
  personalData?: Object;
  creditFileData?: any;
  onSyncSuccess?: any;
  onSync?: any;
  sectionLoading?: Boolean;
  disbursed?: any;
  disableAccordingToTab?: any;
  refreshComponent?: any;
  reloadComponent?: any;
  isLoggedInUserNotTheAssignee?: any;
}

export const ProductDocumentUploadSubSection: FC<ProductDocumentUploadSubSectionProps> = ({
  applicationId,
  applicantType,
  documentTypes,
  uploadedDocumentTypes,
  documentSectionName,
  mandatoryCount,
  uploadedMandatoryCount,
  participantId,
  documentComponentId,
  personalData,
  creditFileData,
  onSyncSuccess,
  onSync,
  sectionLoading,
  disbursed,
  disableAccordingToTab = false,
  reloadComponent,
  isLoggedInUserNotTheAssignee,
}: any) => {
  const userDetailsData = useState(userDetails);
  const { userId }: any = userDetailsData.attach(Downgraded).get();

  const docUploadRef: any = createRef();

  const appSettings: any = useState(appSetting);

  const [sectionMeta, setSectionMeta] = React.useState<any>([]);
  const [primaryFiles, setPrimaryFiles] = React.useState<any>([]);
  const [normalFiles, setNormalFiles] = React.useState<any>([]);
  const [isUploading, setIsUploading] = React.useState(false);

  const [totalFilesFiles, setTotalFilesFiles] = React.useState(0);
  const [numOfUploadedFiles, setNumOfUploadedFiles] = React.useState(0);
  const [numOfUploadedMandatoryFiles, setNumOfUploadedMandatoryFiles] = React.useState(0);

  const [sectionTitle, setSectionTitle] = React.useState<any>("");

  const submitBtnClick = async () => {
    // setIsUploading(true);
    // const primaryDocUploadResult = await docUploadRef?.current?.primaryDocumentUploadFunc();
    const normalDocUploadResult = await docUploadRef?.current?.normalDocumentUploadFunc();

    if (normalDocUploadResult === true) {
      reloadComponent(true);
    }

    // nextBtnClicked && nextBtnClicked();
    // setIsUploading(false);
  };

  useEffect(() => {
    if (personalData) {
      setSectionTitle(
        personalData?.initialsInFull
          ? `${documentSectionName} - ${personalData?.initialsInFull}`
          : documentSectionName
      );
    } else {
      setSectionTitle(sectionMeta?.desc ? `${sectionMeta?.desc} Documents` : documentSectionName);
    }
  });

  return (
    <DocumentUploadSectionWrapper
      title={sectionTitle}
      keyCode={1}
      noBack={true}
      loading={isUploading}
      onNext={() => {
        submitBtnClick();
      }}
      totalFiles={documentTypes.length}
      numOfUploadedFiles={uploadedDocumentTypes.length}
      numOfMandatory={mandatoryCount}
      numOfUploadedMandatoryFiles={uploadedMandatoryCount}
      subTxt={
        sectionMeta?.desc
          ? `Upload ${sectionMeta?.desc} Documents`
          : `Upload ${documentSectionName}`
      }
      documentComponentId={documentComponentId}
      personalData={personalData}
      creditFileData={creditFileData}
      onSyncSuccess={onSyncSuccess}
      onSync={onSync}
      sectionLoading={sectionLoading}
      disableAccordingToTab={disableAccordingToTab}
      documentTypes={documentTypes}>
      <SubSectionsWrapper title="">
        <ApplicationDocumentUploadSection
          ref={docUploadRef}
          primaryFiles={primaryFiles}
          applicantType={applicantType}
          applicationId={applicationId}
          participantId={participantId}
          uploadedBy={userId}
          normalFiles={normalFiles}
          documentTypes={documentTypes}
          uploadedDocumentTypes={uploadedDocumentTypes}
          documentComponentId={documentComponentId}
          disbursed={disbursed}
          disableAccordingToTab={disableAccordingToTab}
          reloadComponent={reloadComponent}
          isLoggedInUserNotTheAssignee={isLoggedInUserNotTheAssignee}
        />
      </SubSectionsWrapper>
    </DocumentUploadSectionWrapper>
  );
};
