import { LengthItemValidate } from "../../../../../../../ApplicationForm/Sections/SecurityDetails/SecurityData/Helpers/LengthItemValidatedFn";

export class FinancialPositionOfTheCompanyController {

    totalRevenue = {};
    totalReductions = {};
    grossProfit = {};
    totalExpenses = {};
    totalExpensesWise = {};
    totalTaxes = {};
    netProfitAfterTax = {};
    netProfitBeforeTax = {};
    formData = {};
    years: Array<any> = [];

    // constructor() {}

    constructor() { }

    getIncludedMainKeys() {
        return this.years.filter(({ dataKey }) => this.formData.hasOwnProperty(dataKey))
    }

    deferencesCalculator(firstValue, secondValue) {
        let intermediateArray = this.getIncludedMainKeys();

        return intermediateArray.reduce((calObject, { dataKey }) => {
            return { ...calObject, [dataKey]: Number(firstValue[dataKey] ?? 0) - Number(secondValue[dataKey] ?? 0) }
        }, {})
    }

    calculateTotValues(dataArray, year) {
        let intermediateTotal = 0;
        let intermediateTotalObject = {};
        dataArray = LengthItemValidate.validatedArray(dataArray);

        dataArray.forEach((object) => {
            intermediateTotal = intermediateTotal + Number((object[year] ?? 0))

            intermediateTotalObject = {
                ...intermediateTotalObject,
                [year]: intermediateTotal
            }
        });

        return intermediateTotalObject
    }

    getTotals(array) {
        let total = {};
        let intermediateArray = this.getIncludedMainKeys();

        if (intermediateArray.length === 0) return 0;

        intermediateArray.forEach(({ dataKey }) => {

            for (const { dataKey: key } of array) {

                if (!this.formData[dataKey].hasOwnProperty(key)) {
                    continue;
                }

                total = {
                    ...total,
                    [dataKey]: (Number((this.formData[dataKey][key] ?? 0)) + Number(total[dataKey] ?? 0)) ?? 0
                }
            }
        });

        return total;
    }
}