import { Grid, Stack } from "@mui/material";
import React, { FC, useEffect } from "react";
import { useState } from "@hookstate/core";
import { createButton, MajorActionButton } from "../../../../styles";
import { ButtonComponent } from "../../../InputComponents/ButtonComponent/ButtonComponent";
import { openGlobalModal } from "../../GlobalModal/GlobalModal";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import { getReferalCauOfficerGroup } from "../../../../services/creditFileApiCall";
import { fetchPrivilegeGroups } from "../../../../services/configurationApis";
export interface ModalPropTypes {
  innerComponentData: any;
  applicationId?: any;
  currentApplicationWfData?: any;
  callBackOnAction?: any;
  IconComponent?: any;
  isPageRenderingClick?: any;
  modalSize?: any;
  disabled?: boolean;
  isRawVersion?: boolean;
  isOfficerGroup?: any;
  isLoading?: any;
  officerGroupId?: any;
  incomeData?: any;
  isLoggedInUserNotTheAssignee?: boolean;
  getCurrentWorkflowStage?: any;
  cauReferalData?: any;
  catalogKey?: any;
}

const InnerSectionHeaderComponent: FC<ModalPropTypes> = ({
  innerComponentData,
  applicationId,
  currentApplicationWfData,
  callBackOnAction,
  IconComponent,
  isPageRenderingClick,
  modalSize = "sm",
  disabled,
  isRawVersion,
  children = null,
  isOfficerGroup = false,
  isLoading = false,
  officerGroupId = null,
  incomeData = null,
  cauReferalData,
  catalogKey = "",
}) => {
  const [btnDisableStatus, setBtnDisableStatus]: any = React.useState(true);

  const onClickCreate = (modalBodyKey) => {
    // just calling the cb
    if (isPageRenderingClick) {
      if (callBackOnAction instanceof Function) {
        callBackOnAction();
      }
    } else {
      let modalId = "";
      if (modalBodyKey === "referral-modal") {
        if (currentApplicationWfData?.currentWorkflowStage === cauReferalData?.data?.ca_review_stage) {
          modalId = "officer-cau-referral-modal";
        } else if (isOfficerGroup === true) {
          modalId = "officer-head-refer-modal";
        } else {
          modalId = modalBodyKey;
        }
      } else {
        modalId = modalBodyKey;
      }
      // opening the modal
      openGlobalModal({
        modalSize: modalSize,
        title: "",
        bodyId: modalId,
        close: false,
        modalParams: {
          applicationId: applicationId,
          callBackOnAction: callBackOnAction,
          ...currentApplicationWfData,
          officerGroupId: officerGroupId,
          ...incomeData,
          cauReferalData: cauReferalData?.data,
          catalogKey: catalogKey,
        },
      });
    }
  };

  const getHeaderIcon = () => {
    const Icon = IconComponent ? IconComponent : AddCircleOutlineIcon;
    return <Icon />;
  };

  useEffect(() => {
    setBtnDisableStatus(disabled);
  }, [disabled]);

  const Suffix = ({ isRawVersion, innerComponentData }) => {
    return (
      <>
        {!isRawVersion && innerComponentData?.headerActionButton && (
          <Grid
            item
            xs={3}
            className="full-height full-width align-right"
            pr={1}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <ButtonComponent
              startIcon={getHeaderIcon()}
              title={innerComponentData?.headerActionButtonTitle}
              variant="contained"
              onClick={() => onClickCreate(innerComponentData?.modalBodyKey)}
              disabled={btnDisableStatus}
              loadingbtn={isLoading}
              loading={true}
              sx={{ alignSelf: "center" }}
            />
          </Grid>
        )}
      </>
    );
  };

  return (
    <Stack m={0}>
      <Grid container style={{ backgroundColor: "#e6f0fa" }} className="align-center">
        <Grid item xs>
          <div className="basic-font basic-font-color-bold font-size-16 padding-m align-left">
            {innerComponentData?.title}
          </div>
        </Grid>
        {/* {!isRawVersion && innerComponentData?.headerActionButton && (
          <Grid item xs={3} className="full-height full-width align-right" pr={1}>
            <ButtonComponent
              startIcon={getHeaderIcon()}
              title={innerComponentData?.headerActionButtonTitle}
              variant="contained"
              onClick={() => onClickCreate(innerComponentData?.modalBodyKey)}
              disabled={disabled}
              // loadingbtn={isLoading}
              // loading={false}
            />
          </Grid>
        )} */}

        {children != null ? (
          <>{children}</>
        ) : (
          <Suffix isRawVersion={isRawVersion} innerComponentData={innerComponentData} />
        )}
      </Grid>
      {/* <div className="full-height full-width"> {children} </div> */}
    </Stack>
  );
};

export default InnerSectionHeaderComponent;
