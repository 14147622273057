import React, { useEffect, useState } from "react";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { ButtonComponent } from "../../InputComponents/ButtonComponent/ButtonComponent";
import CloseIcon from "@mui/icons-material/Close";
import { closeGlobalModal } from "../../OtherComponents/GlobalModal/GlobalModal";
import { closeBtn } from "../../../styles";
import { ErrorMessageComponent } from "../../OtherComponents/ErrorMessageComponent";

const RemindHistoryModal = (params) => {
  const [reminderList, setReminderList]: any[] = React.useState(["1", "2"]);

  const closeReminderHistory = () => {
    closeGlobalModal();
  };

  return (
    <Box>
      <Stack>
        <Typography>
          <div className="basic-font font-size-18 basic-font-color-bold">History</div>
        </Typography>
      </Stack>
      <Stack direction={"column"}>
        {reminderList.length > 0 ? (
          reminderList.map((item) => (
            <Stack p={3} sx={{ background: "#eeeeee" }} mb={2}>
              Initiated application reminder email sent to - S.M.O.K Sakalasooriya by Subash Sanjeewa on 2023-09-11 05:06:15 PM
            </Stack>
          ))
        ) : (
          <ErrorMessageComponent headMessage={""} errorMessage={"No Data Available"} />
        )}
      </Stack>
      <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
        <ButtonComponent title="Close" startIcon={<CloseIcon />} variant="contained" onClick={() => closeReminderHistory()} loadingbtn={true} style={closeBtn} />
      </Stack>
    </Box>
  );
};

export default RemindHistoryModal;
