import React, { useEffect } from "react";
import {
  Paper,
  Grid,
  Stack,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Button,
} from "@mui/material";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";

import { appSetting, userDetails, workflowMasterData } from "../../../../../configs/mainStore";
import { dateFormatWithDate, dateFormatWithTimeChange } from "../../../../../utility/fomatters";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import { createButton } from "../../../../../styles";
import DetailItem from "../DetailItem";
import { openGlobalModal } from "../../../GlobalModal/GlobalModal";
import ForwardIcon from "@mui/icons-material/Forward";
import { referAction, referStatus } from "../../../../../utility/util";

import PersonIcon from "@mui/icons-material/Person";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import EmailIcon from "@mui/icons-material/Email";
import AddIcon from "@mui/icons-material/Add";
import HomeIcon from "@mui/icons-material/Home";
import { MASTER_DATA_KEYS } from "../../../../../configs/constants/masterDataKeys";
import { getUserDetailsById } from "../../../../../services/userService";
import PreviewIcon from "@mui/icons-material/Preview";
import { cribStatusColors } from "../../../WorkflowComponents/utils";
import { IconRowComponent } from "../IconRowComponent";
import { getAuditList, getReferList, getRiskList } from "../../../../../services/creditFileApiCall";
import { compare } from "../../../../../utility/other";

import { AnyContext, useState } from "@hookstate/core";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";
import { ErrorMessageComponent } from "../../../ErrorMessageComponent";
import ErrorIcon from "@mui/icons-material/Error";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { wrap } from "module";
import { disableAccess } from "../../../../../utility/helpers/ApplicationMandatoryValidations/isSecurityDocUploadApprovedPermitted";
import { previewBtnClicked } from "./ApplicationReviewAudit";
import { Downgraded } from "@hookstate/core";

interface DocumentPreviewPropTypes {
  applicationId?: any;
  innerComponentData?: any;
  tabsToEdit?: any;
  data?: any;
}

const AuditDocumentList = ({ applicationId, innerComponentData, tabsToEdit, data }: DocumentPreviewPropTypes) => {
  const appSettings: any = useState(appSetting);

  const [isLoading, setIsLoading] = React.useState(false);
  const [riskList, setRiskList] = React.useState([]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const { userId }: any = userDetails.attach(Downgraded).get();
  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;

  let numOfUploadedMandatoryFiles: any = 5;
  let numOfMandatory: any = 4;
  let statusColor: any = "rgb(213, 200, 28)"; // Should be dynamic

  const colorStatus: any = [
    {
      borderRadius: "5px",
      backgroundColor: "rgba(213, 200, 28, 0.24)",
      border: "1px solid rgb(213, 200, 28)",
    },
    {
      borderRadius: "5px",
      backgroundColor: "rgba(0, 255, 76, 0.24)",
      border: "1px solid rgb(0, 255, 76)",
    },
    {
      borderRadius: "5px",
      backgroundColor: "rgba(255, 144, 144, 0.24)",
      border: "1px solid rgb(255, 144, 144)",
    },
  ];

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      if (applicationId) {
        const data = await getAuditList(applicationId, null, true);

        if (data && data.length > 0) {
          const sortedList = data.sort((a, b) => compare(b, a, "referId"));
          setRiskList(sortedList);
        } else {
          setErrorMessage("No Risk Documents");
        }
      }
      setIsLoading(false);
    })();
  }, []);

  const viewDocument = (id) => {
    previewBtnClicked(id);
  };

  const documentDetailViewDispatcher = (obj: any) => {
    openGlobalModal({
      modalSize: "lg",
      title: "",
      bodyId: "risk-legal-insurancepolicy-document-preview",
      close: true,
      modalParams: {
        applicationId: applicationId,
        disableStatus: checkPrivilegeStatus,
        item: obj,
        callBackOnAction: viewDocument,
      },
    });
  };

  // const checkPrivilegeStatus = isLoggedInUserNotTheAssignee ? true : false;

  const checkPrivilegeStatus = false;

  return (
    <Grid container className="full-width" alignItems="center">
      {isLoading ? (
        <CircularProgressComponent
          size={30}
          sx={{
            marginTop: "30px",
            marginLeft: "auto",
            marginRight: "auto",
            zIndex: 100,
          }}
        />
      ) : errorMessage ? (
        <ErrorMessageComponent headMessage={"Empty"} errorMessage={errorMessage} />
      ) : (
        <Grid container p={1} className="data-box full-width" m={2} width={"100%"}>
          <Accordion style={{ width: "100%" }}>
            <AccordionSummary
              expandIcon={<KeyboardDoubleArrowUpIcon className="basic-font-color" fontSize="medium" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Grid container columns={12}>
                <Grid item xs={0.5}>
                  {numOfUploadedMandatoryFiles === numOfMandatory ? (
                    <CheckCircleIcon sx={{ color: "#00AB71" }} />
                  ) : (
                    <ErrorIcon sx={{ color: "#ffdd00" }} />
                  )}
                </Grid>
                <Grid item xs={9.5}>
                  <Typography>Audit Documents</Typography>
                </Grid>
                <Grid item xs={2} pl={0.75}>
                  <Box className={`document-upload-wrapper-text`} sx={{ textAlign: "left" }}>
                    <small className="basic-font opacity-7 font-size-14">Total: {riskList.length}</small>
                  </Box>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Stack>
                <Grid container gap={2}>
                  {riskList?.map((obj: any) => {
                    let referralTitle = obj?.title;
                    return (
                      <Grid item>
                        <Button
                          className="risk-legal-insurance-document-view-button"
                          style={colorStatus[obj.status]}
                          disabled={false}
                          onClick={() => documentDetailViewDispatcher(obj)}
                        >
                          <FindInPageIcon
                            style={{
                              color: statusColor,
                              fontSize: 40,
                              cursor: "pointer",
                            }}
                          />
                        </Button>
                        <Typography textAlign={"center"}>{`${referralTitle}`}</Typography>
                      </Grid>
                    );
                  })}
                </Grid>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}
    </Grid>
  );
};

export default AuditDocumentList;
