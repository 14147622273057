import { Stack } from "@mui/material";
import React from "react";
import IntermediateFormBuilder from "../../IntermediateFormBuilder";

const Column = ({ metaData, alphaChildren, value, onChange, otherParams }) => {
  return (
    <Stack direction={"column"} flexWrap={"wrap"} maxWidth={"100%"} flex={1}>
      {React.createElement(IntermediateFormBuilder, {
        formChildren: alphaChildren,
        value,
        onChange,
        otherParams,
      })}
    </Stack>
  );
};

export default Column;
