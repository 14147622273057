/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from "@hookstate/core";
import { Divider, Grid, Stack, Typography } from "@mui/material";
import { standardDateFormatter } from "los-util";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { employementOccupationArray } from "../../../../../../configs/constants/contants";
import { MASTER_DATA_KEYS } from "../../../../../../configs/constants/masterDataKeys";
import { langDictionary } from "../../../../../../configs/languageDictionaryEn";
import { appSetting, branchDetails, masterDataDetails } from "../../../../../../configs/mainStore";
import { languageListStore } from "../../../../../../configs/stores/languageStore";
import { getSCVClientDetails } from "../../../../../../services/bankServiceInvorker";
import AppConfig from "../../../../../../utility/AppConfig";
import isProductOf from "../../../../../../utility/AthamaruProductBusinessDetails/AthamaruComponentsValidator";
import cacheDepositedFieldValidator from "../../../../../../utility/CashDepositedFieldValidator";
import MasterDataProvider from "../../../../../../utility/MasterDataProvider";
import DetailItem from "../../DetailItem";
import DocumentToolTip from "../../Tooltips/DocumentToolTip";
import BuildApplicantCloseToRdb from "./BuildApplicantCloseToRdb";

const IdentificationDetailItems = ({ data, isMainContactDetails, isJointBurrower }) => {
  let personalData: any = data?.formData?.personalData;
  let dorinDoraAccDetails = data?.formData?.singleCustomerViewData?.dorinDoraAccount;
  const masterDataStore = MasterDataProvider.provideMasterData();
  const empOccupationArr = masterDataStore?.getMasterData(MASTER_DATA_KEYS?.OCCUPATION);
  const masterData: any = useState(masterDataDetails);
  const { communicationLanguageList }: any = useState(languageListStore);
  const appSettings: any = useState(appSetting);
  const title: any = masterData?.TITLE?.get()?.find((t) => t.id == personalData?.primaryTitle);
  const gender: any = masterData?.GENDER?.get()?.find((t) => t.id == personalData?.gender);
  const race: any = masterData?.RACE?.get()?.find((t) => t.id == personalData?.race);
  const status: any = masterData?.CIVIL_STATE?.get()?.find((t) => t.id == personalData?.civilState);
  const district: any = masterData?.DISTRICT?.get()?.find((t) => t.id == personalData?.district);
  const empStatus: any = masterData?.EMPLOYMENT_STATUS?.get()?.find((t) => t.id == personalData?.empStatus);
  const empOccupation: any = employementOccupationArray(empOccupationArr, false, personalData?.occupation);
  const relationshipToMainApplicant: any = masterData?.RELATION_WITH_MA?.get()?.find((t) => t.id == personalData?.relationshipToMainApplicantCode);
  const preferredLang = communicationLanguageList?.get()[appSettings?.get()?.language]?.[personalData?.preferredCommunicationLanguage];
  const selectedBranch = branchDetails.branchMap[personalData?.branch].get();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t: translate } = useTranslation();
  const [dorindorsAcc, setDorinDoraAcc] = React.useState<string>("");

  if (isProductOf({ primaryApplicant: isMainContactDetails })) {
    personalData = {
      ...personalData,
      dorinDoraAccDetails: dorinDoraAccDetails,
    };
  }

  useEffect(() => {
    if (isProductOf({ primaryApplicant: isMainContactDetails })) {
      (async function () {
        if (dorinDoraAccDetails) {
          let nic: any = personalData?.oldNic ? personalData?.oldNic : personalData?.newNic;
          const scvResponse = await getSCVClientDetails(nic);

          if (scvResponse) {
            const savings = scvResponse?.scvDetails?.savings || [];
            if (savings?.length > 0) {
              setDorinDoraAcc(savings[dorinDoraAccDetails]?.accountNumber || "");
            } else {
              setDorinDoraAcc("");
            }
          } else {
            setDorinDoraAcc("");
          }
        }
      })();
    }
  }, [isMainContactDetails, dorinDoraAccDetails]);

  return <Stack>
    <Stack p={1} px={2} style={{ backgroundColor: "#e6f0fa" }}>
      <Typography variant="subtitle1" color={"gray"}>Primary Identification Details</Typography>
    </Stack>
    <Grid container className="data-box" spacing={1}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <DetailItem title="Title" value={title?.name ? title?.name : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
          </Grid>
          <Grid item xs={3}>
            <DetailItem title="Initials in Full" value={personalData?.initialsInFull ? personalData?.initialsInFull : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
          </Grid>
          <Grid item xs={5}>
            <DetailItem title="Last Name" value={personalData?.primaryLastName ? personalData?.primaryLastName : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <DetailItem title="Used Name" value={personalData?.usedName ? personalData?.usedName : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
          </Grid>
          <Grid item xs={3}>
            <DetailItem title="Initials" value={personalData?.initials ? personalData?.initials : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
          </Grid>
          <Grid item xs={3}>
            <DetailItem title="Other Names" value={personalData?.primaryOtherName ? personalData?.primaryOtherName : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          {personalData?.oldNic && (
            <Grid item xs={3}>
              {AppConfig.control ? <DocumentToolTip applicantType={1} documentId={110}>

                <DetailItem title="Old NIC" value={personalData.oldNic ? personalData?.oldNic : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
              </DocumentToolTip> : <DetailItem title="Old NIC" value={personalData.oldNic ? personalData?.oldNic : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />}
            </Grid>
          )}
          {personalData?.oldNicExp && (
            <Grid item xs={3}>
              <DetailItem title="Issued Date (DD/MM/YYYY)" value={personalData?.oldNicExp ? personalData.oldNicExp : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
            </Grid>
          )}
          {personalData?.newNic && (
            <Grid item xs={3}>
              {AppConfig.control ? <DocumentToolTip applicantType={1} documentId={110}>
                <DetailItem title="New NIC" value={personalData?.newNic ? personalData?.newNic : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
              </DocumentToolTip> : <DetailItem title="New NIC" value={personalData.newNic ? personalData?.newNic : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />}
            </Grid>
          )}
          {personalData?.newNicExp && (
            <Grid item xs={3}>
              <DetailItem title="Issued Date (DD/MM/YYYY)" value={personalData?.newNicExp ? personalData.newNicExp : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <DetailItem title="Civil Status" value={status?.name ? status?.name : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
          </Grid>
          <Grid item xs={3}>
            <DetailItem title="Gender" value={gender?.name ? gender?.name : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
          </Grid>
          {dorindorsAcc ? (
            <Grid item xs={3}>
              <DetailItem title="Dorin Dora Account Number" value={dorindorsAcc} valueFontClass="font-size-14" titleFontClass="font-size-12" />
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={3}>
            <DetailItem title="Date of Birth (DD/MM/YYYY)" value={personalData?.dob ? personalData?.dob : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
          </Grid>
          <Grid item xs={3}>
            <DetailItem title="Age" value={personalData?.dob ? `${personalData?.years} Years and ${personalData?.months} Months` : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
          </Grid>
          <Grid item xs={3}>
            <DetailItem title={translate(langDictionary?.areYouASriLankanCitizen)} value={personalData?.sriLankanCitizenship == 1 ? `Yes, By ${personalData?.sriLankanCitizenshipStatus == 1 ? "Birth" : "Registration"}` : "No"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
          </Grid>
          {personalData?.relationshipToMainApplicantCode && (
            <Grid item xs={6}>
              <DetailItem title="Relationship with the main applicant" value={relationshipToMainApplicant?.name ? relationshipToMainApplicant?.name : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
            </Grid>
          )}

          <Stack display={isMainContactDetails ? "flex" : "none"} width={"100%"} direction={"row"} p={1} spacing={1}>
            <Stack flex={1}>
              <DetailItem title="Race" value={race?.name ? race?.name : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
            </Stack>
            <Stack flex={1}>
              <DetailItem title="Preferred Communication Language" value={personalData?.preferredCommunicationLanguage ? preferredLang?.DESCRIPTION : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
            </Stack>
            <Stack flex={1}>
              <DetailItem title="Nearest RDB District" value={district?.name ? district?.name : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
            </Stack>
            <Stack flex={1}>
              <DetailItem title="Nearest RDB Branch" value={selectedBranch?.name ? selectedBranch?.name : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <Stack width={"100%"} p={1}>
        <Stack flex={1} width={"100%"} display={isMainContactDetails || isJointBurrower ? "flex" : "none"}>
          <DetailItem title={translate(langDictionary?.doesTheApplicantHasCloseRelation)} value={personalData?.isApplicantCloseToRdb === 1 ? "Yes" : "No"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
          <BuildApplicantCloseToRdb personalData={personalData} masterDataStore={masterDataStore} />
        </Stack>

        <Stack display={personalData?.idTypes?.length ? "flex" : "none"} mt={1}>
          <Stack>
            <Typography variant="subtitle2" color={"gray"}>Other Identification Details</Typography>
          </Stack>
          <Divider></Divider>
          <Stack mt={1}>
            {personalData?.idTypes?.map((idData, key) => {
              const idType = masterData?.ID_TYPE?.get()?.find((t) => t?.id == idData?.idType);

              if (!idType) return <Stack key={key}></Stack>

              return (
                <Stack direction="row" spacing={1} key={key} flex={1} mt={1}>
                  <Stack flex={1}>
                    {idType?.name && (
                      <DetailItem title="ID Type" value={idType.name} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                    )}
                  </Stack>
                  <Stack flex={1}>
                    {idData?.idDetails && (
                      <DetailItem title="ID No" value={idData.idDetails} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                    )}
                  </Stack>
                  <Stack flex={1}>
                    {idData?.idExp && (
                      <DetailItem title="ID Expiry Date (DD/MM/YYYY)" value={standardDateFormatter(idData.idExp)} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                    )}
                  </Stack>
                  <Stack flex={1}></Stack>
                </Stack>
              );
            })}
          </Stack>
        </Stack>
      </Stack>
      <Stack display={personalData?.taxPayer || personalData?.taxPayer === 0 ? "flex" : "none"} px={1} direction="row" width={"100%"}>
        <Stack flex={1}>
          <DetailItem title="Tax Payer" value={personalData?.taxPayer === 1 ? "Yes" : "No"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
        </Stack>
        <Stack flex={1} display={personalData?.taxPayer === 1 ? "flex" : "none"}>
          <DetailItem title="Tax Identification No" value={personalData?.individualTaxNo ? personalData?.individualTaxNo : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
        </Stack>
        <Stack flex={1}>
        </Stack>
        <Stack flex={1}></Stack>
      </Stack>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {isMainContactDetails && (
              <Grid item xs={12}>
                <DetailItem title={translate("questionMsg.addJointBorrower")} value={personalData?.addJointBorrower == 1 ? "Yes" : "No"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
              </Grid>
            )}
            <Grid item xs={12}>
              <DetailItem title={translate("questionMsg.publicEmpLoanActCovered")} value={personalData?.publicEmpLoanActCovered == 1 ? "Yes" : "No"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
            </Grid>
            <Grid item xs={12}>
              <DetailItem title={translate("questionMsg.legalWarrantAgainstCustomer")} value={personalData?.legalWarrantAgainstCustomer == 1 ? "Yes" : "No"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
            </Grid>
            {personalData?.legalWarrantAgainstCustomer == 1 && (
              <Grid item xs={12}>
                <DetailItem title="Specify" value={personalData?.specify ? personalData?.specify : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
              </Grid>
            )}
            {cacheDepositedFieldValidator() && isMainContactDetails && (
              <Grid item xs={12}>
                <DetailItem title={translate("questionMsg.cashDeposited")} value={personalData?.cashDeposited !== undefined ? (personalData?.cashDeposited == 1 ? "Yes" : "No") : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
              </Grid>
            )}
          </Grid>
          <Stack display={isMainContactDetails && status?.id === 2 ? "flex" : "none"} px={1} flex={1} mt={1}>
            <Stack>
              <Typography variant="subtitle2" color={"gray"}>Spouse Details</Typography>
            </Stack>
            <Divider></Divider>

            <Stack direction={"row"} flex={1} spacing={1} mt={1} pb={1}>
              <Stack flex={1}>
                <DetailItem title="Spouse Name in Full" value={personalData?.spouseFullName ? personalData?.spouseFullName : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
              </Stack>
              <Stack flex={1} display={personalData?.spouseOldNic ? "flex" : "none"}>
                <DetailItem title="Spouse Old NIC" value={personalData.spouseOldNic ? personalData?.spouseOldNic : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
              </Stack>
              <Stack flex={1} display={personalData?.spouseNewNic ? "flex" : "none"}>
                <DetailItem title="Spouse New NIC" value={personalData?.spouseNewNic ? personalData?.spouseNewNic : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
              </Stack>
              <Stack flex={1}>
                <DetailItem title="Spouse Employment Details" value={empStatus?.name ? empStatus?.name : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
              </Stack>
              <Stack flex={1}>
                <DetailItem title="Occupation" value={empOccupation ? empOccupation : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  </Stack>;
};

export default IdentificationDetailItems;
