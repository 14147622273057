import { Downgraded, useState } from "@hookstate/core";
import { Grid, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { inspectionFormState } from "../../../../../../configs/mainStore";
import { loadInspectionFormStructure } from "../../../../../../services/apiCalls";
import { isTabActionPrivilege } from "../../../../../../utility/helpers/ApplicationMandatoryValidations/isNotPermittedDocs";
import { CircularProgressComponent } from "../../../../ProgressComponent/ProgressComponent";
import { ErrorMessageComponent } from "../../UserCreditComponents/ErrorMessageComponent";
import InspectionHeader from "../Components/Header";
import IntermediateFormBuilder from "./IntermediateFormBuilder";

interface FormMasterBuilderProps {
  formId: string;
  applicationDataObject: {};
  onChange: (params, ...rest) => void;
  title?: string;
  otherParams?: any;
}

interface FormBuilderWrapperProps {
  formId: string;
  applicationDataObject: {};
  onChange: (params, ...rest) => void;
  onDownload: (params, ...rest) => void;
  title: string;
  onSubmit: any;
  otherParams?: any;
}

export const FormBuilderWrapper: React.FC<FormBuilderWrapperProps> = ({ formId, applicationDataObject, onChange, title, onSubmit, onDownload, otherParams = {} }: FormBuilderWrapperProps) => {
  const freezeTabs: any[] = otherParams?.tabsToEdit || [];
  let disableAccordingToTab: any = false;

  if (freezeTabs.length > 0) {
    disableAccordingToTab = freezeTabs.includes(otherParams?.innerComponentData?.id);
  }

  return (
    <InspectionHeader title={title} onSubmit={onSubmit} onDownload={onDownload} otherParams={otherParams}>
      <Grid sx={{ maxHeight: "calc(100vh - 150px)", overflowY: "scroll" }}>
        <Stack style={{ display: "flex", width: "100%", height: "100%", position: "relative" }} py={2} pb={5}>
          {isTabActionPrivilege(disableAccordingToTab) ? <Stack style={{ width: "100%", height: "100%", position: "absolute", left: 0, top: 0, backgroundColor: "255, 255, 255, 0.1", zIndex: 10 }}></Stack> : <></>}
          <FormMasterBuilder formId={formId} applicationDataObject={applicationDataObject} onChange={onChange} otherParams={otherParams} />
        </Stack>
      </Grid>
    </InspectionHeader>
  );
};

export const FormMasterBuilder: React.FC<FormMasterBuilderProps> = ({ formId, applicationDataObject, onChange, otherParams = {} }: FormMasterBuilderProps) => {
  const [formJson, setFormJson]: Array<any> = React.useState({});
  const fetching = useState<boolean>(false);
  const inspectionFormStore = useState<any>(inspectionFormState);
  const { isLoading = false } = otherParams;

  const fetchData = async () => {
    const inspectionFormStoreDowngraded = inspectionFormStore.attach(Downgraded).get();

    fetching.set(true);
    if (inspectionFormStoreDowngraded.hasOwnProperty(formId)) {
      setFormJson(inspectionFormStoreDowngraded[formId]);
    } else {
      await getFormJson();
    }
    fetching.set(false);
  };

  useEffect(() => {
    fetchData();

    return () => {
      setFormJson({});
    };
  }, []);

  const getFormJson = async () => {
    try {
      const res: any = await loadInspectionFormStructure(formId);
      if (res?.status === 1) {
        inspectionFormStore[formId].set(res.data);
        setFormJson(res.data ?? {});
      } else setFormJson({});
    } catch (error) { }
  };

  if (fetching.get() || isLoading) {
    return (
      <CircularProgressComponent
        size={30}
        sx={{
          marginTop: "20px",
          marginBottom: "20px",
          marginLeft: "auto",
          marginRight: "auto",
          zIndex: 100,
        }}
      />
    );
  }

  if (Object.keys(formJson).length === 0) {
    return <ErrorMessageComponent headMessage={"ERROR"} errorMessage={"Error On Fetching Data"} />;
  }

  if (formJson?.children.length === 0) {
    return <ErrorMessageComponent headMessage={"ERROR"} errorMessage={"Error On Fetching Data"} />;
  }

  return React.createElement(IntermediateFormBuilder, {
    formChildren: formJson?.children,
    value: applicationDataObject,
    onChange,
    otherParams,
  });
};
