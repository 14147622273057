import { Stack, Typography } from "@mui/material";

const MainSectionBuilder = ({ metaData }) => {
    const { render = {}, dataKey, title } = metaData;
    const { visibleInForm, visibleInPreview } = render;

    if (!visibleInForm && !visibleInPreview) {
        return <></>;
    }

    return (
        <Stack
            key={"Stack" + dataKey}
            width={"100%"}
            style={{
                backgroundColor: `#7fb4de3d`,
                margin: "2px",
                borderRadius: "5px",
            }}
        >
            <Stack key={"childStack" + dataKey}>
                <Typography key={"Typography" + dataKey}
                    className="basic-font basic-font-color-bold font-size-16 padding-m align-left">{title}</Typography>
            </Stack>
        </Stack>
    );
};

export default MainSectionBuilder;
