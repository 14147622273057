import { Box, Button, Grid, Stack } from "@mui/material";
import React from "react";
import { ButtonComponent } from "../../../InputComponents/ButtonComponent/ButtonComponent";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { useState } from "@hookstate/core";
import { TextBoxComponent } from "../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { userDetails, workflowMasterData } from "../../../../configs/mainStore";
import { closeGlobalModal } from "../../../OtherComponents/GlobalModal/GlobalModal";
import { addToaster } from "../../../OtherComponents/GlobalToast";
import { responseSuccessCode } from "../../../../utility/other";
import ModalInputComponent from "../../../InputComponents/ModalInputComponent/ModalInputComponent";
import { closeBtn } from "../../../../styles";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AutoCompleteProxyComponent from "../../../InputComponents/AutoCompleteComponent/AutoCompleteProxyComponent";
import { signatureDocumentTypes } from "../../../../configs/constants/contants";

const SignatureManualUploadModal = (params) => {
  const [documentTypeList, setDocumentTypeList]: any[] = React.useState(signatureDocumentTypes || []);
  const initialData = { signedContract: "", documentType: "", identificationDocument: "" };
  const signatureState: any = useState(initialData);
  const showErrors = useState(false);
  const { wf_system_users }: any = useState(workflowMasterData);
  const loginUser = useState(userDetails);

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const submitSignatureVerify = async () => {
    // const errorState = validateSave();
    // if (errorState) {
    //   showErrors.set(true);
    // } else {
    //   const taskStat = Object.keys(taskStatus)?.find((sts) => taskStatus[sts]?.key == "open");
    //   const data = {
    //     applicationId: params?.applicationId,
    //     createdBy: loginUser?.userId?.get(),
    //     workflowId: params?.currentWorkflow,
    //     stageId: params?.currentWorkflowStage,
    //     taskStatus: taskStat,
    //     ...taskState.get(),
    //   };
    //   const res = await saveTaskData(data);
    //   if (res?.status === responseSuccessCode) {
    //     addToaster({
    //       status: "success",
    //       title: "Success",
    //       message: res?.data?.msg,
    //     });
    //     // calling the modal callback passing necessary arguments
    //     if (params.callBackOnAction && params.callBackOnAction instanceof Function) {
    //       params.callBackOnAction(res?.data?.data);
    //     }
    //   } else {
    //     addToaster({
    //       status: "error",
    //       title: "Error",
    //       message: res?.data?.msg,
    //     });
    //   }
    //   closeGlobalModal();
    // }
  };

  const closeTask = () => {
    closeGlobalModal();
  };

  // const validateSave = () => {
  //   return signatureState.signedContract.get() === "" || signatureState.identificationDocument.get() === "";
  // };

  const resetError = () => {
    if (showErrors.get()) {
      showErrors.set(false);
    }
  };

  return (
    <Box>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <div className="basic-font font-size-18 basic-font-color-bold">Upload Manual Contract</div>
      </Stack>

      <Grid container spacing={2} mt={1} className="modal-text-field">
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container item xs={12}>
                <Grid item xs={9} mb={2}>
                  <ModalInputComponent
                    title="Manually Signed Contract"
                    component={
                      <TextBoxComponent
                        value={signatureState?.signedContract?.get()}
                        placeholder={"Manually Signed Contract"}
                        onChange={(e) => {
                          resetError();
                          signatureState.signedContract.set(e.target.value);
                        }}
                        variant="outlined"
                        size="small"
                        multiline={false}
                      />
                    }
                    isMandatory={true}
                    showErrors={showErrors.get() && signatureState.signedContract.get() === ""}
                  />
                </Grid>
                <Grid item xs={3} mb={3} sx={{ display: "flex", justifyContent: "flex-end", alignContent: "flex-start", alignItems: "flex-start" }}>
                  <Button component="label" size={"medium"} variant="contained" startIcon={<CloudUploadIcon />}>
                    <VisuallyHiddenInput type="file" />
                  </Button>
                </Grid>
                <Grid item xs={9}>
                  <Grid item xs={12} mb={2}>
                    <ModalInputComponent
                      title="Document Type"
                      component={
                        <AutoCompleteProxyComponent
                          options={documentTypeList}
                          fullWidth={true}
                          onChange={(e) => {
                            resetError();
                            return signatureState.documentType.set(e ? e.id : null);
                          }}
                          label={"Document Type"}
                          value={signatureState?.documentType?.get()}
                          cls="select_basic"
                          size="small"
                        />
                      }
                      isMandatory={true}
                      showErrors={showErrors.get() && !signatureState.documentType.get()}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={9} mb={2}>
                  <ModalInputComponent
                    title="Identification Document"
                    component={
                      <TextBoxComponent
                        value={signatureState?.identificationDocument?.get()}
                        placeholder={"Identification Document"}
                        onChange={(e) => {
                          resetError();
                          signatureState.identificationDocument.set(e.target.value);
                        }}
                        variant="outlined"
                        size="small"
                        multiline={false}
                      />
                    }
                    isMandatory={true}
                    showErrors={showErrors.get() && signatureState.identificationDocument.get() === ""}
                  />
                </Grid>
                <Grid item xs={3} mb={3} sx={{ display: "flex", justifyContent: "flex-end", alignContent: "flex-start", alignItems: "flex-start" }}>
                  <Button component="label" size={"medium"} variant="contained" startIcon={<CloudUploadIcon />}>
                    <VisuallyHiddenInput type="file" />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
        <ButtonComponent title="Close" startIcon={<CloseIcon />} variant="contained" onClick={closeTask} loadingbtn={true} style={closeBtn} />
        <ButtonComponent
          startIcon={<DoneIcon />}
          title="Save"
          variant="contained"
          onClick={submitSignatureVerify}
          // disabled={disableSave()}
          loadingbtn={true}
          color="info"
        />
      </Stack>
    </Box>
  );
};

export default SignatureManualUploadModal;
