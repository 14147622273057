export const privilegeClassTypes = {
  hide: "hide",
  disabled: "disabled",
};

export const privilegeKeys = {
  CREDIT_AUDIT_CREATE: "CREDIT_AUDIT_CREATE",
  FUND_REL_APPR: "FUND_REL_APPR",
  CREATE_EMAIL: "CREATE_EMAIL",
  CREATE_SMS: "CREATE_SMS",
  NAV_CREATE_LEAD: "NAV_CREATE_LEAD",
  NAV_INITIATED_OWN_LEADS: "NAV_INITIATED_OWN_LEADS",
  NAV_VIEW_LEAD: "NAV_VIEW_LEAD",
  NAV_ASSIGNED_ME: "NAV_ASSIGNED_ME",
  NAV_PRODUCT_MANAGE: "NAV_PRODUCT_MANAGE",
  NAV_VIEW_APPLICATION: "NAV_VIEW_APPLICATION",
  NAV_PRODUCT_TYPE: "NAV_PRODUCT_TYPE",
  NAV_PRODUCT_MAP_CONFIG: "NAV_PRODUCT_MAP_CONFIG",
  NAV_SECTOR: "NAV_SECTOR",
  NAV_SCHEME: "NAV_SCHEME",
  NAV_VIEW_PRODUCT_MAPPING: "NAV_VIEW_PRODUCT_MAPPING",
  NAV_CONF_PRODUCT_MAPPING: "NAV_CONF_PRODUCT_MAPPING",
  NAV_MANAGE_EMAIL: "NAV_MANAGE_EMAIL",
  NAV_MANAGE_SMS: "NAV_MANAGE_SMS",
  NAV_MANAGE_WF: "NAV_MANAGE_WF",
  NAV_MANAGE_MASTER: "NAV_MANAGE_MASTER",
  NAV_MANAGE_APPLICATION_FORM: "NAV_MANAGE_APPLICATION_FORM",
  NAV_CONF_INTEREST_RATE: "NAV_CONF_INTEREST_RATE",
  NAV_REVIEW_PRODUCT_MAPPING: "NAV_REVIEW_PRODUCT_MAPPING",
  NAV_WF_PRODUCT_MAPPING: "NAV_WF_PRODUCT_MAPPING",
  NAV_WF_PRODUCT_VIEW: "NAV_WF_PRODUCT_VIEW",
  NAV_WF_REFERRAL_MAANGE: "NAV_WF_REFERRAL_MAANGE",
  NAV_VIEW_INTEREST_RATE: "NAV_VIEW_INTEREST_RATE",
  NAV_CREDIT_FILE: "NAV_CREDIT_FILE",
  NAV_MANAGE_LEADS: "NAV_MANAGE_LEADS",
  NAV_MANAGE_APPLICATIONS: "NAV_MANAGE_APPLICATIONS",
  NAV_MANAGE_REFERRALS: "NAV_MANAGE_REFERRALS",
  NAV_MANAGE_TASKS: "NAV_MANAGE_TASKS",
  NAV_INITIATED_BY_OWN: "NAV_INITIATED_BY_OWN",
  NAV_INITIATED_BY_BRANCH: "NAV_INITIATED_BY_BRANCH",
  NAV_APP_VISIBLE_GROUP: "NAV_APP_VISIBLE_GROUP",
  NAV_RULE_CURD: "NAV_RULE_CURD",
  NAV_FIELD_RULE_MAP: "NAV_FIELD_RULE_MAP",
  NAV_WF_RULE_MAP: "NAV_WF_RULE_MAP",
  NAV_FIELD_RULE_MAP_2: "NAV_FIELD_RULE_MAP_2",
  NAV_INVOKE_TC: "NAV_INVOKE_TC",
  NAV_ADVANCED_SEARCH: "NAV_ADVANCED_SEARCH",
  NAV_RULE_USR_BRANCH_CONFIG: "NAV_RULE_USR_BRANCH_CONFIG",
  ASSIGNED_TO_ME_REFERANCE: "ASSIGNED_TO_ME_REFERANCE",
  LEAD_LIST_REFERENCE: "LEAD_LIST_REFERENCE",
  NIC_SEARCH_REFERENCE_PENDING_LEADS: "NIC_SEARCH_REFERENCE_PENDING_LEADS",
  NIC_SEARCH_REFERENCE_COMPLETED_APPLICATIONS: "NIC_SEARCH_REFERENCE_COMPLETED_APPLICATIONS",
  LEAD_NIC_SEARCH_BUTTON: "LEAD_NIC_SEARCH_BUTTON",
  LEAD_NIC_CLEAR_BUTTON: "LEAD_NIC_CLEAR_BUTTON",
  LEAD_NIC_SEARCH_INPUT: "LEAD_NIC_SEARCH_INPUT",
  LEAD_CREATE_BUTTON: "LEAD_CREATE_BUTTON",
  RESET_USER_PASSWORD: "RESET_USER_PASSWORD",
  MANAGE_APPLICATION_SEARCH_BUTTON: "MANAGE_APPLICATION_SEARCH_BUTTON",
  MANAGE_APPLICATION_CLEAR_BUTTON: "MANAGE_APPLICATION_CLEAR_BUTTON",
  APPLICATION_ADVANCE_SEARCH_BUTTON: "APPLICATION_ADVANCE_SEARCH_BUTTON",
  CREATE_NEW_PRODUCT_TYPE_BUTTON: "CREATE_NEW_PRODUCT_TYPE_BUTTON",
  EDIT_PRODUCT_TYPE_BUTTON: "EDIT_PRODUCT_TYPE_BUTTON",
  RESET_PRODUCT_TYPE_BUTTON: "RESET_PRODUCT_TYPE_BUTTON",
  SUBMIT_NEW_PRODUCT_TYPE_BUTTON: "SUBMIT_NEW_PRODUCT_TYPE_BUTTON",
  CREATE_NEW_PRODUCT_SECTOR_BUTTON: "CREATE_NEW_PRODUCT_SECTOR_BUTTON",
  RESET_PRODUCT_SECTOR_BUTTON: "RESET_PRODUCT_SECTOR_BUTTON",
  SUBMIT_NEW_PRODUCT_SECTOR_BUTTON: "SUBMIT_NEW_PRODUCT_SECTOR_BUTTON",
  CREATE_NEW_PRODUCT_SCHEME_BUTTON: "CREATE_NEW_PRODUCT_SCHEME_BUTTON",
  RESET_PRODUCT_SCHEME_BUTTON: "RESET_PRODUCT_SCHEME_BUTTON",
  SUBMIT_NEW_PRODUCT_SCHEME_BUTTON: "SUBMIT_NEW_PRODUCT_SCHEME_BUTTON",
  SAVE_MAPPING_PRODUCT_TYPE_BUTTON: "SAVE_MAPPING_PRODUCT_TYPE_BUTTON",
  SAVE_MAPPING_PRODUCT_SCHEME_BUTTON: "SAVE_MAPPING_PRODUCT_SCHEME_BUTTON",
  SAVE_MAPPING_PRODUCT_CHANGE_BUTTON: "SAVE_MAPPING_PRODUCT_CHANGE_BUTTON",
  VIEW_EMAIL_TEMPLATE: "VIEW_EMAIL_TEMPLATE",
  EDIT_EMAIL_TEMPLATE: "EDIT_EMAIL_TEMPLATE",
  RESET_EMAIL_TEMPLATE_BUTTON: "RESET_EMAIL_TEMPLATE_BUTTON",
  RESET_SMS_TEMPLATE: "RESET_SMS_TEMPLATE",
  CERATE_NEW_WF_BUTTON: "CERATE_NEW_WF_BUTTON",
  ASSIGN_TAB_SUBMIT_BUTTON: "ASSIGN_TAB_SUBMIT_BUTTON",
  EDIT_WF_BUTTON: "EDIT_WF_BUTTON",
  VIEW_WF_BUTTON: "VIEW_WF_BUTTON",
  LIST_WF_BUTTON: "LIST_WF_BUTTON",
  WF_MAPPING_ASSIGN: "WF_MAPPING_ASSIGN",
  WF_INTEREST_PRODUCT_MAPPING_ASSIGN: "WF_INTEREST_PRODUCT_MAPPING_ASSIGN",
  WF_MAPPING_EDIT: "WF_MAPPING_EDIT",
  EDIT_PRODUCT_INTEREST_RATE_EDIT: "EDIT_PRODUCT_INTEREST_RATE_EDIT",
  CREATE_USER_GROUP: "CREATE_USER_GROUP",
  APPLICATIONS_REPORTS: "APPLICATIONS_REPORTS",
  APPLICATIONS_REPORTS_REJECTED_WITHDRAWN: "APPLICATIONS_REPORTS_REJECTED_WITHDRAWN",
  CAU_REPORT: "CAU_REPORT",
  APPLICATIONS_REPORTS_DISBURSED: "APPLICATIONS_REPORTS_DISBURSED",
  RESET_USER_GROUP_BUTTON: "RESET_USER_GROUP_BUTTON",
  VIEW_ADD_USER: "VIEW_ADD_USER",
  VIEW_GROUP_PRIVILEGE: "VIEW_GROUP_PRIVILEGE",
  EDIT_USER_GROUP: "EDIT_USER_GROUP",
  NAV_MANUAL_ASS_CONFIGURATION: "NAV_MANUAL_ASS_CONFIGURATION",
  NAV_MANUAL_ASSIGNMENT: "NAV_MANUAL_ASSIGNMENT",
  DOC_MANAGE_SYSTEM_VIEW_DOCUMENT: "DOC_MANAGE_SYSTEM_VIEW_DOCUMENT",
  DOC_MANAGE_SYSTEM_UPLOAD_REPLACE_DOCUMENT: "DOC_MANAGE_SYSTEM_UPLOAD_REPLACE_DOCUMENT",
  DOC_MANAGE_SYSTEM_APPROVE_REJECT_DOCUMENT: "DOC_MANAGE_SYSTEM_APPROVE_REJECT_DOCUMENT",
  APPLICATION_TAB_VIEW_APPLICATION: "APPLICATION_TAB_VIEW_APPLICATION",
  APPLICATION_TAB_EDIT_APPILCATION: "APPLICATION_TAB_EDIT_APPILCATION",
  APPRAISAL_TAB_VIEW_APPRAISAL: "APPRAISAL_TAB_VIEW_APPRAISAL",
  APPRAISAL_TAB_EDIT_APPRAISAL: "APPRAISAL_TAB_EDIT_APPRAISAL",
  DECISION_ENGINE_TAB_VIEW: "DECISION_ENGINE_TAB_VIEW",
  DECISION_ENGINE_TAB_VIEW_SCORE_CARD: "DECISION_ENGINE_TAB_VIEW_SCORE_CARD",
  DECISION_ENGINE_TAB_VIEW_CRIB: "DECISION_ENGINE_TAB_VIEW_CRIB",
  DECISION_ENGINE_TAB_UPLOAD_CRIB: "DECISION_ENGINE_TAB_UPLOAD_CRIB",
  HISTORY_TAB_VIEW_APPLICATION_HISTORY: "HISTORY_TAB_VIEW_APPLICATION_HISTORY",
  REFERRAL_TAB_CREATE_REFERRAL: "REFERRAL_TAB_CREATE_REFERRAL",
  TASKS_TAB_CREATE_TASK: "TASKS_TAB_CREATE_TASK",
  TASKS_TAB_CREATE_FOLLOW_CHECK_LIST: "TASKS_TAB_CREATE_FOLLOW_CHECK_LIST",
  TASKS_TAB_UPDATE_FOLLOW_CHECK_LIST_CHECK: "TASKS_TAB_UPDATE_FOLLOW_CHECK_LIST_CHECK",
  NOTE_TAB_CREATE_NOTE: "NOTE_TAB_CREATE_NOTE",
  CONDITIONS_TAB_CREATE_CONDITION: "CONDITIONS_TAB_CREATE_CONDITION",
  LEGAL_TAB_CREATE_LEGAL: "LEGAL_TAB_CREATE_LEGAL",
  RISK_TAB_CREATE_RISK: "RISK_TAB_CREATE_RISK",
  FUND_RELEASE_APPROVAL_TAB_CREATE_FUND_RELEASE: "FUND_RELEASE_APPROVAL_TAB_CREATE_FUND_RELEASE",
  BANK_SERVICES_SELECT_CIF: "BANK_SERVICES_SELECT_CIF",
  BANK_SERVICES_SUBMIT_CIF: "BANK_SERVICES_SUBMIT_CIF",
  SUMMARY_OFFER_TAB_LETTER_PRINT: "SUMMARY_OFFER_TAB_LETTER_PRINT",
  SUMMARY_OFFER_TAB_INVOKE_TC: "SUMMARY_OFFER_TAB_INVOKE_TC",
  NAV_MANAGE_CBS_SCHEME_UPDATE: "NAV_MANAGE_CBS_SCHEME_UPDATE",
  NAV_DASHBOARD_APP_STATUS: "NAV_DASHBOARD_APP_STATUS",
  NAV_DASHBOARD_CAU_OFFICER: "NAV_DASHBOARD_CAU_OFFICER",
  NAV_DASHBOARD_CAU_MANAGER: "NAV_DASHBOARD_CAU_MANAGER",
  CENTRAL_CREDIT_UNIT_REPORT: "CENTRAL_CREDIT_UNIT_REPORT",
  CREDIT_ADMIN_HUB_REPORT: "CREDIT_ADMIN_HUB_REPORT",
  NAV_WF_MANAGE_REFERRAL: "NAV_WF_MANAGE_REFERRAL",
  NAV_WF_MANAGE_STAGE: "NAV_WF_MANAGE_STAGE",
  NAV_APP_CONTROL_TAB: "NAV_APP_CONTROL_TAB",
  NAV_PRODUCT_CONFIG: "NAV_PRODUCT_CONFIG",
  NAV_USER_MANAGEMENT: "NAV_USER_MANAGEMENT",
  NAV_USER_CONFIG: "NAV_USER_CONFIG",
  NAV_USER_GROUP_LIMIT_CONFIG: "NAV_USER_GROUP_LIMIT_CONFIG",
  NAV_USER_PRIVILEGE_AND_ASSIGNMENT: "NAV_USER_PRIVILEGE_AND_ASSIGNMENT",
  NAV_PRIVILEGE_CONFIGURATION: "NAV_PRIVILEGE_CONFIGURATION",
  NAV_SALES_SUPPORT_CALCULATOR: "NAV_SALES_SUPPORT_CALCULATOR",
  NAV_MANAGE_SYSTEM_CACHE: "NAV_MANAGE_SYSTEM_CACHE",
  NAV_LEAD_ADVANCED_SEARCH: "NAV_LEAD_ADVANCED_SEARCH",
  SEC_DOC_EDIT_SAVE_CHANGES: "SEC_DOC_EDIT_SAVE_CHANGES",
  SEC_DOC_PRINT_SECURITY_DOC: "SEC_DOC_PRINT_SECURITY_DOC",
  SEC_DOC_UPLOAD_SIGNED_DOCUMENTS: "SEC_DOC_UPLOAD_SIGNED_DOCUMENTS",
  SEC_DOC_APPROVE_REJECT_DOC: "SEC_DOC_APPROVE_REJECT_DOC",
  INVESTMENT_ACCOUNT_SUBMIT: "INVESTMENT_ACCOUNT_SUBMIT",
  COMPULSORY_AMOUNT_SAVE: "COMPULSORY_AMOUNT_SAVE",
  MARKETING_CHANNEL_CODE_SAVE: "MARKETING_CHANNEL_CODE_SAVE",
  DIRECT_SELLING_AGENT_CODE_SAVE: "DIRECT_SELLING_AGENT_CODE_SAVE",
  VILLAGE_LEADER: "VILLAGE_LEADER",
  NAV_MANAGE_INSPCETION_REPORTS: "NAV_MANAGE_INSPCETION_REPORTS",
  RESET_INSPECTION_TEMPLATE_BUTTON: "RESET_INSPECTION_TEMPLATE_BUTTON",
  CREATE_INSPECTION_TEMPLATE: "CREATE_INSPECTION_TEMPLATE",
  EDIT_INSPECTION_TEMPLATE: "EDIT_INSPECTION_TEMPLATE",
  VIEW_INSPECTION_TEMPLATE: "VIEW_INSPECTION_TEMPLATE",
  EDIT_INSPECTION_TEMPLATE_DETAILS: "EDIT_INSPECTION_TEMPLATE_DETAILS",
  SECTION_DOCUMENT_MAPPING: "SECTION_DOCUMENT_MAPPING",
  DOCUMENT_CONFIGURATION: "DOCUMENT_CONFIGURATION",
  PRODUCT_SECTION_MAPPING: "PRODUCT_SECTION_MAPPING",
  CONDITION_MANAGE: "CONDITION_MANAGE",
  WORKFLOW_CONFIGURATION: "WORKFLOW_CONFIGURATION",
  STAGE_PRIVILEGES: "STAGE_PRIVILEGES",
  DETACH_DOCUMENT: "DETACH_DOCUMENT",
  UNDERWRITING_POLICY_CONFIGURATION: "UNDERWRITING_POLICY_CONFIGURATION",
  MANAGE_CHARGES: "MANAGE_CHARGES",
  CHARGES_PRODUCT: "CHARGES_PRODUCT",
  NAV_PENDING_LEAD_ADVNACED_SEARCH: "NAV_PENDING_LEAD_ADVNACED_SEARCH",
  MANAGE_WATCHLIST: "MANAGE_WATCHLIST",
  ADD_WATCHLIST_RULES: "ADD_WATCHLIST_RULES",
  REPORT_CONFIG: "REPORT_CONFIG",
  SCORECARD_REPORT: "SCORECARD_REPORT",
  WORKLOG_REPORT: "WORKLOG_REPORT",
  REVERT_SUMMARY_REPORT: "REVERT_SUMMARY_REPORT",
  REVIEW_OFFICER_PERFORMANCE_REPORT: "REVIEW_OFFICER_PERFORMANCE_REPORT",
  LOAN_DISBURSEMENT_GROWTH_REPORT: "LOAN_DISBURSEMENT_GROWTH_REPORT",
  PRODUCT_DISBURSEMENT_GROWTH_DASHBOARD: "PRODUCT_DISBURSEMENT_GROWTH_DASHBOARD",
  SECURITY_DISBURSEMENT_GROWTH_REPORT: "SECURITY_DISBURSEMENT_GROWTH_REPORT",
  FUND_SOURCE_DISBURSEMENT_GROWTH_REPORT: "FUND_SOURCE_DISBURSEMENT_GROWTH_REPORT",
  REPAYMENT_PATTERN_REPORT: "REPAYMENT_PATTERN_REPORT",
  RENEWAL_OF_THE_INSURANCE_REPORT: "RENEWAL_OF_THE_INSURANCE_REPORT",
  SECURITY_COVERED_RATIOS_REPORT: "SECURITY_COVERED_RATIOS_REPORT",
  NAV_PRIOR_APPROVALS: "NAV_PRIOR_APPROVALS",
  NAV_REJECTED_LEADS: "NAV_REJECTED_LEADS",
  PRODUCT_LOAN_PURPOSE_MAPPING: "PRODUCT_LOAN_PURPOSE_MAPPING",
  NAV_PRIOR_REJECTED: "NAV_PRIOR_REJECTED",
  NAV_DISBURSED_LODGED_APPLICATIONS: "NAV_DISBURSED_LODGED_APPLICATIONS",
  EXPENSE_TYPE_PRODUCT_MAPPING: "EXPENSE_TYPE_PRODUCT_MAPPING",
  NAV_SYS_ADMIN_MONITOR: "NAV_SYS_ADMIN_MONITOR",
  CREATE_USER_ACCOUNT: "CREATE_USER_ACCOUNT",
  MANAGE_CONDITIONS: "MANAGE_CONDITIONS",
  CONDITIONS_PRODUCT_MAPPING: "CONDITIONS_PRODUCT_MAPPING",
  CHECKLIST_DATA_VERIFICATION: "CHECKLIST_DATA_VERIFICATION",
  AUDIT_LOG: "AUDIT_LOG",
  DIFFER_APPLICATIONS: "DIFFER_APPLICATIONS",
  SCORE_CARD_: "DIFFER_APPLICATIONS",
  SCORE_CARD_PRODUCT_CONFIGURATION: "SCORE_CARD_PRODUCT_CONFIGURATION",
  CREATE_SCORE_CARD_TEMPLATE: "CREATE_SCORE_CARD_TEMPLATE",
  NAV_MANAGE_WITHDRAWN_APPLICATIONS: "NAV_MANAGE_WITHDRAWN_APPLICATIONS",
  NAV_INSPECTION_LIMIT_CONFIGURATION: "NAV_INSPECTION_LIMIT_CONFIGURATION",
  NAV_SYS_ADMIN_RELEASE: "NAV_SYS_ADMIN_RELEASE",
  NAV_SYS_ADMIN_CONFIG: "NAV_SYS_ADMIN_CONFIG",
  NAV_HELP_DLA_CONFIG: "NAV_HELP_DLA_CONFIG",
  NAV_HELP_GROUP_PRIVILEGES: "NAV_HELP_GROUP_PRIVILEGES",
  NAV_HELP_NEW_USER: "NAV_HELP_NEW_USER",
  NAV_HELP_CREDIT_FILE: "NAV_HELP_CREDIT_FILE",
  NAV_HELP_WORKFLOW: "NAV_HELP_WORKFLOW",
  NAV_HELP_APPLICATION: "NAV_HELP_APPLICATION",
  NAV_HELP_WHATSNEW: "NAV_HELP_WHATSNEW",
  NAV_HELP_LEAD: "NAV_HELP_LEAD",
  NAV_WF_MANAGE_STAGE_ACTIONS: "NAV_WF_MANAGE_STAGE_ACTIONS",
  WORKFLOW_STAGE_TASK_CONFIG: "WORKFLOW_STAGE_TASK_CONFIG",
  LOAN_DISBURSEMENT_RESET: "LOAN_DISBURSEMENT_RESET",
  SYS_CONTROL_TROUBLESHOORT: "SYS_CONTROL_TROUBLESHOORT",
  APPLICATIONS_ASSIGNED_TO_USERS: "APPLICATIONS_ASSIGNED_TO_USERS"
};
