import Stack from "@mui/material/Stack/Stack";
import React from "react";
import IntermediateFormBuilder from "../../IntermediateFormBuilder";

const Row = ({ metaData, alphaChildren, value, onChange, otherParams }) => {
  return (
    <Stack direction={"row"} flexWrap={"wrap"} width={"100%"} display={"flex"}>
      {React.createElement(IntermediateFormBuilder, {
        formChildren: alphaChildren,
        value,
        onChange,
        otherParams,
      })}
    </Stack>
  );
};

export default Row;
